























































































































import Vue, { PropType } from "vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { EditableProjectJournal } from "@/ts/objects/project/editable/EditableProjectJournal";
import { messages } from "@/ts/const/Messages";
import { ProjectStore } from "@/store/ProjectStore";
import { Err } from "@/ts/objects/Err";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import firebase from "firebase/app";
import "firebase/storage";
import JournalFilesViewItem from "@/components/journalfile/JournalFilesViewItem.vue";
import JournalFileUploadButton from "@/components/journalfile/JournalFileUploadButton.vue";
import JournalFilePreviewItem from "@/components/journalfile/JournalFilePreviewItem.vue";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import TipBlock from "@/components/TipBlock.vue";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";

export default Vue.extend({
  name: "ProjectStudentViewJournalsT",
  components: {
    TipBlock,
    LoadingBlock,
    MessageView,
    DisableableAutoResizeTextarea,
    JournalFilesViewItem,
    JournalFileUploadButton,
    JournalFilePreviewItem
  },
  props: {
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
    projectRepository: { type: Object as PropType<ProjectRepository>, required: true }
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.storage = this.$storage ?? null;
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave
    });
    this.reloadData(this.$route.params["projectStudentUserId"]);
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      this.reloadData(to.params["projectStudentUserId"]);
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;
    storage: firebase.storage.Storage | null;

    journals: EditableProjectJournal[] | null;

    filesViewJournal: EditableProjectJournal | null;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,
      storage: null,

      journals: null,

      filesViewJournal: null,

      pageLeaveService: null
    };
  },
  computed: {
    needSave(): boolean {
      const journals = this.journals;
      if (journals === null) return false;
      return journals.some(j => j.needSave());
    }
  },
  methods: {
    async reloadData(studentUserId: string | undefined) {
      log.debug(`reloadData: studentUserId=${studentUserId}`);
      if (studentUserId === undefined) {
        this.messageView = { message: messages.pleaseSelectStudent };
        return;
      }
      const project = await this.projectStore.project.getDataWithTimeout();
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectProject };
        return;
      }
      if (!project.published) {
        this.messageView = { message: messages.projectUnpublished };
        return;
      }

      this.journals = null;
      this.messageView = null;

      const resp = await this.projectRepository.listEditableProjectJournals(
        project.projectId,
        studentUserId,
        true,
        false
      );
      if (resp instanceof Err) {
        log.debug("Error loading project or journals!");
        this.messageView = { message: messages.failedToLoadData };
        return;
      }

      this.journals = resp.editableJournals;
      this.messageView = null;
    },
    async saveAll(force: boolean): Promise<SaveResult> {
      const journals = this.journals;
      if (journals === null) return emptySaveResult();
      log.debug("SAVING!");
      return flattenSaveResults(await Promise.all(journals.map(j => j.saveAllChanges(force))));
    },
    setFilesView(journalRname: string | null) {
      const journals = this.journals;
      if (journalRname === null || journals === null) {
        this.filesViewJournal = null;
        return;
      }
      const journal = journals.find(j => j.self === journalRname);
      if (journal === undefined) {
        this.filesViewJournal = null;
        return;
      }
      this.filesViewJournal = journal;
    },
    isMyFilesViewOpen(journalRname: string): boolean {
      const filesViewJournal = this.filesViewJournal;
      if (filesViewJournal === null) return false;
      return filesViewJournal.self === journalRname;
    }
  }
});
