



















































import Vue, { PropType } from "vue";
import { ActionLog } from "@/ts/objects/common/ActionLog";
import ActionLogRow from "@/views/home/student/components/ActionLogRow.vue";
import Activity from "@/views/activity/student/Activity.vue";
import { StudentInfo } from "@/ts/StudentInfo";
import { AppStateStore } from "@/store/AppStateStore";
import { StudentClassInfo } from "@/ts/objects/common/StudentClassInfo";
import { Err } from "@/ts/objects/Err";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { DataSlot } from "@/ts/DataSlot";
import log from "loglevel";
import { LogRepository } from "@/ts/repositories/LogRepository";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { ActivityRepository } from "@/ts/repositories/ActivityRepository";

export default Vue.extend({
  name: "Home",
  components: { LoadingBlock, ActionLogRow, Activity },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    activityRepository: { type: Object as PropType<ActivityRepository>, required: true },
    logRepository: { type: Object as PropType<LogRepository>, required: true }
  },
  created() {
    this.reloadActionLogs();
  },
  data(): {
    actionLogs: DataSlot<ActionLog[]>;
    debouncedReloadActionLogs: () => void;
  } {
    return {
      actionLogs: new DataSlot<ActionLog[]>(),
      debouncedReloadActionLogs: () => {}
    };
  },
  computed: {
    studentInfo(): StudentInfo | null {
      return this.appStateStore.studentOrGuardianState?.studentInfo() ?? null;
    },
    studentName(): string {
      return this.studentInfo?.name ?? "";
    },
    studentIconUrl(): string {
      return this.studentInfo?.iconUrl ?? "";
    },
    studentClassInfo(): StudentClassInfo | null {
      return this.studentInfo?.classOfSchoolYear(this.appStateStore.currentQuarter.schoolYear) ?? null;
    }
  },
  methods: {
    async reloadActionLogs(): Promise<void> {
      const studentInfo = this.studentInfo;
      if (studentInfo === null) return;

      this.actionLogs.startLoadingWith(async () => {
        const resp = await this.logRepository.loadActionLogs([studentInfo.studentUserId]);
        log.debug(`watch:studentInfo: resp=${JSON.stringify(resp)}`);
        if (resp instanceof Err) {
          return null;
        }
        return resp;
      });
    }
  }
});
