import {
  partialArgsToSolanTestDataSet,
  SolanTestDataPartialArgs,
  SolanTestDataSet
} from "@/test-tools/SolanTestDataTypes";

export function getSolanTestData(): SolanTestDataSet {
  const projects: SolanTestDataPartialArgs = {
    project000: {
      studentUserId: "student000",
      schoolYear: 2021,
      name: "SOLANプロジェクト000",
      description: "テーマ000を決めた理由です。",
      started: false,
      completed: false,
      studentInputLocked: false,
      guardianInputLocked: true,
      lookback: {
        studentComment: "ふりかえり000の児童生徒コメント",
        studentRating: "C",
        teacherComment: "ふりかえり000の教師コメント",
        teacherRating: "B",
        teacherInputPublished: true,
        guardianComment: "ふりかえり000の保護者コメント"
      },
      rubrics: {
        rubric000: {
          process: 0,
          learningActivity: "ルーブリック000-学習活動",
          viewPointS: "ルーブリック000-観点S",
          viewPointA: "ルーブリック000-観点A",
          viewPointB: "ルーブリック000-観点B",
          viewPointC: "ルーブリック000-観点C",
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journal: {
            studentComment: "ルーブリック000-児童生徒コメント",
            studentRating: "A",
            teacherComment: "ルーブリック000-教師コメント",
            teacherRating: "S",
            teacherInputPublished: true,
            journalFiles: {
              journalFile000: {
                filename: "myfilename000_000",
                createdAt: "2000-01-01T00:00:00Z",
                updatedAt: "2000-01-01T00:00:00Z"
              }
            }
          }
        },
        rubric001: {
          process: 1,
          learningActivity: "ルーブリック001-学習活動",
          viewPointS: "ルーブリック001-観点S",
          viewPointA: "ルーブリック001-観点A",
          viewPointB: "ルーブリック001-観点B",
          viewPointC: "ルーブリック001-観点C",
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journal: {
            studentComment: "ルーブリック001-児童生徒コメント",
            studentRating: "",
            teacherComment: "ルーブリック001-教師コメント",
            teacherRating: "",
            teacherInputPublished: false,
            journalFiles: {
              journalFile000: {
                filename: "myfilename001_000",
                createdAt: "2000-01-01T00:00:00Z",
                updatedAt: "2000-01-01T00:00:00Z"
              }
            }
          }
        },
        rubric002: {
          process: 1,
          learningActivity: "ルーブリック002-学習活動",
          viewPointS: "ルーブリック002-観点S",
          viewPointA: "ルーブリック002-観点A",
          viewPointB: "ルーブリック002-観点B",
          viewPointC: "ルーブリック002-観点C",
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journal: {
            studentComment: "",
            studentRating: "",
            teacherComment: "",
            teacherRating: "",
            teacherInputPublished: false,
            journalFiles: {}
          }
        }
      }
    },
    project001: {
      studentUserId: "student000",
      schoolYear: 2021,
      name: "SOLANプロジェクト001",
      description: "テーマ001を決めた理由です。",
      started: true,
      completed: false,
      studentInputLocked: false,
      guardianInputLocked: false,
      lookback: {
        studentComment: "ふりかえり001の児童生徒コメント",
        studentRating: "",
        teacherComment: "ふりかえり001の教師コメント",
        teacherRating: "S",
        teacherInputPublished: false,
        guardianComment: "ふりかえり001の保護者コメント"
      }
    }
  };
  return partialArgsToSolanTestDataSet(projects);
}
