
















import Vue, { PropType } from "vue";
import { MonthValue } from "@/ts/utils";
import CurriculumListNECHeaderPublishButton from "@/views/curriculum/teacher/CurriculumListNEC/components/CurriculumListNECHeaderPublishButton/CurriculumListNECHeaderPublishButton.vue";
import CurriculumListNECHeaderMonthEditButton from "@/views/curriculum/teacher/CurriculumListNEC/components/CurriculumListNECHeaderMonthEditButton/CurriculumListNECHeaderMonthEditButton.vue";
import { RatingValue } from "@/ts/objects/common/Rating";
import { CurriculumListNECMonthPublishState } from "@/views/curriculum/teacher/CurriculumListNEC/CurriculumListNECModel";

export default Vue.extend({
  name: "CurriculumListNECEvaluationHeaderCell",
  components: { CurriculumListNECHeaderMonthEditButton, CurriculumListNECHeaderPublishButton },
  props: {
    month: { type: Number as PropType<MonthValue>, required: true },
    publishState: { type: String as PropType<CurriculumListNECMonthPublishState>, required: true },
    width: { type: Number, required: true },
    onChangeMonthPublishState: {
      type: Function as PropType<(month: MonthValue, published: boolean) => void>,
      required: true
    },
    onFillAllEvaluationsOfMonth: {
      type: Function as PropType<(month: MonthValue, rating: RatingValue, overwrite: boolean) => void>,
      required: true
    },
    onChangeFilterState: {
      type: Function as PropType<(month: MonthValue, filtering: boolean) => void>,
      required: true
    }
  },
  data(): {
    filtering: boolean;
  } {
    return {
      filtering: false
    };
  },
  methods: {
    changeFilterState() {
      const newState = !this.filtering;
      this.filtering = newState;
      this.onChangeFilterState(this.month, newState);
    }
  }
});
