import { NECurriculum, NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { Color } from "@/ts/objects/common/Color";
import { NECEvaluation } from "@/ts/objects/curriculum/value/NECEvaluation";
import { EECurriculum, EECurriculumTree } from "@/ts/objects/curriculum/value/EECurriculum";
import { Grade } from "@/ts/objects/common/Grade";
import {
  EECJournal,
  EECJournalFile,
  EECJournalFileTree,
  EECJournalStudent,
  EECJournalStudentTree,
  EECJournalTree
} from "@/ts/objects/curriculum/value/EECJournal";
import { isMonthValue, isNullish, MonthValue, monthValues } from "@/ts/utils";
import { RatingValue } from "@/ts/objects/common/Rating";
import { EditableNECContent } from "@/ts/objects/curriculum/editable/EditableNECContent";
import { CurriculumRepositoryMock } from "@/test-tools/mocks/CurriculumRepositoryMock";
import { EditableNECContentMonth } from "@/ts/objects/curriculum/editable/EditableNECContentMonth";
import {
  EditableEECJournal,
  EditableEECJournalStudentTree,
  EditableEECJournalTree
} from "@/ts/objects/curriculum/editable/EditableEECJournal";
import { EECMonth, EECMonthTree } from "@/ts/objects/curriculum/value/EECMonth";
import { EECActivity, EECActivityTree } from "@/ts/objects/curriculum/value/EECActivity";
import { NECViewPoint, NECViewPointTree } from "@/ts/objects/curriculum/value/NECViewPoint";
import { NECContent, NECContentTree } from "@/ts/objects/curriculum/value/NECContent";
import { NECContentMonth, NECContentMonthTree } from "@/ts/objects/curriculum/value/NECContentMonth";
import { EditableEECActivity } from "@/ts/objects/curriculum/editable/EditableEECActivity";

export type CurriculumTestDataSet = {
  readonly neCurriculum: Record<string, NECurriculumArg>;
  readonly necEvaluation: NECEvaluation[];
  readonly eeCurriculum: Record<string, EECurriculumArg>;
  readonly eecJournalStudent: Record<string, EECJournalStudentArg>;
};

export type CurriculumTestDataPartialArgs = {
  readonly neCurriculum?: Record<string, NECurriculumPartialArg>;
  readonly necEvaluation?: NECEvaluation[];
  readonly eeCurriculum?: Record<string, EECurriculumPartialArg>;
  readonly eecJournalStudent?: Record<string, EECJournalStudentPartialArgWithIds>;
};

export function partialArgsToCurriculumTestDataSet(args: CurriculumTestDataPartialArgs): CurriculumTestDataSet {
  return {
    neCurriculum: Object.fromEntries(
      Object.entries(args.neCurriculum ?? {}).map(([necId, arg]) => [necId, new NECurriculumArg({ necId, ...arg })])
    ),
    necEvaluation: args.necEvaluation ?? [],
    eeCurriculum: Object.fromEntries(
      Object.entries(args.eeCurriculum ?? {}).map(([eecId, arg]) => [eecId, new EECurriculumArg({ eecId, ...arg })])
    ),
    eecJournalStudent: Object.fromEntries(
      Object.entries(args.eecJournalStudent ?? {}).map(([name, { eecId, studentUserId, journals }]) => [
        name,
        new EECJournalStudentArg({ eecId, studentUserId, journals })
      ])
    )
  };
}

export type NECurriculumPartialArg = {
  readonly schoolYear?: number;
  readonly grade?: Grade;
  readonly name?: string;
  readonly viewPoints?: { [viewPointId: string]: NECViewPointPartialArg };
};
export type NECurriculumPartialArgWithIds = {
  readonly necId: string;
} & NECurriculumPartialArg;

export class NECurriculumArg {
  constructor(arg: NECurriculumPartialArgWithIds) {
    this.necId = arg.necId;

    this.schoolYear = arg.schoolYear ?? 2000;
    this.grade = arg.grade ?? new Grade("e1");
    this.name = arg.name ?? "nec-name";
    this.viewPoints = Object.entries(arg.viewPoints ?? {}).map(
      ([viewPointId, viewPoint]) => new NECViewPointArg({ necId: arg.necId, viewPointId, ...viewPoint })
    );
  }

  readonly necId: string;

  readonly schoolYear: number;
  readonly grade: Grade;
  readonly name: string;
  readonly viewPoints: NECViewPointArg[];

  toObject(): NECurriculum {
    return new NECurriculum(this.necId, this.schoolYear, this.grade, this.name, null);
  }

  toTree(): NECurriculumTree {
    return new NECurriculumTree(
      this.toObject(),
      this.viewPoints.map(vp => vp.toTree())
    );
  }
}

export type NECViewPointPartialArg = {
  readonly name?: string;
  readonly color?: Color;
  readonly contents?: { [contentId: string]: NECContentPartialArg };
};
export type NECViewPointPartialArgWithIds = {
  readonly necId: string;
  readonly viewPointId: string;
} & NECViewPointPartialArg;

export class NECViewPointArg {
  constructor(arg: NECViewPointPartialArgWithIds) {
    this.necId = arg.necId;
    this.viewPointId = arg.viewPointId;

    this.name = arg.name ?? "view-point-name";
    this.color = arg.color ?? new Color(0, 0, 0);
    this.contents = Object.entries(arg.contents ?? {}).map(
      ([contentId, content]) =>
        new NECContentArg({
          necId: arg.necId,
          viewPointId: arg.viewPointId,
          contentId,
          ...content
        })
    );
  }

  readonly necId: string;
  readonly viewPointId: string;

  readonly name: string;
  readonly color: Color;
  readonly contents: NECContentArg[];

  toObject(): NECViewPoint {
    return new NECViewPoint(this.necId, this.viewPointId, this.name, this.color);
  }

  toTree(): NECViewPointTree {
    return new NECViewPointTree(
      this.toObject(),
      this.contents.map(c => c.toTree())
    );
  }

  static listFrom({
    necId,
    viewPoints = {}
  }: {
    necId: string;
    viewPoints: {
      [viewPointId: string]: NECViewPointPartialArg;
    };
  }): NECViewPointArg[] {
    return Object.entries(viewPoints).map(
      ([viewPointId, viewPoint]) => new NECViewPointArg({ necId, viewPointId, ...viewPoint })
    );
  }
}

export type NECContentPartialArg = {
  readonly name?: string;
  readonly enabledMonths?: MonthValue[];
  readonly monthBaseText?: string;
};
export type NECContentPartialArgWithIds = {
  readonly necId: string;
  readonly viewPointId: string;
  readonly contentId: string;
} & NECContentPartialArg;

export class NECContentArg {
  constructor(arg: NECContentPartialArgWithIds) {
    this.necId = arg.necId;
    this.viewPointId = arg.viewPointId;
    this.contentId = arg.contentId;

    this.name = arg.name ?? "nec-name";
    this.enabledMonths = arg.enabledMonths ?? [];
    this.monthBaseText = arg.monthBaseText ?? "month-base-text";
  }

  readonly necId: string;
  readonly viewPointId: string;
  readonly contentId: string;

  readonly name: string;
  readonly enabledMonths: MonthValue[];
  readonly monthBaseText: string;

  get resourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}`;
  }

  get monthResourceNames(): string[] {
    const rname = this.resourceName;
    return monthValues.map(month => `${rname}/months/${month}`);
  }

  toTree(): NECContentTree {
    return new NECContentTree(
      new NECContent(this.necId, this.viewPointId, this.contentId, this.name),
      monthValues.map(
        month =>
          new NECContentMonthTree(
            new NECContentMonth(
              this.necId,
              this.viewPointId,
              this.contentId,
              month,
              this.enabledMonths.includes(month),
              `${this.monthBaseText}${month}`
            )
          )
      )
    );
  }

  toEditable(repo: CurriculumRepositoryMock, savable: boolean): EditableNECContent {
    return new EditableNECContent(repo, savable, this.necId, this.viewPointId, this.contentId, this.name, "");
  }

  toEditableMonths(repo: CurriculumRepositoryMock, savable: boolean): EditableNECContentMonth[] {
    return monthValues.map(
      month =>
        new EditableNECContentMonth(
          repo,
          savable,
          this.necId,
          this.viewPointId,
          this.contentId,
          month,
          this.enabledMonths.includes(month),
          `${this.monthBaseText}${month}`,
          ""
        )
    );
  }
}

export type NECEvaluationPartialArg = {
  readonly rating?: RatingValue;
  readonly teacherInputPublished?: boolean;
};

export type EECurriculumPartialArg = {
  readonly schoolYear?: number;
  readonly grade?: Grade;
  readonly name?: string;
  readonly months?: {
    [month in MonthValue]?: EECMonthPartialArg;
  };
};

export type EECurriculumPartialArgWithIds = {
  readonly eecId: string;
} & EECurriculumPartialArg;

export class EECurriculumArg {
  constructor(arg: EECurriculumPartialArgWithIds) {
    this.eecId = arg.eecId;

    this.schoolYear = arg.schoolYear ?? 2000;
    this.grade = arg.grade ?? new Grade("e1");
    this.name = arg.name ?? "nec-name";

    const months = arg.months ?? {};
    this.months = monthValues.map(monthValue => {
      const month = months[monthValue];
      if (month !== undefined) {
        return new EECMonthArg({ eecId: arg.eecId, month: monthValue, ...month });
      } else {
        return new EECMonthArg({ eecId: arg.eecId, month: monthValue });
      }
    });
  }

  readonly eecId: string;

  readonly schoolYear: number;
  readonly grade: Grade;
  readonly name: string;
  readonly months: EECMonthArg[];

  getMonth(month: MonthValue): EECMonthArg {
    const eecMonth = this.months.find(m => m.month === month);
    if (isNullish(eecMonth)) throw new Error(`EECurriculumArg.getMonth: Cannot find eecMonth of month ${month}`);
    return eecMonth;
  }

  toObject(): EECurriculum {
    return new EECurriculum(this.eecId, this.schoolYear, this.grade, this.name, null);
  }

  toTree(): EECurriculumTree {
    return new EECurriculumTree(
      this.toObject(),
      this.months.map(m => m.toTree())
    );
  }
}

export type EECMonthPartialArg = {
  readonly activities?: {
    [activityId: string]: EECActivityPartialArg;
  };
};

export type EECMonthPartialArgWithIds = {
  readonly eecId: string;
  readonly month: MonthValue;
} & EECMonthPartialArg;

export class EECMonthArg {
  constructor(arg: EECMonthPartialArgWithIds) {
    this.eecId = arg.eecId;
    this.month = arg.month;

    this.activities = Object.entries(arg.activities ?? {}).map(
      ([activityId, activity]) => new EECActivityArg({ eecId: arg.eecId, month: arg.month, activityId, ...activity })
    );
  }

  readonly eecId: string;
  readonly month: MonthValue;

  readonly activities: EECActivityArg[];

  toTree(): EECMonthTree {
    return new EECMonthTree(
      new EECMonth(this.eecId, this.month),
      this.activities.map(a => a.toTree())
    );
  }
}

export type EECActivityPartialArg = {
  readonly enabled?: boolean;
  readonly text?: string;
};
export type EECActivityPartialArgWithIds = {
  readonly eecId: string;
  readonly month: MonthValue;
  readonly activityId: string;
} & EECActivityPartialArg;

export class EECActivityArg {
  constructor(arg: EECActivityPartialArgWithIds) {
    this.eecId = arg.eecId;
    this.month = arg.month;
    this.activityId = arg.activityId;

    this.enabled = arg.enabled ?? false;
    this.text = arg.text ?? "";
  }

  readonly eecId: string;
  readonly month: MonthValue;
  readonly activityId: string;

  readonly enabled: boolean;
  readonly text: string;

  toObject(): EECActivity {
    return new EECActivity(this.eecId, this.month, this.activityId, this.enabled, this.text);
  }

  toTree(): EECActivityTree {
    return new EECActivityTree(new EECActivity(this.eecId, this.month, this.activityId, this.enabled, this.text));
  }

  toEditable(repo: CurriculumRepositoryMock, savable: boolean): EditableEECActivity {
    return new EditableEECActivity(repo, savable, this.eecId, this.month, this.activityId, this.enabled, this.text, "");
  }
}

export type EECJournalStudentPartialArg = {
  readonly journals?: {
    [journalId: string]: EECJournalPartialArg;
  };
};
export type EECJournalStudentPartialArgWithIds = {
  readonly eecId: string;
  readonly studentUserId: string;
} & EECJournalStudentPartialArg;

export class EECJournalStudentArg {
  constructor(arg: EECJournalStudentPartialArgWithIds) {
    this.eecId = arg.eecId;
    this.studentUserId = arg.studentUserId;
    this.journals = Object.entries(arg.journals ?? {}).map(
      ([journalId, journal]) =>
        new EECJournalArg({
          eecId: arg.eecId,
          studentUserId: arg.studentUserId,
          journalId,
          ...journal
        })
    );
  }

  readonly eecId: string;
  readonly studentUserId: string;

  readonly journals: EECJournalArg[];

  toObject(): EECJournalStudent {
    return new EECJournalStudent(this.eecId, this.studentUserId);
  }

  toTree(): EECJournalStudentTree {
    return new EECJournalStudentTree(
      this.toObject(),
      this.journals.map(j => j.toTree())
    );
  }

  toEditableTree(
    repo: CurriculumRepositoryMock,
    studentInputSavable: boolean,
    teacherInputSavable: boolean
  ): EditableEECJournalStudentTree {
    return new EditableEECJournalStudentTree(
      this.toObject(),
      this.journals.map(j => j.toEditableTree(repo, studentInputSavable, teacherInputSavable))
    );
  }

  static listFrom(
    eecId: string,
    journalStudents: {
      [studentUserId: string]: EECJournalStudentPartialArg;
    }
  ): EECJournalStudentArg[] {
    return Object.entries(journalStudents).map(
      ([studentUserId, arg]) => new EECJournalStudentArg({ eecId, studentUserId, ...arg })
    );
  }
}

export type EECJournalPartialArg = {
  readonly month?: number;
  readonly activity?: string;
  readonly studentComment?: string;
  readonly teacherComment?: string;
  readonly teacherInputPublished?: boolean;
  readonly createdAt?: string;
  readonly studentInputLocked?: boolean;
  readonly journalFiles?: {
    [journalFileId: string]: EECJournalFilePartialArg;
  };
};

export type EECJournalPartialArgWithIds = {
  readonly eecId: string;
  readonly studentUserId: string;
  readonly journalId: string;
} & EECJournalPartialArg;

export class EECJournalArg {
  constructor(arg: EECJournalPartialArgWithIds) {
    this.eecId = arg.eecId;
    this.studentUserId = arg.studentUserId;
    this.journalId = arg.journalId;

    this.month = isMonthValue(arg.month) ? arg.month : 4;
    this.activity = arg.activity ?? "journal-activity";
    this.studentComment = arg.studentComment ?? "journal-student-comment";
    this.teacherComment = arg.teacherComment ?? "journal-teacher-comment";
    this.teacherInputPublished = arg.teacherInputPublished ?? false;
    this.createdAt = arg.createdAt ?? "2000-01-01T00:00:00Z";
    this.studentInputLocked = arg.studentInputLocked ?? false;
    this.journalFiles = Object.entries(arg.journalFiles ?? {}).map(
      ([journalFileId, journalFile]) =>
        new EECJournalFileArg({
          eecId: arg.eecId,
          studentUserId: arg.studentUserId,
          journalId: arg.journalId,
          journalFileId,
          ...journalFile
        })
    );
  }

  readonly eecId: string;
  readonly studentUserId: string;
  readonly journalId: string;

  readonly month: MonthValue;
  readonly activity: string;
  readonly studentComment: string;
  readonly teacherComment: string;
  readonly teacherInputPublished: boolean;
  readonly createdAt: string;
  readonly studentInputLocked: boolean;
  readonly journalFiles: EECJournalFileArg[];

  toObject(): EECJournal {
    return new EECJournal(
      this.eecId,
      this.studentUserId,
      this.journalId,
      this.month,
      this.activity,
      this.studentComment,
      this.teacherComment,
      this.teacherInputPublished,
      this.createdAt,
      this.studentInputLocked
    );
  }

  toEditable(
    repo: CurriculumRepositoryMock,
    studentInputSavable: boolean,
    teacherInputSavable: boolean
  ): EditableEECJournal {
    return new EditableEECJournal(
      repo,
      teacherInputSavable,
      studentInputSavable,
      this.eecId,
      this.studentUserId,
      this.journalId,
      this.month,
      this.activity,
      "",
      this.studentComment,
      "",
      this.teacherComment,
      "",
      this.teacherInputPublished,
      this.createdAt,
      this.studentInputLocked
    );
  }

  toTree(): EECJournalTree {
    return new EECJournalTree(
      this.toObject(),
      this.journalFiles.map(jf => jf.toTree())
    );
  }

  toEditableTree(
    repo: CurriculumRepositoryMock,
    studentInputSavable: boolean,
    teacherInputSavable: boolean
  ): EditableEECJournalTree {
    return new EditableEECJournalTree(
      repo,
      this.toEditable(repo, studentInputSavable, teacherInputSavable),
      this.journalFiles.map(jf => jf.toTree())
    );
  }

  static listFrom({
    eecId,
    studentUserId,
    journals
  }: {
    eecId: string;
    studentUserId: string;
    journals: {
      [journalId: string]: EECJournalPartialArg;
    };
  }): EECJournalArg[] {
    return Object.entries(journals).map(
      ([journalId, journal]) => new EECJournalArg({ eecId, studentUserId, journalId, ...journal })
    );
  }
}

export type EECJournalFilePartialArg = {
  // TODO 今のところこれだけだが、もうちょい充実させたいとこ。
  readonly createdAt?: string;
  readonly updatedAt?: string;
};

export type EECJournalFilePartialArgWithIds = {
  readonly eecId: string;
  readonly studentUserId: string;
  readonly journalId: string;
  readonly journalFileId: string;
} & EECJournalFilePartialArg;

export class EECJournalFileArg {
  constructor(arg: EECJournalFilePartialArgWithIds) {
    this.eecId = arg.eecId;
    this.studentUserId = arg.studentUserId;
    this.journalId = arg.journalId;
    this.journalFileId = arg.journalFileId;

    this.createdAt = arg.createdAt ?? "2000-01-01T00:00:00Z";
    this.updatedAt = arg.updatedAt ?? "2000-01-01T00:00:00Z";
  }

  readonly eecId: string;
  readonly studentUserId: string;
  readonly journalId: string;
  readonly journalFileId: string;

  readonly createdAt: string;
  readonly updatedAt: string;

  toObject(): EECJournalFile {
    return new EECJournalFile(
      this.eecId,
      this.studentUserId,
      this.journalId,
      this.journalFileId,
      "type",
      "subtype",
      "mediaType",
      "filename",
      "ext",
      "gcsObjectPath",
      "thumbnailGcsObjectPath",
      false,
      null,
      null,
      this.createdAt,
      this.updatedAt
    );
  }

  toTree(): EECJournalFileTree {
    return new EECJournalFileTree(this.toObject());
  }
}

/**
 * 1年分のNECEvaluationを作成する。
 * 指定しなかった月は、空評価になる。
 */
export function genNECEvaluationsForYear(arg: {
  [necId: string]: {
    [viewPointId: string]: {
      [contentId: string]: {
        [studentUserId: string]: { [month in MonthValue]?: NECEvaluationPartialArg };
      };
    };
  };
}): NECEvaluation[] {
  return Object.entries(arg).flatMap(([necId, viewPoints]) =>
    Object.entries(viewPoints).flatMap(([viewPointId, contents]) =>
      Object.entries(contents).flatMap(([contentId, students]) =>
        Object.entries(students).flatMap(([studentUserId, months]) =>
          monthValues.map(monthValue => {
            const evaluationParam = months[monthValue];
            if (evaluationParam !== undefined)
              return genNECEvaluation({
                necId,
                viewPointId,
                contentId,
                month: monthValue,
                studentUserId,
                ...evaluationParam
              });
            else return genNECEvaluation({ necId, viewPointId, contentId, month: monthValue, studentUserId });
          })
        )
      )
    )
  );
}

export function genNECEvaluation({
  necId = "nec000",
  viewPointId = "viewpoint000",
  contentId = "content000",
  month = 4,
  studentUserId = "student000",
  rating = "",
  teacherInputPublished = false
}: {
  necId?: string;
  viewPointId?: string;
  contentId?: string;
  month?: MonthValue;
  studentUserId?: string;
} & NECEvaluationPartialArg): NECEvaluation {
  return new NECEvaluation(necId, viewPointId, contentId, month, studentUserId, rating, teacherInputPublished);
}
