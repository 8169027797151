


























































import Vue, { PropType } from "vue";
import { MonthValue } from "@/ts/utils";
import { RatingValue } from "@/ts/objects/common/Rating";
import CurriculumListNECStudentRow from "@/views/curriculum/teacher/CurriculumListNEC/components/CurriculumListNECStudentRow/CurriculumListNECStudentRow.vue";
import CurriculumListNECEvaluationHeaderCell from "@/views/curriculum/teacher/CurriculumListNEC/components/CurriculumListNECEvaluationHeaderCell/CurriculumListNECEvaluationHeaderCell.vue";
import FilterableHeaderButton from "@/components/FilterableHeaderButton/FilterableHeaderButton.vue";
import uniq from "lodash/uniq";
import {
  CurriculumListNECContentRowFilterState,
  CurriculumListNECMonthFilterState,
  CurriculumListNECMonthStateVM,
  CurriculumListNECStudentRowFilterState,
  ICurriculumListNECModel
} from "@/views/curriculum/teacher/CurriculumListNEC/CurriculumListNECModel";

export default Vue.extend({
  name: "CurriculumListNECTable",
  components: { CurriculumListNECEvaluationHeaderCell, CurriculumListNECStudentRow, FilterableHeaderButton },
  props: {
    model: { type: Object as PropType<ICurriculumListNECModel>, required: true },
    monthStateModel: { type: Array as PropType<CurriculumListNECMonthStateVM[]>, required: true },

    studentRowFilter: { type: Object as PropType<CurriculumListNECStudentRowFilterState>, required: true },
    contentRowFilter: { type: Object as PropType<CurriculumListNECContentRowFilterState>, required: true },
    monthFilter: { type: Object as PropType<CurriculumListNECMonthFilterState>, required: true },

    studentColWidth: { type: Number, required: true },
    viewPointColWidth: { type: Number, required: true },
    contentColWidth: { type: Number, required: true },
    evaluationColWidth: { type: Number, required: true },

    studentUserIdToStudentViewPath: { type: Function as PropType<(studentUserId: string) => string>, required: true },

    onInput: {
      type: Function as PropType<
        (viewPointId: string, contentId: string, month: MonthValue, studentUserId: string, rating: RatingValue) => void
      >,
      required: true
    },
    onChangeSinglePublishState: {
      type: Function as PropType<
        (viewPointId: string, contentId: string, month: MonthValue, studentUserId: string, published: boolean) => void
      >,
      required: true
    },
    onChangeMonthPublishState: {
      type: Function as PropType<(month: MonthValue, published: boolean) => void>,
      required: true
    },
    onFillAllEvaluationsOfMonth: {
      type: Function as PropType<(month: MonthValue, rating: RatingValue, overwrite: boolean) => void>,
      required: true
    },
    onChangeStudentRowFilterState: {
      type: Function as PropType<(filterState: CurriculumListNECStudentRowFilterState) => void>,
      required: true
    },
    onChangeContentRowFilterState: {
      type: Function as PropType<(filterState: CurriculumListNECContentRowFilterState) => void>,
      required: true
    }
  },
  computed: {
    filteredMonthStateModel(): CurriculumListNECMonthStateVM[] {
      return this.monthFilter.months.map(
        month =>
          this.monthStateModel.find(s => s.month === month) ?? {
            month,
            publishState: "none"
          }
      );
    }
  },
  methods: {
    onStudentRowFilterChanged(state: Record<string, boolean>) {
      const studentUserIds = Object.entries(state)
        .filter(([_key, enabled]) => enabled)
        .map(([key, _enabled]) => key);
      this.onChangeStudentRowFilterState({ studentUserIds });
    },
    onViewPointRowFilterChanged(state: Record<string, boolean>) {
      const viewPointIds = Object.entries(state)
        .filter(([_key, enabled]) => enabled)
        .map(([key, _enabled]) => key);
      this.onChangeContentRowFilterState({ ...this.contentRowFilter, viewPointIds });
    },
    onMonthRowFilterStateChanged(month: MonthValue, filtering: boolean) {
      const contentRowFilter = this.contentRowFilter;
      const currentMonths = contentRowFilter.shouldBeEnabledOn;

      let updatedMonths = [];
      if (filtering) {
        updatedMonths = uniq([...currentMonths, month]);
      } else {
        updatedMonths = currentMonths.filter(m => m !== month);
      }

      this.onChangeContentRowFilterState({
        ...contentRowFilter,
        shouldBeEnabledOn: updatedMonths
      });
    }
  }
});
