























import Vue, { PropType } from "vue";
import CurriculumReadNECMonthlyViewPointRow from "@/views/curriculum/student/CurriculumReadNECMonthly/components/CurriculumReadNECMonthlyViewPointRow/CurriculumReadNECMonthlyViewPointRow.vue";
import CurriculumReadMainHeader from "@/views/curriculum/student/components/CurriculumReadMainHeader/CurriculumReadMainHeader.vue";
import { NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { INECEvaluation } from "@/ts/objects/curriculum/INECEvaluation";
import { MonthValue } from "@/ts/utils";
import { Loadable } from "@/ts/Loadable";

export default Vue.extend({
  name: "CurriculumStudentViewNECMonthly",
  components: { CurriculumReadNECMonthlyViewPointRow, CurriculumReadMainHeader },
  props: {
    /**
     * 児童生徒ID。
     */
    studentUserId: { type: String, required: true },

    /**
     * 月。
     */
    month: { type: Number as PropType<MonthValue>, required: true },

    /**
     * 数値評価教科。
     */
    neCurriculumTree: { type: Object as PropType<NECurriculumTree>, required: true },

    /**
     * 現在表示中の教科・児童生徒・月 配下の評価。
     */
    evaluations: { type: Object as PropType<Loadable<INECEvaluation[]>>, required: true }
  }
});
