










import Vue, { PropType } from "vue";
import ActivityView from "@/views/activity/components/ActivityView.vue";
import { AppStateStore } from "@/store/AppStateStore";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { ActivityRepository } from "@/ts/repositories/ActivityRepository";

export default Vue.extend({
  components: { ActivityView },
  name: "Activity",
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    activityRepository: { type: Object as PropType<ActivityRepository>, required: true }
  },
  computed: {
    studentUserId(): string | null {
      const studentUserId = this.appStateStore.studentOrGuardianState?.studentInfo()?.studentUserId;
      if (studentUserId === undefined) return null;
      return studentUserId;
    }
  }
});
