import { Editable } from "@/ts/objects/editable/Editable";
import { Err, DisplayableErr } from "@/ts/objects/Err";
import { SaveResult } from "@/ts/objects/editable/SaveResult";
import log from "loglevel";

export abstract class EditableValue implements Editable {
  abstract readonly name: string;
  readonly savable: boolean;

  private currentError: DisplayableErr | null = null;
  private waitingForResponse = false;

  protected constructor(savable: boolean) {
    this.savable = savable;
  }

  protected abstract _needSave(): boolean;

  protected abstract _save(): Promise<Err | null>;

  protected abstract _changedAfterLastRequest(): boolean;

  needSave(): boolean {
    if (!this.savable) return false;
    return this._needSave();
  }

  async saveAllChanges(force: boolean): Promise<SaveResult> {
    if (this.waitingForResponse) {
      // 既にレスポンス待ち中であれば、保存をスキップする。
      log.debug(`${this.name}: Save skipped because it's already waiting for response...`);
      return {
        didSave: false,
        someSkipped: true,
        errors: []
      };
    }

    if (!this.needSave()) {
      // 保存不要であれば、保存しない。
      this.currentError = null;
      return {
        didSave: false,
        someSkipped: false,
        errors: []
      };
    }

    if (!force && !this._changedAfterLastRequest()) {
      // (forceがfalseの場合のみ)
      // レスポンス待ち中でなく、保存が必要(needSave = true)なのに、最後のリクエストから値に変化がない場合
      // (前回エラーが返ってきていた場合のみ生じうる)は、保存をスキップする。
      log.debug(`${this.name}: Save skipped because it's unchanged after last request...`);
      return {
        didSave: false,
        someSkipped: true,
        errors: []
      };
    }

    log.debug(`${this.name}: Saving to remote...`);
    this.waitingForResponse = true;
    const error = await this._save();
    this.waitingForResponse = false;

    if (error !== null) {
      log.info(`${this.name}: Saving error occurred: ${JSON.stringify(error)}`);
      if (error instanceof DisplayableErr && this.needSave()) {
        this.currentError = error;
      } else {
        this.currentError = null;
      }
      return {
        didSave: true,
        someSkipped: false,
        errors: [error]
      };
    }

    log.debug(`${this.name}: Saving success`);
    this.currentError = null;

    return {
      didSave: true,
      someSkipped: false,
      errors: []
    };
  }

  currentErrors(): DisplayableErr[] {
    const err = this.currentError;
    return err === null ? [] : [err];
  }

  hasError(): boolean {
    return this.currentError !== null;
  }
}
