






























import Vue, { PropType } from "vue";
import { MonthValue } from "@/ts/utils";
import CurriculumSyllabusEECCell from "@/views/curriculum/teacher/CurriculumSyllabusEEC/components/CurriculumSyllabusEECCell/CurriculumSyllabusEECCell.vue";
import { IEECActivity } from "@/ts/objects/curriculum/IEECActivity";
import { ICurriculumSyllabusEECMonthModel } from "@/views/curriculum/teacher/CurriculumSyllabusEEC/CurriculumSyllabusEECModel";

export default Vue.extend({
  name: "CurriculumSyllabusEECColumn",
  components: { CurriculumSyllabusEECCell },
  props: {
    width: { type: Number, required: true },

    monthModel: { type: Object as PropType<ICurriculumSyllabusEECMonthModel>, required: true },

    onInputText: {
      type: Function as PropType<(month: MonthValue, activityId: string, value: string) => void>,
      required: true
    },
    onInputEnabled: {
      type: Function as PropType<(month: MonthValue, activityId: string, value: boolean) => void>,
      required: true
    },
    moveActivity: {
      type: Function as PropType<(month: MonthValue, activityId: string, up: boolean) => Promise<void>>,
      required: true
    },
    createActivity: {
      type: Function as PropType<(month: MonthValue) => Promise<void>>,
      required: true
    },
    deleteActivity: {
      type: Function as PropType<(month: MonthValue, activityId: string) => Promise<void>>,
      required: true
    }
  },
  computed: {
    month(): MonthValue {
      return this.monthModel.month;
    },
    activities(): readonly IEECActivity[] {
      return this.monthModel.activities;
    }
  },
  methods: {
    _onInputText(activityId: string, value: string) {
      this.onInputText(this.month, activityId, value);
    },
    _onInputEnabled(activityId: string, value: boolean) {
      this.onInputEnabled(this.month, activityId, value);
    },
    _moveActivity(activityId: string, up: boolean) {
      const month = this.month;
      this.moveActivity(month, activityId, up);
    },
    _createActivity() {
      this.createActivity(this.month);
    },
    _deleteActivity(activityId: string) {
      this.deleteActivity(this.month, activityId);
    }
  }
});
