


















import Vue, { PropType } from "vue";

export type ErrorNotificationParam = {
  closeKey?: string;
  heading?: string;
  text: string;
  closable?: boolean;
};

export default Vue.extend({
  name: "ErrorNotification",
  props: {
    params: { type: Array as PropType<ErrorNotificationParam[]> },
    maxHeightPx: { type: Number, default: 120 }
  },
  computed: {
    styleVariables() {
      return {
        "--maxHeightPx": `${this.maxHeightPx}px`
      };
    }
  },
  methods: {
    close(closeKey: string | undefined) {
      this.$emit("close", closeKey);
    }
  }
});
