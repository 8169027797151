

















import Vue, { PropType } from "vue";
import { CurriculumStoreS } from "@/store/CurriculumStoreS";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { messages } from "@/ts/const/Messages";
import CurriculumWriteMenu from "@/views/curriculum/student/CurriculumWriteMenu/CurriculumWriteMenu.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";

export default Vue.extend({
  name: "CurriculumWrite",
  components: {
    LoadingBlock,
    CurriculumWriteMenu,
    MessageView
  },
  props: {
    curriculumStoreS: { type: Object as PropType<CurriculumStoreS>, required: true },
    curriculumRepository: { type: Object as PropType<CurriculumRepository>, required: true }
  },
  computed: {
    messageView(): MessageViewParam | null {
      const store = this.curriculumStoreS;
      if (store.tab === null || store.selectedSchoolYear === null || store.grade === null)
        return { message: messages.pageNotFound };
      return null;
    }
  }
});
