import { Quarter } from "@/ts/objects/common/Quarter";
import { YearMonth } from "@/ts/objects/common/YearMonth";
import { RatingValue } from "@/ts/objects/common/Rating";
import { ProjectJournalFile } from "@/ts/objects/project/value/ProjectJournalFile";
import { ProjectRepositoryMock } from "@/test-tools/mocks/ProjectRepositoryMock";
import { EditableProjectJournal } from "@/ts/objects/project/editable/EditableProjectJournal";
import { ProjectRubric } from "@/ts/objects/project/value/ProjectRubric";
import { EditableProjectRubric } from "@/ts/objects/project/editable/EditableProjectRubric";
import { EditableProjectLookback } from "@/ts/objects/project/editable/EditableProjectLookback";
import { Project } from "@/ts/objects/project/value/Project";
import { MonthValue } from "@/ts/utils";
import { EditableProject } from "@/ts/objects/project/editable/EditableProject";

export type ProjectTestDataSet = { [projectId: string]: ProjectArg };

export type ProjectTestDataPartialArgs = { [projectId: string]: ProjectPartialArg };

export function partialArgsToProjectTestDataSet(args: ProjectTestDataPartialArgs): ProjectTestDataSet {
  return Object.fromEntries(
    Object.entries(args).map(([projectId, arg]) => [projectId, new ProjectArg({ projectId, ...arg })])
  );
}

export type ProjectPartialArg = {
  readonly classId?: string;
  readonly quarter?: Quarter;
  readonly name?: string;
  readonly description?: string;
  readonly relatedSyllabus?: string;
  readonly viewPointSEnabled?: boolean;
  readonly viewPointAEnabled?: boolean;
  readonly viewPointBEnabled?: boolean;
  readonly viewPointCEnabled?: boolean;
  readonly startingMonth?: { year: number; month: MonthValue };
  readonly endingMonth?: { year: number; month: MonthValue };
  readonly published?: boolean;
  readonly completed?: boolean;
  readonly lookbacks?: { [lookbackId: string]: ProjectLookbackPartialArg };
  readonly rubrics?: { [rubricId: string]: ProjectRubricPartialArg };
};
export type ProjectPartialArgWithIds = {
  readonly projectId: string;
} & ProjectPartialArg;

export class ProjectArg {
  constructor(arg: ProjectPartialArgWithIds) {
    this.projectId = arg.projectId;

    this.classId = arg.classId ?? "class000";
    this.quarter = arg.quarter ?? new Quarter(2000, 1);
    this.name = arg.name ?? "project-name";
    this.description = arg.description ?? "project-description";
    this.relatedSyllabus = arg.relatedSyllabus ?? "related-syllabus";
    this.viewPointSEnabled = arg.viewPointSEnabled ?? true;
    this.viewPointAEnabled = arg.viewPointAEnabled ?? true;
    this.viewPointBEnabled = arg.viewPointBEnabled ?? true;
    this.viewPointCEnabled = arg.viewPointCEnabled ?? true;
    this.startingMonth = arg.startingMonth ?? new YearMonth(2000, 4);
    this.endingMonth = arg.endingMonth ?? new YearMonth(2000, 4);
    this.published = arg.published ?? false;
    this.completed = arg.completed ?? false;
    this.lookbacks = Object.entries(arg.lookbacks ?? {}).map(
      ([lookbackId, lookback]) =>
        new ProjectLookbackArg({
          projectId: arg.projectId,
          lookbackId,
          ...lookback
        })
    );
    this.rubrics = Object.entries(arg.rubrics ?? {}).map(
      ([rubricId, rubric]) =>
        new ProjectRubricArg({
          projectId: arg.projectId,
          rubricId,
          ...rubric
        })
    );
  }

  readonly projectId: string;

  readonly classId: string;
  readonly quarter: Quarter;
  readonly name: string;
  readonly description: string;
  readonly relatedSyllabus: string;
  readonly viewPointSEnabled: boolean;
  readonly viewPointAEnabled: boolean;
  readonly viewPointBEnabled: boolean;
  readonly viewPointCEnabled: boolean;
  readonly startingMonth: YearMonth;
  readonly endingMonth: YearMonth;
  readonly published: boolean;
  readonly completed: boolean;
  readonly lookbacks: ProjectLookbackArg[];
  readonly rubrics: ProjectRubricArg[];

  get resourceName(): string {
    return `/projects/${this.projectId}`;
  }

  toObject(): Project {
    return new Project(
      this.resourceName,
      this.classId,
      this.quarter,
      this.name,
      this.description,
      this.relatedSyllabus,
      this.viewPointSEnabled,
      this.viewPointAEnabled,
      this.viewPointBEnabled,
      this.viewPointCEnabled,
      this.startingMonth.year,
      this.startingMonth.month,
      this.endingMonth.year,
      this.endingMonth.month,
      this.published,
      this.completed,
      this.rubrics.map(r => r.resourceName)
    );
  }

  toEditable(repo: ProjectRepositoryMock, savable: boolean): EditableProject {
    return new EditableProject(
      repo,
      savable,
      this.resourceName,
      this.classId,
      this.quarter,
      this.name,
      "",
      this.description,
      "",
      this.relatedSyllabus,
      "",
      this.viewPointSEnabled,
      this.viewPointAEnabled,
      this.viewPointBEnabled,
      this.viewPointCEnabled,
      this.startingMonth.year,
      this.startingMonth.month,
      this.endingMonth.year,
      this.endingMonth.month,
      this.published,
      this.completed,
      this.rubrics.map(r => r.resourceName)
    );
  }
}

export type ProjectLookbackPartialArg = {
  readonly studentUserId?: string;
  readonly studentComment?: string;
  readonly studentRating?: RatingValue;
  readonly teacherComment?: string;
  readonly teacherRating?: RatingValue;
  readonly teacherInputPublished?: boolean;
  readonly guardianComment?: string;
  readonly studentInputLocked?: boolean;
  readonly guardianInputLocked?: boolean;
};
export type ProjectLookbackPartialArgWithIds = {
  readonly projectId: string;
  readonly lookbackId: string;
} & ProjectLookbackPartialArg;

export class ProjectLookbackArg {
  constructor(arg: ProjectLookbackPartialArgWithIds) {
    this.projectId = arg.projectId;
    this.lookbackId = arg.lookbackId;

    this.studentUserId = arg.studentUserId ?? "student000";
    this.studentComment = arg.studentComment ?? "student-comment";
    this.studentRating = arg.studentRating ?? "";
    this.teacherComment = arg.teacherComment ?? "teacher-comment";
    this.teacherRating = arg.teacherRating ?? "";
    this.teacherInputPublished = arg.teacherInputPublished ?? false;
    this.guardianComment = arg.guardianComment ?? "guardian-comment";
    this.studentInputLocked = arg.studentInputLocked ?? false;
    this.guardianInputLocked = arg.guardianInputLocked ?? false;
  }

  readonly projectId: string;
  readonly lookbackId: string;

  readonly studentUserId: string;
  readonly studentComment: string;
  readonly studentRating: RatingValue;
  readonly teacherComment: string;
  readonly teacherRating: RatingValue;
  readonly teacherInputPublished: boolean;
  readonly guardianComment: string;
  readonly studentInputLocked: boolean;
  readonly guardianInputLocked: boolean;

  get resourceName(): string {
    return `/projects/${this.projectId}/lookbacks/${this.lookbackId}`;
  }

  toEditable(
    repo: ProjectRepositoryMock,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): EditableProjectLookback {
    return new EditableProjectLookback(
      repo,
      teacherInputSavable,
      studentInputSavable,
      guardianInputSavable,
      this.resourceName,
      this.studentUserId,
      this.studentComment,
      "",
      this.studentRating,
      this.teacherComment,
      "",
      this.teacherRating,
      this.teacherInputPublished,
      this.guardianComment,
      "",
      this.studentInputLocked,
      this.guardianInputLocked
    );
  }
}

export type ProjectRubricPartialArg = {
  readonly orderNum?: number;
  readonly learningActivity?: string;
  readonly viewPointS?: string;
  readonly viewPointA?: string;
  readonly viewPointB?: string;
  readonly viewPointC?: string;
  readonly journals?: { [journalId: string]: ProjectJournalPartialArg };
};
export type ProjectRubricPartialArgWithIds = {
  readonly projectId: string;
  readonly rubricId: string;
} & ProjectRubricPartialArg;

export class ProjectRubricArg {
  constructor(arg: ProjectRubricPartialArgWithIds) {
    this.projectId = arg.projectId;
    this.rubricId = arg.rubricId;

    this.orderNum = arg.orderNum ?? 0;
    this.learningActivity = arg.learningActivity ?? "learning-activity";
    this.viewPointS = arg.viewPointS ?? "view-point-s";
    this.viewPointA = arg.viewPointA ?? "view-point-a";
    this.viewPointB = arg.viewPointB ?? "view-point-b";
    this.viewPointC = arg.viewPointC ?? "view-point-c";
    this.journals = Object.entries(arg.journals ?? {}).map(
      ([journalId, journal]) =>
        new ProjectJournalArg({
          projectId: arg.projectId,
          rubricId: arg.rubricId,
          journalId,
          ...journal
        })
    );
  }

  readonly projectId: string;
  readonly rubricId: string;

  readonly orderNum: number;
  readonly learningActivity: string;
  readonly viewPointS: string;
  readonly viewPointA: string;
  readonly viewPointB: string;
  readonly viewPointC: string;
  readonly journals: ProjectJournalArg[];

  get resourceName(): string {
    return `/projects/${this.projectId}/rubrics/${this.rubricId}`;
  }

  toObject(): ProjectRubric {
    return new ProjectRubric(
      this.resourceName,
      this.orderNum,
      this.learningActivity,
      this.viewPointS,
      this.viewPointA,
      this.viewPointB,
      this.viewPointC
    );
  }

  toEditable(repo: ProjectRepositoryMock, savable: boolean): EditableProjectRubric {
    return new EditableProjectRubric(
      repo,
      savable,
      `/projects/${this.projectId}/rubrics/${this.rubricId}`,
      this.orderNum,
      this.learningActivity,
      "",
      this.viewPointS,
      "",
      this.viewPointA,
      "",
      this.viewPointB,
      "",
      this.viewPointC,
      ""
    );
  }

  toEditableJournals(
    repo: ProjectRepositoryMock,
    studentInputSavable: boolean,
    teacherInputSavable: boolean
  ): EditableProjectJournal[] {
    return this.journals.map(j => j.toEditable(repo, studentInputSavable, teacherInputSavable, this.learningActivity));
  }
}

export type ProjectJournalPartialArg = {
  readonly studentUserId?: string;
  readonly studentComment?: string;
  readonly studentRating?: RatingValue;
  readonly teacherComment?: string;
  readonly teacherRating?: RatingValue;
  readonly teacherInputPublished?: boolean;
  readonly studentInputLocked?: boolean;
  readonly journalFiles?: { [journalFileId: string]: ProjectJournalFilePartialArg };
};
export type ProjectJournalPartialArgWithIds = {
  readonly projectId: string;
  readonly rubricId: string;
  readonly journalId: string;
} & ProjectJournalPartialArg;

export class ProjectJournalArg {
  constructor(arg: ProjectJournalPartialArgWithIds) {
    this.projectId = arg.projectId;
    this.rubricId = arg.rubricId;
    this.journalId = arg.journalId;

    this.studentUserId = arg.studentUserId ?? "student000";
    this.studentComment = arg.studentComment ?? "student-comment";
    this.studentRating = arg.studentRating ?? "";
    this.teacherComment = arg.teacherComment ?? "teacher-comment";
    this.teacherRating = arg.teacherRating ?? "";
    this.teacherInputPublished = arg.teacherInputPublished ?? false;
    this.studentInputLocked = arg.studentInputLocked ?? false;
    this.journalFiles = Object.entries(arg.journalFiles ?? {}).map(
      ([journalFileId, journalFile]) =>
        new ProjectJournalFileArg({
          projectId: arg.projectId,
          rubricId: arg.rubricId,
          journalId: arg.journalId,
          journalFileId,
          ...journalFile
        })
    );
  }

  readonly projectId: string;
  readonly rubricId: string;
  readonly journalId: string;

  readonly studentUserId: string;
  readonly studentComment: string;
  readonly studentRating: RatingValue;
  readonly teacherComment: string;
  readonly teacherRating: RatingValue;
  readonly teacherInputPublished: boolean;
  readonly studentInputLocked: boolean;
  readonly journalFiles: ProjectJournalFileArg[];

  get rubricResourceName(): string {
    return `/projects/${this.projectId}/rubrics/${this.rubricId}`;
  }

  get resourceName(): string {
    return `/projects/${this.projectId}/rubrics/${this.rubricId}/journals/${this.journalId}`;
  }

  toEditable(
    repo: ProjectRepositoryMock,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    rubricLearningActivity: string
  ): EditableProjectJournal {
    return new EditableProjectJournal(
      repo,
      teacherInputSavable,
      studentInputSavable,
      this.resourceName,
      this.rubricResourceName,
      rubricLearningActivity,
      this.studentUserId,
      this.journalFiles.map(jf => jf.toObject()),
      this.studentComment,
      "",
      this.studentRating,
      this.teacherComment,
      "",
      this.teacherRating,
      this.teacherInputPublished,
      this.studentInputLocked
    );
  }
}

export type ProjectJournalFilePartialArg = {
  readonly filename?: string;
  readonly createdAt?: string;
  readonly updatedAt?: string;
};
export type ProjectJournalFilePartialArgWithIds = {
  readonly projectId: string;
  readonly rubricId: string;
  readonly journalId: string;
  readonly journalFileId: string;
} & ProjectJournalFilePartialArg;

export class ProjectJournalFileArg {
  constructor(arg: ProjectJournalFilePartialArgWithIds) {
    this.projectId = arg.projectId;
    this.rubricId = arg.rubricId;
    this.journalId = arg.journalId;
    this.journalFileId = arg.journalFileId;

    this.filename = arg.filename ?? "filename";
    this.createdAt = arg.createdAt ?? "2000-01-01T00:00:00Z";
    this.updatedAt = arg.updatedAt ?? "2000-01-01T00:00:00Z";
  }

  readonly projectId: string;
  readonly rubricId: string;
  readonly journalId: string;
  readonly journalFileId: string;

  readonly filename: string;
  readonly createdAt: string;
  readonly updatedAt: string;

  get journalResourceName(): string {
    return `/projects/${this.projectId}/rubrics/${this.rubricId}/journals/${this.journalId}`;
  }

  get resourceName(): string {
    return `/projects/${this.projectId}/rubrics/${this.rubricId}/journals/${this.journalId}/journalFiles/${this.journalFileId}`;
  }

  toObject(): ProjectJournalFile {
    return new ProjectJournalFile(
      this.resourceName,
      this.journalResourceName,
      "type",
      "subtype",
      "mediaType",
      this.filename,
      "ext",
      "gcsObjectPath",
      "thumbnailGcsObjectPath",
      false,
      null,
      null,
      this.createdAt,
      this.updatedAt
    );
  }
}
