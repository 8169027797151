



















import Vue, { PropType } from "vue";
import { AppStateStore } from "@/store/AppStateStore";

export default Vue.extend({
  name: "SolanHeaderT",
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true }
  },
  computed: {
    isCurrentTabJournals(): boolean {
      return this.$route.name === "T.Solan.Journals";
    },
    isCurrentTabStudentView(): boolean {
      return (
        this.$route.name === "T.Solan.StudentView" || (this.$route.name?.startsWith("T.Solan.StudentView.") ?? false)
      );
    }
  }
});
