/* tslint:disable */
/* eslint-disable */
/**
 * ユーザーサービス
 * ユーザならびに学校の情報を取得する
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BasicUserInfo
 */
export interface BasicUserInfo {
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfo
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfo
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfo
     */
    userType: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfo
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUserInfo
     */
    photoUrl: string;
}
/**
 * 子供の検診情報
 * @export
 * @interface ChildHealth
 */
export interface ChildHealth {
    /**
     * 血液型
     * @type {string}
     * @memberof ChildHealth
     */
    bloodType?: string;
    /**
     * 平熱
     * @type {string}
     * @memberof ChildHealth
     */
    favor?: string;
    /**
     * 予防接種
     * @type {string}
     * @memberof ChildHealth
     */
    inoculation?: string;
    /**
     * 既往歴
     * @type {string}
     * @memberof ChildHealth
     */
    medicalHistory?: string;
    /**
     * かかりつけ医
     * @type {string}
     * @memberof ChildHealth
     */
    homeDoctor?: string;
    /**
     * 食物アレルギー
     * @type {string}
     * @memberof ChildHealth
     */
    foodAllergy?: string;
    /**
     * アナフィラキシー
     * @type {string}
     * @memberof ChildHealth
     */
    anaphylaxia?: string;
    /**
     * その他アレルギー
     * @type {string}
     * @memberof ChildHealth
     */
    otherAllergy?: string;
    /**
     * 喘息
     * @type {string}
     * @memberof ChildHealth
     */
    asthma?: string;
}
/**
 * 子供のプロファイル情報
 * @export
 * @interface ChildProfile
 */
export interface ChildProfile {
    /**
     * 氏名
     * @type {string}
     * @memberof ChildProfile
     */
    name?: string;
    /**
     * 氏名よみ
     * @type {string}
     * @memberof ChildProfile
     */
    nameKana?: string;
    /**
     * 通称名
     * @type {string}
     * @memberof ChildProfile
     */
    nickName?: string;
    /**
     * 通称名よみ
     * @type {string}
     * @memberof ChildProfile
     */
    nickNameKana?: string;
    /**
     * 誕生日 (yyyyMMdd)
     * @type {string}
     * @memberof ChildProfile
     */
    birthday?: string;
    /**
     * 性別
     * @type {string}
     * @memberof ChildProfile
     */
    sex?: ChildProfileSexEnum;
    /**
     * 志願申込日
     * @type {string}
     * @memberof ChildProfile
     */
    entryDate?: string;
    /**
     * 入園年月日 (yyyyMMdd)
     * @type {string}
     * @memberof ChildProfile
     */
    kindergartenEnterDate?: string;
    /**
     * 小学校入学年月日 (yyyyMMdd)
     * @type {string}
     * @memberof ChildProfile
     */
    primarySchoolEnterDate?: string;
    /**
     * 中学校入学年月日 (yyyyMMdd)
     * @type {string}
     * @memberof ChildProfile
     */
    juniorHighSchoolEnterDate?: string;
    /**
     * 転学年月日 (yyyyMMdd)
     * @type {string}
     * @memberof ChildProfile
     */
    transfarDate?: string;
    /**
     * 卒業年月日 (yyyyMMdd)
     * @type {string}
     * @memberof ChildProfile
     */
    graduationDate?: string;
    /**
     * 一つ前の学校
     * @type {string}
     * @memberof ChildProfile
     */
    previosSchool?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof ChildProfile
     */
    zip?: string;
    /**
     * 住所
     * @type {string}
     * @memberof ChildProfile
     */
    address?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof ChildProfile
     */
    mail?: string;
    /**
     * 携帯番号
     * @type {string}
     * @memberof ChildProfile
     */
    mobilePhone?: string;
    /**
     * 写真保存パス
     * @type {string}
     * @memberof ChildProfile
     */
    picPath?: string;
    /**
     * 通学方法
     * @type {string}
     * @memberof ChildProfile
     */
    commuting?: string;
    /**
     * 国籍
     * @type {string}
     * @memberof ChildProfile
     */
    country?: string;
    /**
     * 宗教・宗派
     * @type {string}
     * @memberof ChildProfile
     */
    religion?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ChildProfileSexEnum {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * クラス情報
 * @export
 * @interface Class
 */
export interface Class {
    /**
     * Class識別ID
     * @type {string}
     * @memberof Class
     */
    id: string;
    /**
     * 小中区分
     * @type {string}
     * @memberof Class
     */
    schoolType: ClassSchoolTypeEnum;
    /**
     * 学年
     * @type {number}
     * @memberof Class
     */
    grade: number;
    /**
     * クラス番号 (1から始まる連番）
     * @type {number}
     * @memberof Class
     */
    classNo: number;
    /**
     * クラス通称名（さくら、かえで、など）
     * @type {string}
     * @memberof Class
     */
    nickName: string;
    /**
     * 担任教師ID
     * @type {number}
     * @memberof Class
     */
    mainTeacherId?: number;
    /**
     * 副担任教師ID
     * @type {number}
     * @memberof Class
     */
    subTeacherId?: number;
    /**
     * 年度
     * @type {number}
     * @memberof Class
     */
    year: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ClassSchoolTypeEnum {
    Primary = 'Primary',
    Juniorhigh = 'Juniorhigh'
}

/**
 * 保護者基本情報
 * @export
 * @interface Guardian
 */
export interface Guardian {
    /**
     * 保護者ID
     * @type {string}
     * @memberof Guardian
     */
    id?: string;
    /**
     * Gsuiteアカウント
     * @type {string}
     * @memberof Guardian
     */
    account?: string;
    /**
     * 保護者１氏名
     * @type {string}
     * @memberof Guardian
     */
    name1?: string;
    /**
     * 保護者２氏名
     * @type {string}
     * @memberof Guardian
     */
    name2?: string;
    /**
     * 子供情報
     * @type {Array<Student>}
     * @memberof Guardian
     */
    children?: Array<Student>;
}
/**
 * 保護者のプロファイル情報 プロファイルは２名分 子供のプロファイルは児童生徒プロファイルで取得する必要がある。
 * @export
 * @interface GuardianProfile
 */
export interface GuardianProfile {
    /**
     * 保護者ID
     * @type {number}
     * @memberof GuardianProfile
     */
    id?: number;
    /**
     * 保護者プロファイル（最大２名分）
     * @type {Array<GuardianProfileProfile>}
     * @memberof GuardianProfile
     */
    profile?: Array<GuardianProfileProfile>;
}
/**
 * 
 * @export
 * @interface GuardianProfileProfile
 */
export interface GuardianProfileProfile {
    /**
     * 連番
     * @type {number}
     * @memberof GuardianProfileProfile
     */
    seq?: number;
    /**
     * 氏名
     * @type {string}
     * @memberof GuardianProfileProfile
     */
    name?: string;
    /**
     * メールアドレス
     * @type {string}
     * @memberof GuardianProfileProfile
     */
    mail?: string;
    /**
     * 携帯電話番号
     * @type {string}
     * @memberof GuardianProfileProfile
     */
    mobilePhone?: string;
    /**
     * 職業
     * @type {string}
     * @memberof GuardianProfileProfile
     */
    job?: string;
    /**
     * 勤務地
     * @type {string}
     * @memberof GuardianProfileProfile
     */
    workPlace?: string;
    /**
     * 写真保存パス
     * @type {string}
     * @memberof GuardianProfileProfile
     */
    picPath?: string;
}
/**
 * get-identity問い合わせへの返却値。
 * @export
 * @interface Identity
 */
export interface Identity {
    /**
     * `teacher`, `staff`, `student`, `guardian`, `none`のいずれか。
     * @type {string}
     * @memberof Identity
     */
    userType: string;
    /**
     * `teacher`, `staff`, `none`の場合はnull。
     * @type {object}
     * @memberof Identity
     */
    info?: object;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {boolean}
     * @memberof InlineResponse200
     */
    updated?: boolean;
}
/**
 * 学校基本情報
 * @export
 * @interface School
 */
export interface School {
    /**
     * 学校名
     * @type {string}
     * @memberof School
     */
    name?: string;
    /**
     * 学校ロゴパス
     * @type {string}
     * @memberof School
     */
    logo?: string;
    /**
     * 現在の年度
     * @type {number}
     * @memberof School
     */
    currentYear?: number;
    /**
     * 学期情報
     * @type {Array<Semester>}
     * @memberof School
     */
    semesters?: Array<Semester>;
}
/**
 * 学期
 * @export
 * @interface Semester
 */
export interface Semester {
    /**
     * 年度
     * @type {number}
     * @memberof Semester
     */
    year: number;
    /**
     * 学校タイプ（小学校 or 中学校）
     * @type {string}
     * @memberof Semester
     */
    schoolType: string;
    /**
     * 学期
     * @type {number}
     * @memberof Semester
     */
    semester: number;
    /**
     * 開始年月日 (yyyyMMdd)
     * @type {string}
     * @memberof Semester
     */
    startDate: string;
    /**
     * 終了年月日 (yyyyMMdd)
     * @type {string}
     * @memberof Semester
     */
    endDate: string;
}
/**
 * 教職員情報
 * @export
 * @interface Staff
 */
export interface Staff {
    /**
     * 教職員ID
     * @type {string}
     * @memberof Staff
     */
    id?: string;
    /**
     * Gsuiteアカウント
     * @type {string}
     * @memberof Staff
     */
    account?: string;
    /**
     * 
     * @type {StaffProfile}
     * @memberof Staff
     */
    profile?: StaffProfile;
}
/**
 * プロファイル情報
 * @export
 * @interface StaffProfile
 */
export interface StaffProfile {
    /**
     * 氏名
     * @type {string}
     * @memberof StaffProfile
     */
    name?: string;
    /**
     * 郵便番号
     * @type {string}
     * @memberof StaffProfile
     */
    zip?: string;
    /**
     * 住所
     * @type {string}
     * @memberof StaffProfile
     */
    address?: string;
    /**
     * 電話番号
     * @type {string}
     * @memberof StaffProfile
     */
    phoneNo?: string;
    /**
     * 外国人タイプ (0:日本人 1:外国人)
     * @type {string}
     * @memberof StaffProfile
     */
    foeighType?: StaffProfileFoeighTypeEnum;
    /**
     * 教職員タイプ
     * @type {string}
     * @memberof StaffProfile
     */
    teacherType?: StaffProfileTeacherTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum StaffProfileFoeighTypeEnum {
    Japanese = 'japanese',
    Foeigher = 'foeigher'
}
/**
    * @export
    * @enum {string}
    */
export enum StaffProfileTeacherTypeEnum {
    Teacher = 'teacher',
    Staff = 'staff',
    Other = 'other'
}

/**
 * 児童生徒情報（学校内で使用するのはこちらのみ）
 * @export
 * @interface Student
 */
export interface Student {
    /**
     * 児童生徒ID
     * @type {string}
     * @memberof Student
     */
    id: string;
    /**
     * Gsuiteアカウント
     * @type {string}
     * @memberof Student
     */
    account: string;
    /**
     * Gsuiteプロフィール写真URL
     * @type {string}
     * @memberof Student
     */
    photoUrl: string;
    /**
     * 児童生徒氏名（通称名があればそちら）
     * @type {string}
     * @memberof Student
     */
    name: string;
    /**
     * 児童生徒よみ（通称名よみがあればそちら）
     * @type {string}
     * @memberof Student
     */
    nameKana: string;
    /**
     * 性別
     * @type {string}
     * @memberof Student
     */
    sex: StudentSexEnum;
    /**
     * 
     * @type {Class}
     * @memberof Student
     */
    _class?: Class;
    /**
     * 出席番号
     * @type {number}
     * @memberof Student
     */
    number?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum StudentSexEnum {
    Male = 'male',
    Female = 'female',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface StudentClass
 */
export interface StudentClass {
    /**
     * 
     * @type {string}
     * @memberof StudentClass
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StudentClass
     */
    schoolType: string;
    /**
     * 
     * @type {number}
     * @memberof StudentClass
     */
    grade: number;
    /**
     * 
     * @type {number}
     * @memberof StudentClass
     */
    classNo: number;
    /**
     * 
     * @type {string}
     * @memberof StudentClass
     */
    nickName: string;
    /**
     * 
     * @type {string}
     * @memberof StudentClass
     */
    mainTeacherId: string;
    /**
     * 
     * @type {string}
     * @memberof StudentClass
     */
    subTeacherId: string;
    /**
     * 
     * @type {number}
     * @memberof StudentClass
     */
    year: number;
    /**
     * 
     * @type {number}
     * @memberof StudentClass
     */
    studentNumber: number;
}
/**
 * 教師情報
 * @export
 * @interface Teacher
 */
export interface Teacher {
    /**
     * 教師ID
     * @type {string}
     * @memberof Teacher
     */
    id?: string;
    /**
     * Gsuiteアカウント
     * @type {string}
     * @memberof Teacher
     */
    account?: string;
    /**
     * Gsuiteプロフィール写真URL
     * @type {string}
     * @memberof Teacher
     */
    photoUrl?: string;
    /**
     * 氏名
     * @type {string}
     * @memberof Teacher
     */
    name?: string;
    /**
     * 外国人タイプ
     * @type {string}
     * @memberof Teacher
     */
    foreignType?: TeacherForeignTypeEnum;
    /**
     * 受け持つクラス情報
     * @type {Array<Class>}
     * @memberof Teacher
     */
    classes?: Array<Class>;
}

/**
    * @export
    * @enum {string}
    */
export enum TeacherForeignTypeEnum {
    Japanese = 'japanese',
    Foreigner = 'foreigner'
}

/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * teacher / student / guardian / staff / none のいずれか。
     * @type {string}
     * @memberof UserInfo
     */
    userType: string;
    /**
     * 
     * @type {UserInfoTeacher | UserInfoStudent | UserInfoGuardian}
     * @memberof UserInfo
     */
    details: UserInfoTeacher | UserInfoStudent | UserInfoGuardian;
}
/**
 * 
 * @export
 * @interface UserInfoGuardian
 */
export interface UserInfoGuardian {
    /**
     * 
     * @type {string}
     * @memberof UserInfoGuardian
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoGuardian
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoGuardian
     */
    name1: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoGuardian
     */
    name2: string;
    /**
     * 
     * @type {Array<UserInfoStudent>}
     * @memberof UserInfoGuardian
     */
    students: Array<UserInfoStudent>;
}
/**
 * 
 * @export
 * @interface UserInfoStudent
 */
export interface UserInfoStudent {
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    photoUrl: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    nameKana: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    sex: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoStudent
     */
    primarySchoolEnterDate: string;
    /**
     * 
     * @type {Array<StudentClass>}
     * @memberof UserInfoStudent
     */
    classes: Array<StudentClass>;
}
/**
 * 
 * @export
 * @interface UserInfoTeacher
 */
export interface UserInfoTeacher {
    /**
     * 
     * @type {string}
     * @memberof UserInfoTeacher
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoTeacher
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoTeacher
     */
    photoUrl: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoTeacher
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoTeacher
     */
    foreignType: string;
    /**
     * 
     * @type {Array<Class>}
     * @memberof UserInfoTeacher
     */
    classes: Array<Class>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Firebase権限情報を登録する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firebaseAuthUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/firebaseAuthUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザー情報を返却する。 photoUrlはブラウザキャッシュが行われる(10分)
         * @summary ユーザー基本情報を取得
         * @param {string} userId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicUserInfo: async (userId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getBasicUserInfo', 'userId', userId)
            const localVarPath = `/basicUserInfo/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスに所属する児童生徒を出席番号順に取得する。
         * @summary クラスの児童生徒一覧を取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassStudents: async (classId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('getClassStudents', 'classId', classId)
            const localVarPath = `/classes/{classId}/students`
                .replace(`{${"classId"}}`, encodeURIComponent(String(classId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * クラスを検索し、学校区分、学年、クラス番号順に取得する。
         * @summary クラスの一覧を取得する
         * @param {number} [grade] 学年を指定する。 
         * @param {string} [schoolType] 小中区分を指定する。
         * @param {number} [schoolYear] 年度（省略時は今年度）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClasses: async (grade?: number, schoolType?: string, schoolYear?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/classes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (grade !== undefined) {
                localVarQueryParameter['grade'] = grade;
            }

            if (schoolType !== undefined) {
                localVarQueryParameter['schoolType'] = schoolType;
            }

            if (schoolYear !== undefined) {
                localVarQueryParameter['schoolYear'] = schoolYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した クラス情報を取得する。
         * @summary クラスを取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassesClassId: async (classId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'classId' is not null or undefined
            assertParamExists('getClassesClassId', 'classId', classId)
            const localVarPath = `/classes/{classId}`
                .replace(`{${"classId"}}`, encodeURIComponent(String(classId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者の保護下にある児童生徒一覧を取得する。 保護者もしくは教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒一覧を取得する。
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdChildren: async (guardianId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('getGuardiansGuardianIdChildren', 'guardianId', guardianId)
            const localVarPath = `/guardians/{guardianId}/children`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定の保護者に紐付く児童生徒を指定して児童生徒プロファイル情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒プロファイル情報を取得する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdChildrenChildId: async (guardianId: string, childId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('getGuardiansGuardianIdChildrenChildId', 'guardianId', guardianId)
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getGuardiansGuardianIdChildrenChildId', 'childId', childId)
            const localVarPath = `/guardians/{guardianId}/children/{childId}/profile`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)))
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定の保護者に紐付く児童生徒を指定して児童生徒の検診情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒の検診プ情報を取得する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdChildrenChildIdHealth: async (guardianId: string, childId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('getGuardiansGuardianIdChildrenChildIdHealth', 'guardianId', guardianId)
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getGuardiansGuardianIdChildrenChildIdHealth', 'childId', childId)
            const localVarPath = `/guardians/{guardianId}/children/{childId}/health`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)))
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 保護者プロファイル情報を取得する
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdProfile: async (guardianId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('getGuardiansGuardianIdProfile', 'guardianId', guardianId)
            const localVarPath = `/guardians/{guardianId}/profile`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者もしくは教師のみアクセスできる。
         * @summary 保護者情報を取得する
         * @param {string} guardianId 保護者ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaentsGuardianId: async (guardianId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('getPaentsGuardianId', 'guardianId', guardianId)
            const localVarPath = `/guardians/{guardianId}`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 現在の学校情報を取得する。
         * @summary 学校情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchool: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/school`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 現在の学期を取得する。
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemester: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/semester`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 教職員プロファイル情報を取得する。 教職員のみアクセスできる。
         * @summary 教職員プロファイル情報を取得する
         * @param {string} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffStaffId: async (staffId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'staffId' is not null or undefined
            assertParamExists('getStaffStaffId', 'staffId', staffId)
            const localVarPath = `/staff/{staffId}`
                .replace(`{${"staffId"}}`, encodeURIComponent(String(staffId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した児童生徒IDの児童生徒情報を取得する。 児童生徒本人、児童生徒の保護者、教師のみアクセスできる。
         * @summary 児童生徒を取得する
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsStudentId: async (studentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studentId' is not null or undefined
            assertParamExists('getStudentsStudentId', 'studentId', studentId)
            const localVarPath = `/students/{studentId}`
                .replace(`{${"studentId"}}`, encodeURIComponent(String(studentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した教師IDの教師情報を取得する。
         * @summary 教師情報を取得する
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachersTeacherId: async (teacherId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'teacherId' is not null or undefined
            assertParamExists('getTeachersTeacherId', 'teacherId', teacherId)
            const localVarPath = `/teachers/{teacherId}`
                .replace(`{${"teacherId"}}`, encodeURIComponent(String(teacherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザー情報を取得する。
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserInfo', 'userId', userId)
            const localVarPath = `/userInfo/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーについて、以下2点を返す。  * ユーザータイプ。（教師`teacher` / 職員`staff` / 児童生徒`student` / 保護者`guardian` / 該当なし`none`） * ユーザータイプに応じた、権限判定に関わる情報。   * `teacher`の場合、何もなし。   * `staff`の場合、何もなし。   * `student`の場合、所属クラス一覧(※年度毎にクラスは異なるが、過去のものを含めてすべて返却する)。   * `guardian`の場合、自分の子供(`student`)の一覧。さらに、それら`student`が、それぞれ所属クラス一覧を持つ。   * `none`の場合、何もなし。  # ユーザー別制約  * 児童生徒と保護者   * 自分自身のみ取得可能。 * 教師   * なんでも取得可能。
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identity: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('identity', 'userId', userId)
            const localVarPath = `/identity/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardiansGuardianIdChildrenChildId: async (guardianId: string, childId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('patchGuardiansGuardianIdChildrenChildId', 'guardianId', guardianId)
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('patchGuardiansGuardianIdChildrenChildId', 'childId', childId)
            const localVarPath = `/guardians/{guardianId}/children/{childId}/profile`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)))
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 対象の児童生徒の検診情報を更新する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {ChildHealth} [childHealth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardiansGuardianIdChildrenChildIdHealth: async (guardianId: string, childId: string, childHealth?: ChildHealth, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('patchGuardiansGuardianIdChildrenChildIdHealth', 'guardianId', guardianId)
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('patchGuardiansGuardianIdChildrenChildIdHealth', 'childId', childId)
            const localVarPath = `/guardians/{guardianId}/children/{childId}/health`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)))
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(childHealth, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 保護者プロファイル情報を更新する。
         * @param {string} guardianId 
         * @param {GuardianProfile} [guardianProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardiansGuardianIdProfile: async (guardianId: string, guardianProfile?: GuardianProfile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'guardianId' is not null or undefined
            assertParamExists('patchGuardiansGuardianIdProfile', 'guardianId', guardianId)
            const localVarPath = `/guardians/{guardianId}/profile`
                .replace(`{${"guardianId"}}`, encodeURIComponent(String(guardianId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(guardianProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStaffStaffId: async (staffId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'staffId' is not null or undefined
            assertParamExists('patchStaffStaffId', 'staffId', staffId)
            const localVarPath = `/staff/{staffId}`
                .replace(`{${"staffId"}}`, encodeURIComponent(String(staffId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Firebase権限情報を登録する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async firebaseAuthUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.firebaseAuthUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザー情報を返却する。 photoUrlはブラウザキャッシュが行われる(10分)
         * @summary ユーザー基本情報を取得
         * @param {string} userId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBasicUserInfo(userId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicUserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBasicUserInfo(userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスに所属する児童生徒を出席番号順に取得する。
         * @summary クラスの児童生徒一覧を取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassStudents(classId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Student>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassStudents(classId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * クラスを検索し、学校区分、学年、クラス番号順に取得する。
         * @summary クラスの一覧を取得する
         * @param {number} [grade] 学年を指定する。 
         * @param {string} [schoolType] 小中区分を指定する。
         * @param {number} [schoolYear] 年度（省略時は今年度）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClasses(grade?: number, schoolType?: string, schoolYear?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Class>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClasses(grade, schoolType, schoolYear, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した クラス情報を取得する。
         * @summary クラスを取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClassesClassId(classId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Class>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClassesClassId(classId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者の保護下にある児童生徒一覧を取得する。 保護者もしくは教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒一覧を取得する。
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuardiansGuardianIdChildren(guardianId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Student>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuardiansGuardianIdChildren(guardianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定の保護者に紐付く児童生徒を指定して児童生徒プロファイル情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒プロファイル情報を取得する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuardiansGuardianIdChildrenChildId(guardianId: string, childId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuardiansGuardianIdChildrenChildId(guardianId, childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定の保護者に紐付く児童生徒を指定して児童生徒の検診情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒の検診プ情報を取得する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuardiansGuardianIdChildrenChildIdHealth(guardianId: string, childId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChildHealth>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuardiansGuardianIdChildrenChildIdHealth(guardianId, childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 保護者プロファイル情報を取得する
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuardiansGuardianIdProfile(guardianId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuardianProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuardiansGuardianIdProfile(guardianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者もしくは教師のみアクセスできる。
         * @summary 保護者情報を取得する
         * @param {string} guardianId 保護者ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaentsGuardianId(guardianId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Guardian>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaentsGuardianId(guardianId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 現在の学校情報を取得する。
         * @summary 学校情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSchool(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<School>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSchool(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 現在の学期を取得する。
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSemester(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Semester>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSemester(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 教職員プロファイル情報を取得する。 教職員のみアクセスできる。
         * @summary 教職員プロファイル情報を取得する
         * @param {string} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStaffStaffId(staffId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Staff>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStaffStaffId(staffId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した児童生徒IDの児童生徒情報を取得する。 児童生徒本人、児童生徒の保護者、教師のみアクセスできる。
         * @summary 児童生徒を取得する
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStudentsStudentId(studentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Student>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStudentsStudentId(studentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した教師IDの教師情報を取得する。
         * @summary 教師情報を取得する
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeachersTeacherId(teacherId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Teacher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeachersTeacherId(teacherId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザー情報を取得する。
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInfo(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInfo(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーについて、以下2点を返す。  * ユーザータイプ。（教師`teacher` / 職員`staff` / 児童生徒`student` / 保護者`guardian` / 該当なし`none`） * ユーザータイプに応じた、権限判定に関わる情報。   * `teacher`の場合、何もなし。   * `staff`の場合、何もなし。   * `student`の場合、所属クラス一覧(※年度毎にクラスは異なるが、過去のものを含めてすべて返却する)。   * `guardian`の場合、自分の子供(`student`)の一覧。さらに、それら`student`が、それぞれ所属クラス一覧を持つ。   * `none`の場合、何もなし。  # ユーザー別制約  * 児童生徒と保護者   * 自分自身のみ取得可能。 * 教師   * なんでも取得可能。
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identity(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Identity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identity(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchGuardiansGuardianIdChildrenChildId(guardianId: string, childId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGuardiansGuardianIdChildrenChildId(guardianId, childId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 対象の児童生徒の検診情報を更新する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {ChildHealth} [childHealth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchGuardiansGuardianIdChildrenChildIdHealth(guardianId: string, childId: string, childHealth?: ChildHealth, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGuardiansGuardianIdChildrenChildIdHealth(guardianId, childId, childHealth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 保護者プロファイル情報を更新する。
         * @param {string} guardianId 
         * @param {GuardianProfile} [guardianProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchGuardiansGuardianIdProfile(guardianId: string, guardianProfile?: GuardianProfile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGuardiansGuardianIdProfile(guardianId, guardianProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchStaffStaffId(staffId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchStaffStaffId(staffId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Firebase権限情報を登録する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        firebaseAuthUser(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.firebaseAuthUser(options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザー情報を返却する。 photoUrlはブラウザキャッシュが行われる(10分)
         * @summary ユーザー基本情報を取得
         * @param {string} userId 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicUserInfo(userId: string, body?: object, options?: any): AxiosPromise<BasicUserInfo> {
            return localVarFp.getBasicUserInfo(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスに所属する児童生徒を出席番号順に取得する。
         * @summary クラスの児童生徒一覧を取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassStudents(classId: string, options?: any): AxiosPromise<Array<Student>> {
            return localVarFp.getClassStudents(classId, options).then((request) => request(axios, basePath));
        },
        /**
         * クラスを検索し、学校区分、学年、クラス番号順に取得する。
         * @summary クラスの一覧を取得する
         * @param {number} [grade] 学年を指定する。 
         * @param {string} [schoolType] 小中区分を指定する。
         * @param {number} [schoolYear] 年度（省略時は今年度）
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClasses(grade?: number, schoolType?: string, schoolYear?: number, options?: any): AxiosPromise<Array<Class>> {
            return localVarFp.getClasses(grade, schoolType, schoolYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した クラス情報を取得する。
         * @summary クラスを取得する
         * @param {string} classId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClassesClassId(classId: string, options?: any): AxiosPromise<Class> {
            return localVarFp.getClassesClassId(classId, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者の保護下にある児童生徒一覧を取得する。 保護者もしくは教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒一覧を取得する。
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdChildren(guardianId: string, options?: any): AxiosPromise<Array<Student>> {
            return localVarFp.getGuardiansGuardianIdChildren(guardianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定の保護者に紐付く児童生徒を指定して児童生徒プロファイル情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒プロファイル情報を取得する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdChildrenChildId(guardianId: string, childId: string, options?: any): AxiosPromise<ChildProfile> {
            return localVarFp.getGuardiansGuardianIdChildrenChildId(guardianId, childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定の保護者に紐付く児童生徒を指定して児童生徒の検診情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
         * @summary 保護者に紐付く児童生徒の検診プ情報を取得する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdChildrenChildIdHealth(guardianId: string, childId: string, options?: any): AxiosPromise<ChildHealth> {
            return localVarFp.getGuardiansGuardianIdChildrenChildIdHealth(guardianId, childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 保護者プロファイル情報を取得する
         * @param {string} guardianId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuardiansGuardianIdProfile(guardianId: string, options?: any): AxiosPromise<GuardianProfile> {
            return localVarFp.getGuardiansGuardianIdProfile(guardianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者もしくは教師のみアクセスできる。
         * @summary 保護者情報を取得する
         * @param {string} guardianId 保護者ID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaentsGuardianId(guardianId: string, options?: any): AxiosPromise<Guardian> {
            return localVarFp.getPaentsGuardianId(guardianId, options).then((request) => request(axios, basePath));
        },
        /**
         * 現在の学校情報を取得する。
         * @summary 学校情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSchool(options?: any): AxiosPromise<School> {
            return localVarFp.getSchool(options).then((request) => request(axios, basePath));
        },
        /**
         * 現在の学期を取得する。
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSemester(options?: any): AxiosPromise<Semester> {
            return localVarFp.getSemester(options).then((request) => request(axios, basePath));
        },
        /**
         * 教職員プロファイル情報を取得する。 教職員のみアクセスできる。
         * @summary 教職員プロファイル情報を取得する
         * @param {string} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStaffStaffId(staffId: string, options?: any): AxiosPromise<Staff> {
            return localVarFp.getStaffStaffId(staffId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した児童生徒IDの児童生徒情報を取得する。 児童生徒本人、児童生徒の保護者、教師のみアクセスできる。
         * @summary 児童生徒を取得する
         * @param {string} studentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudentsStudentId(studentId: string, options?: any): AxiosPromise<Student> {
            return localVarFp.getStudentsStudentId(studentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した教師IDの教師情報を取得する。
         * @summary 教師情報を取得する
         * @param {string} teacherId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeachersTeacherId(teacherId: string, options?: any): AxiosPromise<Teacher> {
            return localVarFp.getTeachersTeacherId(teacherId, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザー情報を取得する。
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInfo(userId: string, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.getUserInfo(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーについて、以下2点を返す。  * ユーザータイプ。（教師`teacher` / 職員`staff` / 児童生徒`student` / 保護者`guardian` / 該当なし`none`） * ユーザータイプに応じた、権限判定に関わる情報。   * `teacher`の場合、何もなし。   * `staff`の場合、何もなし。   * `student`の場合、所属クラス一覧(※年度毎にクラスは異なるが、過去のものを含めてすべて返却する)。   * `guardian`の場合、自分の子供(`student`)の一覧。さらに、それら`student`が、それぞれ所属クラス一覧を持つ。   * `none`の場合、何もなし。  # ユーザー別制約  * 児童生徒と保護者   * 自分自身のみ取得可能。 * 教師   * なんでも取得可能。
         * @summary Your GET endpoint
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identity(userId: string, options?: any): AxiosPromise<Identity> {
            return localVarFp.identity(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardiansGuardianIdChildrenChildId(guardianId: string, childId: string, options?: any): AxiosPromise<void> {
            return localVarFp.patchGuardiansGuardianIdChildrenChildId(guardianId, childId, options).then((request) => request(axios, basePath));
        },
        /**
         * 対象の児童生徒の検診情報を更新する
         * @param {string} guardianId 
         * @param {string} childId 
         * @param {ChildHealth} [childHealth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardiansGuardianIdChildrenChildIdHealth(guardianId: string, childId: string, childHealth?: ChildHealth, options?: any): AxiosPromise<void> {
            return localVarFp.patchGuardiansGuardianIdChildrenChildIdHealth(guardianId, childId, childHealth, options).then((request) => request(axios, basePath));
        },
        /**
         * 保護者プロファイル情報を更新する。
         * @param {string} guardianId 
         * @param {GuardianProfile} [guardianProfile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGuardiansGuardianIdProfile(guardianId: string, guardianProfile?: GuardianProfile, options?: any): AxiosPromise<void> {
            return localVarFp.patchGuardiansGuardianIdProfile(guardianId, guardianProfile, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} staffId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchStaffStaffId(staffId: string, options?: any): AxiosPromise<void> {
            return localVarFp.patchStaffStaffId(staffId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Firebase権限情報を登録する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public firebaseAuthUser(options?: any) {
        return DefaultApiFp(this.configuration).firebaseAuthUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザー情報を返却する。 photoUrlはブラウザキャッシュが行われる(10分)
     * @summary ユーザー基本情報を取得
     * @param {string} userId 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBasicUserInfo(userId: string, body?: object, options?: any) {
        return DefaultApiFp(this.configuration).getBasicUserInfo(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスに所属する児童生徒を出席番号順に取得する。
     * @summary クラスの児童生徒一覧を取得する
     * @param {string} classId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClassStudents(classId: string, options?: any) {
        return DefaultApiFp(this.configuration).getClassStudents(classId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * クラスを検索し、学校区分、学年、クラス番号順に取得する。
     * @summary クラスの一覧を取得する
     * @param {number} [grade] 学年を指定する。 
     * @param {string} [schoolType] 小中区分を指定する。
     * @param {number} [schoolYear] 年度（省略時は今年度）
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClasses(grade?: number, schoolType?: string, schoolYear?: number, options?: any) {
        return DefaultApiFp(this.configuration).getClasses(grade, schoolType, schoolYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した クラス情報を取得する。
     * @summary クラスを取得する
     * @param {string} classId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getClassesClassId(classId: string, options?: any) {
        return DefaultApiFp(this.configuration).getClassesClassId(classId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者の保護下にある児童生徒一覧を取得する。 保護者もしくは教師のみアクセスできる。
     * @summary 保護者に紐付く児童生徒一覧を取得する。
     * @param {string} guardianId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGuardiansGuardianIdChildren(guardianId: string, options?: any) {
        return DefaultApiFp(this.configuration).getGuardiansGuardianIdChildren(guardianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定の保護者に紐付く児童生徒を指定して児童生徒プロファイル情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
     * @summary 保護者に紐付く児童生徒プロファイル情報を取得する
     * @param {string} guardianId 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGuardiansGuardianIdChildrenChildId(guardianId: string, childId: string, options?: any) {
        return DefaultApiFp(this.configuration).getGuardiansGuardianIdChildrenChildId(guardianId, childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定の保護者に紐付く児童生徒を指定して児童生徒の検診情報を取得する。 児童生徒の保護者、教師のみアクセスできる。
     * @summary 保護者に紐付く児童生徒の検診プ情報を取得する
     * @param {string} guardianId 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGuardiansGuardianIdChildrenChildIdHealth(guardianId: string, childId: string, options?: any) {
        return DefaultApiFp(this.configuration).getGuardiansGuardianIdChildrenChildIdHealth(guardianId, childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 保護者プロファイル情報を取得する
     * @param {string} guardianId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGuardiansGuardianIdProfile(guardianId: string, options?: any) {
        return DefaultApiFp(this.configuration).getGuardiansGuardianIdProfile(guardianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者もしくは教師のみアクセスできる。
     * @summary 保護者情報を取得する
     * @param {string} guardianId 保護者ID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPaentsGuardianId(guardianId: string, options?: any) {
        return DefaultApiFp(this.configuration).getPaentsGuardianId(guardianId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 現在の学校情報を取得する。
     * @summary 学校情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSchool(options?: any) {
        return DefaultApiFp(this.configuration).getSchool(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 現在の学期を取得する。
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSemester(options?: any) {
        return DefaultApiFp(this.configuration).getSemester(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 教職員プロファイル情報を取得する。 教職員のみアクセスできる。
     * @summary 教職員プロファイル情報を取得する
     * @param {string} staffId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStaffStaffId(staffId: string, options?: any) {
        return DefaultApiFp(this.configuration).getStaffStaffId(staffId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した児童生徒IDの児童生徒情報を取得する。 児童生徒本人、児童生徒の保護者、教師のみアクセスできる。
     * @summary 児童生徒を取得する
     * @param {string} studentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStudentsStudentId(studentId: string, options?: any) {
        return DefaultApiFp(this.configuration).getStudentsStudentId(studentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した教師IDの教師情報を取得する。
     * @summary 教師情報を取得する
     * @param {string} teacherId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTeachersTeacherId(teacherId: string, options?: any) {
        return DefaultApiFp(this.configuration).getTeachersTeacherId(teacherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザー情報を取得する。
     * @summary Your GET endpoint
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getUserInfo(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).getUserInfo(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーについて、以下2点を返す。  * ユーザータイプ。（教師`teacher` / 職員`staff` / 児童生徒`student` / 保護者`guardian` / 該当なし`none`） * ユーザータイプに応じた、権限判定に関わる情報。   * `teacher`の場合、何もなし。   * `staff`の場合、何もなし。   * `student`の場合、所属クラス一覧(※年度毎にクラスは異なるが、過去のものを含めてすべて返却する)。   * `guardian`の場合、自分の子供(`student`)の一覧。さらに、それら`student`が、それぞれ所属クラス一覧を持つ。   * `none`の場合、何もなし。  # ユーザー別制約  * 児童生徒と保護者   * 自分自身のみ取得可能。 * 教師   * なんでも取得可能。
     * @summary Your GET endpoint
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public identity(userId: string, options?: any) {
        return DefaultApiFp(this.configuration).identity(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} guardianId 
     * @param {string} childId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchGuardiansGuardianIdChildrenChildId(guardianId: string, childId: string, options?: any) {
        return DefaultApiFp(this.configuration).patchGuardiansGuardianIdChildrenChildId(guardianId, childId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 対象の児童生徒の検診情報を更新する
     * @param {string} guardianId 
     * @param {string} childId 
     * @param {ChildHealth} [childHealth] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchGuardiansGuardianIdChildrenChildIdHealth(guardianId: string, childId: string, childHealth?: ChildHealth, options?: any) {
        return DefaultApiFp(this.configuration).patchGuardiansGuardianIdChildrenChildIdHealth(guardianId, childId, childHealth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 保護者プロファイル情報を更新する。
     * @param {string} guardianId 
     * @param {GuardianProfile} [guardianProfile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchGuardiansGuardianIdProfile(guardianId: string, guardianProfile?: GuardianProfile, options?: any) {
        return DefaultApiFp(this.configuration).patchGuardiansGuardianIdProfile(guardianId, guardianProfile, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} staffId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchStaffStaffId(staffId: string, options?: any) {
        return DefaultApiFp(this.configuration).patchStaffStaffId(staffId, options).then((request) => request(this.axios, this.basePath));
    }
}


