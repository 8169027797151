import { render, staticRenderFns } from "./JournalFileUploadButton.vue?vue&type=template&id=3722c750&scoped=true&"
import script from "./JournalFileUploadButton.vue?vue&type=script&lang=ts&"
export * from "./JournalFileUploadButton.vue?vue&type=script&lang=ts&"
import style0 from "./JournalFileUploadButton.vue?vue&type=style&index=0&id=3722c750&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3722c750",
  null
  
)

export default component.exports