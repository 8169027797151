// TODO 本番ビルドでは消えてくれるだろうか？ tree-shakingがどう働くのか良く分かっていない。
import { Quarter } from "@/ts/objects/common/Quarter";
import {
  partialArgsToProjectTestDataSet,
  ProjectTestDataPartialArgs,
  ProjectTestDataSet
} from "@/test-tools/ProjectTestDataTypes";

export function getProjectTestData(): ProjectTestDataSet {
  const projects: ProjectTestDataPartialArgs = {
    project000: {
      classId: "class000",
      quarter: new Quarter(2000, 1),
      name: "プロジェクト000",
      description: "プロジェクト000の説明",
      relatedSyllabus: "プロジェクト000の関連するシラバス",
      viewPointSEnabled: true,
      viewPointAEnabled: true,
      viewPointBEnabled: false,
      viewPointCEnabled: true,
      startingMonth: { year: 1999, month: 4 },
      endingMonth: { year: 2001, month: 3 },
      published: true,
      completed: false,
      lookbacks: {
        lookback000: {
          studentUserId: "student000",
          studentComment: "ふりかえり000の児童生徒コメント",
          studentRating: "C",
          teacherComment: "ふりかえり000の教師コメント",
          teacherRating: "B",
          teacherInputPublished: true,
          guardianComment: "ふりかえり000の保護者コメント",
          studentInputLocked: false,
          guardianInputLocked: true
        }
      },
      rubrics: {
        rubric000: {
          orderNum: 0,
          learningActivity: "学習活動000",
          viewPointS: "観点S 000",
          viewPointA: "観点A 000",
          viewPointB: "観点B 000",
          viewPointC: "観点C 000",
          journals: {
            journal000: {
              studentUserId: "student000",
              studentComment: "児童生徒コメント000",
              studentRating: "A",
              teacherComment: "教師コメント000",
              teacherRating: "S",
              teacherInputPublished: true,
              studentInputLocked: false,
              journalFiles: {
                journalFile000: {
                  filename: "filename000",
                  createdAt: "2000-01-01T00:00:00Z",
                  updatedAt: "2000-01-01T00:00:00Z"
                },
                journalFile001: {
                  filename: "filename001",
                  createdAt: "2000-01-01T00:00:00Z",
                  updatedAt: "2000-01-01T00:00:00Z"
                }
              }
            }
          }
        },
        rubric001: {
          orderNum: 1,
          learningActivity: "学習活動001",
          viewPointS: "観点S 001",
          viewPointA: "観点A 001",
          viewPointB: "観点B 001",
          viewPointC: "観点C 001",
          journals: {
            journal001: {
              studentUserId: "student000",
              studentComment: "児童生徒コメント001",
              studentRating: "",
              teacherComment: "教師コメント001",
              teacherRating: "B",
              teacherInputPublished: true,
              studentInputLocked: false
            }
          }
        },
        rubric002: {
          orderNum: 2,
          learningActivity: "学習活動002",
          viewPointS: "観点S 002",
          viewPointA: "観点A 002",
          viewPointB: "観点B 002",
          viewPointC: "観点C 002",
          journals: {
            journal002: {
              studentUserId: "student000",
              studentComment: "児童生徒コメント002",
              studentRating: "A",
              teacherComment: "教師コメント002",
              teacherRating: "",
              teacherInputPublished: true,
              studentInputLocked: false
            }
          }
        },
        rubric003: {
          orderNum: 3,
          learningActivity:
            "おれはその時その青黒く淀んだ室の中の堅い灰色の自分の席にそわそわ立ったり座ったりしてゐた。\n" +
            "　二人の男がその室の中に居た。一人はたしかに獣医の有本でも一人はさまざまのやつらのもやもやした区分キメラであった。\n" +
            "　おれはどこかへ出て行かうと考へてゐるらしかった。飛ぶんだぞ霧の中をきいっとふっとんでやるんだなどと頭の奥で叫んでゐた。ところがその二人がしきりに着物のはなしをした。",
          viewPointS:
            "おれはひどくむしゃくしゃした。そして卓をガタガタゆすってゐた。\n" +
            "　いきなり霧積が入って来た。霧積は変に白くぴかぴかする金襴の羽織を着てゐた。そしてひどく嬉しさうに見えた。今朝は支那版画展覧会があって自分はその幹事になってゐるからそっちへ行くんだと云ってかなり大声で笑った。おれはそれがしゃくにさわった。第一霧積は今日はおれと北の方の野原へ出かける約束だったのだ、それを白っぽい金襴の羽織などを着込んでわけもわからない処へ行ってけらけら笑ったりしやうといふのはあんまり失敬だと　おれは考へた。",
          viewPointA: "観点A 003",
          viewPointB: "観点B 003",
          viewPointC: "観点C 003"
        }
      }
    },
    project001: {
      classId: "class000",
      quarter: new Quarter(2000, 1),
      name: "プロジェクト001",
      description: "プロジェクト001の説明",
      relatedSyllabus: "プロジェクト001の関連するシラバス",
      viewPointSEnabled: true,
      viewPointAEnabled: true,
      viewPointBEnabled: false,
      viewPointCEnabled: true,
      startingMonth: { year: 2000, month: 5 },
      endingMonth: { year: 2000, month: 8 },
      published: true,
      completed: false,
      rubrics: {}
    }
  };
  return partialArgsToProjectTestDataSet(projects);
}
