























import Vue, { PropType } from "vue";
import clamp from "lodash/clamp";
import RouterLinkWrapper from "@/components/RouterLinkWrapper.vue";

export default Vue.extend({
  name: "SchoolYearControl",
  components: { RouterLinkWrapper },
  props: {
    schoolYear: { type: Number as PropType<number | null>, required: true },
    minSchoolYear: { type: Number as PropType<number | null>, required: true },
    maxSchoolYear: { type: Number as PropType<number | null>, required: true },
    schoolYearToPath: { type: Function as PropType<(schoolYear: number) => string>, required: true },
    opacity: { type: Number, default: 0.25 }
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--opacity": `${this.opacity}`
      };
    },
    prevSchoolYear(): number | null {
      const schoolYear = this.schoolYear;
      if (schoolYear === null) return null;
      return clamp(schoolYear - 1, this.minSchoolYear ?? 2000, this.maxSchoolYear ?? 2000);
    },
    nextSchoolYear(): number | null {
      const schoolYear = this.schoolYear;
      if (schoolYear === null) return null;
      return clamp(schoolYear + 1, this.minSchoolYear ?? 2000, this.maxSchoolYear ?? 2000);
    }
  },
  methods: {
    _schoolYearToPath(schoolYear: number | null): string | null {
      if (schoolYear === null) return null;
      return this.schoolYearToPath(schoolYear);
    }
  }
});
