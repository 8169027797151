















































import Vue, { PropType } from "vue";
import { MonthValue } from "@/ts/utils";
import { RatingValue } from "@/ts/objects/common/Rating";
import RouterLinkWrapper from "@/components/RouterLinkWrapper.vue";
import CurriculumListNECContentRow from "@/views/curriculum/teacher/CurriculumListNEC/components/CurriculumListNECContentRow/CurriculumListNECContentRow.vue";
import {
  CurriculumListNECContentRowFilterState,
  CurriculumListNECMonthFilterState,
  ICurriculumListNECStudentRowModel
} from "@/views/curriculum/teacher/CurriculumListNEC/CurriculumListNECModel";

export default Vue.extend({
  name: "CurriculumListNECStudentRow",
  components: { CurriculumListNECContentRow, RouterLinkWrapper },
  props: {
    model: { type: Object as PropType<ICurriculumListNECStudentRowModel>, required: true },

    contentRowFilter: { type: Object as PropType<CurriculumListNECContentRowFilterState>, required: true },
    monthFilter: { type: Object as PropType<CurriculumListNECMonthFilterState>, required: true },

    studentColWidth: { type: Number, required: true },
    viewPointColWidth: { type: Number, required: true },
    contentColWidth: { type: Number, required: true },
    evaluationColWidth: { type: Number, required: true },

    studentUserIdToStudentViewPath: { type: Function as PropType<(studentUserId: string) => string>, required: true },
    onInput: {
      type: Function as PropType<
        (viewPointId: string, contentId: string, month: MonthValue, studentUserId: string, rating: RatingValue) => void
      >,
      required: true
    },
    onChangeSinglePublishState: {
      type: Function as PropType<
        (viewPointId: string, contentId: string, month: MonthValue, studentUserId: string, published: boolean) => void
      >,
      required: true
    }
  },
  computed: {
    noContentRowsWidth(): number {
      return this.viewPointColWidth + this.contentColWidth + this.evaluationColWidth * this.monthFilter.months.length;
    },
    studentViewPath(): string {
      return this.studentUserIdToStudentViewPath(this.model.studentUserId);
    }
  }
});
