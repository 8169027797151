import { getDownloadUrlOfStorageObject, isNullish } from "@/ts/utils";

export abstract class JournalFile {
  abstract readonly type: string;
  abstract readonly subtype: string;
  abstract readonly mediaType: string;

  abstract readonly filename: string;
  abstract readonly ext: string;

  abstract readonly width: number | null;
  abstract readonly height: number | null;

  abstract readonly updatedAt: Date;

  private _fileUrl: string | null = null;
  private _thumbnailUrl: string | null = null;

  protected constructor(hasThumbnail: boolean, gcsObjectPath: string, thumbnailGcsObjectPath: string | null) {
    this.initUrls(hasThumbnail, gcsObjectPath, thumbnailGcsObjectPath);
  }

  get fileUrl(): string | null {
    return this._fileUrl;
  }

  get thumbnailUrl(): string | null {
    return this._thumbnailUrl;
  }

  private _markedAsDeleting: boolean = false;

  markAsDeleting() {
    this._markedAsDeleting = true;
  }

  get markedAsDeleting(): boolean {
    return this._markedAsDeleting;
  }

  private async initUrls(
    hasThumbnail: boolean,
    gcsObjectPath: string,
    thumbnailGcsObjectPath: string | null
  ): Promise<void> {
    if (hasThumbnail && !isNullish(thumbnailGcsObjectPath)) {
      const [fileUrl, thumbnailUrl] = await Promise.all([
        getDownloadUrlOfStorageObject(gcsObjectPath),
        getDownloadUrlOfStorageObject(thumbnailGcsObjectPath)
      ]);

      this._fileUrl = fileUrl;
      this._thumbnailUrl = thumbnailUrl;
    } else {
      this._fileUrl = await getDownloadUrlOfStorageObject(gcsObjectPath);
    }
  }
}
