import { MonthValue } from "@/ts/utils";
import { EECActivityTree } from "@/ts/objects/curriculum/value/EECActivity";
import { IEECMonth, IEECMonthTree } from "@/ts/objects/curriculum/IEECMonth";

/**
 * 文書評価教科の月。
 *
 * リソース名は /eeCurriculums/{eecId}/months/{1, 2, ..., 12}。
 *
 * 文書評価教科 : 月 = 1 : 12
 */
export class EECMonth implements IEECMonth {
  constructor(public readonly eecId: string, public readonly month: MonthValue) {}

  get resourceName(): string {
    return `/eeCurriculums/${this.eecId}/months/${this.month}`;
  }
}

export class EECMonthTree implements IEECMonthTree {
  constructor(public readonly self: EECMonth, public readonly activities: EECActivityTree[]) {}
}
