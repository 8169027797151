import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { isMonthValue, MonthValue, parseUTCOrZero } from "@/ts/utils";
import {
  EditableBoolean,
  EditableHashedString,
  EditableNumber
} from "@/ts/objects/editable/value/EditablePrimitiveValue";
import { Editable } from "@/ts/objects/editable/Editable";
import { DisplayableErr, Err } from "@/ts/objects/Err";
import { messages } from "@/ts/const/Messages";
import { EECJournalFileTree, EECJournalStudent } from "@/ts/objects/curriculum/value/EECJournal";
import { Journal as JournalResp } from "@/ts/api/curriculum-service";
import { IEECJournal } from "@/ts/objects/curriculum/IEECJournal";
import log from "loglevel";

export class EditableEECJournalStudentTree {
  constructor(public readonly self: EECJournalStudent, public readonly journals: EditableEECJournalTree[]) {}
}

/**
 * 編集可能な文書評価教科の学習活動記録。
 *
 * リソース名は /eeCurriculums/{eecId}/journalStudents/{studentUserId}/journals/{journalId}。
 */
export class EditableEECJournal extends EditableObject implements IEECJournal {
  constructor(
    repo: CurriculumRepository,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    public readonly eecId: string,
    public readonly studentUserId: string,
    public readonly journalId: string,
    month: MonthValue,
    activity: string,
    activityHash: string,
    studentComment: string,
    studentCommentHash: string,
    teacherComment: string,
    teacherCommentHash: string,
    teacherInputPublished: boolean,
    createdAt: string,
    public readonly studentInputLocked: boolean
  ) {
    super();

    this._month = new EditableNumber("month", month, studentInputSavable, async value => {
      const resp = await repo.patchEECJournal(eecId, studentUserId, journalId, {
        month: value
      });
      if (resp instanceof Err) return resp;
      return resp.month;
    });
    this._activity = new EditableHashedString(
      "activity",
      activity,
      activityHash,
      studentInputSavable,
      async ({ value, hash }) => {
        log.debug(`EditableEECJournal:activity: value=${value}, hash=${hash}`);
        const resp = await repo.patchEECJournal(eecId, studentUserId, journalId, {
          activity: { value, hash }
        });
        if (resp instanceof Err) return resp;
        return [resp.activity.value, resp.activity.hash];
      }
    );
    this._studentComment = new EditableHashedString(
      "studentComment",
      studentComment,
      studentCommentHash,
      studentInputSavable,
      async ({ value, hash }) => {
        const resp = await repo.patchEECJournal(eecId, studentUserId, journalId, {
          studentComment: { value, hash }
        });
        if (resp instanceof Err) return resp;
        return [resp.studentComment.value, resp.studentComment.hash];
      }
    );
    this._teacherComment = new EditableHashedString(
      "teacherComment",
      teacherComment,
      teacherCommentHash,
      teacherInputSavable,
      async ({ value, hash }) => {
        const resp = await repo.patchEECJournal(eecId, studentUserId, journalId, {
          teacherComment: { value, hash }
        });
        if (resp instanceof Err) return resp;
        if (resp.teacherComment === undefined)
          return new DisplayableErr(`invalid response: ${JSON.stringify(resp)}`, messages.failedToLoadData);
        return [resp.teacherComment.value, resp.teacherComment.hash];
      }
    );
    this._teacherInputPublished = new EditableBoolean(
      "teacherInputPublished",
      teacherInputPublished,
      teacherInputSavable,
      async value => {
        const resp = await repo.patchEECJournal(eecId, studentUserId, journalId, {
          teacherInputPublished: value
        });
        if (resp instanceof Err) return resp;
        return resp.teacherInputPublished;
      }
    );
    this.createdAt = parseUTCOrZero(createdAt);
  }

  private readonly _month: EditableNumber;
  private readonly _activity: EditableHashedString;
  private readonly _studentComment: EditableHashedString;
  private readonly _teacherComment: EditableHashedString;
  private readonly _teacherInputPublished: EditableBoolean;
  readonly createdAt: Date;

  protected allEditables(): Editable[] {
    return [this._month, this._activity, this._studentComment, this._teacherComment, this._teacherInputPublished];
  }

  get resourceName(): string {
    return `/eeCurriculums/${this.eecId}/journalStudents/${this.studentUserId}/journals/${this.journalId}`;
  }

  get month(): MonthValue {
    const value = this._month.value;
    if (!isMonthValue(value)) throw new Error(`EditableEECJournal.month: value ${value} is not a month`);
    return value;
  }

  set month(value: MonthValue) {
    this._month.value = value;
  }

  get activity(): string {
    return this._activity.value;
  }

  set activity(value: string) {
    this._activity.value = value;
  }

  get studentComment(): string {
    return this._studentComment.value;
  }

  set studentComment(value: string) {
    this._studentComment.value = value;
  }

  get teacherComment(): string {
    return this._teacherComment.value;
  }

  set teacherComment(value: string) {
    this._teacherComment.value = value;
  }

  get teacherInputPublished(): boolean {
    return this._teacherInputPublished.value;
  }

  set teacherInputPublished(value: boolean) {
    this._teacherInputPublished.value = value;
  }

  static fromJournalResp(
    repo: CurriculumRepository,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    resp: JournalResp
  ): EditableEECJournal {
    log.debug(`EditableEECJournal.fromJournalResp: ${JSON.stringify(resp)}`);
    if (!isMonthValue(resp.month))
      throw new Error(`EditableEECJournal.fromJournalResp: invalid month value on ${resp.self}: ${resp.month}`);
    return new EditableEECJournal(
      repo,
      teacherInputSavable,
      studentInputSavable,
      resp.eecId,
      resp.studentUserId,
      resp.journalId,
      resp.month,
      resp.activity.value,
      resp.activity.hash,
      resp.studentComment.value,
      resp.studentComment.hash,
      resp.teacherComment?.value ?? "",
      resp.teacherComment?.hash ?? "",
      resp.teacherInputPublished,
      resp.createdAt,
      resp.studentInputLocked
    );
  }
}

export class EditableEECJournalTree {
  constructor(
    private readonly repo: CurriculumRepository,
    public readonly self: EditableEECJournal,
    journalFiles: EECJournalFileTree[]
  ) {
    this._journalFiles = journalFiles;
  }

  private _journalFiles: EECJournalFileTree[];

  get journalFiles(): EECJournalFileTree[] {
    return this._journalFiles;
  }

  async reloadJournalFiles(): Promise<void> {
    const resp = await this.repo.listEECJournalFiles(this.self.eecId, this.self.studentUserId, this.self.journalId);
    if (resp instanceof Err) {
      // TODO エラーハンドリング？
      log.error(`EditableEECJournalTree.reloadJournalFiles: Error reloading journalFiles.`);
      return;
    }

    this._journalFiles = resp.map(jf => new EECJournalFileTree(jf));
  }
}
