import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import Vue from "vue";
import { initializeRepositories, initializeRepositoryMocks } from "@/ts/Services";
import { AppStateStore, appStateStoreGlobal } from "@/store/AppStateStore";
import { Err } from "@/ts/objects/Err";
import log, { LogLevelDesc } from "loglevel";
import { getLogLevel } from "@/ts/Logging";
import { UserType } from "@/ts/objects/common/UserType";
import { AuthService, getAuthService } from "@/ts/AuthService";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { LogRepository } from "@/ts/repositories/LogRepository";
import { ActivityRepository } from "@/ts/repositories/ActivityRepository";
import { ProjectStore } from "@/store/ProjectStore";
import { ProjectInfoOnEditStore } from "@/store/ProjectInfoOnEditStore";
import { SolanStoreS, SolanStoreT } from "@/store/SolanStore";
import { SolanProjectInfoOnEditStore } from "@/store/SolanProjectOnEditStore";
import { getModule } from "vuex-module-decorators";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { CurriculumStoreS } from "@/store/CurriculumStoreS";
import { CurriculumStoreT } from "@/store/CurriculumStoreT";

const HOST_TO_CONFIG = new Map<string, AppConfig>([
  [
    "develop0.manaport.seto-solan.ed.jp",
    {
      clientId: "336753560362-3ugsjam3c4l5eior42gvfphdrci1onmt.apps.googleusercontent.com",
      storageBucket: "gs://eportfolio-dev0-storage",
      defaultLogLevel: log.levels.DEBUG
    }
  ],
  [
    "develop1.manaport.seto-solan.ed.jp",
    {
      clientId: "143483732184-sv0mrcaldf13r2oon9amqtrkum7evbsg.apps.googleusercontent.com",
      storageBucket: "gs://eportfolio-dev1-storage",
      defaultLogLevel: log.levels.DEBUG
    }
  ],
  [
    "develop2.manaport.seto-solan.ed.jp",
    {
      clientId: "513789769022-iukf8o7mhic6oofh9ugoia4jbe0eh9bf.apps.googleusercontent.com",
      storageBucket: "gs://eportfolio-dev2-storage",
      defaultLogLevel: log.levels.DEBUG
    }
  ],
  [
    "develop3.manaport.seto-solan.ed.jp",
    {
      clientId: "277774883463-2mh5erqf8uamsib5aveqcl0r53ceo2l7.apps.googleusercontent.com",
      storageBucket: "gs://eportfolio-dev3-storage",
      defaultLogLevel: log.levels.DEBUG
    }
  ],
  [
    "develop4.manaport.seto-solan.ed.jp",
    {
      clientId: "549131332481-v34df8jsrc19olbebiert8ok0ug7617g.apps.googleusercontent.com",
      storageBucket: "gs://eportfolio-dev4-storage",
      defaultLogLevel: log.levels.DEBUG
    }
  ],
  [
    "testing.manaport.seto-solan.ed.jp",
    {
      clientId: "335621474908-oco8kmcni4pqs7eq6mpoe98j9rbsb1sq.apps.googleusercontent.com",
      storageBucket: "gs://eportfolio-testing-storage",
      defaultLogLevel: log.levels.DEBUG
    }
  ],
  [
    "staging.manaport.seto-solan.ed.jp",
    {
      clientId: "486707120532-rumlbb970u96kmu1litm6udnus04ud5d.apps.googleusercontent.com",
      storageBucket: "gs://eportfolio-staging-storage",
      defaultLogLevel: log.levels.INFO
    }
  ],
  [
    "www.manaport.seto-solan.ed.jp",
    {
      clientId: "1095247098471-eq0j0la5njv1220lepsv8910868lqpa8.apps.googleusercontent.com",
      storageBucket: "gs://eportfolio-production-storage",
      defaultLogLevel: log.levels.INFO
    }
  ]
]);

export type AppConfig = {
  readonly clientId: string | null;
  readonly storageBucket: string | null;
  readonly defaultLogLevel: LogLevelDesc;
};

export type DebugConfig = {
  readonly debugUserType: UserType;
  readonly debugUserId: string;
};

export type InitializationResult = {
  readonly appStateStore: AppStateStore;
  readonly curriculumStoreS: CurriculumStoreS;
  readonly curriculumStoreT: CurriculumStoreT;
  readonly projectStore: ProjectStore;
  readonly projectInfoOnEditStore: ProjectInfoOnEditStore;
  readonly solanStoreS: SolanStoreS;
  readonly solanStoreT: SolanStoreT;
  readonly solanProjectInfoOnEditStore: SolanProjectInfoOnEditStore;

  readonly authService: AuthService;
  readonly userRepository: UserRepository;
  readonly activityRepository: ActivityRepository;
  readonly projectRepository: ProjectRepository;
  readonly solanRepository: SolanRepository;
  readonly curriculumRepository: CurriculumRepository;
  readonly logRepository: LogRepository;
};

export async function initializeApp(localDebugging: boolean): Promise<InitializationResult> {
  const conf = HOST_TO_CONFIG.get(window.location.host) ?? {
    clientId: null,
    storageBucket: null,
    defaultLogLevel: log.levels.DEBUG
  };
  log.setDefaultLevel(conf.defaultLogLevel);
  console.log(`Log level is "${getLogLevel()}". Run "setLogLevel('debug')" to change.`);
  log.debug(`conf=${JSON.stringify(conf)}`);

  const debugConfig = localDebugging ? { debugUserType: "teacher" as const, debugUserId: "teacher000" } : null;
  // const debugConfig = localDebugging ? { debugUserType: "student" as const, debugUserId: "student000" } : null;
  // const debugConfig = localDebugging ? { debugUserType: "guardian" as const, debugUserId: "guardian000" } : null;

  // Vuexストアモジュールを作成・取得する。
  const appStateStore = appStateStoreGlobal;
  const projectStore = getModule(ProjectStore);
  const projectInfoOnEditStore = getModule(ProjectInfoOnEditStore);
  const solanStoreS = getModule(SolanStoreS);
  const solanStoreT = getModule(SolanStoreT);
  const solanProjectInfoOnEditStore = getModule(SolanProjectInfoOnEditStore);
  const curriculumStoreS = getModule(CurriculumStoreS);
  const curriculumStoreT = getModule(CurriculumStoreT);

  const authService = await getAuthService(conf.clientId, appStateStore, debugConfig);

  const [userRepository, activityRepository, projectRepository, solanRepository, curriculumRepository, logRepository] =
    debugConfig === null ? initializeRepositories(authService) : initializeRepositoryMocks();

  authService.setUserRepository(userRepository);

  if (!localDebugging && !(await initializeFirebase(conf))) {
    // TODO redirect to 500 page or something?
    log.error("Failed to initialize Firebase.");
  }

  // Googleにログイン。
  const googleIdToken = await authService.signInToGoogle(false);
  if (googleIdToken === null) {
    // TODO redirect to 500 page or something?
    log.error("Failed to sign in to Google.");
  }

  // Firebaseにログイン。
  if (!(await authService.signInToFirebase(googleIdToken ?? ""))) {
    // TODO redirect to 500 page or something?
    log.error("Failed to sign in.");
  }

  await setCurrentQuarter(userRepository);

  log.debug("Initialized app.");

  return {
    appStateStore,
    curriculumStoreS,
    curriculumStoreT,
    projectStore,
    projectInfoOnEditStore,
    solanStoreS,
    solanStoreT,
    solanProjectInfoOnEditStore,

    authService,
    userRepository,
    activityRepository,
    projectRepository,
    solanRepository,
    curriculumRepository,
    logRepository
  };
}

async function initializeFirebase(conf: AppConfig): Promise<boolean> {
  try {
    const initResp = await fetch("/__/firebase/init.json");
    const initJson = await initResp.json();
    const firebaseApp = firebase.initializeApp(initJson);
    const db = firebaseApp.firestore();

    Vue.prototype.$firebaseApp = firebaseApp;
    Vue.prototype.$firestore = db;
    Vue.prototype.$storage = firebaseApp.storage(conf.storageBucket ?? "");

    log.debug(`Initialized Firebase with ${JSON.stringify(initJson)}`);

    return true;
  } catch (e) {
    log.debug(`Failed to initialize Firebase: name=${e.name}, message=${e.message}`);
    return false;
  }
}

declare global {
  interface Window {
    gapi: any;
  }
}

declare module "vue/types/vue" {
  interface Vue {
    // $localDebugging: boolean;
    // $localDebuggingAs: string;
    $firebaseApp: firebase.app.App;
    $firestore: firebase.firestore.Firestore;
    $storage: firebase.storage.Storage | undefined;
    $googleAuthInstance: any;
    $googleIdToken: string | null; // NOT a firebase ID Token.
  }
}

async function setCurrentQuarter(userRepository: UserRepository): Promise<void> {
  const quarter = await userRepository.getCurrentQuarter();
  if (quarter instanceof Err) return;
  appStateStoreGlobal.setCurrentQuarter(quarter);
}
