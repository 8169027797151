import { MonthValue } from "@/ts/utils";

export type FormattedDatetime = {
  month: MonthValue;
  dayOfMonth: number;
  dayOfWeek: string;
  hour: number;
  minute: number;
  second: number;
};

const daysOfWeek = ["日", "月", "火", "水", "木", "金", "土"];

export function formatDate(date: Date): FormattedDatetime {
  return {
    month: (date.getMonth() + 1) as MonthValue,
    dayOfMonth: date.getDate(),
    dayOfWeek: daysOfWeek[date.getDay()],
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds()
  };
}
