


























































import Vue, { PropType } from "vue";
import { EECJournal, EECJournalFile } from "@/ts/objects/curriculum/value/EECJournal";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import JournalFilePreviewArea from "@/components/journalfile/JournalFilePreviewArea/JournalFilePreviewArea.vue";
import JournalFilesView from "@/components/journalfile/JournalFilesView.vue";
import { IEECJournalTree } from "@/ts/objects/curriculum/IEECJournal";
import { isMonthValue, isNullish, MonthValue } from "@/ts/utils";
import PopupMenuButton, { MenuButton, MenuItem } from "@/components/PopupMenuButton.vue";
import CurriculumJournalActivityCell from "@/views/curriculum/components/CurriculumJournalActivityCell/CurriculumJournalActivityCell.vue";

export default Vue.extend({
  name: "CurriculumJournalRow",
  components: {
    CurriculumJournalActivityCell,
    PopupMenuButton,
    JournalFilesView,
    JournalFilePreviewArea,
    DisableableAutoResizeTextarea
  },
  props: {
    /**
     * 学習記録。
     */
    journalTree: { type: Object as PropType<IEECJournalTree>, required: true },

    /**
     * 学習活動の選択肢。
     */
    activityOptions: { type: Array as PropType<string[] | null | undefined> },

    /**
     * trueなら、他の条件を満たした場合には編集可能。
     * falseなら、何があっても編集不可能。
     */
    editableIfPossible: { type: Boolean, required: true },

    /**
     * 現在、この学習記録のファイルビューが開かれていればtrue。
     */
    isFilesViewOpen: { type: Boolean, required: true },

    /**
     * 月カラムの幅（px）。
     */
    monthColWidth: { type: Number, required: true },

    /**
     * メニューボタンカラムの幅（px）。
     */
    extraMenuColWidth: { type: Number, required: true },

    /**
     * その他のカラムの最小幅（px）。
     */
    otherColMinWidth: { type: Number, required: true },

    /**
     * 現在ファイルビューを開いている学習記録を変更する関数。
     */
    selectFilesViewJournal: { type: Function as PropType<(rname: string | null) => void>, required: true },

    onInputMonth: { type: Function as PropType<(journalId: string, value: MonthValue) => void> },
    onInputActivity: { type: Function as PropType<(journalId: string, value: string) => void> },
    onInputStudentComment: { type: Function as PropType<(journalId: string, value: string) => void> },
    onUploadJournalFile: { type: Function as PropType<(journalId: string, file: any) => Promise<void>> },
    onDeleteJournalFile: { type: Function as PropType<(journalId: string, journalFileId: string) => Promise<void>> },
    onDeleteJournal: { type: Function as PropType<(journalId: string) => Promise<boolean>> }
  },
  data(): {
    cellColor0: string;
    cellColor1: string;

    extraMenuItems: MenuItem[];

    currentlyDeleting: boolean;
  } {
    return {
      cellColor0: "#e8e9f4",
      cellColor1: "transparent",

      extraMenuItems: [new MenuButton("delete", "削除", ["fas", "trash-alt"])],

      currentlyDeleting: false
    };
  },
  computed: {
    journal(): EECJournal {
      return this.journalTree.self;
    },
    journalId(): string {
      return this.journal.journalId;
    },
    journalFiles(): EECJournalFile[] {
      return this.journalTree.journalFiles.map(jf => jf.self);
    },
    styles(): Record<string, string> {
      return {
        "--cell-color-0": this.cellColor0,
        "--cell-color-1": this.cellColor1,
        "--monthColWidth": `${this.monthColWidth}px`,
        "--extraMenuColWidth": `${this.extraMenuColWidth}px`,
        "--otherColMinWidth": `${this.otherColMinWidth}px`
      };
    },
    editable(): boolean {
      return (
        this.editableIfPossible &&
        !this.journal.studentInputLocked &&
        !isNullish(this.onInputMonth) &&
        !isNullish(this.onInputActivity) &&
        !isNullish(this.onInputStudentComment) &&
        !isNullish(this.onUploadJournalFile) &&
        !isNullish(this.onDeleteJournalFile) &&
        !isNullish(this.onDeleteJournal) &&
        !this.currentlyDeleting
      );
    }
  },
  methods: {
    _onInputMonth(e: Event) {
      if (!this.editable) return;

      const target = e?.target;
      if (!(target instanceof HTMLSelectElement)) return;
      const month = parseInt(target.value, 10);
      if (!isMonthValue(month)) return;
      this.onInputMonth(this.journalId, month);
    },
    _onInputActivity(value: string) {
      if (!this.editable) return;
      this.onInputActivity(this.journalId, value);
    },
    _onInputStudentComment(value: string) {
      if (!this.editable) return;
      this.onInputStudentComment(this.journalId, value);
    },
    onClickFilesCell() {
      if (this.isFilesViewOpen) this.selectFilesViewJournal(null);
      else this.selectFilesViewJournal(this.journal.resourceName);
    },
    async _onUploadJournalFile(file: File) {
      if (!this.editable) return;
      await this.onUploadJournalFile(this.journalId, file);
    },
    async _onDeleteJournalFile(journalFile: EECJournalFile) {
      if (!this.editable) return;
      await this.onDeleteJournalFile(journalFile.journalId, journalFile.journalFileId);
    },
    async onSelectExtraMenu(menuKey: string) {
      if (!this.editable) return;
      switch (menuKey) {
        case "delete":
          if (this.isFilesViewOpen) this.selectFilesViewJournal(null);
          this.currentlyDeleting = true;
          await this.onDeleteJournal(this.journalId);
          this.currentlyDeleting = false;
          return;
      }
    }
  }
});
