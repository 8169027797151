

















import Vue, { PropType } from "vue";
import TipBlock from "@/components/TipBlock.vue";

export default Vue.extend({
  name: "PublishSelectedRowsButton",
  components: { TipBlock },
  props: {
    enabled: { type: Boolean, required: true },
    onClickPublish: { type: Function as PropType<() => void> },
    onClickUnpublish: { type: Function as PropType<() => void> }
  }
});
