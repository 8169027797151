





































import Vue, { PropType } from "vue";
import MessageView from "@/components/MessageView.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import CurriculumListNECTable from "@/views/curriculum/teacher/CurriculumListNEC/components/CurriculumListNECTable/CurriculumListNECTable.vue";
import CurriculumListHeader from "@/views/curriculum/teacher/CurriculumListNEC/components/CurriculumListNECHeader/CurriculumListNECHeader.vue";
import { Loadable } from "@/ts/Loadable";
import { QuarterSwitchValue } from "@/components/QuarterSwitch/QuarterSwitch.vue";
import { MonthValue } from "@/ts/utils";
import { RatingValue } from "@/ts/objects/common/Rating";
import {
  CurriculumListNECContentRowFilterState,
  CurriculumListNECMonthFilterState,
  CurriculumListNECMonthStateVM,
  CurriculumListNECStudentRowFilterState,
  ICurriculumListNECModel
} from "@/views/curriculum/teacher/CurriculumListNEC/CurriculumListNECModel";
import log from "loglevel";
import { ErrorNotificationParam } from "@/components/ErrorNotification.vue";

export default Vue.extend({
  name: "CurriculumListNECPure",
  components: { MessageView, LoadingBlock, CurriculumListNECTable, CurriculumListHeader },
  props: {
    model: { type: Object as PropType<Loadable<ICurriculumListNECModel>>, required: true },
    monthStateModel: { type: Object as PropType<Loadable<CurriculumListNECMonthStateVM[]>>, required: true },

    studentRowFilter: { type: Object as PropType<CurriculumListNECStudentRowFilterState>, required: true },
    contentRowFilter: { type: Object as PropType<CurriculumListNECContentRowFilterState>, required: true },
    monthFilter: { type: Object as PropType<CurriculumListNECMonthFilterState>, required: true },

    quarterSwitchValue: { type: String as PropType<QuarterSwitchValue>, required: true },

    needSave: { type: Boolean, required: true },
    currentErrors: { type: Array as PropType<ErrorNotificationParam[]>, required: true },

    studentUserIdToStudentViewPath: { type: Function as PropType<(studentUserId: string) => string>, required: true },

    onInputQuarterSwitch: { type: Function as PropType<(value: QuarterSwitchValue) => void>, required: true },
    onInput: {
      type: Function as PropType<
        (viewPointId: string, contentId: string, month: MonthValue, studentUserId: string, rating: RatingValue) => void
      >,
      required: true
    },
    onChangeSinglePublishState: {
      type: Function as PropType<
        (viewPointId: string, contentId: string, month: MonthValue, studentUserId: string, published: boolean) => void
      >,
      required: true
    },
    onChangeMonthPublishState: {
      type: Function as PropType<(month: MonthValue, published: boolean) => void>,
      required: true
    },
    onFillAllEvaluationsOfMonth: {
      type: Function as PropType<(month: MonthValue, rating: RatingValue, overwrite: boolean) => void>,
      required: true
    },
    onChangeStudentRowFilterState: {
      type: Function as PropType<(filterState: CurriculumListNECStudentRowFilterState) => void>,
      required: true
    },
    onChangeContentRowFilterState: {
      type: Function as PropType<(filterState: CurriculumListNECContentRowFilterState) => void>,
      required: true
    },
    exportCsv: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  updated() {
    log.debug(`CurriculumListNECPure:updated`);
  }
});
