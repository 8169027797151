













































import Vue, { PropType } from "vue";
import { Loadable } from "@/ts/Loadable";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { messages } from "@/ts/const/Messages";
import CurriculumJournalRow from "@/views/curriculum/components/CurriculumJournalRow/CurriculumJournalRow.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import CurriculumWriteMainHeader from "@/views/curriculum/student/components/CurriculumWriteMainHeader/CurriculumWriteMainHeader.vue";
import CurriculumJournalHeaderRow from "@/views/curriculum/components/CurriculumJournalHeaderRow/CurriculumJournalHeaderRow.vue";
import { IEECJournalTree } from "@/ts/objects/curriculum/IEECJournal";
import log from "loglevel";
import { isNullish, MonthValue } from "@/ts/utils";
import { EECurriculumTree } from "@/ts/objects/curriculum/value/EECurriculum";

export default Vue.extend({
  name: "CurriculumWriteEECPure",
  components: {
    CurriculumJournalHeaderRow,
    CurriculumJournalRow,
    MessageView,
    LoadingBlock,
    CurriculumWriteMainHeader
  },
  props: {
    /**
     * 児童生徒ID。
     */
    studentUserId: { type: String as PropType<string | null | undefined> },

    /**
     * 文書評価教科。
     */
    eeCurriculumTree: { type: Object as PropType<EECurriculumTree | null | undefined> },

    /**
     * 現在表示中の教科・児童生徒配下の学習記録。
     */
    journalTrees: { type: Object as PropType<Loadable<IEECJournalTree[]>>, required: true },

    /**
     * 現在ファイルビューを開いている学習記録のリソース名。
     */
    filesViewJournal: { type: String as PropType<string | null | undefined> },

    /**
     * 現在ファイルビューを開いている学習記録を変更する関数。
     */
    selectFilesViewJournal: {
      type: Function as PropType<(rname: string | null) => void>,
      required: true
    },

    onInputMonth: {
      type: Function as PropType<(journalId: string, value: MonthValue) => void>,
      required: true
    },
    onInputActivity: {
      type: Function as PropType<(journalId: string, value: string) => void>,
      required: true
    },
    onInputStudentComment: {
      type: Function as PropType<(journalId: string, value: string) => void>,
      required: true
    },
    onUploadJournalFile: {
      type: Function as PropType<(journalId: string, file: any) => Promise<void>>,
      required: true
    },
    onDeleteJournalFile: {
      type: Function as PropType<(journalId: string, journalFileId: string) => Promise<void>>,
      required: true
    },

    onCreateJournal: { type: Function as PropType<() => Promise<void>>, required: true },
    onDeleteJournal: { type: Function as PropType<(journalId: string) => Promise<boolean>>, required: true }
  },
  data(): {
    months: number[];
    monthColWidth: number;
    extraMenuColWidth: number;
    otherColMinWidth: number;
  } {
    return {
      months: [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3],
      monthColWidth: 71,
      extraMenuColWidth: 20,
      otherColMinWidth: 110
    };
  },
  computed: {
    messageView(): MessageViewParam | null {
      // if (isNullish(this.eeCurriculumTree) || isNullish(this.studentUserId)) {
      //   return { message: messages.pageNotFound };
      // }
      if (this.journalTrees.isError) {
        return { message: messages.failedToLoadData };
      }
      return null;
    },
    styles(): Record<string, string> {
      return {
        "--monthColWidth": `${this.monthColWidth}px`,
        "--extraMenuColWidth": `${this.extraMenuColWidth}px`,
        "--otherColMinWidth": `${this.otherColMinWidth}px`
      };
    },
    reversedJournalTrees(): IEECJournalTree[] | null {
      const journalTrees = this.journalTrees.data;
      if (journalTrees === null) return null;
      log.debug(`reversedJournalTrees: journalTrees=${JSON.stringify(journalTrees)}`);
      return journalTrees.slice().reverse();
    },
    monthToActivityOptionsDict(): Record<string, string[]> {
      const eecTree = this.eeCurriculumTree;
      if (isNullish(eecTree)) return {};

      const monthToActivityTexts = eecTree.months.map((month): [MonthValue, string[]] => [
        month.self.month,
        month.activities.filter(a => a.self.enabled).map(a => a.self.text)
      ]);
      return Object.fromEntries(monthToActivityTexts);
    }
  },
  methods: {
    getActivityOptions(month: MonthValue): string[] {
      return this.monthToActivityOptionsDict[month.toString(10)] ?? [];
    }
  }
});
