







import Vue, { PropType } from "vue";
import CurriculumTitle from "@/views/curriculum/components/CurriculumTitle/CurriculumTitle.vue";
import CreateJournalButton from "@/views/curriculum/components/CreateJournalButton/CreateJournalButton.vue";

export default Vue.extend({
  name: "CurriculumWriteMainHeader",
  components: { CreateJournalButton, CurriculumTitle },
  props: {
    // TODO curriculum icon

    curriculumName: { type: String, required: true },
    onCreateJournal: { type: Function as PropType<() => Promise<void>>, required: true }
  }
});
