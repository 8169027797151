















































import Vue, { PropType } from "vue";
import RouterLinkWrapper from "@/components/RouterLinkWrapper.vue";
import { IEECJournalTree } from "@/ts/objects/curriculum/IEECJournal";
import { CurriculumListEECSelectionState } from "@/views/curriculum/teacher/CurriculumListEEC/CurriculumListEECModel";
import CurriculumListEECJournalRow from "@/views/curriculum/teacher/CurriculumListEEC/components/CurriculumListEECJournalRow/CurriculumListEECJournalRow.vue";
import { MonthValue } from "@/ts/utils";

export default Vue.extend({
  name: "CurriculumListEECStudentRow",
  components: { CurriculumListEECJournalRow, RouterLinkWrapper },
  props: {
    /**
     * 児童生徒ID。
     */
    studentUserId: { type: String, required: true },

    /**
     * 児童生徒番号。
     */
    studentNumber: { type: Number, required: true },

    /**
     * 児童生徒名。
     */
    studentName: { type: String, required: true },

    /**
     * 学習記録のリスト。(フィルタ済)
     */
    journalTrees: { type: Array as PropType<IEECJournalTree[]>, required: true },

    /**
     * 現在の選択状態。
     */
    selectionState: { type: Object as PropType<CurriculumListEECSelectionState>, required: true },

    /**
     * 現在、ファイルビューが開かれている学習記録のリソース名。
     */
    filesViewJournal: { type: String as PropType<string | null | undefined> },

    /**
     * 児童生徒カラムの幅（px）。
     */
    studentColWidth: { type: Number, required: true },

    /**
     * 月カラムの幅（px）。
     */
    monthColWidth: { type: Number, required: true },

    /**
     * 学習活動カラムの幅（px）。
     */
    activityColWidth: { type: Number, required: true },

    /**
     * 学習記録ファイルカラムの幅（px）。
     */
    filesColWidth: { type: Number, required: true },

    /**
     * 児童生徒コメント(学習のふりかえり)カラムの幅（px）。
     */
    studentCommentColWidth: { type: Number, required: true },

    /**
     * 教師コメント(先生から)カラムの幅（px）。
     */
    teacherCommentColWidth: { type: Number, required: true },

    /**
     * 公開状態カラムの幅（px）。
     */
    publishStateColWidth: { type: Number, required: true },

    /**
     * チェックボックスカラムの幅（px）。
     */
    checkboxColWidth: { type: Number, required: true },

    studentUserIdToStudentViewPath: { type: Function as PropType<(studentUserId: string) => string>, required: true },

    /**
     * 現在ファイルビューを開いている学習記録を変更する関数。
     */
    selectFilesViewJournal: { type: Function as PropType<(rname: string | null) => void>, required: true },

    onInputMonth: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: MonthValue) => void>,
      required: true
    },
    onInputActivity: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: string) => void>,
      required: true
    },
    onInputStudentComment: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: string) => void>,
      required: true
    },
    onInputTeacherComment: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: string) => void>,
      required: true
    },
    onUploadJournalFile: {
      type: Function as PropType<(studentUserId: string, journalId: string, file: File) => Promise<void>>,
      required: true
    },
    onDeleteJournalFile: {
      type: Function as PropType<(studentUserId: string, journalId: string, journalFileId: string) => Promise<void>>,
      required: true
    },
    onChangeSelectionState: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: boolean) => void>,
      required: true
    }
  },
  computed: {
    noJournalRowsWidth(): number {
      return (
        this.monthColWidth +
        this.activityColWidth +
        this.filesColWidth +
        this.studentCommentColWidth +
        this.teacherCommentColWidth +
        this.publishStateColWidth +
        this.checkboxColWidth
      );
    },
    studentViewPath(): string {
      return this.studentUserIdToStudentViewPath(this.studentUserId);
    }
  },
  methods: {
    _onInputMonth(journalId: string, value: MonthValue) {
      this.onInputMonth(this.studentUserId, journalId, value);
    },
    _onInputActivity(journalId: string, value: string) {
      this.onInputActivity(this.studentUserId, journalId, value);
    },
    _onInputStudentComment(journalId: string, value: string) {
      this.onInputStudentComment(this.studentUserId, journalId, value);
    },
    _onInputTeacherComment(journalId: string, value: string) {
      this.onInputTeacherComment(this.studentUserId, journalId, value);
    },
    _onUploadJournalFile(journalId: string, file: File) {
      this.onUploadJournalFile(this.studentUserId, journalId, file);
    },
    _onDeleteJournalFile(journalId: string, journalFileId: string) {
      this.onDeleteJournalFile(this.studentUserId, journalId, journalFileId);
    },
    _onChangeSelectionState(journalId: string, value: boolean) {
      this.onChangeSelectionState(this.studentUserId, journalId, value);
    }
  }
});
