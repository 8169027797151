









import CurriculumReadNECMonthlyPure from "@/views/curriculum/student/CurriculumReadNECMonthly/CurriculumReadNECMonthlyPure.vue";
import Vue, { PropType } from "vue";
import { CurriculumStoreS } from "@/store/CurriculumStoreS";

export default Vue.extend({
  name: "CurriculumReadNECMonthly",
  components: { CurriculumReadNECMonthlyPure },
  props: {
    curriculumStoreS: { type: Object as PropType<CurriculumStoreS>, required: true }
  }
});
