import { isMonthValue, MonthValue } from "@/ts/utils";

export class ActionLog {
  constructor(
    public readonly actionLogId: string,
    public readonly studentUserId: string,
    public readonly studentUserName: string,
    public readonly studentPhotoUrl: string,
    public readonly subjectUserType: string,
    public readonly subjectUserId: string,
    public readonly subjectUserName: string,
    public readonly subjectUserPhotoUrl: string,
    public readonly objectService: string,
    public readonly objectResourceName: string,
    public readonly updatedPropertyCategory: string | undefined,
    public readonly data: string,
    public readonly meta: ActionLogMeta,
    public readonly createdAt: Date,
    public readonly updatedAt: Date
  ) {}
}

export class ActionLogMeta {
  constructor(public readonly schoolYear: number | undefined, month: number | undefined) {
    this.month = isMonthValue(month) ? month : undefined;
  }

  readonly month: MonthValue | undefined;
}
