













import Vue from "vue";

export default Vue.extend({
  name: "TipBlock",
  props: {
    tipMessage: { type: String },
    tipPosition: { type: String, default: "top" }, // top, right, bottom, left
    tipMargin: { type: Number, default: 7 },
    tipWidth: { type: Number, default: undefined },
    tipFontSize: { type: Number, default: 14 },
    tipLineHeight: { type: String, default: "normal" },
    tipZIndex: { type: Number, default: 10 },
    tipTextAlign: { type: String, default: "center" }, // left, center or right
    tipBackgroundColor: { type: String, default: "#000000bf" },
    tipTextColor: { type: String, default: "#ffffff" },
    tipDisabled: { type: Boolean, default: false }
  },
  computed: {
    styles(): any {
      return {
        "--margin": `${this.tipMargin}px`,
        "--width": `${this.tipWidth}px`,
        "--font-size": `${this.tipFontSize}px`,
        "--line-height": this.tipLineHeight,
        "--z-index": this.tipZIndex,
        "--tip-text-align": this.tipTextAlign,
        "--tip-background-color": this.tipBackgroundColor,
        "--tip-text-color": this.tipTextColor
      };
    }
  }
});
