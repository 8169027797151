















































import Vue, { PropType } from "vue";
import {
  CurriculumListEECJournalRowFilterState,
  CurriculumListEECSelectionState,
  CurriculumListEECStudentRowFilterState,
  CurriculumListEECModel
} from "@/views/curriculum/teacher/CurriculumListEEC/CurriculumListEECModel";
import CurriculumListEECTable from "@/views/curriculum/teacher/CurriculumListEEC/components/CurriculumListEECTable/CurriculumListEECTable.vue";
import CurriculumListEECHeader from "@/views/curriculum/teacher/CurriculumListEEC/components/CurriculumListEECHeader/CurriculumListEECHeader.vue";
import { Loadable } from "@/ts/Loadable";
import MessageView from "@/components/MessageView.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { WholeSelectionState } from "@/ts/objects/common/WholeSelectionState";
import { ClassStudent } from "@/ts/objects/common/Class";
import { ErrorNotificationParam } from "@/components/ErrorNotification.vue";
import { MonthValue } from "@/ts/utils";

export default Vue.extend({
  name: "CurriculumListEECPure",
  components: { LoadingBlock, MessageView, CurriculumListEECHeader, CurriculumListEECTable },
  props: {
    /**
     * 児童生徒のリスト。
     */
    students: { type: Object as PropType<Loadable<ClassStudent[]>>, required: true },

    /**
     * ビューモデル。
     * フィルタは適用済。
     */
    model: { type: Object as PropType<Loadable<CurriculumListEECModel>>, required: true },

    needSave: { type: Boolean, required: true },
    currentErrors: { type: Array as PropType<ErrorNotificationParam[]>, required: true },

    /**
     * 児童生徒行のフィルタ状態。
     */
    studentRowFilterState: { type: Object as PropType<CurriculumListEECStudentRowFilterState>, required: true },

    /**
     * 学習記録行のフィルタ状態。
     */
    journalRowFilterState: { type: Object as PropType<CurriculumListEECJournalRowFilterState>, required: true },

    /**
     * 現在の選択状態。
     */
    selectionState: { type: Object as PropType<CurriculumListEECSelectionState>, required: true },

    /**
     * 現在、ファイルビューが開かれている学習記録のリソース名。
     */
    filesViewJournal: { type: String as PropType<string | null | undefined> },

    studentUserIdToStudentViewPath: { type: Function as PropType<(studentUserId: string) => string>, required: true },

    /**
     * 現在ファイルビューを開いている学習記録を変更する関数。
     */
    selectFilesViewJournal: { type: Function as PropType<(rname: string | null) => void>, required: true },

    onInputMonth: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: MonthValue) => void>,
      required: true
    },
    onInputActivity: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: string) => void>,
      required: true
    },
    onInputStudentComment: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: string) => void>,
      required: true
    },
    onInputTeacherComment: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: string) => void>,
      required: true
    },
    onUploadJournalFile: {
      type: Function as PropType<(studentUserId: string, journalId: string, file: File) => Promise<void>>,
      required: true
    },
    onDeleteJournalFile: {
      type: Function as PropType<(studentUserId: string, journalId: string, journalFileId: string) => Promise<void>>,
      required: true
    },
    onChangeSelectionState: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: boolean) => void>,
      required: true
    },
    onChangeStudentRowFilterState: {
      type: Function as PropType<(filterState: CurriculumListEECStudentRowFilterState) => void>,
      required: true
    },
    onChangeJournalRowFilterState: {
      type: Function as PropType<(filterState: CurriculumListEECJournalRowFilterState) => void>,
      required: true
    },
    toggleAllSelections: {
      type: Function as PropType<(currentSelectionState: WholeSelectionState) => void>,
      required: true
    },

    onClickPublish: { type: Function as PropType<() => void> },
    onClickUnpublish: { type: Function as PropType<() => void> },

    exportCsv: {
      type: Function as PropType<() => void>,
      required: true
    }
  }
});
