import { isMonthValue, MonthValue } from "@/ts/utils";
import { ContentMonth as ContentMonthResp } from "@/ts/api/curriculum-service";
import { INECContentMonth, INECContentMonthTree } from "@/ts/objects/curriculum/INECContentMonth";

/**
 * 数値評価教科の内容構成の1か月分の内容。
 *
 * リソース名は /neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months/{1, 2, ..., 12}。
 */
export class NECContentMonth implements INECContentMonth {
  constructor(
    public readonly necId: string,
    public readonly viewPointId: string,
    public readonly contentId: string,
    public readonly month: MonthValue,
    public readonly enabled: boolean,
    public readonly text: string
  ) {}

  get resourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}/months/${this.month}`;
  }

  static fromContentMonthResp(resp: ContentMonthResp): NECContentMonth {
    if (!isMonthValue(resp.month))
      throw new Error(`NECContentMonth.fromContentMonthResp: invalid month value on ${resp.self}: ${resp.month}`);
    return new NECContentMonth(
      resp.necId,
      resp.viewPointId,
      resp.contentId,
      resp.month,
      resp.enabled,
      resp.text?.value ?? ""
    );
  }
}

export class NECContentMonthTree implements INECContentMonthTree {
  constructor(public readonly self: NECContentMonth) {}
}
