


















































import Vue, { PropType } from "vue";
import MessageView from "@/components/MessageView.vue";
import CurriculumReadMenu from "@/views/curriculum/components/CurriculumReadMenu/CurriculumReadMenu.vue";
import CurriculumStudentViewNECYearly from "@/views/curriculum/teacher/CurriculumStudentView/components/CurriculumStudentViewNECYearly/CurriculumStudentViewNECYearly.vue";
import { Grade } from "@/ts/objects/common/Grade";
import { CurriculumPeriodMode } from "@/ts/objects/curriculum/value/CurriculumPeriodMode";
import { MonthValue } from "@/ts/utils";
import { NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { EECurriculumTree } from "@/ts/objects/curriculum/value/EECurriculum";
import { Loadable, LoadableIdle } from "@/ts/Loadable";
import { INECEvaluation } from "@/ts/objects/curriculum/INECEvaluation";
import { IEECJournalTree } from "@/ts/objects/curriculum/IEECJournal";
import CurriculumStudentViewNECMonthly from "@/views/curriculum/teacher/CurriculumStudentView/components/CurriculumStudentViewNECMonthly/CurriculumStudentViewNECMonthly.vue";
import CurriculumStudentViewEEC from "@/views/curriculum/teacher/CurriculumStudentView/components/CurriculumStudentViewEEC/CurriculumStudentViewEEC.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";

export default Vue.extend({
  name: "CurriculumStudentViewRead",
  components: {
    LoadingBlock,
    CurriculumStudentViewEEC,
    MessageView,
    CurriculumStudentViewNECMonthly,
    CurriculumStudentViewNECYearly,
    CurriculumReadMenu
  },
  props: {
    studentUserId: { type: String, required: true },
    schoolYear: { type: Number, required: true },
    grade: { type: Object as PropType<Grade>, required: true },
    periodMode: { type: String as PropType<CurriculumPeriodMode>, required: true },
    thisMonth: { type: Number as PropType<MonthValue>, required: true },
    selectedMonth: { type: Number as PropType<MonthValue | null | undefined> },
    curriculumRname: { type: String, required: true },
    neCurriculumTrees: { type: Object as PropType<Loadable<NECurriculumTree[]>>, required: true },
    eeCurriculumTrees: { type: Object as PropType<Loadable<EECurriculumTree[]>>, required: true },

    /**
     * **現在表示中の児童生徒配下の** 、全教科の評価。
     */
    studentEvaluations: { type: Object as PropType<Loadable<INECEvaluation[]>>, required: true },

    /**
     * **現在表示中の児童生徒配下の** 、全教科の学習記録。
     */
    studentJournalTrees: { type: Object as PropType<Loadable<IEECJournalTree[]>>, required: true },

    /**
     * 教科学習のパスを取得する関数。
     */
    getPath: {
      type: Function as PropType<
        (
          periodMode: CurriculumPeriodMode | null,
          month: MonthValue | null | undefined,
          resourceName: string | null
        ) => string
      >,
      required: true
    }
  },
  computed: {
    // messageView(): MessageViewParam | null {
    //   // if (this.tab === null || this.schoolYear === null || this.grade === null || this.periodMode === null)
    //   if (this.neCurriculumTree === null && this.eeCurriculumTree === null) return { message: messages.pageNotFound };
    //   return null;
    // },
    neCurriculumTree(): NECurriculumTree | null {
      const necTrees = this.neCurriculumTrees.data;
      if (necTrees === null) return null;

      return necTrees.find(nec => nec.self.resourceName === this.curriculumRname) ?? null;
    },
    eeCurriculumTree(): EECurriculumTree | null {
      const eecTrees = this.eeCurriculumTrees.data;
      if (eecTrees === null) return null;

      return eecTrees.find(eec => eec.self.resourceName === this.curriculumRname) ?? null;
    },
    evaluationsOfNEC(): Loadable<INECEvaluation[]> {
      const necTree = this.neCurriculumTree;
      if (necTree === null) return new LoadableIdle();

      const necId = necTree.self.necId;
      return Loadable.fromLoadable(this.studentEvaluations, evaluations => {
        return evaluations.filter(ev => ev.necId === necId);
      });
    },
    evaluationsOfNECMonth(): Loadable<INECEvaluation[]> {
      const month = this.selectedMonth;
      if (month === null) return new LoadableIdle();

      return Loadable.fromLoadable(this.evaluationsOfNEC, evaluationsOfNEC => {
        return evaluationsOfNEC.filter(ev => ev.month === month);
      });
    },
    journalsOfEEC(): Loadable<IEECJournalTree[]> {
      const eecTree = this.eeCurriculumTree;
      if (eecTree === null) return new LoadableIdle();

      const eecId = eecTree.self.eecId;
      return Loadable.fromLoadable(this.studentJournalTrees, journals => {
        return journals.filter(j => j.self.eecId === eecId);
      });
    },
    journalsOfEECMonth(): Loadable<IEECJournalTree[]> {
      const month = this.selectedMonth;
      if (month === null) return new LoadableIdle();

      return Loadable.fromLoadable(this.journalsOfEEC, journalsOfEEC => {
        return journalsOfEEC.filter(j => j.self.month === month);
      });
    }
  }
});
