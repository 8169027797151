/**
 * read-onlyデータ専用の、データの入れ物。
 */
import log from "loglevel";
import { delay } from "@/ts/utils";

export class DataSlot<T> {
  private _data: T | null = null;
  private _loading = true;

  /**
   * 複数回startLoadingWithが呼ばれたとき、最後の呼び出しで渡されたloadingFn以外の結果は無視したい。
   * そのために使う。
   */
  private _lastLoadingFn: (() => Promise<T | null>) | null = null;

  constructor() {}

  /**
   * 初期状態（外から見たらロード中状態に見えるが、実際は何もロードしようとしていない）にリセットする。
   */
  resetState() {
    this._data = null;
    this._loading = true;
    this._lastLoadingFn = null;
  }

  /**
   * データ読み込みを開始する。
   * 連続で複数回呼んだ場合、最後の呼び出しの結果だけを用いる。
   *
   * @param loadingFn データ読み込みに使う関数。
   */
  async startLoadingWith(loadingFn: () => Promise<T | null>) {
    log.debug(`DataSlot.startLoadingWith`);

    this._lastLoadingFn = loadingFn;
    this.setLoading();

    const loadedData = await loadingFn();

    if (this._lastLoadingFn !== loadingFn) {
      log.debug(`DataSlot: loading finished, but discarding the result.`);
      return;
    }
    log.debug(`DataSlot: loading finished, using the result.`);
    if (loadedData === null) {
      this.setError();
      return;
    }
    this.setData(loadedData);
  }

  private setLoading() {
    this._data = null;
    this._loading = true;
  }

  private setError() {
    this._data = null;
    this._loading = false;
  }

  private setData(data: T) {
    this._data = data;
    this._loading = false;
  }

  /**
   * データを取得する。
   * データがロード済でなければ、nullを返す。
   */
  get data(): T | null {
    return this._data;
  }

  /**
   * データを取得する。
   * データがロード済でなければ、指定した秒数だけ待ち、それでもまだならnullを返す。
   *
   * @param timeoutSec 待ち秒数。
   */
  async getDataWithTimeout(timeoutSec: number = 20): Promise<T | null> {
    log.debug(`DataSlot.getDataWithTimeout: start: data=${this._data}`);
    if (this._data !== null) return this._data;
    for (let i = 0; i < timeoutSec * 2; i++) {
      await delay(500);
      log.debug(`DataSlot.getDataWithTimeout: i=${i}: data=${this._data}`);
      if (this._data !== null) return this._data;
    }
    log.debug(`DataSlot.getDataWithTimeout: no data: data=${this._data}`);
    return null;
  }

  /**
   * 状態。
   * ロード中 / エラー / データロード済 のいずれか。
   */
  get state(): DataSlotState {
    if (this.isLoading()) {
      return DataSlotState.Loading;
    } else if (this.isError()) {
      return DataSlotState.Error;
    } else {
      return DataSlotState.DataLoaded;
    }
  }

  /**
   * ロード中状態ならtrue。
   */
  isLoading() {
    return this._loading;
  }

  /**
   * エラー状態ならtrue。
   */
  isError() {
    return !this._loading && this._data === null;
  }

  /**
   * データロード済状態ならtrue。
   */
  isDataLoaded() {
    return !this._loading && this._data !== null;
  }
}

/**
 * DataSlotの読み込み専用版。
 * つまり、startLoadingWithができない、というだけ。
 */
export class DataSlotView<T> {
  private readonly _dataSlot: DataSlot<T>;

  constructor(dataSlot: DataSlot<T>) {
    this._dataSlot = dataSlot;
  }

  get data(): T | null {
    return this._dataSlot.data;
  }

  async getDataWithTimeout(timeoutSec: number = 20): Promise<T | null> {
    return this._dataSlot.getDataWithTimeout(timeoutSec);
  }

  get state(): DataSlotState {
    return this._dataSlot.state;
  }

  isLoading() {
    return this._dataSlot.isLoading();
  }

  isError() {
    return this._dataSlot.isError();
  }

  isDataLoaded() {
    return this._dataSlot.isDataLoaded();
  }
}

/**
 * DataSlotの読み込み専用版だが、DB用語のいわゆるviewと同じように、元データそのままではなく、その射影を提供する。
 */
export class DataSlotProjectionView<T, U> {
  private readonly _dataSlot: DataSlot<T>;
  private readonly _projectionFn: (data: T) => U;

  constructor(dataSlot: DataSlot<T>, projectionFn: (data: T) => U) {
    this._dataSlot = dataSlot;
    this._projectionFn = projectionFn;
  }

  get data(): U | null {
    const data = this._dataSlot.data;
    if (data === null) return null;
    return this._projectionFn(data);
  }

  async getDataWithTimeout(timeoutSec: number = 20): Promise<U | null> {
    const data = await this._dataSlot.getDataWithTimeout(timeoutSec);
    log.debug(`DataSlotProjectionView.getDataWithTimeout: data=${JSON.stringify(data)}`);
    if (data === null) return null;
    return this._projectionFn(data);
  }

  get state(): DataSlotState {
    return this._dataSlot.state;
  }

  isLoading() {
    return this._dataSlot.isLoading();
  }

  isError() {
    return this._dataSlot.isError();
  }

  isDataLoaded() {
    return this._dataSlot.isDataLoaded();
  }
}

export enum DataSlotState {
  Loading,
  Error,
  DataLoaded
}
