






















































































import Vue, { PropType } from "vue";
import AutoResizeTextarea from "@/components/AutoResizeTextarea.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { ProjectStore } from "@/store/ProjectStore";
import { Err } from "@/ts/objects/Err";
import { EditableProjectLookback } from "@/ts/objects/project/editable/EditableProjectLookback";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { messages } from "@/ts/const/Messages";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { emptySaveResult, SaveResult } from "@/ts/objects/editable/SaveResult";
import TipBlock from "@/components/TipBlock.vue";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";

export default Vue.extend({
  name: "ProjectStudentViewLookbackT",
  components: { LoadingBlock, MessageView, AutoResizeTextarea, TipBlock },
  props: {
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
    projectRepository: { type: Object as PropType<ProjectRepository>, required: true }
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave
    });
    this.reloadData(this.$route.params["projectStudentUserId"]);
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      this.reloadData(to.params["projectStudentUserId"]);
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;
    lookback: EditableProjectLookback | null;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,
      lookback: null,

      pageLeaveService: null
    };
  },
  computed: {
    needSave(): boolean {
      return this.lookback?.needSave() ?? false;
    }
  },
  methods: {
    async reloadData(studentUserId: string | undefined) {
      if (studentUserId === undefined) {
        this.messageView = { message: messages.pleaseSelectStudent };
        return;
      }
      const project = await this.projectStore.project.getDataWithTimeout();
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectProject };
        return;
      }

      this.lookback = null;
      this.messageView = null;

      const resp = await this.projectRepository.listEditableProjectLookbacks(
        project.projectId,
        studentUserId,
        true,
        false,
        false
      );
      if (resp instanceof Err || resp.length === 0) {
        log.debug(`Error loading lookbacks: ${JSON.stringify(resp)}`);
        this.messageView = { message: messages.failedToLoadData };
        return;
      }

      this.lookback = resp[0];
      this.messageView = null;
    },
    async saveAll(force: boolean): Promise<SaveResult> {
      log.debug("SAVING!");
      return (await this.lookback?.saveAllChanges(force)) ?? emptySaveResult();
    }
  }
});
