import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { isMonthValue, MonthValue } from "@/ts/utils";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { EditableBoolean, EditableHashedString } from "@/ts/objects/editable/value/EditablePrimitiveValue";
import { Editable } from "@/ts/objects/editable/Editable";
import { Err } from "@/ts/objects/Err";
import { Activity as ActivityResp } from "@/ts/api/curriculum-service";
import { IEECActivity } from "@/ts/objects/curriculum/IEECActivity";

/**
 * 編集可能な文書評価教科の学習活動。
 *
 * リソース名は /eeCurriculums/{eecId}/months/{1, 2, ..., 12}/activities/{eecActivityId}。
 *
 * 月 : 学習活動  = 1 : n
 */
export class EditableEECActivity extends EditableObject implements IEECActivity {
  constructor(
    repo: CurriculumRepository,
    savable: boolean,
    public readonly eecId: string,
    public readonly month: MonthValue,
    public readonly activityId: string,
    enabled: boolean,
    text: string,
    textHash: string
  ) {
    super();

    this._enabled = new EditableBoolean("enabled", enabled, savable, async value => {
      const resp = await repo.patchEECActivity(eecId, month, activityId, { enabled: value });
      if (resp instanceof Err) return resp;
      return resp.enabled;
    });
    this._text = new EditableHashedString("text", text, textHash, savable, async ({ value, hash }) => {
      const resp = await repo.patchEECActivity(eecId, month, activityId, { text: { value, hash } });
      if (resp instanceof Err) return resp;
      return [resp.text.value, resp.text.hash];
    });
  }

  private readonly _enabled: EditableBoolean;
  private readonly _text: EditableHashedString;

  protected allEditables(): Editable[] {
    return [this._enabled, this._text];
  }

  get resourceName(): string {
    return `/eeCurriculums/${this.eecId}/months/${this.month}/activities/${this.activityId}`;
  }

  get enabled(): boolean {
    return this._enabled.value;
  }

  set enabled(value: boolean) {
    this._enabled.value = value;
  }

  get text(): string {
    return this._text.value;
  }

  set text(value: string) {
    this._text.value = value;
  }

  static fromActivityResp(repo: CurriculumRepository, savable: boolean, resp: ActivityResp): EditableEECActivity {
    if (!isMonthValue(resp.month))
      throw new Error(`EECActivity.fromActivityResp: invalid month value on ${resp.self}: ${resp.month}`);
    return new EditableEECActivity(
      repo,
      savable,
      resp.eecId,
      resp.month,
      resp.activityId,
      resp.enabled,
      resp.text.value,
      resp.text.hash
    );
  }
}
