























import Vue, { PropType } from "vue";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import PopupSelectButton from "@/components/PopupSelectButton.vue";
import { isNullish } from "@/ts/utils";

export default Vue.extend({
  name: "CurriculumJournalActivityCell",
  components: { PopupSelectButton, DisableableAutoResizeTextarea },
  props: {
    value: { type: String, required: true },
    options: { type: Array as PropType<string[] | null | undefined> },
    editable: { type: Boolean, required: true },
    onInput: { type: Function as PropType<(value: string) => void>, required: true }
  },
  computed: {
    selectItems(): { key: string; label: string }[] {
      const options = this.options;
      if (isNullish(options)) return [];
      return options.map(opt => ({ key: opt, label: opt }));
    }
  },
  methods: {
    _onInput(value: string) {
      if (!this.editable) return;
      this.onInput(value);
    }
  }
});
