





















import log from "loglevel";
import { isNullish } from "@/ts/utils";
import Vue from "vue";

export default Vue.extend({
  name: "OpenSyllabusFileButton",
  props: {
    /**
     * 対象シラバスファイルの、GCSオブジェクトパス。
     */
    gcsObjectPath: { type: String }
  },
  data(): { syllabusFileUrl: string | null } {
    return {
      syllabusFileUrl: null
    };
  },
  watch: {
    gcsObjectPath: {
      handler: async function(newValue: string | null | undefined, oldValue: string | null | undefined) {
        log.debug(`OpenSyllabusFileButton:watch:gcsObjectPath: oldValue=${oldValue}, newValue=${newValue}`);
        if (isNullish(newValue)) {
          this.syllabusFileUrl = null;
          return;
        }
        if (newValue === oldValue) return;

        const fileRef = Vue.prototype.$storage?.ref(newValue);
        if (isNullish(fileRef)) return;

        const url = await fileRef.getDownloadURL().catch((e: any) => {
          log.info(`OpenSyllabusFileButton:watch:gcsObjectPath: error=${JSON.stringify(e)}`);
          return null;
        });
        if (typeof url !== "string") return;

        this.syllabusFileUrl = url;
      },
      immediate: true
    }
  }
});
