
























import Vue, { PropType } from "vue";
import { MonthValue } from "@/ts/utils";
import { RatingValue } from "@/ts/objects/common/Rating";

export default Vue.extend({
  name: "CurriculumListNECHeaderMonthEditButton",
  props: {
    month: { type: Number as PropType<MonthValue>, required: true },
    onClickFillAll: { type: Function as PropType<(rating: RatingValue, overwrite: boolean) => void>, required: true }
  },
  data(): {
    active: boolean;
    rating: RatingValue;
    overwrite: boolean;
  } {
    return {
      active: false,
      rating: "A",
      overwrite: false
    };
  },
  computed: {
    buttonMessage(): string {
      const month = this.month;
      const rating = this.rating;
      if (rating !== "") {
        return `${month}月の評価を${rating}で埋める`;
      } else {
        return `${month}月の評価を空にする`;
      }
    },
    buttonEnabled(): boolean {
      const rating = this.rating;
      const overwrite = this.overwrite;

      return ["S", "A", "B", "C"].includes(rating) || (rating === "" && overwrite);
    }
  },
  methods: {
    onClick() {
      this.rating = "A";
      this.overwrite = false;
      this.active = !this.active;
    },
    onClickOutside() {
      this.active = false;
    },
    _onClickFillAll() {
      if (!this.active || !this.buttonEnabled) return;

      this.active = false;
      this.onClickFillAll(this.rating, this.overwrite);
    }
  }
});
