


















































































import Vue, { PropType } from "vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { ClassStudent } from "@/ts/objects/common/Class";
import { AppStateStore } from "@/store/AppStateStore";
import { SolanStoreT } from "@/store/SolanStore";
import { SolanProject } from "@/ts/objects/solan/value/SolanProject";
import PopupSelectButton, { SelectItem } from "@/components/PopupSelectButton.vue";
import { DataSlotState } from "@/ts/DataSlot";
import log from "loglevel";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { SolanRepository } from "@/ts/repositories/SolanRepository";

export default Vue.extend({
  name: "SolanStudentViewT",
  components: { MessageView, PopupSelectButton },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    solanStoreT: { type: Object as PropType<SolanStoreT>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true }
  },
  created() {
    this.solanStoreT.resetState();
  },
  beforeDestroy() {
    this.solanStoreT.resetState();
  },
  data(): { messageView: MessageViewParam | null } {
    return {
      messageView: null
    };
  },
  computed: {
    studentViewBasePath(): string {
      return this.solanStoreT.studentViewBasePathOf(this.selectedProjectId);
    },
    selectedStudentUserId(): string | null {
      const id: string | null | undefined = this.$route.params["solanStudentUserId"];
      if (id === null || id === undefined || id.length === 0) return null;
      return id;
    },
    selectedProjectId(): string | null {
      const id: string | null | undefined = this.$route.params["solanProjectId"];
      if (id === null || id === undefined || id.length === 0) return null;
      return id;
    },
    isCurrentTabJournals(): boolean {
      return this.$route.name === "T.Solan.StudentView.Journals";
    },
    isCurrentTabRubrics(): boolean {
      return this.$route.name === "T.Solan.StudentView.Rubrics";
    },
    isCurrentTabAbout(): boolean {
      return this.$route.name === "T.Solan.StudentView.About";
    },
    isCurrentTabLookback(): boolean {
      return this.$route.name === "T.Solan.StudentView.Lookback";
    },
    currentTabPathSegment(): string {
      if (this.isCurrentTabJournals) return "journals";
      if (this.isCurrentTabRubrics) return "rubrics";
      if (this.isCurrentTabAbout) return "about";
      if (this.isCurrentTabLookback) return "lookback";
      return "";
    },
    solanStoreDataSlotState(): DataSlotState {
      return this.solanStoreT.projects.state;
    }
  },
  asyncComputed: {
    async selectedStudent(): Promise<ClassStudent | null> {
      const studentUserId = this.selectedStudentUserId;
      if (studentUserId === null) return null;
      return this.appStateStore.teacherState?.studentOfSelectedClass(this.userRepository, studentUserId) ?? null;
    },
    async selectedProject(): Promise<SolanProject | null> {
      const projectId = this.selectedProjectId;
      if (projectId === null) return null;
      return await this.solanStoreT.getProject(projectId).getDataWithTimeout();
    },
    async classStudents(): Promise<ClassStudent[]> {
      return this.appStateStore.teacherState?.studentsOfSelectedClass(this.userRepository) ?? [];
    },
    async projectSelections(): Promise<SelectItem[]> {
      const projects = await this.solanStoreT.projects.getDataWithTimeout();
      if (projects === null) return [];
      return projects.map(p => {
        return {
          key: `${this.appStateStore.teacherBasePath}/solan/studentView/${p.studentUserId}/${p.projectId}`,
          label: p.name !== "" ? p.name : "新しいテーマ"
        };
      });
    }
  },
  watch: {
    selectedStudentUserId: {
      handler: function(newValue: string, oldValue: string) {
        log.debug(`watch:selectedStudentUserId: oldValue=${oldValue}, newValue=${newValue}`);
        if (newValue !== oldValue) {
          this.solanStoreT.reloadProjectsIfStudentChanged({
            userRepository: this.userRepository,
            solanRepository: this.solanRepository,
            studentUserId: newValue
          });
        }
      },
      immediate: true
    },
    solanStoreDataSlotState(newValue: DataSlotState, oldValue: DataSlotState) {
      // プロジェクトリストのロードが終わったとき、プロジェクト未指定状態なら、最初のプロジェクトのパスに遷移する。
      const project = this.solanStoreT.getProject(this.selectedProjectId).data;
      const firstProjectPath = this.solanStoreT.firstProjectPath.data;
      log.debug(
        `watch:solanStoreDataSlotState: oldValue=${oldValue}, newValue=${newValue}, project=${project?.projectId}`
      );
      if (
        oldValue === DataSlotState.Loading &&
        newValue === DataSlotState.DataLoaded &&
        project === null &&
        firstProjectPath !== null
      ) {
        this.$router.push(firstProjectPath).catch(() => {});
      }
    }
  },
  methods: {
    selectProject(path: string) {
      log.debug(`selectProject: path=${path}`);
      this.$router.push(`${path}/${this.currentTabPathSegment}`).catch(() => {});
    }
  }
});
