







































































































import Vue, { PropType } from "vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { SolanProject } from "@/ts/objects/solan/value/SolanProject";
import { EditableSolanRubric } from "@/ts/objects/solan/editable/EditableSolanRubric";
import { solanProcessTextOf } from "@/ts/utils/AppUtil";
import PopupMenuButton, { MenuButton, MenuItem } from "@/components/PopupMenuButton.vue";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";

export default Vue.extend({
  name: "SolanRubricsPure",
  components: { LoadingBlock, MessageView, DisableableAutoResizeTextarea, PopupMenuButton },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },

    project: { type: Object as PropType<SolanProject | null> },
    rubrics: { type: Array as PropType<EditableSolanRubric[] | null> },

    selectedProcess: { type: Number },
    addRubricEnabled: { type: Boolean },

    selectProcess: { type: Function },
    addRubric: { type: Function },
    deleteRubric: { type: Function }
  },
  data(): {
    rubricPopupMenuItems: MenuItem[];
  } {
    return {
      rubricPopupMenuItems: [new MenuButton("delete", "削除", ["fas", "trash-alt"])]
    };
  },
  computed: {
    reversedRubrics(): EditableSolanRubric[] | null {
      const rubrics = this.rubrics;
      if (rubrics === null) return null;
      return rubrics.slice().reverse();
    }
  },
  methods: {
    onRubricPopupMenuClicked(menuKey: string, rubricId: string) {
      switch (menuKey) {
        case "delete":
          this.deleteRubric(rubricId);
          return;
      }
    },
    _solanProcessTextOf(process: number): string {
      return solanProcessTextOf(process);
    }
  }
});
