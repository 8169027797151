import { Err } from "@/ts/objects/Err";

export type SaveResult = {
  didSave: boolean; // （エラーが返ってきた場合を含めて）1個でもセーブを行ったならtrue。
  someSkipped: boolean; // 既にレスポンス待ち中等の理由で、セーブリクエストをスキップしたものが1個でもあればtrue。
  errors: Err[];
};

export function emptySaveResult(): SaveResult {
  return {
    didSave: false,
    someSkipped: false,
    errors: []
  };
}

export function flattenSaveResults(saveResults: SaveResult[]): SaveResult {
  return {
    didSave: saveResults.some(r => r.didSave),
    someSkipped: saveResults.some(r => r.someSkipped),
    errors: saveResults.flatMap(r => r.errors)
  };
}

// export function notifiableErrsOf(saveResult: SaveResult): NotifiableErr[] {
//   const errs = saveResult.errors.filter((e): e is NotifiableErr => e instanceof NotifiableErr);
//   return _.sortBy(errs, [
//     function(e) {
//       return e.notificationKey;
//     }
//   ]);
// }
