import { render, staticRenderFns } from "./CurriculumReadEEC.vue?vue&type=template&id=5ef72f16&scoped=true&"
import script from "./CurriculumReadEEC.vue?vue&type=script&lang=ts&"
export * from "./CurriculumReadEEC.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ef72f16",
  null
  
)

export default component.exports