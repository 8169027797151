import { Grade } from "@/ts/objects/common/Grade";
import { Color } from "@/ts/objects/common/Color";
import { NECEvaluation } from "@/ts/objects/curriculum/value/NECEvaluation";
import {
  CurriculumTestDataSet,
  EECurriculumPartialArg,
  genNECEvaluationsForYear,
  NECurriculumPartialArg,
  partialArgsToCurriculumTestDataSet,
  EECJournalStudentPartialArgWithIds
} from "@/test-tools/CurriculumTestDataTypes";

export function getCurriculumTestData(): CurriculumTestDataSet {
  /**
   * 数値評価教科。
   */
  const neCurriculums: Record<string, NECurriculumPartialArg> = {
    nec000: {
      schoolYear: 2000,
      grade: new Grade("e1"),
      name: "国語",
      viewPoints: {
        viewPoint000: {
          name: "知識・技能",
          color: new Color(255, 87, 147),
          contents: {
            content000: {
              name: "言葉の特徴や使い方に関すること",
              enabledMonths: [4, 5, 6, 8, 9, 3],
              monthBaseText:
                "・司会，記録，提案者，などの役割について理解する。aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa\n・司会，記録，提案者，などの役割について理解する。\n・司会，記録，提案者，などの役割について理解する。\n・司会，記録，提案者，などの役割について理解する。\n・司会，記録，提案者，などの役割について理解する。"
            },
            content001: {
              name: "情報の扱い方に関すること",
              enabledMonths: [6, 8, 9],
              monthBaseText: "段落の役割について理解している．"
            },
            content002: {
              name: "我が国の言語文化に関する事項、いろはにほへとちりぬるをわかよたれそつねならむ",
              enabledMonths: [4, 7, 8, 9],
              monthBaseText: "８つの考え方とその考え方に対応した思考ツールの使い方を理解する．"
            }
          }
        },
        viewPoint001: {
          name: "思考・判断・表現",
          color: new Color(67, 97, 255),
          contents: {
            content000: {
              name: "話すこと・聞くこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText: "目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．"
            },
            content001: {
              name: "書くこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText: "言葉の役割や性質を捉えようとする．"
            },
            content002: {
              name: "読むこと",
              enabledMonths: [],
              monthBaseText:
                "進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。"
            }
          }
        },
        viewPoint002: {
          name: "学びに向かう力・人間性等",
          color: new Color(71, 173, 62),
          contents: {
            // content000: {
            //   name: "話すこと・聞くこと",
            //   enabledMonths: [4, 5, 6, 8, 9],
            //   monthBaseText: "目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．"
            // },
            // content001: {
            //   name: "書くこと",
            //   enabledMonths: [4, 5, 6, 8, 9],
            //   monthBaseText: "言葉の役割や性質を捉えようとする．"
            // },
            // content002: {
            //   name: "読むこと",
            //   enabledMonths: [4, 5, 6, 8, 9],
            //   monthBaseText:
            //     "進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。"
            // }
          }
        }
      }
    },
    nec001: {
      schoolYear: 2000,
      grade: new Grade("e1"),
      name: "算数",
      viewPoints: {
        viewPoint000: {
          name: "知識・技能",
          color: new Color(255, 87, 147),
          contents: {
            content000: {
              name: "言葉の特徴や使い方に関すること",
              enabledMonths: [4, 5, 6, 8, 9, 3],
              monthBaseText:
                "司会，記録，提案者，などの役割について理解する．aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa"
            },
            content001: {
              name: "情報の扱い方に関すること",
              enabledMonths: [6, 8, 9],
              monthBaseText: "段落の役割について理解している．"
            },
            content002: {
              name: "我が国の言語文化に関する事項、いろはにほへとちりぬるをわかよたれそつねならむ",
              enabledMonths: [4, 7, 8, 9],
              monthBaseText: "８つの考え方とその考え方に対応した思考ツールの使い方を理解する．"
            }
          }
        },
        viewPoint001: {
          name: "思考・判断・表現",
          color: new Color(67, 97, 255),
          contents: {
            content000: {
              name: "話すこと・聞くこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText: "目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．"
            },
            content001: {
              name: "書くこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText: "言葉の役割や性質を捉えようとする．"
            },
            content002: {
              name: "読むこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText:
                "進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。"
            }
          }
        },
        viewPoint002: {
          name: "学びに向かう力・人間性等",
          color: new Color(71, 173, 62),
          contents: {
            content000: {
              name: "話すこと・聞くこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText:
                "・目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．\n・目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．\n・目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．"
            },
            content001: {
              name: "書くこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText: "言葉の役割や性質を捉えようとする．"
            },
            content002: {
              name: "読むこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText:
                "・進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。\n・進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。\n・進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。"
            }
          }
        }
      }
    },
    nec002: {
      schoolYear: 2000,
      grade: new Grade("e1"),
      name: "理科",
      viewPoints: {
        viewPoint000: {
          name: "知識・技能",
          color: new Color(255, 87, 147),
          contents: {
            content000: {
              name: "言葉の特徴や使い方に関すること",
              enabledMonths: [4, 5, 6, 8, 9, 3],
              monthBaseText:
                "・司会，記録，提案者，などの役割について理解する。aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa。"
            },
            content001: {
              name: "情報の扱い方に関すること",
              enabledMonths: [6, 8, 9],
              monthBaseText: "段落の役割について理解している．"
            },
            content002: {
              name: "我が国の言語文化に関する事項、いろはにほへとちりぬるをわかよたれそつねならむ",
              enabledMonths: [4, 7, 8, 9],
              monthBaseText: "８つの考え方とその考え方に対応した思考ツールの使い方を理解する．"
            }
          }
        },
        viewPoint001: {
          name: "思考・判断・表現",
          color: new Color(67, 97, 255),
          contents: {
            content000: {
              name: "話すこと・聞くこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText: "目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．"
            },
            content001: {
              name: "書くこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText: "言葉の役割や性質を捉えようとする．"
            },
            content002: {
              name: "読むこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText:
                "進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。"
            }
          }
        },
        viewPoint002: {
          name: "学びに向かう力・人間性等",
          color: new Color(71, 173, 62),
          contents: {
            content000: {
              name: "話すこと・聞くこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText: "目的・進め方の確認と司会の役割、意見の共通・相違点に着目して考えをまとめる．"
            },
            content001: {
              name: "書くこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText: "言葉の役割や性質を捉えようとする．"
            },
            content002: {
              name: "読むこと",
              enabledMonths: [4, 5, 6, 8, 9],
              monthBaseText:
                "進んで段落相互の関係に着目しながら内容を捉え，学習課題に沿って，読んで考えたことを文章にまとめようとしている。"
            }
          }
        }
      }
    }
  };
  /**
   * 数値評価教科の評価。
   */
  const necEvaluations: NECEvaluation[] = [
    ...genNECEvaluationsForYear({
      nec000: {
        viewPoint000: {
          content000: {
            student000: {
              4: { rating: "B", teacherInputPublished: true },
              5: { rating: "", teacherInputPublished: true },
              6: { rating: "A", teacherInputPublished: true },
              3: { rating: "S", teacherInputPublished: true }
            }
          },
          content001: {
            student001: {
              4: { rating: "C", teacherInputPublished: true },
              5: { rating: "B", teacherInputPublished: true },
              6: { rating: "A", teacherInputPublished: true }
            }
          }
        },
        viewPoint001: {
          content000: {
            student000: {
              4: { rating: "B", teacherInputPublished: true },
              5: { rating: "", teacherInputPublished: true },
              6: { rating: "A", teacherInputPublished: true },
              7: { rating: "A", teacherInputPublished: false },
              3: { rating: "S", teacherInputPublished: true }
            }
          }
        }
      },
      nec001: {
        viewPoint000: {
          content000: {
            student000: {
              4: { rating: "B", teacherInputPublished: true },
              5: { rating: "", teacherInputPublished: true },
              6: { rating: "A", teacherInputPublished: true },
              3: { rating: "S", teacherInputPublished: true }
            }
          },
          content001: {
            student001: {
              4: { rating: "C", teacherInputPublished: true },
              5: { rating: "B", teacherInputPublished: true },
              6: { rating: "A", teacherInputPublished: true }
            }
          }
        }
      }
    })
  ];
  /**
   * 文書評価教科。
   */
  const eeCurriculums: Record<string, EECurriculumPartialArg> = {
    eec000: {
      schoolYear: 2000,
      grade: new Grade("e1"),
      name: "道徳",
      months: {
        4: {
          activities: {
            activity000: { enabled: true, text: "みんなで話し合う4-000" },
            activity001: { enabled: true, text: "みんなで話し合う4-001" },
            activity002: { enabled: false, text: "みんなで話し合う4-002" },
            activity003: { enabled: true, text: "みんなで話し合う4-003" }
          }
        },
        5: {
          activities: {
            activity000: { enabled: true, text: "みんなで話し合う5-000" },
            activity001: { enabled: true, text: "みんなで話し合う5-001" },
            activity002: { enabled: true, text: "みんなで話し合う5-002" },
            activity003: { enabled: true, text: "みんなで話し合う5-003" }
          }
        },
        6: {
          activities: {
            activity000: { enabled: true, text: "みんなで話し合う6-000" },
            activity001: { enabled: true, text: "みんなで話し合う6-001" },
            activity002: { enabled: true, text: "みんなで話し合う6-002" },
            activity003: { enabled: true, text: "みんなで話し合う6-003" }
          }
        },
        7: {}
      }
    },
    eec001: {
      schoolYear: 2000,
      grade: new Grade("e1"),
      name: "委員会活動",
      months: {
        4: {
          activities: {
            activity000: { enabled: true, text: "みんなで話し合う4-000" },
            activity001: { enabled: true, text: "みんなで話し合う4-001" },
            activity002: { enabled: false, text: "みんなで話し合う4-002" },
            activity003: { enabled: true, text: "みんなで話し合う4-003" }
          }
        },
        5: {
          activities: {
            activity000: { enabled: true, text: "みんなで話し合う5-000" },
            activity001: { enabled: true, text: "みんなで話し合う5-001" },
            activity002: { enabled: true, text: "みんなで話し合う5-002" },
            activity003: { enabled: true, text: "みんなで話し合う5-003" }
          }
        },
        6: {
          activities: {
            activity000: { enabled: true, text: "みんなで話し合う6-000" },
            activity001: { enabled: true, text: "みんなで話し合う6-001" },
            activity002: { enabled: true, text: "みんなで話し合う6-002" },
            activity003: { enabled: true, text: "みんなで話し合う6-003" }
          }
        },
        7: {}
      }
    }
  };
  /**
   * 文書評価教科の学習記録児童生徒。
   */
  const eecJournalStudents: Record<string, EECJournalStudentPartialArgWithIds> = {
    "eec000-student000": {
      eecId: "eec000",
      studentUserId: "student000",
      journals: {
        journal000: {
          month: 4,
          activity: "みんなで話し合う！4-0",
          studentComment: "話し合った。4-0",
          teacherComment: "話し合いましたね。4-0",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        },
        journal001: {
          month: 4,
          activity: "みんなで話し合う！4-1",
          studentComment: "話し合った。4-1",
          teacherComment: "話し合いましたね。4-1",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        },
        journal002: {
          month: 5,
          activity: "みんなで話し合う！5-0",
          studentComment: "話し合った。5-0",
          teacherComment: "話し合いましたね。5-0",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        },
        journal003: {
          month: 5,
          activity: "みんなで話し合う！5-1",
          studentComment: "話し合った。5-1",
          teacherComment: "話し合いましたね。5-1",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        },
        journal004: {
          month: 6,
          activity: "みんなで話し合う！6-0",
          studentComment: "話し合った。6-0",
          teacherComment: "話し合いましたね。6-0",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        }
      }
    },
    "eec000-student001": {
      eecId: "eec000",
      studentUserId: "student001",
      journals: {
        journal000: {
          month: 4,
          activity: "みんなで話し合う？4-0",
          studentComment: "話し合った？4-0",
          teacherComment: "話し合いましたね？4-0",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        },
        journal001: {
          month: 4,
          activity: "みんなで話し合う？4-1",
          studentComment: "話し合った？4-1",
          teacherComment: "話し合いましたね？4-1",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        },
        journal002: {
          month: 5,
          activity: "みんなで話し合う？5-0",
          studentComment: "話し合った？5-0",
          teacherComment: "話し合いましたね？5-0",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        },
        journal004: {
          month: 6,
          activity: "みんなで話し合う？6-0",
          studentComment: "話し合った？6-0",
          teacherComment: "話し合いましたね？6-0",
          teacherInputPublished: true,
          createdAt: "2000-01-01T00:00:00Z",
          studentInputLocked: false,
          journalFiles: {}
        }
      }
    }
  };
  return partialArgsToCurriculumTestDataSet({
    neCurriculum: neCurriculums,
    necEvaluation: necEvaluations,
    eeCurriculum: eeCurriculums,
    eecJournalStudent: eecJournalStudents
  });
}
