import { ActionLog } from "@/ts/objects/common/ActionLog";
import { format } from "date-fns";
import max from "date-fns/max";
import min from "date-fns/min";
import uniqBy from "lodash/uniqBy";

/**
 * 複数のアクションログをまとめた「最近の学習」。
 *
 * 以下の前提。
 *
 * - アクションログ必ず1件以上。
 * - 対象サービス(objectService)、内容(data)は必ず同じ。
 * - 日を跨ぐことは無い。
 * - 同じ児童生徒のアクションログが複数含まれることがありうる。（同じ児童生徒のアクションログだけでもありうる）
 *
 * ただし、ここではその前提の検証はしない（バックエンドの責務とする）。
 */
export class RecentStudy {
  private readonly _actionLogs: ActionLog[];
  private readonly _displayName: string;
  private readonly _photoUrls: string[]; // 最初の3つだけ。 TODO 順番どうしよう？
  private readonly _displayDate: string;
  private readonly _displayTime: string;
  private readonly _students: BasicStudentInfo[];
  private readonly _studentNames: string[];

  constructor(actionLogs: ActionLog[]) {
    if (actionLogs.length === 0) throw Error("RecentStudyMulti must contain 1 or more action logs.");

    this._actionLogs = actionLogs;

    const students = uniqBy(actionLogs, "studentUserId").map(a => {
      return {
        studentUserId: a.studentUserId,
        studentUserName: a.studentUserName,
        studentPhotoUrl: a.studentPhotoUrl
      };
    });
    this._students = students;

    this._photoUrls = students.map(log => log.studentPhotoUrl).slice(0, 3);

    const datetimes = actionLogs.map(log => log.updatedAt);
    const minDatetime = min(datetimes);
    const maxDatetime = max(datetimes);
    this._displayDate = format(minDatetime, "M/d"); // 日を跨ぐことはないので、最小のdatetimeの日を採用する。
    this._studentNames = students.map(log => log.studentUserName);

    if (students.length === 1) {
      this._displayName = actionLogs[0].studentUserName;
    } else {
      this._displayName = `${students.length}名`;
    }

    if (actionLogs.length === 1) {
      this._displayTime = format(actionLogs[0].updatedAt, "H:mm");
    } else {
      this._displayTime = `${format(minDatetime, "H:mm")}～${format(maxDatetime, "H:mm")}`;
    }
  }

  photoUrls(): string[] {
    return this._photoUrls;
  }

  displayName(): string {
    return this._displayName;
  }

  displayDate(): string {
    return this._displayDate;
  }

  displayTime(): string {
    return this._displayTime;
  }

  objectService(): string {
    return this._actionLogs[0].objectService;
  }

  data(): string {
    return this._actionLogs[0].data;
  }

  numStudents(): number {
    return this._students.length;
  }

  numActionLogs(): number {
    return this._actionLogs.length;
  }

  studentNames(): string[] {
    return this._studentNames;
  }
}

type BasicStudentInfo = {
  studentUserId: string;
  studentUserName: string;
  studentPhotoUrl: string;
};
