














import Vue, { PropType } from "vue";
import { ProjectStore } from "@/store/ProjectStore";
import { Quarter } from "@/ts/objects/common/Quarter";
import { Project } from "@/ts/objects/project/value/Project";
import { AppStateStore } from "@/store/AppStateStore";
import log from "loglevel";
import ProjectHeaderPure from "@/views/project/student/ProjectHeader/ProjectHeaderPure.vue";
import { ProjectTabName } from "@/ts/objects/project/value/ProjectTabName";

export default Vue.extend({
  name: "ProjectHeader",
  components: { ProjectHeaderPure },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    projectStore: { type: Object as PropType<ProjectStore>, required: true },

    currentRouteName: { type: String as PropType<string | null> }
  },
  computed: {
    selectedTabName(): ProjectTabName | null {
      if (this.currentRouteName === "S.Project.Journals") {
        return "journals";
      } else if (this.currentRouteName === "S.Project.Rubrics") {
        return "rubrics";
      } else if (this.currentRouteName === "S.Project.About") {
        return "about";
      } else if (this.currentRouteName === "S.Project.Lookback") {
        return "lookback";
      }

      return null;
    },

    project(): Project | null {
      return this.projectStore.project.data;
    },
    prevQuarter(): Quarter | null {
      const minSchoolYear = this.appStateStore.studentOrGuardianState?.studentMinSchoolYear() ?? null;
      if (minSchoolYear === null) return null;
      return this.projectStore.quarter?.getPrevQuarterUnderMinSchoolYear(minSchoolYear) ?? null;
    },
    nextQuarter(): Quarter | null {
      const maxSchoolYear = this.appStateStore.studentOrGuardianState?.studentMaxSchoolYear() ?? null;
      if (maxSchoolYear === null) return null;
      return this.projectStore.quarter?.getNextQuarterUnderMaxSchoolYear(maxSchoolYear) ?? null;
    }
  },
  methods: {
    selectProject(path: string) {
      log.debug(`selectProject: path=${path}`);
      this.$router.push(path).catch(() => {});
    }
  }
});
