import {
  DefaultApi as CurriculumDefaultApi,
  NECurriculum as NECurriculumResp,
  EECurriculum as EECurriculumResp,
  Content as ContentResp,
  EvaluationWrite,
  Evaluation as EvaluationResp,
  ContentWrite,
  ContentMonth as ContentMonthResp,
  ContentMonthWrite,
  Journal as JournalResp,
  JournalWrite,
  Activity as ActivityResp,
  ActivityWrite
} from "@/ts/api/curriculum-service";
import { Grade } from "@/ts/objects/common/Grade";
import { NECurriculum, NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { Err } from "@/ts/objects/Err";
import { EECurriculum, EECurriculumTree } from "@/ts/objects/curriculum/value/EECurriculum";
import { NECEvaluation } from "@/ts/objects/curriculum/value/NECEvaluation";
import {
  EECJournal,
  EECJournalFile,
  EECJournalFileTree,
  EECJournalStudent,
  EECJournalTree
} from "@/ts/objects/curriculum/value/EECJournal";
import { Configuration } from "@/ts/api/log-service";
import { AxiosInstance } from "axios";
import { doReq, isMonthValue, isNullish, MonthValue, monthValues } from "@/ts/utils";
import { isRatingValue } from "@/ts/objects/common/Rating";
import { EditableNECEvaluation } from "@/ts/objects/curriculum/editable/EditableNECEvaluation";
import { EditableNECContent } from "@/ts/objects/curriculum/editable/EditableNECContent";
import { EditableNECContentMonth } from "@/ts/objects/curriculum/editable/EditableNECContentMonth";
import {
  EditableEECJournal,
  EditableEECJournalStudentTree,
  EditableEECJournalTree
} from "@/ts/objects/curriculum/editable/EditableEECJournal";
import { EditableEECActivity } from "@/ts/objects/curriculum/editable/EditableEECActivity";
import { EECMonth, EECMonthTree } from "@/ts/objects/curriculum/value/EECMonth";
import { EECActivity, EECActivityTree } from "@/ts/objects/curriculum/value/EECActivity";
import { NECViewPoint, NECViewPointTree } from "@/ts/objects/curriculum/value/NECViewPoint";
import { NECContent, NECContentTree } from "@/ts/objects/curriculum/value/NECContent";
import { NECContentMonth, NECContentMonthTree } from "@/ts/objects/curriculum/value/NECContentMonth";

export abstract class CurriculumRepository {
  abstract getNECurriculum(necId: string): Promise<NECurriculumTree | Err>;

  abstract listNECurriculums(schoolYear: number, grade: Grade): Promise<NECurriculumTree[] | Err>;

  abstract uploadNECSyllabusFile(necId: string, file: any, timeoutMillis: number): Promise<NECurriculumResp | Err>;

  abstract getEECurriculum(eecId: string): Promise<EECurriculumTree | Err>;

  abstract listEECurriculums(schoolYear: number, grade: Grade): Promise<EECurriculumTree[] | Err>;

  abstract uploadEECSyllabusFile(eecId: string, file: any, timeoutMillis: number): Promise<EECurriculumResp | Err>;

  abstract sortNECViewPoints(eecId: string, viewPointIds: string[]): Promise<void | Err>;

  abstract listEditableNECContents(savable: boolean, necId: string): Promise<EditableNECContent[] | Err>;

  abstract postAndGetEditableNECContent(
    savable: boolean,
    necId: string,
    viewPointId: string
  ): Promise<{ content: EditableNECContent; contentMonths: EditableNECContentMonth[] } | Err>;

  abstract patchNECContent(
    necId: string,
    viewPointId: string,
    contentId: string,
    contentWrite: ContentWrite
  ): Promise<ContentResp | Err>;

  abstract deleteNECContent(necId: string, viewPointId: string, contentId: string): Promise<void | Err>;

  abstract sortNECContents(necId: string, viewPointId: string, contentIds: string[]): Promise<void | Err>;

  abstract listEditableNECContentMonths(savable: boolean, necId: string): Promise<EditableNECContentMonth[] | Err>;

  abstract patchNECContentMonth(
    necId: string,
    viewPointId: string,
    contentId: string,
    month: MonthValue,
    contentMonthWrite: ContentMonthWrite
  ): Promise<ContentMonthResp | Err>;

  abstract listNECEvaluations(
    necId?: string,
    viewPointId?: string,
    contentId?: string,
    month?: MonthValue,
    classId?: string,
    studentUserId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<NECEvaluation[] | Err>;

  abstract listEditableNECEvaluations(
    savable: boolean,
    necId?: string,
    viewPointId?: string,
    contentId?: string,
    month?: MonthValue,
    classId?: string,
    studentUserId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<EditableNECEvaluation[] | Err>;

  abstract patchNECEvaluation(
    necId: string,
    viewPointId: string,
    contentId: string,
    month: MonthValue,
    studentUserId: string,
    evaluationWrite: EvaluationWrite
  ): Promise<EvaluationResp | Err>;

  abstract listEditableEECActivities(savable: boolean, eecId: string): Promise<EditableEECActivity[] | Err>;

  abstract postAndGetEditableEECActivity(
    savable: boolean,
    eecId: string,
    month: MonthValue,
    activityWrite: ActivityWrite
  ): Promise<EditableEECActivity | Err>;

  abstract patchEECActivity(
    eecId: string,
    month: MonthValue,
    activityId: string,
    activityWrite: ActivityWrite
  ): Promise<ActivityResp | Err>;

  abstract deleteEECActivity(eecId: string, month: MonthValue, activityId: string): Promise<void | Err>;

  abstract sortEECActivities(eecId: string, month: MonthValue, activityIds: string[]): Promise<void | Err>;

  abstract listEditableEECJournalStudents(
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    eecId: string | undefined,
    studentUserId?: string,
    classId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<EditableEECJournalStudentTree[] | Err>;

  abstract listEECJournals(
    eecId?: string,
    classId?: string,
    studentUserId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<EECJournalTree[] | Err>;

  abstract listEditableEECJournals(
    studentInputSavable: boolean,
    teacherInputSavable: boolean,
    eecId?: string,
    classId?: string,
    studentUserId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<EditableEECJournalTree[] | Err>;

  abstract postAndGetEditableEECJournal(
    studentInputSavable: boolean,
    teacherInputSavable: boolean,
    eecId: string,
    studentUserId: string,
    journalWrite: JournalWrite
  ): Promise<EditableEECJournalTree | Err>;

  abstract patchEECJournal(
    eecId: string,
    studentUserId: string,
    journalId: string,
    journalWrite: JournalWrite
  ): Promise<JournalResp | Err>;

  abstract deleteEECJournal(eecId: string, studentUserId: string, journalId: string): Promise<void | Err>;

  abstract listEECJournalFiles(
    eecId: string,
    studentUserId: string,
    journalId: string
  ): Promise<EECJournalFile[] | Err>;

  abstract uploadEECJournalFile(
    eecId: string,
    studentUserId: string,
    journalId: string,
    file: any,
    timeoutMillis: number
  ): Promise<EECJournalFile | Err>;

  abstract deleteEECJournalFile(
    eecId: string,
    studentUserId: string,
    journalId: string,
    journalFileId: string
  ): Promise<void | Err>;
}

export class CurriculumRepositoryImpl extends CurriculumRepository {
  private readonly curriculumService: CurriculumDefaultApi;

  constructor(serviceBasePath: string, axiosConf: Configuration | undefined, axiosInstance: AxiosInstance) {
    super();
    this.curriculumService = new CurriculumDefaultApi(axiosConf, serviceBasePath, axiosInstance);
  }

  async getNECurriculum(this: this, necId: string): Promise<NECurriculumTree | Err> {
    const [necResp, viewPointResp, contentResp, contentMonthResp] = await Promise.all([
      doReq(() => this.curriculumService.getNECurriculum(necId)),
      doReq(() => this.curriculumService.listViewPoint(necId)),
      doReq(() => this.curriculumService.listContent(necId, "-")),
      doReq(() => this.curriculumService.listContentMonth(necId, "-", "-"))
    ]);
    if (necResp instanceof Err) return necResp;
    if (viewPointResp instanceof Err) return viewPointResp;
    if (contentResp instanceof Err) return contentResp;
    if (contentMonthResp instanceof Err) return contentMonthResp;

    return new NECurriculumTree(
      NECurriculum.fromNECurriculumResp(necResp),
      viewPointResp
        .filter(vp => vp.necId === necResp.necId)
        .map(
          vp =>
            new NECViewPointTree(
              NECViewPoint.fromViewPointResp(vp),
              contentResp
                .filter(c => c.necId === vp.necId && c.viewPointId === vp.viewPointId)
                .map(
                  c =>
                    new NECContentTree(
                      NECContent.fromContentResp(c),
                      contentMonthResp
                        .filter(
                          cm => cm.necId === c.necId && cm.viewPointId === c.viewPointId && cm.contentId === c.contentId
                        )
                        .map(cm => new NECContentMonthTree(NECContentMonth.fromContentMonthResp(cm)))
                    )
                )
            )
        )
    );
  }

  async listNECurriculums(this: this, schoolYear: number, grade: Grade): Promise<NECurriculumTree[] | Err> {
    const [necResp, viewPointResp, contentResp, contentMonthResp] = await Promise.all([
      doReq(() => this.curriculumService.listNECurriculum(schoolYear, grade.value, undefined)),
      doReq(() => this.curriculumService.listViewPoint("-", undefined, schoolYear, grade.value)),
      doReq(() => this.curriculumService.listContent("-", "-", undefined, schoolYear, grade.value)),
      doReq(() =>
        this.curriculumService.listContentMonth(
          "-",
          "-",
          "-",
          undefined,
          undefined,
          undefined,
          schoolYear,
          grade.value,
          undefined
        )
      )
    ]);
    if (necResp instanceof Err) return necResp;
    if (viewPointResp instanceof Err) return viewPointResp;
    if (contentResp instanceof Err) return contentResp;
    if (contentMonthResp instanceof Err) return contentMonthResp;

    return necResp.map(
      nec =>
        new NECurriculumTree(
          NECurriculum.fromNECurriculumResp(nec),
          viewPointResp
            .filter(vp => vp.necId === nec.necId)
            .map(
              vp =>
                new NECViewPointTree(
                  NECViewPoint.fromViewPointResp(vp),
                  contentResp
                    .filter(c => c.necId === vp.necId && c.viewPointId === vp.viewPointId)
                    .map(
                      c =>
                        new NECContentTree(
                          NECContent.fromContentResp(c),
                          contentMonthResp
                            .filter(
                              cm =>
                                cm.necId === c.necId && cm.viewPointId === c.viewPointId && cm.contentId === c.contentId
                            )
                            .map(cm => new NECContentMonthTree(NECContentMonth.fromContentMonthResp(cm)))
                        )
                    )
                )
            )
        )
    );
  }

  async uploadNECSyllabusFile(
    this: this,
    necId: string,
    file: any,
    timeoutMillis: number
  ): Promise<NECurriculumResp | Err> {
    return await doReq(() => this.curriculumService.updateNECSyllabusFile(necId, file, { timeout: timeoutMillis }));
  }

  async getEECurriculum(this: this, eecId: string): Promise<EECurriculumTree | Err> {
    const [eecResp, activityResp] = await Promise.all([
      doReq(() => this.curriculumService.getEECurriculum(eecId)),
      doReq(() => this.curriculumService.listActivity(eecId, "-"))
    ]);
    if (eecResp instanceof Err) return eecResp;
    if (activityResp instanceof Err) return activityResp;

    return new EECurriculumTree(
      EECurriculum.fromEECurriculumResp(eecResp),
      monthValues.map(
        month =>
          new EECMonthTree(
            new EECMonth(eecResp.eecId, month),
            activityResp
              .filter(a => a.eecId === eecResp.eecId && a.month === month)
              .map(a => new EECActivityTree(EECActivity.fromActivityResp(a)))
          )
      )
    );
  }

  async listEECurriculums(this: this, schoolYear: number, grade: Grade): Promise<EECurriculumTree[] | Err> {
    const [eecResp, activityResp] = await Promise.all([
      doReq(() => this.curriculumService.listEECurriculum(schoolYear, grade.value)),
      doReq(() => this.curriculumService.listActivity("-", "-", undefined, undefined, schoolYear, grade.value))
    ]);
    if (eecResp instanceof Err) return eecResp;
    if (activityResp instanceof Err) return activityResp;

    return eecResp.map(
      eec =>
        new EECurriculumTree(
          EECurriculum.fromEECurriculumResp(eec),
          monthValues.map(
            month =>
              new EECMonthTree(
                new EECMonth(eec.eecId, month),
                activityResp
                  .filter(a => a.eecId === eec.eecId && a.month === month)
                  .map(a => new EECActivityTree(EECActivity.fromActivityResp(a)))
              )
          )
        )
    );
  }

  async uploadEECSyllabusFile(
    this: this,
    eecId: string,
    file: any,
    timeoutMillis: number
  ): Promise<EECurriculumResp | Err> {
    return await doReq(() => this.curriculumService.updateEECSyllabusFile(eecId, file, { timeout: timeoutMillis }));
  }

  async sortNECViewPoints(this: this, necId: string, viewPointIds: string[]): Promise<void | Err> {
    return await doReq(() => this.curriculumService.sortViewPoint(necId, { viewPointIds }));
  }

  async listEditableNECContents(this: this, savable: boolean, necId: string): Promise<EditableNECContent[] | Err> {
    const resp = await doReq(() => this.curriculumService.listContent(necId, "-"));
    if (resp instanceof Err) return resp;

    return resp.map(c => EditableNECContent.fromContentResp(this, savable, c));
  }

  async postAndGetEditableNECContent(
    this: this,
    savable: boolean,
    necId: string,
    viewPointId: string
  ): Promise<{ content: EditableNECContent; contentMonths: EditableNECContentMonth[] } | Err> {
    const resp = await doReq(() => this.curriculumService.postContent(necId, viewPointId, true, {}));
    if (resp instanceof Err) return resp;

    const contentMonths = resp.months;
    if (contentMonths === undefined)
      throw new Error(
        `CurriculumRepositoryImpl.postAndGetEditableNECContent: months of response ${resp.self} is undefined.`
      );

    return {
      content: EditableNECContent.fromContentResp(this, savable, resp),
      contentMonths: monthValues.map(month => {
        const contentMonth = contentMonths.find(m => m.month === month);
        if (contentMonth === undefined)
          throw new Error(
            `CurriculumRepositoryImpl.postAndGetEditableNECContent: months of response ${resp.self} does not include month of ${month}.`
          );
        return EditableNECContentMonth.fromContentMonthResp(this, savable, contentMonth);
      })
    };
  }

  async patchNECContent(
    this: this,
    necId: string,
    viewPointId: string,
    contentId: string,
    contentWrite: ContentWrite
  ): Promise<ContentResp | Err> {
    return await doReq(() =>
      this.curriculumService.patchContent(necId, viewPointId, contentId, undefined, contentWrite)
    );
  }

  async deleteNECContent(this: this, necId: string, viewPointId: string, contentId: string): Promise<void | Err> {
    return await doReq(() => this.curriculumService.deleteContent(necId, viewPointId, contentId));
  }

  async sortNECContents(this: this, necId: string, viewPointId: string, contentIds: string[]): Promise<void | Err> {
    return await doReq(() => this.curriculumService.sortContent(necId, viewPointId, { contentIds }));
  }

  async listEditableNECContentMonths(
    this: this,
    savable: boolean,
    necId: string
  ): Promise<EditableNECContentMonth[] | Err> {
    const resp = await doReq(() =>
      this.curriculumService.listContentMonth(necId, "-", "-", undefined, undefined, undefined, undefined, undefined)
    );
    if (resp instanceof Err) return resp;

    return resp.map(c => {
      if (!isMonthValue(c.month))
        throw new Error(
          `CurriculumRepositoryImpl.listEditableNECContentMonths: invalid month value on ${c.self}: ${c.month}`
        );
      if (isNullish(c.text))
        throw new Error(`CurriculumRepositoryImpl.listEditableNECContentMonths: text is undefined on ${c.self}`);
      return new EditableNECContentMonth(
        this,
        savable,
        c.necId,
        c.viewPointId,
        c.contentId,
        c.month,
        c.enabled,
        c.text.value,
        c.text.hash
      );
    });
  }

  async patchNECContentMonth(
    this: this,
    necId: string,
    viewPointId: string,
    contentId: string,
    month: MonthValue,
    contentMonthWrite: ContentMonthWrite
  ): Promise<ContentMonthResp | Err> {
    return doReq(() =>
      this.curriculumService.patchContentMonth(necId, viewPointId, contentId, month, contentMonthWrite)
    );
  }

  async listNECEvaluations(
    this: this,
    necId?: string,
    viewPointId?: string,
    contentId?: string,
    month?: MonthValue,
    classId?: string,
    studentUserId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<NECEvaluation[] | Err> {
    const resp = await doReq(() =>
      this.curriculumService.listEvaluation(
        necId ?? "-",
        viewPointId ?? "-",
        contentId ?? "-",
        month?.toString() ?? "-",
        classId,
        studentUserId,
        schoolYear,
        grade?.value
      )
    );
    if (resp instanceof Err) return resp;

    return resp.map(ev => NECEvaluation.fromEvaluationResp(ev));
  }

  async listEditableNECEvaluations(
    this: this,
    savable: boolean,
    necId?: string,
    viewPointId?: string,
    contentId?: string,
    month?: MonthValue,
    classId?: string,
    studentUserId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<EditableNECEvaluation[] | Err> {
    const resp = await doReq(() =>
      this.curriculumService.listEvaluation(
        necId ?? "-",
        viewPointId ?? "-",
        contentId ?? "-",
        month?.toString() ?? "-",
        classId,
        studentUserId,
        schoolYear,
        grade?.value
      )
    );
    if (resp instanceof Err) return resp;

    return resp.map(ev => {
      if (!isMonthValue(ev.month))
        throw new Error(`CurriculumRepositoryImpl.listNECEvaluations: invalid month value on ${ev.self}: ${ev.month}`);
      const rating = ev.rating ?? "";
      if (!isRatingValue(rating))
        throw new Error(
          `CurriculumRepositoryImpl.listNECEvaluations: invalid rating value on ${ev.self}: ${ev.rating}`
        );

      return new EditableNECEvaluation(
        this,
        savable,
        ev.necId,
        ev.viewPointId,
        ev.contentId,
        ev.month,
        ev.studentUserId,
        rating,
        ev.teacherInputPublished
      );
    });
  }

  async patchNECEvaluation(
    this: this,
    necId: string,
    viewPointId: string,
    contentId: string,
    month: MonthValue,
    studentUserId: string,
    evaluationWrite: EvaluationWrite
  ): Promise<EvaluationResp | Err> {
    return await doReq(() =>
      this.curriculumService.patchEvaluation(
        necId,
        viewPointId,
        contentId,
        month.toString(),
        studentUserId,
        evaluationWrite
      )
    );
  }

  // async getEECJournalStudent(this: this, eecId: string, studentUserId: string): Promise<EECJournalStudentTree | Err> {
  //   const resp = await doReq(() => this.curriculumService.getJournalStudent(eecId, studentUserId, true, true));
  //   if (resp instanceof Err) return resp;
  //
  //   if (resp.journals === undefined)
  //     throw new Error(`CurriculumRepositoryImpl.getEECJournalStudent: ${resp.self}: journals field is undefined.`);
  //
  //   return new EECJournalStudentTree(
  //     EECJournalStudent.fromJournalStudentResp(resp),
  //     resp.journals.map(j => {
  //       if (j.files === undefined)
  //         throw new Error(`CurriculumRepositoryImpl.getEECJournalStudent: ${j.self}: files field is undefined.`);
  //       return new EECJournalTree(
  //         EECJournal.fromJournalResp(j),
  //         j.files.map(jf => new EECJournalFileTree(EECJournalFile.fromJournalFileResp(jf)))
  //       );
  //     })
  //   );
  // }

  async patchEECActivity(
    this: this,
    eecId: string,
    month: MonthValue,
    activityId: string,
    activityWrite: ActivityWrite
  ): Promise<ActivityResp | Err> {
    return doReq(() => this.curriculumService.patchActivity(eecId, month.toString(10), activityId, activityWrite));
  }

  async listEditableEECActivities(savable: boolean, eecId: string): Promise<EditableEECActivity[] | Err> {
    const resp = await doReq(() => this.curriculumService.listActivity(eecId, "-"));
    if (resp instanceof Err) return resp;

    return resp.map(a => EditableEECActivity.fromActivityResp(this, savable, a));
  }

  async postAndGetEditableEECActivity(
    this: this,
    savable: boolean,
    eecId: string,
    month: MonthValue,
    activityWrite: ActivityWrite
  ): Promise<EditableEECActivity | Err> {
    const resp = await doReq(() => this.curriculumService.postActivity(eecId, month.toString(10), activityWrite));
    if (resp instanceof Err) return resp;

    return EditableEECActivity.fromActivityResp(this, savable, resp);
  }

  async deleteEECActivity(this: this, eecId: string, month: MonthValue, activityId: string): Promise<void | Err> {
    return doReq(() => this.curriculumService.deleteActivity(eecId, month.toString(10), activityId));
  }

  async sortEECActivities(this: this, eecId: string, month: MonthValue, activityIds: string[]): Promise<void | Err> {
    return doReq(() => this.curriculumService.sortActivity(eecId, month.toString(10), { activityIds }));
  }

  async listEditableEECJournalStudents(
    this: this,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    eecId: string | undefined,
    studentUserId?: string,
    classId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<EditableEECJournalStudentTree[] | Err> {
    const resp = await doReq(() =>
      this.curriculumService.listJournalStudent(
        eecId ?? "-",
        studentUserId,
        classId,
        schoolYear,
        grade?.value,
        true,
        true
      )
    );
    if (resp instanceof Err) return resp;

    return resp.map(student => {
      if (student.journals === undefined)
        throw new Error(
          `CurriculumRepositoryImpl.listEECJournalStudents: ${student.self}: journals field is undefined.`
        );
      return new EditableEECJournalStudentTree(
        EECJournalStudent.fromJournalStudentResp(student),
        student.journals.map(j => {
          if (j.files === undefined)
            throw new Error(`CurriculumRepositoryImpl.listEECJournalStudents: ${j.self}: files field is undefined.`);
          return new EditableEECJournalTree(
            this,
            EditableEECJournal.fromJournalResp(this, teacherInputSavable, studentInputSavable, j),
            j.files.map(jf => new EECJournalFileTree(EECJournalFile.fromJournalFileResp(jf)))
          );
        })
      );
    });
  }

  async listEECJournals(
    this: this,
    eecId?: string,
    classId?: string,
    studentUserId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<EECJournalTree[] | Err> {
    const resp = await doReq(() =>
      this.curriculumService.listJournal(
        eecId ?? "-",
        studentUserId ?? "-",
        undefined,
        undefined,
        classId,
        schoolYear,
        grade?.value,
        true
      )
    );
    if (resp instanceof Err) return resp;

    return resp.map(j => {
      if (j.files === undefined)
        throw new Error(`CurriculumRepositoryImpl.listEECJournals: ${j.self}: files field is undefined.`);
      return new EECJournalTree(
        EECJournal.fromJournalResp(j),
        j.files.map(jf => new EECJournalFileTree(EECJournalFile.fromJournalFileResp(jf)))
      );
    });
  }

  async listEditableEECJournals(
    this: this,
    studentInputSavable: boolean,
    teacherInputSavable: boolean,
    eecId?: string,
    classId?: string,
    studentUserId?: string,
    schoolYear?: number,
    grade?: Grade
  ): Promise<EditableEECJournalTree[] | Err> {
    const resp = await doReq(() =>
      this.curriculumService.listJournal(
        eecId ?? "-",
        studentUserId ?? "-",
        undefined,
        undefined,
        classId,
        schoolYear,
        grade?.value,
        true
      )
    );
    if (resp instanceof Err) return resp;

    return resp.map(j => {
      if (j.files === undefined)
        throw new Error(`CurriculumRepositoryImpl.listEditableEECJournals: ${j.self}: file is undefined.`);
      return new EditableEECJournalTree(
        this,
        EditableEECJournal.fromJournalResp(this, teacherInputSavable, studentInputSavable, j),
        j.files.map(jf => new EECJournalFileTree(EECJournalFile.fromJournalFileResp(jf)))
      );
    });
  }

  async postAndGetEditableEECJournal(
    this: this,
    studentInputSavable: boolean,
    teacherInputSavable: boolean,
    eecId: string,
    studentUserId: string,
    journalWrite: JournalWrite
  ): Promise<EditableEECJournalTree | Err> {
    const resp = await doReq(() => this.curriculumService.postJournal(eecId, studentUserId, undefined, journalWrite));
    if (resp instanceof Err) return resp;

    if (!isMonthValue(resp.month))
      throw new Error(
        `CurriculumRepositoryImpl.postAndGetEditableEECJournal: invalid month value on ${resp.self}: ${resp.month}`
      );
    return new EditableEECJournalTree(
      this,
      EditableEECJournal.fromJournalResp(this, teacherInputSavable, studentInputSavable, resp),
      []
    );
  }

  async patchEECJournal(
    this: this,
    eecId: string,
    studentUserId: string,
    journalId: string,
    journalWrite: JournalWrite
  ): Promise<JournalResp | Err> {
    return await doReq(() =>
      this.curriculumService.patchJournal(eecId, studentUserId, journalId, undefined, journalWrite)
    );
  }

  async deleteEECJournal(this: this, eecId: string, studentUserId: string, journalId: string): Promise<void | Err> {
    return await doReq(() => this.curriculumService.deleteJournal(eecId, studentUserId, journalId));
  }

  async listEECJournalFiles(
    this: this,
    eecId: string,
    studentUserId: string,
    journalId: string
  ): Promise<EECJournalFile[] | Err> {
    const resp = await doReq(() => this.curriculumService.listJournalFile(eecId, studentUserId, journalId));
    if (resp instanceof Err) return resp;

    return resp.map(jf => EECJournalFile.fromJournalFileResp(jf));
  }

  async uploadEECJournalFile(
    this: this,
    eecId: string,
    studentUserId: string,
    journalId: string,
    file: any,
    timeoutMillis: number
  ): Promise<EECJournalFile | Err> {
    const resp = await doReq(() =>
      this.curriculumService.postJournalFile(eecId, studentUserId, journalId, file, { timeout: timeoutMillis })
    );
    if (resp instanceof Err) return resp;

    return EECJournalFile.fromJournalFileResp(resp);
  }

  async deleteEECJournalFile(
    this: this,
    eecId: string,
    studentUserId: string,
    journalId: string,
    journalFileId: string
  ): Promise<void | Err> {
    return await doReq(() => this.curriculumService.deleteJournalFile(eecId, studentUserId, journalId, journalFileId));
  }
}
