




































import Vue, { PropType } from "vue";
import CurriculumSyllabusHeader from "@/views/curriculum/teacher/components/CurriculumSyllabusHeader/CurriculumSyllabusHeader.vue";
import { QuarterSwitchValue, quarterSwitchValueToMonths } from "@/components/QuarterSwitch/QuarterSwitch.vue";
import CurriculumSyllabusNECViewPointRow from "@/views/curriculum/teacher/CurriculumSyllabusNEC/components/CurriculumSyllabusNECViewPointRow/CurriculumSyllabusNECViewPointRow.vue";
import { MonthValue } from "@/ts/utils";
import { Loadable } from "@/ts/Loadable";
import {
  ContentInputEvent,
  ContentMonthInputEvent,
  ICurriculumSyllabusNECModel
} from "@/views/curriculum/teacher/CurriculumSyllabusNEC/CurriculumSyllabusNECModel";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import MessageView from "@/components/MessageView.vue";
import { ErrorNotificationParam } from "@/components/ErrorNotification.vue";

export default Vue.extend({
  name: "CurriculumSyllabusNECPure",
  components: { MessageView, LoadingBlock, CurriculumSyllabusNECViewPointRow, CurriculumSyllabusHeader },
  props: {
    model: { type: Object as PropType<Loadable<ICurriculumSyllabusNECModel>>, required: true },
    quarterSwitchValue: { type: String as PropType<QuarterSwitchValue>, required: true },
    needSave: { type: Boolean, required: true },
    currentErrors: { type: Array as PropType<ErrorNotificationParam[]>, required: true },

    onInputContent: {
      type: Function as PropType<(viewPointId: string, contentId: string, event: ContentInputEvent) => void>,
      required: true
    },
    onInputContentMonth: {
      type: Function as PropType<
        (viewPointId: string, contentId: string, month: MonthValue, event: ContentMonthInputEvent) => void
      >,
      required: true
    },
    moveContent: {
      type: Function as PropType<(viewPointId: string, contentId: string, up: boolean) => Promise<void>>,
      required: true
    },
    createContent: {
      type: Function as PropType<(viewPointId: string) => Promise<void>>,
      required: true
    },
    deleteContent: {
      type: Function as PropType<(viewPointId: string, contentId: string) => Promise<void>>,
      required: true
    },

    /**
     * シラバスファイルをアップロードする関数。
     */
    uploadSyllabusFile: { type: Function as PropType<(file: File) => Promise<void>>, required: true },

    onInputQuarterSwitch: { type: Function as PropType<(value: QuarterSwitchValue) => void>, required: true },
    exportCsv: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  computed: {
    necName(): string {
      return this.model.data?.necName ?? "";
    },
    syllabusFileGcsObjectPath(): string | null {
      return this.model.data?.syllabusFileGcsObjectPath ?? null;
    },
    monthsToDisplay(): readonly MonthValue[] {
      return quarterSwitchValueToMonths(this.quarterSwitchValue);
    }
  }
});
