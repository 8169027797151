import firebase from "firebase/app";
import "firebase/firestore";
import { addDays } from "date-fns";

function getDate() {
  const d = addDays(new Date(), -14); // 2週間
  return firebase.firestore.Timestamp.fromDate(d);
}

function convertFirestoreTimestamp(d: Date) {
  return firebase.firestore.Timestamp.fromDate(d);
}

function fsActivityLogs(db: firebase.firestore.Firestore, studentUserId: string) {
  return db
    .collection("activityLog")
    .where("studentUserId", "==", studentUserId)
    .orderBy("createdAt", "desc");
}

export function loadPreviousActivityLogs(
  db: firebase.firestore.Firestore,
  studentUserId: string,
  baseDate: Date,
  limit: number
) {
  if (baseDate === null) return;
  if (studentUserId === null) return;
  const activitylogs = fsActivityLogs(db, studentUserId);
  return activitylogs.limit(limit).startAt(convertFirestoreTimestamp(baseDate));
}

export function loadRealActivityLogs(db: firebase.firestore.Firestore, studentUserId: string, baseDate: Date) {
  if (baseDate === null) return;
  if (studentUserId === null) return;
  return fsActivityLogs(db, studentUserId).endBefore(convertFirestoreTimestamp(baseDate));
}

function fsRecentActivityLogs(db: firebase.firestore.Firestore, classId: string) {
  return db
    .collection("activityLog")
    .where("classId", "==", classId)
    .where("deleteFlg", "==", false)
    .orderBy("createdAt", "desc");
}

function fsAllRecentActivities(db: firebase.firestore.Firestore, classId: string) {
  return fsRecentActivityLogs(db, classId).endBefore(getDate());
}

function fsReadRecentActivities(db: firebase.firestore.Firestore, classId: string, myUserId: string) {
  return fsRecentActivityLogs(db, classId)
    .where("advanced.readUsers", "array-contains-any", [myUserId])
    .endBefore(getDate());
}

function fsUnReadRecentActivities(db: firebase.firestore.Firestore, classId: string, _myUserId: string) {
  // not-in が使用できないため、取得後にデータを間引く
  return fsRecentActivityLogs(db, classId).endBefore(getDate());
}

function fsMyListRecentActivities(db: firebase.firestore.Firestore, classId: string, myUserId: string) {
  return fsRecentActivityLogs(db, classId)
    .where("advanced.mylistedUsers", "array-contains-any", [myUserId])
    .limit(50);
}

export enum TabType {
  all,
  unread,
  read,
  mylist,
  search_result
}

export function loadRecentActivityLogs(
  db: firebase.firestore.Firestore,
  tab: TabType,
  classId: string,
  myUserId: string
) {
  let fsDocument = {};
  switch (tab) {
    case TabType.all:
      fsDocument = fsAllRecentActivities(db, classId);
      break;
    case TabType.read:
      fsDocument = fsReadRecentActivities(db, classId, myUserId);
      break;
    case TabType.unread:
      fsDocument = fsUnReadRecentActivities(db, classId, myUserId);
      break;
    case TabType.mylist:
      fsDocument = fsMyListRecentActivities(db, classId, myUserId);
      break;
    default:
      break;
  }
  return fsDocument;
}
