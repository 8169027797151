






















































































import Vue, { PropType } from "vue";
import { Quarter } from "@/ts/objects/common/Quarter";
import { Project } from "@/ts/objects/project/value/Project";
import PopupSelectButton, { SelectItem } from "@/components/PopupSelectButton.vue";
import { ProjectTabName } from "@/ts/objects/project/value/ProjectTabName";

export default Vue.extend({
  name: "ProjectHeaderPure",
  components: { PopupSelectButton },
  props: {
    selectedTabName: { type: String as PropType<ProjectTabName | null> },

    project: { type: Object as PropType<Project | null> },
    projectsOfQuarter: { type: Array as PropType<Project[] | null> },

    studentOrGuardianAppBasePath: { type: String },
    studentProjectBasePath: { type: String },

    quarter: { type: Object as PropType<Quarter | null> },
    prevQuarter: { type: Object as PropType<Quarter | null> },
    nextQuarter: { type: Object as PropType<Quarter | null> },

    selectProject: { type: Function as PropType<(path: string) => void> }
  },
  computed: {
    projectName(): string | null {
      return this.project?.name ?? null;
    },
    startingYear(): number | null {
      return this.project?.startingMonth.year ?? null;
    },
    startingMonth(): number | null {
      return this.project?.startingMonth.month ?? null;
    },
    endingYear(): number | null {
      return this.project?.endingMonth.year ?? null;
    },
    endingMonth(): number | null {
      return this.project?.endingMonth.month ?? null;
    },
    projectSelections(): SelectItem[] {
      const projectsOfQuarter = this.projectsOfQuarter ?? [];
      return projectsOfQuarter.map(p => {
        return {
          key: `${this.studentOrGuardianAppBasePath}/project/${p.quarter.schoolYear}/${p.quarter.quarter}/${p.projectId}`,
          label: p.name !== "" ? p.name : "名称未定プロジェクト"
        };
      });
    }
  }
});
