import { EditableObject } from "@/ts/objects/editable/EditableObject";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import {
  EditableBoolean,
  EditableHashedString,
  EditableString
} from "@/ts/objects/editable/value/EditablePrimitiveValue";
import { Editable } from "@/ts/objects/editable/Editable";
import { isMonthValue, isNullish, MonthValue } from "@/ts/utils";
import { DisplayableErr, Err } from "@/ts/objects/Err";
import { messages } from "@/ts/const/Messages";
import { ContentMonth as ContentMonthResp } from "@/ts/api/curriculum-service";
import { INECContentMonth } from "@/ts/objects/curriculum/INECContentMonth";

export class EditableNECContentMonth extends EditableObject implements INECContentMonth {
  /**
   * コンストラクタ。
   *
   * @param repo
   * @param savable
   * @param necId
   * @param viewPointId
   * @param contentId
   * @param month
   * @param enabled
   * @param text
   * @param textHash
   */
  constructor(
    repo: CurriculumRepository,
    savable: boolean,
    public readonly necId: string,
    public readonly viewPointId: string,
    public readonly contentId: string,
    public readonly month: MonthValue,
    enabled: boolean,
    text: string,
    textHash: string
  ) {
    super();
    this._enabled = new EditableBoolean("enabled", enabled, savable, async value => {
      const resp = await repo.patchNECContentMonth(necId, viewPointId, contentId, month, { enabled: value });
      if (resp instanceof Err) return resp;
      return resp.enabled;
    });
    this._text = new EditableHashedString("text", text, textHash, savable, async ({ value, hash }) => {
      const resp = await repo.patchNECContentMonth(necId, viewPointId, contentId, month, { text: { value, hash } });
      if (resp instanceof Err) return resp;
      if (resp.text === undefined)
        return new DisplayableErr(`invalid response: ${JSON.stringify(resp)}`, messages.failedToLoadData);
      return [resp.text.value, resp.text.hash];
    });
  }

  readonly _enabled: EditableBoolean;
  readonly _text: EditableString;

  protected allEditables(): Editable[] {
    return [this._enabled, this._text];
  }

  get enabled(): boolean {
    return this._enabled.value;
  }

  set enabled(value: boolean) {
    this._enabled.value = value;
  }

  get text(): string {
    return this._text.value;
  }

  set text(value: string) {
    this._text.value = value;
  }

  get resourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}/months/${this.month}`;
  }

  static fromContentMonthResp(
    repo: CurriculumRepository,
    savable: boolean,
    resp: ContentMonthResp
  ): EditableNECContentMonth {
    if (!isMonthValue(resp.month))
      throw new Error(
        `EditableNECContentMonth.fromContentMonthResp: invalid month value on ${resp.self}: ${resp.month}`
      );
    if (isNullish(resp.text))
      throw new Error(`EditableNECContentMonth.fromContentMonthResp: text is undefined on ${resp.self}`);
    return new EditableNECContentMonth(
      repo,
      savable,
      resp.necId,
      resp.viewPointId,
      resp.contentId,
      resp.month,
      resp.enabled,
      resp.text.value,
      resp.text.hash
    );
  }
}
