














































































import Vue, { PropType } from "vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { EditableProjectLookback } from "@/ts/objects/project/editable/EditableProjectLookback";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";

export default Vue.extend({
  name: "ProjectLookbackPure",
  components: { DisableableAutoResizeTextarea, LoadingBlock, MessageView },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },
    lookbackRef: { type: Function as PropType<() => EditableProjectLookback | null> },

    saveAll: { type: Function, required: true }
  },
  computed: {
    lookback(): EditableProjectLookback | null {
      return this.lookbackRef();
    }
  }
});
