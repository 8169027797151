

































import Vue, { PropType } from "vue";
import chunk from "lodash/chunk";
import log from "loglevel";

export type CheckboxItem = {
  key: string;
  label: string;
};

enum WholeSelectionState {
  AllSelected,
  SomeSelected,
  NoneSelected
}

export default Vue.extend({
  name: "FilterableHeaderButton",
  props: {
    text: { type: String, default: "" },
    items: { type: Array as PropType<CheckboxItem[]>, required: true },
    toggleAllCheckBox: { type: String, default: null },
    maxSingleRowItems: { type: Number, default: 10 }
  },
  created() {
    log.debug(`created: items=${JSON.stringify(this.items)}`);
    this.reinitializeItems();
  },
  data(): {
    chunkedItems: CheckboxItem[][];
    checkState: Record<string, boolean>;
    showCheckboxArea: boolean;
  } {
    return {
      chunkedItems: [], // chunkなんてせずに、flexboxのwrap機能を使ってもいけるかもしれない。
      checkState: {},
      showCheckboxArea: false
    };
  },
  computed: {
    wholeSelectionState(): WholeSelectionState {
      const values = Object.values(this.checkState);
      let foundSelected = false;
      let foundUnselected = false;
      for (const v of values) {
        if (v === true) {
          foundSelected = true;
        } else {
          foundUnselected = true;
        }
      }

      if (foundSelected && !foundUnselected) {
        log.debug(`state updated to AllSelected!`);
        return WholeSelectionState.AllSelected;
      } else if (foundSelected && foundUnselected) {
        log.debug(`state updated to SomeSelected!`);
        return WholeSelectionState.SomeSelected;
      } else {
        log.debug(`state updated to NoneSelected!`);
        return WholeSelectionState.NoneSelected;
      }
    },
    allSelected(): boolean {
      return this.wholeSelectionState === WholeSelectionState.AllSelected;
    },
    someSelected(): boolean {
      return this.wholeSelectionState === WholeSelectionState.SomeSelected;
    },
    noneSelected(): boolean {
      return this.wholeSelectionState === WholeSelectionState.NoneSelected;
    }
  },
  watch: {
    items() {
      log.debug(`watch: items=${JSON.stringify(this.items)}`);
      this.reinitializeItems();
    }
  },
  methods: {
    reinitializeItems() {
      this.chunkedItems = chunk(this.items, this.maxSingleRowItems);
      this.checkState = Object.fromEntries(new Map(this.items.map((item: CheckboxItem) => [item.key, true])));
      this.emitCheckState();
    },
    toggleCheckboxArea() {
      this.showCheckboxArea = !this.showCheckboxArea;
    },
    onClickOutside() {
      this.showCheckboxArea = false;
    },
    toggleAllSelections() {
      // 参考: https://github.com/vuejs/vue/issues/9535#issuecomment-466217819
      // TODO WindowsのChrome以外でもちゃんと動くか確認。
      setTimeout(() => {
        const changeTo = this.noneSelected ? true : false;
        for (const key of Object.keys(this.checkState)) {
          this.checkState[key] = changeTo;
        }

        this.emitCheckState();
      });
    },
    emitCheckState() {
      this.$emit("changed", this.checkState);
    }
  }
});
