






















import Vue, { PropType } from "vue";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { ClassStudent } from "@/ts/objects/common/Class";
import { getDataWithTimeout, Loadable } from "@/ts/Loadable";
import { EditableNECEvaluation } from "@/ts/objects/curriculum/editable/EditableNECEvaluation";
import { Err } from "@/ts/objects/Err";
import log from "loglevel";
import { delay, downloadBlob, isNullish, MonthValue } from "@/ts/utils";
import { RatingValue } from "@/ts/objects/common/Rating";
import { QuarterSwitchValue } from "@/components/QuarterSwitch/QuarterSwitch.vue";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import { AppStateStore } from "@/store/AppStateStore";
import { CurriculumStoreT } from "@/store/CurriculumStoreT";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { NavigationGuardNext, Route } from "vue-router";
import CurriculumListNECPure from "@/views/curriculum/teacher/CurriculumListNEC/CurriculumListNECPure.vue";
import {
  CurriculumListNECContentRowFilterState,
  CurriculumListNECModel,
  CurriculumListNECMonthFilterState,
  CurriculumListNECMonthStateVM,
  CurriculumListNECStudentRowFilterState,
  dataToCurriculumListNECModel,
  dataToCurriculumListNECMonthStateVMs
} from "@/views/curriculum/teacher/CurriculumListNEC/CurriculumListNECModel";
import debounce from "lodash/debounce";
import { unparse } from "papaparse";
import { format } from "date-fns";
import { ErrorNotificationParam } from "@/components/ErrorNotification.vue";

export default Vue.extend({
  name: "CurriculumListNEC",
  components: { CurriculumListNECPure },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    curriculumStoreT: { type: Object as PropType<CurriculumStoreT>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    curriculumRepository: { type: Object as PropType<CurriculumRepository>, required: true }
  },
  async created() {
    log.debug(`CurriculumListNEC:created: Started.`);

    this.debouncedUpdateNeedSave = debounce(this.updateNeedSave, 500);
    this.debouncedUpdateCurrentErrors = debounce(this.updateCurrentErrors, 500);
    this.debouncedSaveAll = debounce(() => this.saveAll(false), 1000);
    this.periodicSaverId = window.setInterval(() => {
      if (!this.needSave) return;
      this.debouncedSaveAll();
    }, 10000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
        this.updateNeedSave(); // この行が無いと、needSaveがdebounceにより未更新である場合、requirementToLeaveを一瞬で通ってしまう。
      },
      requirementToLeave: async () => !this.needSave,
      onRequirementUnmet: async () => {
        // TODO highlightUnsaved?
        // this.highlightUnsaved = true;
      }
    });

    this.model = Loadable.loading();
    this.filteredModel = Loadable.loading();

    const necTree = await getDataWithTimeout(() => this.curriculumStoreT.neCurriculumTree, 10);
    const cls = this.curriculumStoreT.class;
    if (necTree === null || cls === null) {
      log.error(`CurriculumListNEC:created: necTree or class is null.`);
      this.model = Loadable.error();
      this.filteredModel = Loadable.error();
      return;
    }

    log.debug(`CurriculumListNEC:created: necTree=${JSON.stringify(necTree)}`);

    const [students, resp] = await Promise.all<ClassStudent[], EditableNECEvaluation[] | Err>([
      cls.sortedClassStudents(this.userRepository),
      this.curriculumRepository.listEditableNECEvaluations(
        true,
        necTree.self.necId,
        undefined,
        undefined,
        undefined,
        cls.id,
        undefined,
        undefined,
        undefined
      )
    ]);
    if (resp instanceof Err) {
      log.error(`CurriculumListNEC:created: error loading evaluations: ${resp.internalMessage}`);
      this.model = Loadable.error();
      this.filteredModel = Loadable.error();
      return;
    }

    const model = dataToCurriculumListNECModel(necTree, students, resp);
    this.model = Loadable.fromValue(model);
    this.filteredModel = Loadable.fromValue(model);

    this.studentRowFilterState = {
      studentUserIds: students.map(s => s.studentUserId)
    };
    this.contentRowFilterState = {
      viewPointIds: necTree.viewPoints.map(vp => vp.self.viewPointId),
      contentSearchText: "",
      shouldBeEnabledOn: []
    };

    await this.createMonthStateModel();

    log.debug(`CurriculumListNEC:created: Completed.`);
  },
  async beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    const ok = await this.pageLeaveService!.tryLeave();
    if (!ok) {
      next(false);
      return;
    }
    next();
  },
  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    const ok = await this.pageLeaveService!.tryLeave();
    if (!ok) {
      next(false);
      return;
    }
    next();
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    model: Loadable<CurriculumListNECModel>;
    filteredModel: Loadable<CurriculumListNECModel>;

    studentRowFilterState: CurriculumListNECStudentRowFilterState;
    contentRowFilterState: CurriculumListNECContentRowFilterState;

    monthStateModel: Loadable<CurriculumListNECMonthStateVM[]>;

    quarterSwitchValue: QuarterSwitchValue;

    currentErrors: ErrorNotificationParam[];

    needSave: boolean;

    debouncedUpdateNeedSave: any;
    debouncedUpdateCurrentErrors: any;
    debouncedSaveAll: any;
    periodicSaverId: number | undefined;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      model: Loadable.idle(),
      filteredModel: Loadable.idle(),

      studentRowFilterState: {
        studentUserIds: []
      },
      contentRowFilterState: {
        viewPointIds: [],
        contentSearchText: "",
        shouldBeEnabledOn: []
      },

      monthStateModel: Loadable.idle(),

      quarterSwitchValue: "all",

      currentErrors: [],

      needSave: false,

      debouncedUpdateNeedSave: undefined,
      debouncedUpdateCurrentErrors: undefined,
      debouncedSaveAll: undefined,
      periodicSaverId: undefined,

      pageLeaveService: null
    };
  },
  computed: {
    modelData(): CurriculumListNECModel | null {
      return this.model.data;
    },
    necId(): string | null {
      return this.modelData?.necId ?? null;
    },
    allEvaluations(): EditableNECEvaluation[] | null {
      return (
        this.modelData?.studentRows
          ?.flatMap(sr => sr.contentRows)
          ?.flatMap(cr => cr.evaluationCells)
          .map(ev => ev.evaluation) ?? null
      );
    },
    allEvaluationsDict(): Record<string, EditableNECEvaluation> | null {
      const allEvaluations = this.allEvaluations;
      if (allEvaluations === null) return null;

      return Object.fromEntries(allEvaluations.map(ev => [ev.resourceName, ev]));
    },
    monthFilter(): CurriculumListNECMonthFilterState {
      switch (this.quarterSwitchValue) {
        case "all":
          return { months: [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3] };
        case "q1":
          return { months: [4, 5, 6] };
        case "q2":
          return { months: [7, 8, 9] };
        case "q3":
          return { months: [10, 11, 12] };
        case "q4":
          return { months: [1, 2, 3] };
        default:
          return { months: [] };
      }
    }
  },
  watch: {
    allEvaluations: {
      handler: function() {
        log.debug(`CurriculumListNEC:watch:allEvaluations`);
        this.debouncedUpdateNeedSave();
        this.debouncedUpdateCurrentErrors();
      },
      deep: true
    }
  },
  methods: {
    updateNeedSave() {
      const allEvaluations = this.allEvaluations;
      if (allEvaluations === null) {
        this.needSave = false;
        return;
      }

      this.needSave = allEvaluations.some(ev => ev.needSave());
    },
    updateCurrentErrors() {
      const model = this.modelData;
      if (model === null) {
        this.currentErrors = [];
        return;
      }

      this.currentErrors = model.studentRows.flatMap(studentRow =>
        studentRow.contentRows.flatMap(contentRow =>
          contentRow.evaluationCells.flatMap(evaluationCell =>
            evaluationCell.evaluation.currentErrors().map(err => ({
              heading: `${studentRow.studentNumber}番 ${studentRow.studentName} - ${contentRow.viewPointName} - ${contentRow.contentName} - ${evaluationCell.evaluation.month}月`,
              text: err.message
            }))
          )
        )
      );
    },
    applyRowFilter() {
      const model = this.modelData;
      if (model === null) return;

      const studentRowFilter = this.studentRowFilterState;
      const contentRowFilter = this.contentRowFilterState;

      const filteredModel: CurriculumListNECModel = {
        ...model,
        studentRows: model.studentRows
          .filter(sr => studentRowFilter.studentUserIds.includes(sr.studentUserId))
          .map(studentRow => ({
            ...studentRow,
            contentRows: studentRow.contentRows.filter(
              cr =>
                contentRowFilter.viewPointIds.includes(cr.viewPointId) &&
                contentRowFilter.shouldBeEnabledOn.every(m => cr.enabledMonths.includes(m))
            )
          }))
      };
      this.filteredModel = Loadable.fromValue(filteredModel);
    },
    async createMonthStateModel() {
      await delay(0);
      const monthStateModel = dataToCurriculumListNECMonthStateVMs(
        this.allEvaluationsDict,
        this.modelData?.allEnabledContentMonthRnames ?? []
      );
      if (monthStateModel !== null) {
        this.monthStateModel = Loadable.fromValue(monthStateModel);
      } else {
        this.monthStateModel = Loadable.loading();
      }
    },
    onInput(
      viewPointId: string,
      contentId: string,
      month: MonthValue,
      studentUserId: string,
      rating: RatingValue
    ): void {
      log.debug(
        `CurriculumListNEC:onInput: viewPointId=${viewPointId}, contentId=${contentId}, month=${month}, studentUserId=${studentUserId}, rating=${rating}`
      );

      const necId = this.necId;
      const allEvaluationsDict = this.allEvaluationsDict;
      if (necId === null || allEvaluationsDict === null) return;

      const evaluationRname = `/neCurriculums/${necId}/viewPoints/${viewPointId}/contents/${contentId}/months/${month}/evaluations/${studentUserId}`;
      const evaluation = allEvaluationsDict[evaluationRname];
      if (isNullish(evaluation)) return;

      evaluation.rating = rating;

      this.debouncedSaveAll();
    },
    onChangeSinglePublishState(
      viewPointId: string,
      contentId: string,
      month: MonthValue,
      studentUserId: string,
      published: boolean
    ): void {
      const necId = this.necId;
      const allEvaluationsDict = this.allEvaluationsDict;
      if (necId === null || allEvaluationsDict === null) return;

      const evaluationRname = `/neCurriculums/${necId}/viewPoints/${viewPointId}/contents/${contentId}/months/${month}/evaluations/${studentUserId}`;
      const evaluation = allEvaluationsDict[evaluationRname];
      if (isNullish(evaluation)) return;

      evaluation.teacherInputPublished = published;

      this.createMonthStateModel();
      this.debouncedSaveAll();
    },
    onChangeMonthPublishState(month: MonthValue, published: boolean): void {
      // TODO フィルタした結果、見えている行だけに対して変更したい・・・という話だった気もする。だったらボタンの文言含めて要変更。EECの方との兼ね合いもある。
      const model = this.modelData;
      const allEvaluations = this.allEvaluations;
      if (model === null || allEvaluations === null) return;

      const evaluationsOfMonth = allEvaluations.filter(
        ev =>
          ev.necId === model.necId &&
          ev.month === month &&
          model.allEnabledContentMonthRnames.includes(ev.contentMonthResourceName)
      );
      log.debug(
        `CurriculumListNEC:onChangeMonthPublishState: allEnabledContentMonthRnames=${model.allEnabledContentMonthRnames}`
      );
      log.debug(
        `CurriculumListNEC:onChangeMonthPublishState: evaluationsOfMonth=${evaluationsOfMonth.map(
          ev => ev.resourceName
        )}`
      );
      evaluationsOfMonth.forEach(ev => {
        ev.teacherInputPublished = published;
      });

      this.createMonthStateModel();
      this.debouncedSaveAll();
    },
    onFillAllEvaluationsOfMonth(month: MonthValue, rating: RatingValue, overwrite: boolean): void {
      log.debug(
        `CurriculumListNEC:onFillAllEvaluationsOfMonth: month=${month}, rating=${rating}, overwrite=${overwrite}`
      );
      const model = this.modelData;
      const allEvaluations = this.allEvaluations;
      if (model === null || allEvaluations === null) return;

      const evaluationsToUpdate = allEvaluations.filter(
        ev =>
          ev.necId === model.necId &&
          ev.month === month &&
          model.allEnabledContentMonthRnames.includes(ev.contentMonthResourceName) &&
          (overwrite || ev.rating === "")
      );

      evaluationsToUpdate.forEach(ev => {
        ev.rating = rating;
      });

      this.debouncedSaveAll();
    },
    onChangeStudentRowFilterState(filterState: CurriculumListNECStudentRowFilterState): void {
      this.studentRowFilterState = filterState;
      this.applyRowFilter();
    },
    onChangeContentRowFilterState(filterState: CurriculumListNECContentRowFilterState): void {
      this.contentRowFilterState = filterState;
      this.applyRowFilter();
    },
    onInputQuarterSwitch(value: QuarterSwitchValue) {
      this.quarterSwitchValue = value;
    },
    async saveAll(force: boolean): Promise<SaveResult> {
      const allEvaluations = this.allEvaluations;
      if (allEvaluations === null) return emptySaveResult();
      log.debug("SAVING!");
      return flattenSaveResults(await Promise.all(allEvaluations.map(ev => ev.saveAllChanges(force))));
    },
    studentUserIdToStudentViewPath(studentUserId: string): string {
      return this.curriculumStoreT.path("studentview", `/neCurriculums/${this.necId}`, studentUserId);
    },
    exportCsv() {
      const filteredModel = this.filteredModel.data;
      if (isNullish(filteredModel)) return;

      exportCsv(filteredModel, this.monthFilter);
    }
  }
});

function exportCsv(filteredModel: CurriculumListNECModel, monthFilter: CurriculumListNECMonthFilterState) {
  const csvRows = filteredModel.studentRows.flatMap(sr =>
    sr.contentRows.map(cr => {
      const monthToRating = monthFilter.months.map((month): [string, string] => {
        const evaluationCell = cr.evaluationCells.find(ec => ec.evaluation.month === month);
        if (evaluationCell === undefined || !evaluationCell.contentMonthEnabled) {
          return [`${month}月`, ""];
        }
        return [`${month}月`, evaluationCell.evaluation.rating];
      });
      return {
        番号: sr.studentNumber,
        氏名: sr.studentName,
        観点: cr.viewPointName,
        内容構成: cr.contentName,
        ...Object.fromEntries(monthToRating)
      };
    })
  );
  const columnNames = ["番号", "氏名", "観点", "内容構成", ...monthFilter.months.map(m => `${m}月`)];

  // BOMはエクセル対策。参考: https://qiita.com/wadahiro/items/eb50ac6bbe2e18cf8813
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, unparse(csvRows, { columns: columnNames })], { type: "text/plain" });
  downloadBlob(blob, `${filteredModel.necName}_${format(new Date(), "yyyyMMdd'T'HHmmss")}.csv`);
}
