









import Vue from "vue";

export default Vue.extend({
  name: "LoadingDotsBlock",
  props: {
    message: { type: String }
  }
});
