







import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "CreateJournalButton",
  props: {
    onClick: { type: Function as PropType<() => Promise<void>>, required: true }
  }
});
