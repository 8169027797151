





































import Vue, { PropType } from "vue";
import CurriculumSyllabusNECCell from "@/views/curriculum/teacher/CurriculumSyllabusNEC/components/CurriculumSyllabusNECCell/CurriculumSyllabusNECCell.vue";
import { MonthValue } from "@/ts/utils";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import PopupMenuButton, { MenuButton, MenuItem, Separator } from "@/components/PopupMenuButton.vue";
import {
  ContentInputEvent,
  ContentMonthInputEvent,
  ICurriculumSyllabusNECContentRowModel
} from "@/views/curriculum/teacher/CurriculumSyllabusNEC/CurriculumSyllabusNECModel";
import { INECContentMonth } from "@/ts/objects/curriculum/INECContentMonth";

export type CurriculumSyllabusNECRowStyleType = 0 | 1;

export default Vue.extend({
  name: "CurriculumSyllabusNECContentRow",
  components: { DisableableAutoResizeTextarea, CurriculumSyllabusNECCell, PopupMenuButton },
  props: {
    styleType: { type: Number as PropType<CurriculumSyllabusNECRowStyleType>, required: true },

    model: { type: Object as PropType<ICurriculumSyllabusNECContentRowModel>, required: true },
    monthsToDisplay: { type: Array as PropType<MonthValue[]>, required: true },
    onInputContent: {
      type: Function as PropType<(event: ContentInputEvent) => void>,
      required: true
    },
    onInputContentMonth: {
      type: Function as PropType<(month: MonthValue, event: ContentMonthInputEvent) => void>,
      required: true
    },
    moveContent: {
      type: Function as PropType<(up: boolean) => Promise<void>>,
      required: true
    },
    deleteContent: {
      type: Function as PropType<() => Promise<void>>,
      required: true
    }
  },
  data(): {
    hovering: boolean;
    extraMenuItems: MenuItem[];
  } {
    return {
      hovering: false,
      extraMenuItems: [
        new MenuButton("up", "上へ", ["fas", "arrow-up"]),
        new MenuButton("down", "下へ", ["fas", "arrow-down"]),
        new Separator(),
        new MenuButton("delete", "削除", ["fas", "trash-alt"])
      ]
    };
  },
  computed: {
    filteredMonths(): INECContentMonth[] {
      return this.model.months.filter(m => this.monthsToDisplay.includes(m.month));
    }
  },
  methods: {
    onInputContentName(name: string) {
      this.onInputContent({ name });
    },
    selectExtraMenu(menuItem: string) {
      switch (menuItem) {
        case "up":
          this.moveContent(true);
          return;
        case "down":
          this.moveContent(false);
          return;
        case "delete":
          this.deleteContent();
          return;
        default:
          return;
      }
    }
  }
});
