









import CurriculumReadNECYearlyPure from "@/views/curriculum/student/CurriculumReadNECYearly/CurriculumReadNECYearlyPure.vue";
import Vue, { PropType } from "vue";
import { CurriculumStoreS } from "@/store/CurriculumStoreS";

export default Vue.extend({
  name: "CurriculumReadNECYearly",
  components: { CurriculumReadNECYearlyPure },
  props: {
    curriculumStoreS: { type: Object as PropType<CurriculumStoreS>, required: true }
  }
});
