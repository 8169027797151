




import Vue, { PropType } from "vue";
import { ProjectStore } from "@/store/ProjectStore";
import { Project } from "@/ts/objects/project/value/Project";
import { MessageViewParam } from "@/components/MessageView.vue";
import { messages } from "@/ts/const/Messages";
import ProjectAboutPure from "@/views/project/student/ProjectAbout/ProjectAboutPure.vue";

export default Vue.extend({
  name: "ProjectAbout",
  components: { ProjectAboutPure },
  props: {
    projectStore: { type: Object as PropType<ProjectStore>, required: true }
  },
  computed: {
    messageView(): MessageViewParam | null {
      if (this.project === null) return { message: messages.pleaseSelectProject };
      return null;
    },
    project(): Project | null {
      return this.projectStore.project.data;
    }
  }
});
