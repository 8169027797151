










import Vue, { PropType } from "vue";
import { Loadable, LoadableIdle } from "@/ts/Loadable";
import { EECurriculum } from "@/ts/objects/curriculum/value/EECurriculum";
import { EECJournalTree } from "@/ts/objects/curriculum/value/EECJournal";
import CurriculumReadEECPure from "@/views/curriculum/student/CurriculumReadEEC/CurriculumReadEECPure.vue";
import log from "loglevel";
import { CurriculumStoreS } from "@/store/CurriculumStoreS";
import { CurriculumPeriodMode } from "@/ts/objects/curriculum/value/CurriculumPeriodMode";

export default Vue.extend({
  name: "CurriculumReadEEC",
  components: { CurriculumReadEECPure },
  props: {
    curriculumStoreS: { type: Object as PropType<CurriculumStoreS>, required: true }
  },
  data(): {
    /**
     * 現在ファイルビューを開いている学習記録のリソース名。
     */
    filesViewJournal: string | null;
  } {
    return {
      filesViewJournal: null
    };
  },
  computed: {
    periodMode(): CurriculumPeriodMode | null {
      return this.curriculumStoreS.periodMode;
    },
    studentUserId(): string | null {
      return this.curriculumStoreS.studentUserId;
    },
    eeCurriculum(): Loadable<EECurriculum | null> {
      return this.curriculumStoreS.eeCurriculum;
    },
    journalTrees(): Loadable<EECJournalTree[]> {
      switch (this.periodMode) {
        case "yearly":
          return this.curriculumStoreS.eecJournalTreesOfYear;
        case "monthly":
          return this.curriculumStoreS.eecJournalTreesOfMonth;
        default:
          return new LoadableIdle();
      }
    }
  },
  methods: {
    /**
     * 現在ファイルビューを開いている学習記録を変更する。
     * 閉じる場合はnullを渡す。
     */
    selectFilesViewJournal(rname: string | null) {
      log.debug(`CurriculumReadEEC:selectFilesViewJournal: rname=${rname}`);
      this.filesViewJournal = rname;
    }
  }
});
