




































































































import Vue, { PropType } from "vue";
import { formatDate, FormattedDatetime } from "@/ts/objects/common/FormattedDatetime";
import RecentActivityView from "@/views/activity/components/RecentActivityView.vue";
import RecentStudyItem from "@/views/home/teacher/components/RecentStudyItem.vue";
import { RecentStudy } from "@/ts/objects/common/RecentStudy";
import { AppStateStore } from "@/store/AppStateStore";
import { Class, SchoolType } from "@/ts/objects/common/Class";
import ClassModalButton from "@/views/home/teacher/components/ClassModalButton.vue";
import { Err } from "@/ts/objects/Err";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { DataSlot } from "@/ts/DataSlot";
import { schoolTypeText } from "@/ts/utils/AppUtil";
import { LogRepository } from "@/ts/repositories/LogRepository";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { ActivityRepository } from "@/ts/repositories/ActivityRepository";

type RecentStudiesTabState = {
  curriculum: boolean;
  project: boolean;
  solan: boolean;
};

export default Vue.extend({
  name: "HomeT",
  components: { LoadingBlock, ClassModalButton, RecentStudyItem, RecentActivityView },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    activityRepository: { type: Object as PropType<ActivityRepository>, required: true },
    logRepository: { type: Object as PropType<LogRepository>, required: true }
  },
  created() {
    this.reloadRecentStudies();
  },
  data(): {
    now: FormattedDatetime;
    recentStudies: DataSlot<RecentStudy[]>;
    recentStudiesTabState: RecentStudiesTabState;
    debouncedReloadRecentStudies: () => void;
  } {
    return {
      now: formatDate(new Date()),
      recentStudies: new DataSlot<RecentStudy[]>(),
      recentStudiesTabState: {
        curriculum: true,
        project: true,
        solan: true
      },
      debouncedReloadRecentStudies: () => {}
    };
  },
  computed: {
    _appStateStore(): AppStateStore {
      return this.appStateStore;
    },
    selectedClass(): Class | null {
      return this.appStateStore.teacherState?.selectedClass() ?? null;
    },
    classSchoolYear(): number | null {
      return this.selectedClass?.schoolYear ?? null;
    },
    reversedRecentStudies(): RecentStudy[] | null {
      return this.recentStudies.data?.slice().reverse() ?? null;
    },
    filteredRecentStudies(): RecentStudy[] | null {
      const filterState = this.recentStudiesTabState;
      return (
        this.reversedRecentStudies?.filter(
          rs =>
            (filterState.curriculum && rs.objectService() === "curriculum") ||
            (filterState.project && rs.objectService() === "project") ||
            (filterState.solan && rs.objectService() === "solan")
        ) ?? null
      );
    }
  },

  methods: {
    async reloadRecentStudies(): Promise<void> {
      const selectedClass = this.selectedClass;
      if (selectedClass === null) return;

      const classStudents = await selectedClass.sortedClassStudents(this.userRepository);
      this.recentStudies.startLoadingWith(async () => {
        const resp = await this.logRepository.loadRecentStudies(classStudents.map(s => s.studentUserId));
        if (resp instanceof Err) {
          return null;
        }
        return resp;
      });
    },
    schoolTypeText(schoolType: SchoolType): string {
      return schoolTypeText(schoolType);
    },
    selectedRecentActivity(studentUserId: string, uuid: string, date: Date) {
      // query にはstringしか指定できないためdate.toString() としている。ActivityTでnew Date(d)としている。
      this.$router
        .push({
          name: "T.Activity",
          query: {
            targetStudentUserId: studentUserId,
            targetUuid: uuid,
            targetBaseDate: date.toString()
          }
        })
        .catch(() => {});
    }
  }
});
