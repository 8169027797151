import { isRatingValue, RatingValue } from "@/ts/objects/common/Rating";
import { isMonthValue, MonthValue } from "@/ts/utils";
import { Evaluation as EvaluationResp } from "@/ts/api/curriculum-service";
import { INECEvaluation } from "@/ts/objects/curriculum/INECEvaluation";

/**
 * 数値評価教科の内容構成の1か月分の評価。
 *
 * リソース名は /neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}/months/{1, 2, ..., 12}/evaluations/{studentUserId}。
 */
export class NECEvaluation implements INECEvaluation {
  constructor(
    public readonly necId: string,
    public readonly viewPointId: string,
    public readonly contentId: string,
    public readonly month: MonthValue,
    public readonly studentUserId: string,
    public readonly rating: RatingValue,
    public readonly teacherInputPublished: boolean
  ) {}

  get contentResourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}`;
  }

  get contentMonthResourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}/months/${this.month}`;
  }

  get resourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}/months/${this.month}/evaluations/${this.studentUserId}`;
  }

  static fromEvaluationResp(resp: EvaluationResp): NECEvaluation {
    if (!isMonthValue(resp.month))
      throw new Error(`NECEvaluation.fromEvaluationResp: invalid month value on ${resp.self}: ${resp.month}`);
    const rating = resp.rating ?? "";
    if (!isRatingValue(rating))
      throw new Error(`NECEvaluation.fromEvaluationResp: invalid rating value on ${resp.self}: ${resp.rating}`);

    return new NECEvaluation(
      resp.necId,
      resp.viewPointId,
      resp.contentId,
      resp.month,
      resp.studentUserId,
      rating,
      resp.teacherInputPublished
    );
  }
}
