















































































import Vue, { PropType } from "vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { EditableSolanLookback } from "@/ts/objects/solan/editable/EditableSolanLookback";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";

export default Vue.extend({
  name: "SolanLookbackPure",
  components: { LoadingBlock, MessageView, DisableableAutoResizeTextarea },
  props: {
    messageView: { type: Object as PropType<MessageViewParam | null> },
    lookbackRef: { type: Function as PropType<() => EditableSolanLookback | null> },

    reloadData: { type: Function },
    saveAll: { type: Function }
  },
  computed: {
    lookback(): EditableSolanLookback | null {
      return this.lookbackRef();
    }
  }
});
