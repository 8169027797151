

























import Vue, { PropType } from "vue";
import { MonthValue } from "@/ts/utils";
import { RatingValue } from "@/ts/objects/common/Rating";
import CurriculumListNECContentCell from "@/views/curriculum/teacher/CurriculumListNEC/components/CurriculumListNECEvaluationCell/CurriculumListNECEvaluationCell.vue";
import {
  CurriculumListNECMonthFilterState,
  ICurriculumListNECContentRowModel,
  ICurriculumListNECEvaluationCellModel
} from "@/views/curriculum/teacher/CurriculumListNEC/CurriculumListNECModel";

export default Vue.extend({
  name: "CurriculumListNECContentRow",
  components: { CurriculumListNECContentCell },
  props: {
    /**
     * ビューモデル。
     */
    model: { type: Object as PropType<ICurriculumListNECContentRowModel>, required: true },

    monthFilter: { type: Object as PropType<CurriculumListNECMonthFilterState>, required: true },

    viewPointColWidth: { type: Number, required: true },
    contentColWidth: { type: Number, required: true },
    evaluationColWidth: { type: Number, required: true },

    onInput: {
      type: Function as PropType<(month: MonthValue, rating: RatingValue) => void>,
      required: true
    },
    onChangeSinglePublishState: {
      type: Function as PropType<(month: MonthValue, published: boolean) => void>,
      required: true
    }
  },
  computed: {
    monthFilteredCells(): ICurriculumListNECEvaluationCellModel[] {
      return this.model.evaluationCells.filter(ev => this.monthFilter.months.includes(ev.evaluation.month));
    }
  }
});
