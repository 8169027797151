



















import Vue from "vue";

type StampInfo = { gcsObjectPath: string; url: string };

export default Vue.extend({
  name: "StampModal",
  props: {
    stamps: { type: Object },
    studentUserId: { type: String },
    f: { type: Function }
  },
  data: function() {
    return {
      initFlg: false,
      selectItem: null
    };
  },
  methods: {
    selected(idx: any) {
      if (this.selectItem === idx) {
        this.uploadStamp();
        this.selectItem = null;
      } else {
        this.selectItem = idx;
      }
    },
    uploadStamp() {
      const item = this.selectItem;
      if (item === null) return;
      const s: StampInfo[] = this.stamps;
      const gcsObjectPath = s[item].gcsObjectPath;
      this.f(this.studentUserId, gcsObjectPath); // 遷移元のFunctionを実装
      this.$modal.hide("StampModal");
    }
  }
});
