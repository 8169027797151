import log from "loglevel";
import { delay } from "@/ts/utils";
import { Message, messages } from "@/ts/const/Messages";

export abstract class Loadable<T> {
  abstract readonly data: T | null;
  abstract readonly state: LoadableState;

  get isIdle(): boolean {
    return this.state === LoadableState.Idle;
  }

  get isLoading(): boolean {
    return this.state === LoadableState.Loading;
  }

  get isError(): boolean {
    return this.state === LoadableState.Error;
  }

  get isDataLoaded(): boolean {
    return this.state === LoadableState.DataLoaded;
  }

  get isIdleOrLoading(): boolean {
    return this.isIdle || this.isLoading;
  }

  abstract toMessageViewParam(): { message: string } | null;

  static idle<T>(): LoadableIdle<T> {
    return new LoadableIdle();
  }

  static loading<T>(): LoadableLoading<T> {
    return new LoadableLoading();
  }

  static error<T>(message?: Message): LoadableError<T> {
    return new LoadableError(message);
  }

  static fromValue<T>(value: T): Loadable<T> {
    return new LoadableDataLoaded(value);
  }

  static fromLoadable<A, T>(loadable: Loadable<A>, projectionFn: (a: A) => T, errorMessage?: Message): Loadable<T> {
    if (loadable.isDataLoaded) return Loadable.fromValue(projectionFn(loadable.data!));
    if (loadable.isError) return Loadable.error(errorMessage);
    if (loadable.isIdle) return Loadable.idle();
    return new LoadableLoading();
  }

  static fromLoadable2<A, B, T>(
    loadableA: Loadable<A>,
    loadableB: Loadable<B>,
    projectionFn: (a: A, b: B) => T,
    errorMessage?: Message
  ): Loadable<T> {
    if (loadableA.isDataLoaded && loadableB.isDataLoaded)
      return Loadable.fromValue(projectionFn(loadableA.data!, loadableB.data!));
    if (loadableA.isError || loadableB.isError) return Loadable.error(errorMessage);
    if (loadableA.isIdle || loadableB.isIdle) return Loadable.idle();
    return new LoadableLoading();
  }

  static fromLoadable3<A, B, C, T>(
    loadableA: Loadable<A>,
    loadableB: Loadable<B>,
    loadableC: Loadable<C>,
    projectionFn: (a: A, b: B, c: C) => T,
    errorMessage?: Message
  ): Loadable<T> {
    if (loadableA.isDataLoaded && loadableB.isDataLoaded && loadableC.isDataLoaded)
      return Loadable.fromValue(projectionFn(loadableA.data!, loadableB.data!, loadableC.data!));
    if (loadableA.isError || loadableB.isError || loadableC.isError) return Loadable.error(errorMessage);
    if (loadableA.isIdle || loadableB.isIdle || loadableC.isIdle) return Loadable.idle();
    return new LoadableLoading();
  }
}

export class LoadableIdle<T> extends Loadable<T> {
  readonly data: T | null = null;
  readonly state: LoadableState = LoadableState.Idle;

  toMessageViewParam(): { message: string } | null {
    return null;
  }
}

export class LoadableLoading<T> extends Loadable<T> {
  readonly data: T | null = null;
  readonly state: LoadableState = LoadableState.Loading;

  toMessageViewParam(): { message: string } | null {
    return null;
  }
}

export class LoadableError<T> extends Loadable<T> {
  readonly data: T | null = null;
  readonly state: LoadableState = LoadableState.Error;

  constructor(public readonly message?: Message) {
    super();
  }

  toMessageViewParam(): { message: string } {
    return { message: this.message ?? messages.pageNotFound };
  }
}

export class LoadableDataLoaded<T> extends Loadable<T> {
  readonly data: T;
  readonly state: LoadableState = LoadableState.DataLoaded;

  constructor(data: T) {
    super();
    this.data = data;
  }

  toMessageViewParam(): { message: string } | null {
    return null;
  }
}

export enum LoadableState {
  /**
   * ロード開始前。
   */
  Idle,

  /**
   * ロード中。
   */
  Loading,

  /**
   * エラー。
   */
  Error,

  /**
   * ロード完了済。
   */
  DataLoaded
}

export async function getDataWithTimeout<T>(
  loadableGetter: () => Loadable<T>,
  timeoutSec: number = 20
): Promise<T | null> {
  const ticks = timeoutSec * 2;
  const getData = () => loadableGetter().data;

  const firstTry = getData();
  log.debug(`getDataWithTimeout: start: data=${firstTry}`);
  if (firstTry !== null) return firstTry;

  for (let i = 0; i < ticks; i++) {
    await delay(500);
    const data = getData();
    log.debug(`getDataWithTimeout: ${i}/${ticks}: data=${data}`);
    if (data !== null) return data;
  }

  log.debug(`getDataWithTimeout: no data`);
  return null;
}
