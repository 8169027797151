import { EditableNECContent } from "@/ts/objects/curriculum/editable/EditableNECContent";
import { EditableNECContentMonth } from "@/ts/objects/curriculum/editable/EditableNECContentMonth";
import { Color } from "@/ts/objects/common/Color";
import { monthValues } from "@/ts/utils";
import log from "loglevel";
import { NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { INECContent } from "@/ts/objects/curriculum/INECContent";
import { INECContentMonth } from "@/ts/objects/curriculum/INECContentMonth";

export type CurriculumSyllabusNECModel = {
  readonly necId: string;
  syllabusFileGcsObjectPath: string | null;
  readonly necName: string;
  readonly viewPointRows: readonly CurriculumSyllabusNECViewPointRowModel[];
};

export type CurriculumSyllabusNECViewPointRowModel = {
  readonly viewPointId: string;
  readonly name: string;
  readonly color: Color;
  contentRows: readonly CurriculumSyllabusNECContentRowModel[];
};

export type CurriculumSyllabusNECContentRowModel = {
  readonly content: EditableNECContent;
  readonly months: readonly EditableNECContentMonth[];
};

// export type CurriculumSyllabusNECContentMonthCellModel = {
//   month: MonthValue;
//   enabled: boolean;
//   text: string;
// };

export type ICurriculumSyllabusNECModel = {
  readonly necId: string;
  readonly syllabusFileGcsObjectPath: string | null;
  readonly necName: string;
  readonly viewPointRows: readonly ICurriculumSyllabusNECViewPointRowModel[];
};

export type ICurriculumSyllabusNECViewPointRowModel = {
  readonly viewPointId: string;
  readonly name: string;
  readonly color: Color;
  readonly contentRows: readonly ICurriculumSyllabusNECContentRowModel[];
};

export type ICurriculumSyllabusNECContentRowModel = {
  readonly content: INECContent;
  readonly months: readonly INECContentMonth[];
};

export function dataToCurriculumSyllabusNECModel(
  necTree: NECurriculumTree,
  contents: EditableNECContent[],
  contentMonths: EditableNECContentMonth[]
): CurriculumSyllabusNECModel {
  const viewPointRows = necTree.viewPoints.map(viewPoint => {
    const necId = viewPoint.self.necId;
    const viewPointId = viewPoint.self.viewPointId;
    const contentRows = contents
      .filter(c => c.necId === necId && c.viewPointId === viewPointId)
      .map(content => {
        const contentId = content.contentId;
        const months = monthValues.map(month => {
          const contentMonth = contentMonths.find(
            cm =>
              cm.necId === content.necId &&
              cm.viewPointId === content.viewPointId &&
              cm.contentId === contentId &&
              cm.month === month
          );
          if (contentMonth === undefined) {
            log.error(`dataToCurriculumSyllabusNECVM: contentMonth for month ${month} is not found`);
            throw new Error(`dataToCurriculumSyllabusNECVM: contentMonth for month ${month} is not found`);
          }
          return contentMonth;
          // return {
          //   month: contentMonth.month,
          //   enabled: contentMonth.enabled,
          //   text: contentMonth.text
          // };
        });
        return {
          content,
          months
        };
      });
    return {
      viewPointId,
      name: viewPoint.self.name,
      color: viewPoint.self.color,
      contentRows
    };
  });
  return {
    necId: necTree.self.necId,
    necName: necTree.self.name,
    syllabusFileGcsObjectPath: necTree.self.syllabusFileGcsObjectPath,
    viewPointRows
  };
}

export type ContentInputEvent = {
  name: string;
};

export type ContentMonthInputEvent = {
  enabled: boolean;
  text: string;
};
