























import Vue, { PropType } from "vue";
import { Class, SchoolType } from "@/ts/objects/common/Class";
import { schoolTypeText } from "@/ts/utils/AppUtil";
import { AppStateStore } from "@/store/AppStateStore";

export default Vue.extend({
  name: "ClassModalButton",
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true }
  },
  data(): {
    showModal: boolean;
  } {
    return {
      showModal: false
    };
  },
  computed: {
    groupedSelections(): { schoolYear: number; selections: Class[] }[] {
      return this.appStateStore.teacherState?.orderedGroupedClasses() ?? [];
    }
  },
  methods: {
    toggleModal() {
      if (this.groupedSelections.length === 0) {
        this.showModal = false;
        return;
      }
      this.showModal = !this.showModal;
    },
    onClickOutside() {
      this.showModal = false;
    },
    schoolTypeText(schoolType: SchoolType): string {
      return schoolTypeText(schoolType);
    }
  }
});
