








































































































































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import { AppStateStore } from "@/store/AppStateStore";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import ErrorNotification, { ErrorNotificationParam } from "@/components/ErrorNotification.vue";
import AutoResizeTextarea from "@/components/AutoResizeTextarea.vue";
import FilterableHeaderButton, { CheckboxItem } from "@/components/FilterableHeaderButton/FilterableHeaderButton.vue";
import JournalFilePreviewItem from "@/components/journalfile/JournalFilePreviewItem.vue";
import { messages } from "@/ts/const/Messages";
import { Err } from "@/ts/objects/Err";
import { NavigationGuardNext, Route } from "vue-router";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import { EditableSolanStudent, EditableSolanStudentProject } from "@/ts/objects/solan/editable/EditableSolanStudent";
import { EditableSolanJournal } from "@/ts/objects/solan/editable/EditableSolanJournal";
import { EditableSolanLookback } from "@/ts/objects/solan/editable/EditableSolanLookback";
import { downloadBlob, solanProcessTextOf } from "@/ts/utils/AppUtil";
import { unparse } from "papaparse";
import { format } from "date-fns";
import PopupMenuButton, { MenuButton } from "@/components/PopupMenuButton.vue";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { UserRepository } from "@/ts/repositories/UserRepository";
import PublishSelectedRowsButton from "@/components/PublishSelectedRowsButton/PublishSelectedRowsButton.vue";
import SaveStateIndicator from "@/components/SaveStateIndicator/SaveStateIndicator.vue";
import JournalFilesView from "@/components/journalfile/JournalFilesView.vue";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { SolanJournalFile } from "@/ts/objects/solan/value/SolanJournalFile";

// TODO col-filterを切り替えた時、選択状態が解除されたりされなかったりする。なぜ？
// TODO テーマを決めた理由とか開始終了年月は表示しなくて良いのか？

type EmptyFilterCheckState = {
  empty: boolean;
  hasValue: boolean;
};

type RowFilterState = {
  student: Record<string, boolean>;
  teacherRating: EmptyFilterCheckState;
  teacherComment: EmptyFilterCheckState;
  guardianComment: EmptyFilterCheckState;
};

type ColFilterState = {
  learningActivity: boolean;
  rubrics: boolean;
  journalFiles: boolean;
  ratingsAndComments: boolean;
  guardianComment: boolean;
};

/**
 * 児童生徒行。
 */
type StudentRow = {
  student: EditableSolanStudent;

  /**
   * 現在表示中のプロジェクト行のリスト。
   */
  projectRows: ProjectRow[];
};

/**
 * プロジェクト行。
 */
type ProjectRow = {
  studentProject: EditableSolanStudentProject;

  /**
   * 現在表示中の学習記録行のリスト。
   */
  journalRows: JournalRow[];

  /**
   * 現在表示中のふりかえり行。
   */
  lookbackRow: LookbackRow | null;
};

/**
 * 学習記録行。
 */
type JournalRow = {
  journal: EditableSolanJournal;
  selected: boolean;
};

/**
 * ふりかえり行。
 */
type LookbackRow = {
  lookback: EditableSolanLookback;
  selected: boolean;
};

enum WholeSelectionState {
  AllSelected,
  SomeSelected,
  NoneSelected
}

const studentColWidth = 140;
const projectColWidth = 140;
const processColWidth = 110;
const learningActivityColWidth = 160;
const viewPointSColWidth = 160;
const viewPointAColWidth = 160;
const viewPointBColWidth = 160;
const viewPointCColWidth = 160;
const journalFilesColWidth = 160;
const studentCommentColWidth = 200;
const studentRatingColWidth = 60;
const studentInputHeaderWidth = studentCommentColWidth + studentRatingColWidth;
const teacherRatingColWidth = 60;
const teacherCommentColWidth = 200;
const guardianCommentColWidth = 160;
const teacherInputPublishedColWidth = 97;
const selectionColWidth = 60;

export default Vue.extend({
  name: "SolanJournalsT",
  components: {
    LoadingBlock,
    MessageView,
    ErrorNotification,
    AutoResizeTextarea,
    FilterableHeaderButton,
    JournalFilePreviewItem,
    JournalFilesView,
    PopupMenuButton,
    PublishSelectedRowsButton,
    SaveStateIndicator
  },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true }
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave,
      onRequirementUnmet: async () => {
        this.highlightUnsaved = true;
      }
    });

    this.solanRepository
      .listEditableSolanStudents(
        this.userRepository,
        this.appStateStore.teacherState?.selectedClass() ?? null,
        true,
        true,
        false
      )
      .then(resp => {
        if (resp instanceof Err) {
          log.debug("Error loading journals!");
          this.messageView = { message: messages.failedToLoadData, fadeIn: true };
          return;
        }

        this.students = resp;
        this.studentRowFilterCheckboxItems = resp.map(student => {
          return {
            key: student.studentUserId,
            label: `${student.studentNumber}番 ${student.name}`
          };
        });

        const students = this.students;
        if (students === null) return;
      });
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;

    extraMenuItems: MenuButton[];

    students: EditableSolanStudent[] | null;

    studentRowFilterCheckboxItems: CheckboxItem[];
    learningActivityRowFilterCheckboxItems: CheckboxItem[];
    teacherRatingRowFilterCheckboxItems: CheckboxItem[];
    teacherCommentRowFilterCheckboxItems: CheckboxItem[];
    guardianCommentRowFilterCheckboxItems: CheckboxItem[];

    rowFilterState: RowFilterState;
    colFilterState: ColFilterState;

    studentRows: StudentRow[];

    filesViewJournal: EditableSolanJournal | null;

    highlightUnsaved: boolean;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,

      extraMenuItems: [new MenuButton("exportCsv", "現在の状態をCSV出力", ["fas", "download"])],

      students: null,

      studentRowFilterCheckboxItems: [],
      learningActivityRowFilterCheckboxItems: [],
      teacherRatingRowFilterCheckboxItems: [
        { key: "empty", label: "未入力" },
        { key: "hasValue", label: "入力済" }
      ],
      teacherCommentRowFilterCheckboxItems: [
        { key: "empty", label: "未入力" },
        { key: "hasValue", label: "入力済" }
      ],
      guardianCommentRowFilterCheckboxItems: [
        { key: "empty", label: "未入力" },
        { key: "hasValue", label: "入力済" }
      ],

      rowFilterState: {
        student: {},
        teacherRating: { empty: true, hasValue: true },
        teacherComment: { empty: true, hasValue: true },
        guardianComment: { empty: true, hasValue: true }
      },
      colFilterState: {
        learningActivity: true,
        rubrics: true,
        journalFiles: true,
        ratingsAndComments: true,
        guardianComment: true
      },

      studentRows: [],

      filesViewJournal: null,

      highlightUnsaved: false,

      pageLeaveService: null
    };
  },
  computed: {
    styles(): any {
      return {
        "--studentColWidth": `${studentColWidth}px`,
        "--projectColWidth": `${projectColWidth}px`,
        "--processColWidth": `${processColWidth}px`,
        "--learningActivityColWidth": `${learningActivityColWidth}px`,
        "--viewPointSColWidth": `${viewPointSColWidth}px`,
        "--viewPointAColWidth": `${viewPointAColWidth}px`,
        "--viewPointBColWidth": `${viewPointBColWidth}px`,
        "--viewPointCColWidth": `${viewPointCColWidth}px`,
        "--journalFilesColWidth": `${journalFilesColWidth}px`,
        "--studentCommentColWidth": `${studentCommentColWidth}px`,
        "--studentRatingColWidth": `${studentRatingColWidth}px`,
        "--studentInputHeaderWidth": `${studentInputHeaderWidth}px`,
        "--teacherRatingColWidth": `${teacherRatingColWidth}px`,
        "--teacherCommentColWidth": `${teacherCommentColWidth}px`,
        "--guardianCommentColWidth": `${guardianCommentColWidth}px`,
        "--teacherInputPublishedColWidth": `${teacherInputPublishedColWidth}px`,
        "--selectionColWidth": `${selectionColWidth}px`,
        "--currentJournalRowWidth": `${this.currentJournalRowWidth}px`,
        "--currentTableWidth": `${this.currentTableWidth}px`
      };
    },
    currentJournalRowWidth(): number {
      let width = processColWidth + teacherInputPublishedColWidth + selectionColWidth;
      if (this.colFilterState.learningActivity) {
        width += learningActivityColWidth;
      }
      if (this.colFilterState.rubrics) {
        width += viewPointSColWidth + viewPointAColWidth + viewPointBColWidth + viewPointCColWidth;
      }
      if (this.colFilterState.journalFiles) {
        width += journalFilesColWidth;
      }
      if (this.colFilterState.ratingsAndComments) {
        width += studentInputHeaderWidth + teacherRatingColWidth + teacherCommentColWidth;
      }
      if (this.colFilterState.guardianComment) {
        width += guardianCommentColWidth;
      }
      return width;
    },
    currentTableWidth(): number {
      return studentColWidth + projectColWidth + this.currentJournalRowWidth;
    },
    needSave(): boolean {
      const students = this.students;
      if (students === null) return false;
      return students.some(s => s.needSave());
    },
    currentErrors(): ErrorNotificationParam[] {
      return (
        this.students?.flatMap(s =>
          s.currentErrors().map(e => {
            return {
              heading: `${s.studentNumber}番 ${s.name}`,
              text: `${e.message}`
            };
          })
        ) ?? []
      );
    },
    wholeSelectionState(): WholeSelectionState {
      // TODO この程度重くないとは思うが、要確認？
      const values: boolean[] = this.studentRows.flatMap(sr => {
        return sr.projectRows.flatMap(pr => {
          const journalSelections = pr.journalRows.map(jr => jr.selected);
          const lookbackSelections = pr.lookbackRow !== null ? [pr.lookbackRow.selected] : [];
          return [...journalSelections, ...lookbackSelections];
        });
      });
      let foundSelected = false;
      let foundUnselected = false;
      for (const v of values) {
        if (v === true) {
          foundSelected = true;
        } else {
          foundUnselected = true;
        }
      }

      if (foundSelected && !foundUnselected) {
        return WholeSelectionState.AllSelected;
      } else if (foundSelected && foundUnselected) {
        return WholeSelectionState.SomeSelected;
      } else {
        return WholeSelectionState.NoneSelected;
      }
    },
    allSelected(): boolean {
      return this.wholeSelectionState === WholeSelectionState.AllSelected;
    },
    someSelected(): boolean {
      return this.wholeSelectionState === WholeSelectionState.SomeSelected;
    },
    noneSelected(): boolean {
      return this.wholeSelectionState === WholeSelectionState.NoneSelected;
    }
  },
  methods: {
    async saveAll(force: boolean): Promise<SaveResult> {
      const students = this.students;
      if (students === null) return emptySaveResult();
      log.debug("SAVING!");
      return flattenSaveResults(await Promise.all(students.map(s => s.saveAllChanges(force))));
    },
    onStudentRowFilterChanged(state: Record<string, boolean>) {
      log.debug(`onStudentRowFilterChanged!: ${JSON.stringify(state)}`);
      this.rowFilterState.student = state;
      this.applyRowFilter();
    },
    onTeacherRatingRowFilterChanged(state: EmptyFilterCheckState) {
      log.debug(`onTeacherRatingRowFilterChanged!: ${JSON.stringify(state)}`);
      this.rowFilterState.teacherRating = state;
      this.applyRowFilter();
    },
    onTeacherCommentRowFilterChanged(state: EmptyFilterCheckState) {
      log.debug(`onTeacherCommentRowFilterChanged!: ${JSON.stringify(state)}`);
      this.rowFilterState.teacherComment = state;
      this.applyRowFilter();
    },
    onGuardianCommentRowFilterChanged(state: EmptyFilterCheckState) {
      log.debug(`onGuardianCommentRowFilterChanged!: ${JSON.stringify(state)}`);
      this.rowFilterState.guardianComment = state;
      this.applyRowFilter();
    },
    applyRowFilter() {
      const students = this.students;
      if (students === null) return [];

      // 画面初期表示時に何度も呼ばれるので、もし重ければなんとかする。
      const rowFilterState = this.rowFilterState;

      const filteredStudents = students.filter(s => rowFilterState.student[s.studentUserId] === true);
      const filteredStudentRows = filteredStudents
        .map(student => {
          return {
            student: student,
            projectRows: student.projects
              .map(p => {
                return {
                  studentProject: p,
                  filteredJournals: p.journals.filter(j => shouldDisplayJournal(j, rowFilterState)),
                  filteredLookback: shouldDisplayLookback(p.lookback, rowFilterState) ? p.lookback : null
                };
              })
              .filter(v => v.filteredJournals.length > 0 || v.filteredLookback !== null)
              .map(v => {
                return {
                  studentProject: v.studentProject,
                  journalRows: v.filteredJournals.map(j => {
                    return {
                      journal: j,
                      selected: false
                    };
                  }),
                  lookbackRow:
                    v.filteredLookback !== null
                      ? {
                          lookback: v.filteredLookback,
                          selected: false
                        }
                      : null
                };
              })
          };
        })
        .filter(studentRow => studentRow.projectRows.length > 0);
      log.debug(`applyRowFilter!: filteredStudentRows=${JSON.stringify(filteredStudentRows)}`);
      this.studentRows = filteredStudentRows;
    },
    toggleAllSelections() {
      // 参考: https://github.com/vuejs/vue/issues/9535#issuecomment-466217819
      // TODO WindowsのChrome以外でもちゃんと動くか確認。
      setTimeout(() => {
        const changeTo = this.noneSelected ? true : false;
        for (const sr of this.studentRows) {
          for (const pr of sr.projectRows) {
            for (const jr of pr.journalRows) {
              jr.selected = changeTo;
            }
            if (pr.lookbackRow !== null) {
              pr.lookbackRow.selected = changeTo;
            }
          }
        }
      });
    },
    publishSelectedRows() {
      if (this.noneSelected) return;
      changeSelectedRowsPublishState(true, this.studentRows);
      this.saveAll(false);
    },
    unpublishSelectedRows() {
      if (this.noneSelected) return;
      changeSelectedRowsPublishState(false, this.studentRows);
      this.saveAll(false);
    },
    setFilesView(studentUserId: string | null, projectId: string | null, rubricId: string | null) {
      if (studentUserId === null || projectId === null || rubricId === null) {
        this.filesViewJournal = null;
        return;
      }

      const studentRow: StudentRow | undefined = this.studentRows.find(s => s.student.studentUserId === studentUserId);
      if (studentRow === undefined) {
        this.filesViewJournal = null;
        return;
      }

      const projectRow: ProjectRow | undefined = studentRow.projectRows.find(
        p => p.studentProject.project.projectId === projectId
      );
      if (projectRow === undefined) {
        this.filesViewJournal = null;
        return;
      }

      const journalRow = projectRow.journalRows.find(j => j.journal.rubricId === rubricId);
      if (journalRow === undefined) {
        this.filesViewJournal = null;
        return;
      }

      this.filesViewJournal = journalRow.journal;
    },
    isMyFilesViewOpen(rubricResourceName: string): boolean {
      const filesViewJournal = this.filesViewJournal;
      if (filesViewJournal === null) return false;
      return filesViewJournal.rubricResourceName === rubricResourceName;
    },
    _solanProcessTextOf(process: number): string {
      return solanProcessTextOf(process);
    },
    selectExtraMenu(menuItem: string) {
      switch (menuItem) {
        case "exportCsv":
          this.exportCsv();
          break;
      }
    },
    async uploadJournalFile(file: File, journal: EditableSolanJournal) {
      const resp = await this.solanRepository.postJournalFile(journal.projectId, journal.rubricId, file, 30000);
      log.debug(`uploadFile: resp=${JSON.stringify(resp)}`);

      await journal.reloadJournalFiles();
    },
    async deleteJournalFile(journalFile: JournalFile, journal: EditableSolanJournal): Promise<void> {
      if (!(journalFile instanceof SolanJournalFile)) return;

      const resp = await this.solanRepository.deleteJournalFile(
        journalFile.projectId,
        journalFile.rubricId,
        journalFile.journalFileId
      );
      log.debug(`deleteFile: resp=${JSON.stringify(resp)}`);

      await journal.reloadJournalFiles();
    },
    exportCsv() {
      exportCsv(this.studentRows, this.colFilterState);
    }
  }
});

function changeSelectedRowsPublishState(changeTo: boolean, studentRows: StudentRow[]) {
  studentRows.forEach(sr => {
    sr.projectRows.forEach(pr => {
      pr.journalRows
        .filter(jr => jr.selected)
        .forEach(jr => {
          jr.journal.teacherInputPublished = changeTo;
        });
      if (pr.lookbackRow !== null && pr.lookbackRow.selected) {
        pr.lookbackRow.lookback.teacherInputPublished = changeTo;
      }
    });
  });
}

function shouldDisplayJournal(journal: EditableSolanJournal, rowFilterState: RowFilterState): boolean {
  const teacherRatingOk =
    (rowFilterState.teacherRating.empty && journal.teacherRating === "") ||
    (rowFilterState.teacherRating.hasValue && journal.teacherRating !== "");
  const teacherCommentOk =
    (rowFilterState.teacherComment.empty && journal.teacherComment === "") ||
    (rowFilterState.teacherComment.hasValue && journal.teacherComment !== "");
  const guardianCommentOk = rowFilterState.guardianComment.empty;
  return teacherRatingOk && teacherCommentOk && guardianCommentOk;
}

function shouldDisplayLookback(lookback: EditableSolanLookback, rowFilterState: RowFilterState): boolean {
  const teacherRatingOk =
    (rowFilterState.teacherRating.empty && lookback.teacherRating === "") ||
    (rowFilterState.teacherRating.hasValue && lookback.teacherRating !== "");
  const teacherCommentOk =
    (rowFilterState.teacherComment.empty && lookback.teacherComment === "") ||
    (rowFilterState.teacherComment.hasValue && lookback.teacherComment !== "");
  const guardianCommentOk =
    (rowFilterState.guardianComment.empty && lookback.guardianComment === "") ||
    (rowFilterState.guardianComment.hasValue && lookback.guardianComment !== "");
  return teacherRatingOk && teacherCommentOk && guardianCommentOk;
}

function exportCsv(studentRows: StudentRow[], colFilterState: ColFilterState) {
  const csvRows = studentRows.flatMap(s => {
    return s.projectRows.flatMap(p => {
      const journalCsvRows = p.journalRows.map(j => {
        return {
          番号: s.student.studentNumber,
          氏名: s.student.name,
          テーマ: p.studentProject.project.name,
          プロセス: solanProcessTextOf(j.journal.process),
          学習活動: j.journal.rubricLearningActivity,
          S: j.journal.rubricViewPointS,
          A: j.journal.rubricViewPointA,
          B: j.journal.rubricViewPointB,
          C: j.journal.rubricViewPointC,
          学習の記録: j.journal.journalFiles.length,
          "学習のふりかえり(コメント)": j.journal.studentComment,
          "学習のふりかえり(評価)": j.journal.studentRating,
          先生評価: j.journal.teacherRating,
          先生から: j.journal.teacherComment,
          保護者から: "",
          状態: j.journal.teacherInputPublished ? "公開" : "非公開"
        };
      });
      if (p.lookbackRow === null) {
        return journalCsvRows;
      }

      const lookbackRow = {
        番号: s.student.studentNumber,
        氏名: s.student.name,
        テーマ: p.studentProject.project.name,
        プロセス: "ふりかえり",
        学習活動: "",
        S: "",
        A: "",
        B: "",
        C: "",
        学習の記録: 0,
        "学習のふりかえり(コメント)": p.lookbackRow.lookback.studentComment,
        "学習のふりかえり(評価)": p.lookbackRow.lookback.studentRating,
        先生評価: p.lookbackRow.lookback.teacherRating,
        先生から: p.lookbackRow.lookback.teacherComment,
        保護者から: p.lookbackRow.lookback.guardianComment,
        状態: p.lookbackRow.lookback.teacherInputPublished ? "公開" : "非公開"
      };

      return [...journalCsvRows, lookbackRow];
    });
  });
  const columnNames = [
    "番号",
    "氏名",
    "テーマ",
    "プロセス",
    colFilterState.learningActivity ? "学習活動" : undefined,
    colFilterState.rubrics ? "S" : undefined,
    colFilterState.rubrics ? "A" : undefined,
    colFilterState.rubrics ? "B" : undefined,
    colFilterState.rubrics ? "C" : undefined,
    colFilterState.journalFiles ? "学習の記録" : undefined,
    colFilterState.ratingsAndComments ? "学習のふりかえり(コメント)" : undefined,
    colFilterState.ratingsAndComments ? "学習のふりかえり(評価)" : undefined,
    colFilterState.ratingsAndComments ? "先生評価" : undefined,
    colFilterState.ratingsAndComments ? "先生から" : undefined,
    colFilterState.guardianComment ? "保護者から" : undefined,
    "状態"
  ].filter((c: string | undefined): c is string => c !== undefined);

  // BOMはエクセル対策。参考: https://qiita.com/wadahiro/items/eb50ac6bbe2e18cf8813
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, unparse(csvRows, { columns: columnNames })], { type: "text/plain" });
  downloadBlob(blob, `SOLAN学習_${format(new Date(), "yyyyMMdd'T'HHmmss")}.csv`);
}
