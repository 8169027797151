import clamp from "lodash/clamp";
import arrayMove from "array-move";

/**
 * 引数がnullまたはundefinedであればtrueを返す。
 *
 * `value == null` でも全く同じことができる: https://stackoverflow.com/a/5515385/12579447
 * が、イコールの数を数えるのが面倒だし、きっとそのうち間違えるので、テンプレート外ではこの関数を推奨する。
 */
export function isNullish<T>(value: T | null | undefined): value is null | undefined {
  return value === null || value === undefined;
}

/**
 * 指定したミリ秒だけ待つ。
 */
export async function delay(millis: number): Promise<void> {
  await new Promise<void>(resolve => setTimeout(() => resolve(), millis));
}

/**
 * 配列の末尾に値を追加するが、既に同じものが存在すれば何もしない。
 * ※ setでやればいいようなものだが、setはリアクティブにできないので・・・。
 */
export function pushUniq<T>(arr: T[], value: T): T[] {
  if (arr.find(v => v === value) !== undefined) return arr;
  return [...arr, value];
}

/**
 * 配列の中で、1要素を上(indexの小さい方)か下(indexの大きい方)へ1個動かす。
 * 配列をmutateせず、変更があった場合はコピーを返す。
 *
 * 指定した要素が見つからなければ何もしない。('==='で比較する。)
 * upを指定したのにすでに一番上にいたりと、動けない場合も何もしない。
 */
export function arrayMoveOne<T>(arr: readonly T[], target: T, up: boolean): T[] {
  const currentIdx = arr.findIndex(value => value === target);
  if (currentIdx < 0) return arr.slice();

  const toIdx = clamp(up ? currentIdx - 1 : currentIdx + 1, 0, arr.length - 1);

  return arrayMove(arr, currentIdx, toIdx);
}
