












import Vue from "vue";
import debounce from "lodash/debounce";
import clamp from "lodash/clamp";
import log from "loglevel";

// 参考: https://qiita.com/Ishidall/items/5f88aba31270d9dc98dc
export default Vue.extend({
  name: "AutoResizeTextarea",
  props: {
    placeholder: { type: String, default: "" },
    value: { type: String, default: "" },
    maxChars: { type: Number, default: 9999 },
    minHeight: { type: Number, default: 1 }, // 単位: ピクセル
    maxHeight: { type: Number, default: 500 }, // 単位: ピクセル
    paddingHorizontal: { type: Number, default: 0 },
    paddingVertical: { type: Number, default: 0 },
    triggerOnWindowResize: { type: Boolean, default: true },
    blur: {
      type: Function,
      default: () => {
        return;
      }
    }
  },
  created() {
    this.debouncedResize = debounce(this.resize, 100);
  },
  mounted() {
    this.$nextTick(() => {
      this.emit();
      this.resize();

      // たまに最初にリサイズできないことがあるので、試しに仕込んでみる。無意味なら消す。
      setTimeout(this.resize, 500);

      // TODO 1画面で大量に本テキストエリアを使う場合は、もしかしたら重くなるかも。このリスナーのせいであれば、リスナーを親コンポーネントで1個だけ持ち、各テキストエリアに通知すると良いかもしれない。
      // リサイズイベントによるトリガーはdebounceする。（そうしないと非常に重くなる）
      if (this.triggerOnWindowResize) window.addEventListener("resize", this.debouncedResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedResize);
  },
  data() {
    return {
      debouncedResize: () => {},
      textareaHeight: 1
    };
  },
  computed: {
    textareaStyle(): any {
      return {
        height: `${this.textareaHeight}px`,
        padding: `${this.paddingVertical}px ${this.paddingHorizontal}px`
      };
    }
  },
  watch: {
    value() {
      // emit等によりvalueが変更されると、このwatchがトリガーする。
      // emitとresizeを、watchを介して分けることで、外部からvalueが直接変更された場合もresizeできるようにする。
      this.resize();
    }
  },
  methods: {
    async emit() {
      const el = this.$el as HTMLTextAreaElement;
      this.$emit("input", el.value); // 親にinputを伝える。
    },
    async resize() {
      log.debug("AutoResizeTextarea resize!");
      this.textareaHeight = 0;

      await this.$nextTick(); // ここで待たないとDOMの更新前に下のコードが走って変な挙動になる。

      // heightが0になった瞬間textareaはmin-heightになる。
      // 入力済み文字列の高さがmin-heightを超えたら、scrollHeightが必要な分だけ大きくなる = それをheightにしちゃえばOK！
      this.textareaHeight = clamp(this.$el.scrollHeight + 4, this.minHeight, this.maxHeight);
    }
  }
});
