































import Vue, { PropType } from "vue";
import { format } from "date-fns";
import { fileTypeToIcon, fileTypeToJapanese } from "@/ts/utils/AppUtil";
import JournalFileModal from "@/components/journalfile/JournalFileModal/JournalFileModal.vue";
import LoadingDotsBlock from "@/components/loading/LoadingDotsBlock.vue";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { isNullish } from "@/ts/utils/CommonUtil";

export default Vue.extend({
  name: "JournalFilesViewItem",
  components: { LoadingDotsBlock },
  props: {
    journalFile: { type: Object as PropType<JournalFile>, required: true },
    readonly: { type: Boolean, default: false },

    /**
     * この学習記録ファイルを削除する関数。
     * readonly = true の場合は不要。
     */
    deleteJournalFile: {
      type: Function as PropType<(file: JournalFile) => Promise<void>>
    }
  },
  computed: {
    deleteStarted(): boolean {
      return this.journalFile.markedAsDeleting;
    }
  },
  methods: {
    openJournalFileModal() {
      if (this.deleteStarted) return;

      let width: string | number = "80%";
      if (!isNullish(this.journalFile.width) && !isNullish(this.journalFile.height)) {
        const widthRatio = this.journalFile.width / window.innerWidth;
        const heightRatio = this.journalFile.height / window.innerHeight;

        if (widthRatio < heightRatio) {
          width = window.innerHeight * 0.8 * (this.journalFile.width / this.journalFile.height);
        }
      }
      if (typeof width === "number" && width < 360) width = 360;

      this.$modal.show(
        JournalFileModal,
        {
          journalFile: this.journalFile,
          readonly: this.readonly,
          deleteJournalFile: this.deleteJournalFile
        },
        {
          adaptive: true,
          width: width,
          height: "auto"
        }
      );
    },
    formatDate(date: Date): string {
      return format(date, "M/d H:mm");
    },
    fileTypeToJapanese(fileType: string): string {
      return fileTypeToJapanese(fileType);
    },
    fileTypeToIcon(fileType: string): [string, string] {
      return fileTypeToIcon(fileType);
    }
  }
});
