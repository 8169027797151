import { EditableProject } from "@/ts/objects/project/editable/EditableProject";
import { Err } from "@/ts/objects/Err";
import { Quarter } from "@/ts/objects/common/Quarter";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";
import { getProjectTestData } from "@/test-tools/ProjectTestData";
import { Project } from "@/ts/objects/project/value/Project";
import {
  JournalWrite,
  Project as ProjectResp,
  ProjectWrite,
  RubricWrite,
  Rubric as RubricResp,
  Journal as JournalResp,
  LookbackWrite,
  Lookback as LookbackResp,
  JournalFile as JournalFileResp
} from "@/ts/api/project-service";
import { v4 as uuidv4 } from "uuid";
import { EditableProjectLookback } from "@/ts/objects/project/editable/EditableProjectLookback";
import { EditableProjectJournal } from "@/ts/objects/project/editable/EditableProjectJournal";
import { ProjectRubric } from "@/ts/objects/project/value/ProjectRubric";
import { EditableProjectRubric } from "@/ts/objects/project/editable/EditableProjectRubric";
import { ProjectJournalFile } from "@/ts/objects/project/value/ProjectJournalFile";
import { delay, isNullish } from "@/ts/utils";
import {
  partialArgsToProjectTestDataSet,
  ProjectJournalArg,
  ProjectTestDataPartialArgs,
  ProjectTestDataSet
} from "@/test-tools/ProjectTestDataTypes";

/**
 * ProjectRepositoryのモック（スタブ）。
 *
 * Get/Listは、 {@link getProjectTestData} から検索して取得する。
 * Postは、もらったデータそのままで作成したふりをし、足りない項目は適当に埋める。実際には作成しない。
 * Patchは、とにかくただ成功する。実際には更新しない。
 * Deleteも、とにかくただ成功する。実際には削除しない。
 */
export class ProjectRepositoryMock extends ProjectRepository {
  constructor(testData?: ProjectTestDataPartialArgs) {
    super();

    this.testData = isNullish(testData) ? getProjectTestData() : partialArgsToProjectTestDataSet(testData);
  }

  private testData: ProjectTestDataSet;

  async getEditableProject(this: this, projectId: string, savable: boolean): Promise<EditableProject | Err> {
    const projectArg = Object.values(this.testData).find(p => p.projectId === projectId);
    if (projectArg === undefined) throw new Error("error on ProjectRepositoryMock");
    return projectArg.toEditable(this, savable);
  }

  async listProjects(this: this, classIds: string[], quarter: Quarter | undefined): Promise<Project[] | Err> {
    return Object.values(this.testData)
      .filter(
        p =>
          (quarter === undefined || p.quarter.schoolYear === quarter?.schoolYear) &&
          (quarter === undefined || p.quarter.quarter === quarter?.quarter) &&
          classIds.includes(p.classId)
      )
      .map(p => p.toObject());
  }

  async listEditableProjects(
    this: this,
    classIds: string[],
    quarter: Quarter | undefined,
    savable: boolean
  ): Promise<EditableProject[] | Err> {
    return Object.values(this.testData)
      .filter(
        p =>
          (quarter === undefined || p.quarter.schoolYear === quarter?.schoolYear) &&
          (quarter === undefined || p.quarter.quarter === quarter?.quarter) &&
          classIds.includes(p.classId)
      )
      .map(p => p.toEditable(this, savable));
  }

  async postProject(this: this, classId: string, quarter: Quarter): Promise<ProjectResp | Err> {
    const projectId = uuidv4();
    return {
      self: `/projects/${projectId}`,
      projectId: projectId,
      classId: classId,
      quarter: { schoolYear: quarter.schoolYear, quarter: quarter.quarter },
      name: { value: "", hash: "" },
      description: { value: "", hash: "" },
      relatedSyllabus: { value: "", hash: "" },
      viewPointSEnabled: true,
      viewPointAEnabled: true,
      viewPointBEnabled: true,
      viewPointCEnabled: true,
      startingMonth: { year: 2020, month: 1 },
      endingMonth: { year: 2020, month: 1 },
      published: false,
      completed: false,
      rubrics: [],
      createdAt: "2000-01-01T00:00:00Z"
    };
  }

  /**
   * 受け取ったprojectWriteを、ほぼそのまま返す。
   * よって、patchで更新したところだけを書き換える使い方をする場合(EditableProject等)は問題ないが、
   * 更新後に全データがあることを期待している箇所では、このモックはうまく働かないことになる。
   *
   * @param projectId
   * @param projectWrite
   */
  async patchProject(this: this, projectId: string, projectWrite: ProjectWrite): Promise<ProjectResp | Err> {
    await delay(500);
    return {
      self: `/projects/${projectId}`,
      projectId: projectId,
      classId: projectWrite.classId ?? "",
      quarter: projectWrite.quarter ?? { schoolYear: 2000, quarter: 1 },
      name: projectWrite.name ?? { value: "", hash: "" },
      description: projectWrite.description ?? { value: "", hash: "" },
      relatedSyllabus: projectWrite.relatedSyllabus ?? { value: "", hash: "" },
      viewPointSEnabled: projectWrite.viewPointSEnabled ?? true,
      viewPointAEnabled: projectWrite.viewPointAEnabled ?? true,
      viewPointBEnabled: projectWrite.viewPointBEnabled ?? true,
      viewPointCEnabled: projectWrite.viewPointCEnabled ?? true,
      startingMonth: projectWrite.startingMonth ?? { year: 2000, month: 1 },
      endingMonth: projectWrite.endingMonth ?? { year: 2000, month: 1 },
      published: projectWrite.published ?? false,
      completed: projectWrite.completed ?? false,
      rubrics: projectWrite.rubrics ?? [],
      createdAt: "2000-01-01T00:00:00Z"
    };
  }

  async deleteProject(this: this, _projectId: string): Promise<void | Err> {
    return;
  }

  async getEditableProjectLookback(
    this: this,
    resourceName: string,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<EditableProjectLookback | Err> {
    const lookbackArg = Object.values(this.testData)
      .flatMap(p => p.lookbacks)
      .find(l => l.resourceName === resourceName);
    if (lookbackArg === undefined) throw new Error("error on ProjectRepositoryMock");
    return lookbackArg.toEditable(this, teacherInputSavable, studentInputSavable, guardianInputSavable);
  }

  async listEditableProjectLookbacks(
    this: this,
    projectId: string,
    studentUserId: string | undefined,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<EditableProjectLookback[] | Err> {
    return Object.values(this.testData)
      .flatMap(p => p.lookbacks)
      .filter(l => l.projectId === projectId && (studentUserId === undefined || l.studentUserId === studentUserId))
      .map(l => l.toEditable(this, teacherInputSavable, studentInputSavable, guardianInputSavable));
  }

  /**
   * 受け取ったLookbackWriteを、ほぼそのまま返す。
   * よって、patchで更新したところだけを書き換える使い方をする場合は問題ないが、
   * 更新後に全データがあることを期待している箇所では、このモックはうまく働かないことになる。
   *
   * @param projectId
   * @param lookbackId
   * @param lookbackWrite
   */
  async patchLookback(
    this: this,
    projectId: string,
    lookbackId: string,
    lookbackWrite: LookbackWrite
  ): Promise<LookbackResp | Err> {
    await delay(500);
    return {
      self: `/projects/${projectId}/lookbacks/${lookbackId}`,
      lookbackId: lookbackId,
      project: `/projects/${projectId}`,
      studentUserId: lookbackWrite.studentUserId ?? "",
      studentComment: lookbackWrite.studentComment ?? { value: "", hash: "" },
      studentRating: lookbackWrite.studentRating ?? "",
      teacherComment: lookbackWrite.teacherComment ?? { value: "", hash: "" },
      teacherRating: lookbackWrite.teacherRating ?? "",
      teacherInputPublished: lookbackWrite.teacherInputPublished ?? false,
      guardianComment: lookbackWrite.guardianComment ?? { value: "", hash: "" },
      studentInputLocked: false,
      guardianInputLocked: false
    };
  }

  async listProjectRubrics(this: this, projectId: string): Promise<ProjectRubric[] | Err> {
    return Object.values(this.testData)
      .flatMap(p => p.rubrics)
      .filter(r => r.projectId === projectId)
      .map(r => r.toObject());
  }

  async listEditableProjectRubrics(
    this: this,
    projectId: string,
    savable: boolean
  ): Promise<EditableProjectRubric[] | Err> {
    return Object.values(this.testData)
      .flatMap(p => p.rubrics)
      .filter(r => r.projectId === projectId)
      .map(r => r.toEditable(this, savable));
  }

  async getEditableProjectRubric(
    this: this,
    resourceName: string,
    savable: boolean
  ): Promise<EditableProjectRubric | Err> {
    const rubricArg = Object.values(this.testData)
      .flatMap(p => p.rubrics)
      .find(r => r.resourceName === resourceName);
    if (rubricArg === undefined) throw new Error("error on ProjectRepositoryMock");
    return rubricArg.toEditable(this, savable);
  }

  async postRubric(this: this, projectId: string, rubricWrite: RubricWrite): Promise<RubricResp | Err> {
    const rubricId = uuidv4();
    return {
      self: `/projects/${projectId}/rubrics/${rubricId}`,
      rubricId: rubricId,
      project: `/projects/${projectId}`,
      orderNum: 0, // モック内では判定不能。testData一覧の最後にくっつける、という程度ならできなくはないが・・・。
      learningActivity: rubricWrite.learningActivity ?? { value: "", hash: "" },
      viewPointS: rubricWrite.viewPointS ?? { value: "", hash: "" },
      viewPointA: rubricWrite.viewPointA ?? { value: "", hash: "" },
      viewPointB: rubricWrite.viewPointB ?? { value: "", hash: "" },
      viewPointC: rubricWrite.viewPointC ?? { value: "", hash: "" }
    };
  }

  /**
   * 受け取ったrubricWriteを、ほぼそのまま返す。
   * よって、patchで更新したところだけを書き換える使い方をする場合は問題ないが、
   * 更新後に全データがあることを期待している箇所では、このモックはうまく働かないことになる。
   *
   * @param projectId
   * @param rubricId
   * @param rubricWrite
   */
  async patchRubric(
    this: this,
    projectId: string,
    rubricId: string,
    rubricWrite: RubricWrite
  ): Promise<RubricResp | Err> {
    await delay(500);
    return {
      self: `/projects/${projectId}/rubrics/${rubricId}`,
      rubricId: rubricId,
      project: `/projects/${projectId}`,
      orderNum: 0, // モック内では判定不能。testData一覧の最後にくっつける、という程度ならできなくはないが・・・。
      learningActivity: rubricWrite.learningActivity ?? { value: "", hash: "" },
      viewPointS: rubricWrite.viewPointS ?? { value: "", hash: "" },
      viewPointA: rubricWrite.viewPointA ?? { value: "", hash: "" },
      viewPointB: rubricWrite.viewPointB ?? { value: "", hash: "" },
      viewPointC: rubricWrite.viewPointC ?? { value: "", hash: "" }
    };
  }

  async deleteRubric(this: this, _projectId: string, _rubricId: string): Promise<void | Err> {
    return;
  }

  async listEditableProjectJournals(
    this: this,
    projectId: string,
    studentUserId: string | undefined,
    teacherInputSavable: boolean,
    studentInputSavable: boolean
  ): Promise<{ rubrics: ProjectRubric[]; editableJournals: EditableProjectJournal[] } | Err> {
    const rubrics = Object.values(this.testData)
      .flatMap(p => p.rubrics)
      .filter(r => r.projectId === projectId)
      .map(r => r.toObject());
    const editableJournals = Object.values(this.testData)
      .flatMap(p => p.rubrics)
      .flatMap(r => r.journals.map((j): [ProjectJournalArg, string] => [j, r.learningActivity]))
      .filter(
        ([j, _rubricLearningActivity]) =>
          j.projectId === projectId && (studentUserId === undefined || j.studentUserId === studentUserId)
      )
      .map(([j, rubricLearningActivity]) =>
        j.toEditable(this, teacherInputSavable, studentInputSavable, rubricLearningActivity)
      );
    return { rubrics, editableJournals };
  }

  /**
   * 受け取ったjournalWriteを、ほぼそのまま返す。
   * よって、patchで更新したところだけを書き換える使い方をする場合は問題ないが、
   * 更新後に全データがあることを期待している箇所では、このモックはうまく働かないことになる。
   *
   * @param projectId
   * @param rubricId
   * @param journalId
   * @param journalWrite
   */
  async patchJournal(
    this: this,
    projectId: string,
    rubricId: string,
    journalId: string,
    journalWrite: JournalWrite
  ): Promise<JournalResp | Err> {
    await delay(500);
    return {
      self: `/projects/${projectId}/rubrics/${rubricId}/journals/${journalId}`,
      journalId: journalId,
      project: `/projects/${projectId}`,
      rubric: `/projects/${projectId}/rubrics/${rubricId}`,
      studentUserId: journalWrite.studentUserId ?? "",
      files: journalWrite.files ?? [],
      studentComment: journalWrite.studentComment ?? { value: "", hash: "" },
      studentRating: journalWrite.studentRating ?? "",
      teacherComment: journalWrite.teacherComment ?? { value: "", hash: "" },
      teacherRating: journalWrite.teacherRating ?? "",
      teacherInputPublished: journalWrite.teacherInputPublished ?? false,
      studentInputLocked: false
    };
  }

  async listJournalFiles(this: this, journal: string): Promise<ProjectJournalFile[] | Err> {
    return Object.values(this.testData)
      .flatMap(p => p.rubrics)
      .flatMap(r => r.journals)
      .flatMap(j => j.journalFiles)
      .filter(jf => jf.journalResourceName === journal)
      .map(jf => jf.toObject());
  }

  async postJournalFile(
    projectId: string,
    rubricId: string,
    journalId: string,
    _file: any,
    _timeoutMillis: number
  ): Promise<JournalFileResp | Err> {
    const journalFileId = uuidv4();
    return {
      self: `/projects/${projectId}/rubrics/${rubricId}/journals/${journalId}/journalFiles/${journalFileId}`,
      journalFileId: journalFileId,
      project: `/projects/${projectId}`,
      journal: `/projects/${projectId}/rubrics/${rubricId}/journals/${journalId}`,
      type: "any",
      subtype: "any",
      mediaType: "application/octet-stream",
      filename: "myfilename",
      ext: "ext",
      gcsObjectPath: "gcs-object-path",
      thumbnailGcsObjectPath: "thumbnail-gcs-object-path",
      width: undefined,
      height: undefined,
      createdAt: "2000-01-01T00:00:00Z",
      updatedAt: "2000-01-01T00:00:00Z",
      hasThumbnail: false
    };
  }

  async deleteJournalFile(
    this: this,
    _projectId: string,
    _rubricId: string,
    _journalId: string,
    _journalFileId: string
  ): Promise<void | Err> {
    return;
  }
}
