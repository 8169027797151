import { SolanJournalFile } from "@/ts/objects/solan/value/SolanJournalFile";
import { SolanRubric } from "@/ts/objects/solan/value/SolanRubric";
import { EditableSolanRubric } from "@/ts/objects/solan/editable/EditableSolanRubric";
import { SolanRepositoryMock } from "@/test-tools/mocks/SolanRepositoryMock";
import { EditableSolanJournal } from "@/ts/objects/solan/editable/EditableSolanJournal";
import { SolanProject } from "@/ts/objects/solan/value/SolanProject";
import { EditableSolanProject } from "@/ts/objects/solan/editable/EditableSolanProject";
import { RatingValue } from "@/ts/objects/common/Rating";
import { EditableSolanLookback } from "@/ts/objects/solan/editable/EditableSolanLookback";
import { EditableSolanStudentProject } from "@/ts/objects/solan/editable/EditableSolanStudent";

export type SolanTestDataSet = { [projectId: string]: SolanProjectArg };

export type SolanTestDataPartialArgs = {
  [projectId: string]: { studentUserId: string } & SolanProjectPartialArg;
};

export function partialArgsToSolanTestDataSet(args: SolanTestDataPartialArgs): SolanTestDataSet {
  return Object.fromEntries(
    Object.entries(args).map(([projectId, arg]) => [projectId, new SolanProjectArg({ projectId, ...arg })])
  );
}

export type SolanProjectPartialArg = {
  readonly schoolYear?: number;
  readonly name?: string;
  readonly description?: string;
  readonly started?: boolean;
  readonly completed?: boolean;
  readonly studentInputLocked?: boolean;
  readonly guardianInputLocked?: boolean;
  readonly lookback?: {
    readonly studentComment?: string;
    readonly studentRating?: RatingValue;
    readonly teacherComment?: string;
    readonly teacherRating?: RatingValue;
    readonly teacherInputPublished?: boolean;
    readonly guardianComment?: string;
  };
  readonly rubrics?: { [rubricId: string]: SolanRubricPartialArg };
};
export type SolanProjectPartialArgWithIds = {
  readonly projectId: string;

  readonly studentUserId: string;
} & SolanProjectPartialArg;

export class SolanProjectArg {
  constructor(arg: SolanProjectPartialArgWithIds) {
    this.projectId = arg.projectId;

    this.studentUserId = arg.studentUserId;

    this.schoolYear = arg.schoolYear ?? 2000;
    this.name = arg.name ?? "solan-project-name";
    this.description = arg.description ?? "solan-project-description";
    this.started = arg.started ?? false;
    this.completed = arg.completed ?? false;
    this.studentInputLocked = arg.studentInputLocked ?? false;
    this.guardianInputLocked = arg.guardianInputLocked ?? false;
    this.lookback = {
      studentComment: arg.lookback?.studentComment ?? "student-comment",
      studentRating: arg.lookback?.studentRating ?? "",
      teacherComment: arg.lookback?.teacherComment ?? "teacher-comment",
      teacherRating: arg.lookback?.teacherRating ?? "",
      teacherInputPublished: arg.lookback?.teacherInputPublished ?? false,
      guardianComment: arg.lookback?.guardianComment ?? ""
    };
    this.rubrics = Object.entries(arg.rubrics ?? {}).map(
      ([rubricId, rubric]) =>
        new SolanRubricArg({
          projectId: arg.projectId,
          rubricId,
          studentUserId: arg.studentUserId,
          ...rubric
        })
    );
  }

  readonly projectId: string;

  readonly studentUserId: string;

  readonly schoolYear: number;
  readonly name: string;
  readonly description: string;
  readonly started: boolean;
  readonly completed: boolean;
  readonly studentInputLocked: boolean;
  readonly guardianInputLocked: boolean;
  readonly lookback: {
    readonly studentComment: string;
    readonly studentRating: RatingValue;
    readonly teacherComment: string;
    readonly teacherRating: RatingValue;
    readonly teacherInputPublished: boolean;
    readonly guardianComment: string;
  };
  readonly rubrics: SolanRubricArg[];

  get resourceName(): string {
    return `/solanProjects/${this.projectId}`;
  }

  toObject(): SolanProject {
    return new SolanProject(
      this.projectId,
      this.studentUserId,
      this.schoolYear,
      this.name,
      this.description,
      this.started,
      this.completed,
      this.studentInputLocked
    );
  }

  toEditable(repo: SolanRepositoryMock, savable: boolean): EditableSolanProject {
    return new EditableSolanProject(
      repo,
      savable,
      this.projectId,
      this.studentUserId,
      this.schoolYear,
      this.name,
      "",
      this.description,
      "",
      this.started,
      this.completed
    );
  }

  toEditableLookback(
    repo: SolanRepositoryMock,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): EditableSolanLookback {
    return new EditableSolanLookback(
      repo,
      teacherInputSavable,
      studentInputSavable,
      guardianInputSavable,
      this.projectId,
      this.studentUserId,
      this.lookback.studentComment,
      "",
      this.lookback.studentRating,
      this.lookback.teacherComment,
      "",
      this.lookback.teacherRating,
      this.lookback.teacherInputPublished,
      this.lookback.guardianComment,
      "",
      this.studentInputLocked,
      this.guardianInputLocked
    );
  }

  toEditableStudentProject(
    repo: SolanRepositoryMock,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): EditableSolanStudentProject {
    return new EditableSolanStudentProject(
      this.toObject(),
      this.rubrics.map(r => r.toEditableJournal(repo, teacherInputSavable, studentInputSavable)),
      this.toEditableLookback(repo, teacherInputSavable, studentInputSavable, guardianInputSavable)
    );
  }
}

export type SolanRubricPartialArg = {
  readonly process?: number;
  readonly learningActivity?: string;
  readonly viewPointS?: string;
  readonly viewPointA?: string;
  readonly viewPointB?: string;
  readonly viewPointC?: string;
  readonly createdAt?: string;
  readonly studentInputLocked?: boolean;
  readonly journal?: {
    readonly studentComment?: string;
    readonly studentRating?: RatingValue;
    readonly teacherComment?: string;
    readonly teacherRating?: RatingValue;
    readonly teacherInputPublished?: boolean;
    readonly journalFiles?: { [journalFileId: string]: SolanJournalFilePartialArg };
  };
};
export type SolanRubricPartialArgWithIds = {
  readonly projectId: string;
  readonly rubricId: string;

  readonly studentUserId: string;
} & SolanRubricPartialArg;

export class SolanRubricArg {
  constructor(arg: SolanRubricPartialArgWithIds) {
    this.projectId = arg.projectId;
    this.rubricId = arg.rubricId;
    this.studentUserId = arg.studentUserId;

    this.process = arg.process ?? 0;
    this.learningActivity = arg.learningActivity ?? "learning-activity";
    this.viewPointS = arg.viewPointS ?? "view-point-s";
    this.viewPointA = arg.viewPointA ?? "view-point-a";
    this.viewPointB = arg.viewPointB ?? "view-point-b";
    this.viewPointC = arg.viewPointC ?? "view-point-c";
    this.createdAt = arg.createdAt ?? "2000-01-01T00:00:00Z";
    this.studentInputLocked = arg.studentInputLocked ?? false;
    this.journal = {
      studentComment: arg.journal?.studentComment ?? "student-comment",
      studentRating: arg.journal?.studentRating ?? "",
      teacherComment: arg.journal?.teacherComment ?? "teacher-comment",
      teacherRating: arg.journal?.teacherRating ?? "",
      teacherInputPublished: arg?.journal?.teacherInputPublished ?? false,
      journalFiles: Object.entries(arg.journal?.journalFiles ?? {}).map(
        ([journalFileId, journalFile]) =>
          new SolanJournalFileArg({
            projectId: arg.projectId,
            rubricId: arg.rubricId,
            journalFileId,
            ...journalFile
          })
      )
    };
  }

  readonly projectId: string;
  readonly rubricId: string;

  readonly studentUserId: string;

  readonly process: number;
  readonly learningActivity: string;
  readonly viewPointS: string;
  readonly viewPointA: string;
  readonly viewPointB: string;
  readonly viewPointC: string;
  readonly createdAt: string;
  readonly studentInputLocked: boolean;
  readonly journal: {
    readonly studentComment: string;
    readonly studentRating: RatingValue;
    readonly teacherComment: string;
    readonly teacherRating: RatingValue;
    readonly teacherInputPublished: boolean;
    readonly journalFiles: SolanJournalFileArg[];
  };

  get projectResourceName(): string {
    return `/solanProjects/${this.projectId}`;
  }

  get resourceName(): string {
    return `/solanProjects/${this.projectId}/rubrics/${this.rubricId}`;
  }

  toObject(): SolanRubric {
    return new SolanRubric(
      this.rubricId,
      this.projectId,
      this.studentUserId,
      this.process,
      this.learningActivity,
      this.viewPointS,
      this.viewPointA,
      this.viewPointB,
      this.viewPointC,
      this.createdAt
    );
  }

  toEditable(repo: SolanRepositoryMock, savable: boolean): EditableSolanRubric {
    return new EditableSolanRubric(
      repo,
      savable,
      this.projectId,
      this.rubricId,
      this.studentUserId,
      this.process,
      this.learningActivity,
      "",
      this.viewPointS,
      "",
      this.viewPointA,
      "",
      this.viewPointB,
      "",
      this.viewPointC,
      "",
      this.createdAt,
      this.studentInputLocked
    );
  }

  toEditableJournal(
    repo: SolanRepositoryMock,
    teacherInputSavable: boolean,
    studentInputSavable: boolean
  ): EditableSolanJournal {
    return new EditableSolanJournal(
      repo,
      teacherInputSavable,
      studentInputSavable,
      this.projectId,
      this.rubricId,
      this.process,
      this.learningActivity,
      this.viewPointS,
      this.viewPointA,
      this.viewPointB,
      this.viewPointC,
      this.studentUserId,
      this.journal.journalFiles.map(jf => jf.toObject()),
      this.journal.studentComment,
      "",
      this.journal.studentRating,
      this.journal.teacherComment,
      "",
      this.journal.teacherRating,
      this.journal.teacherInputPublished,
      this.studentInputLocked
    );
  }
}

export type SolanJournalFilePartialArg = {
  readonly filename?: string;
  readonly createdAt?: string;
  readonly updatedAt?: string;
};
export type SolanJournalFilePartialArgWithIds = {
  readonly projectId: string;
  readonly rubricId: string;
  readonly journalFileId: string;
} & SolanJournalFilePartialArg;

export class SolanJournalFileArg {
  constructor(arg: SolanJournalFilePartialArgWithIds) {
    this.projectId = arg.projectId;
    this.rubricId = arg.rubricId;
    this.journalFileId = arg.journalFileId;

    this.filename = arg.filename ?? "filename";
    this.createdAt = arg.createdAt ?? "2000-01-01T00:00:00Z";
    this.updatedAt = arg.updatedAt ?? "2000-01-01T00:00:00Z";
  }

  readonly projectId: string;
  readonly rubricId: string;
  readonly journalFileId: string;

  readonly filename: string;
  readonly createdAt: string;
  readonly updatedAt: string;

  get projectResourceName(): string {
    return `/solanProjects/${this.projectId}`;
  }

  get rubricResourceName(): string {
    return `/solanProjects/${this.projectId}/rubrics/${this.rubricId}`;
  }

  get resourceName(): string {
    return `/solanProjects/${this.projectId}/rubrics/${this.rubricId}/journalFiles/${this.journalFileId}`;
  }

  toObject(): SolanJournalFile {
    return new SolanJournalFile(
      this.projectId,
      this.journalFileId,
      this.rubricId,
      "type",
      "subtype",
      "mediaType",
      this.filename,
      "ext",
      "gcsObjectPath",
      "thumbnailGcsObjectPath",
      false,
      null,
      null,
      this.createdAt,
      this.updatedAt
    );
  }
}
