import Vue from "vue";
import vmodal from "vue-js-modal";
import { firestorePlugin } from "vuefire";
import { library } from "@fortawesome/fontawesome-svg-core";

// アルファベット順
import {
  faCircle as faCircleReg,
  faFileAlt as faFileAltReg,
  faImage as faImageReg,
  faPaperPlane as faPaperPlaneReg,
  faPlayCircle,
  faSmile,
  faStar as faStarReg
} from "@fortawesome/free-regular-svg-icons";

// アルファベット順
import {
  faArrowDown,
  faArrowUp,
  faBars,
  faBook,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheckCircle,
  faChevronCircleDown,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileAlt,
  faFileAudio,
  faFileVideo,
  faFilter,
  faImage,
  faInfoCircle,
  faLock,
  faLockOpen,
  faPaperPlane,
  faPencilAlt,
  faPlay,
  faPlus,
  faQuestionCircle,
  faSearch,
  faSpinner,
  faStamp,
  faStar,
  faTimes,
  faTrashAlt,
  faUnlock,
  faUpload
} from "@fortawesome/free-solid-svg-icons";

// アルファベット順
import { faReadme as faReadmeBrand } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import AsyncComputed from "vue-async-computed";
import vClickOutside from "v-click-outside";
import VueLazyLoad from "vue-lazyload";

// import VueRouter from "vue-router";

export function initializeVue(testing: boolean = false) {
  // Vue.use(VueRouter);
  Vue.use(AsyncComputed);
  Vue.use(vmodal);
  Vue.use(firestorePlugin);
  Vue.use(vClickOutside);

  if (!testing) {
    Vue.use(VueLazyLoad, {
      loading: require("@/assets/no-photo.png") // ロード中に表示する画像指定する
    });
  }

  // 使用したいアイコンをここで登録する。アルファベット順。
  library.add(
    faArrowDown,
    faArrowUp,
    faBars,
    faBook,
    faCaretDown,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCheckCircle,
    faChevronUp,
    faChevronCircleDown,
    faChevronCircleUp,
    faChevronDown,
    faChevronLeft,
    faChevronRight,
    faCircleReg,
    faDownload,
    faEdit,
    faEllipsisH,
    faEllipsisV,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLinkAlt,
    faFileAlt,
    faFileAltReg,
    faFileAudio,
    faFileVideo,
    faFilter,
    faImage,
    faImageReg,
    faInfoCircle,
    faLock,
    faLockOpen,
    faPaperPlane,
    faPaperPlaneReg,
    faPencilAlt,
    faPlay,
    faPlayCircle,
    faPlus,
    faQuestionCircle,
    faReadmeBrand,
    faSearch,
    faSmile,
    faSpinner,
    faStamp,
    faStar,
    faStarReg,
    faTimes,
    faTrashAlt,
    faUpload,
    faUnlock
  );
  Vue.component("font-awesome-icon", FontAwesomeIcon);
}
