
























import Vue, { PropType } from "vue";
import DisableableAutoResizeTextarea from "@/components/DisableableAutoResizeTextarea.vue";
import { CurriculumSyllabusNECRowStyleType } from "@/views/curriculum/teacher/CurriculumSyllabusNEC/components/CurriculumSyllabusNECContentRow/CurriculumSyllabusNECContentRow.vue";
import { ContentMonthInputEvent } from "@/views/curriculum/teacher/CurriculumSyllabusNEC/CurriculumSyllabusNECModel";

export default Vue.extend({
  name: "CurriculumSyllabusNECCell",
  components: { DisableableAutoResizeTextarea },
  props: {
    styleType: { type: Number as PropType<CurriculumSyllabusNECRowStyleType>, required: true },
    enabled: { type: Boolean, required: true },
    text: { type: String, required: true },
    onInput: { type: Function as PropType<(e: ContentMonthInputEvent) => void>, required: true }
  },
  computed: {
    styles(): Record<string, string> {
      return {
        "--background-color": this.styleType === 0 ? "rgba(255, 255, 255, 0.8)" : "rgba(255, 255, 255, 0.9)"
      };
    }
  },
  methods: {
    onChangeCheckbox(e: Event) {
      this.onInput({
        enabled: (e.target as HTMLInputElement).checked,
        text: this.text
      });
    },
    onInputText(text: string) {
      this.onInput({
        enabled: this.enabled,
        text
      });
    }
  }
});
