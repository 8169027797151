import { delay } from "@/ts/utils";

export class PageLeaveService {
  /**
   * page-leave処理の開始時に呼ばれるコールバック。
   */
  private readonly onLeaveStart: () => Promise<void>;

  /**
   * page-leaveが成功するための条件。
   * これが満たされるまで、timeoutSecで指定した秒数だけ待つ。
   */
  private readonly requirementToLeave: () => Promise<boolean>;

  /**
   * page-leaveが成功するための条件が満たされたときのコールバック。
   */
  private readonly onRequirementMet: () => Promise<void>;

  /**
   * page-leaveが成功するための条件が満たされず、タイムアウトしたときのコールバック。
   * タイムアウトした場合も、その後の確認メッセージでユーザーが「OK」を選択した場合、page-leaveする。
   */
  private readonly onRequirementUnmet: () => Promise<void>;

  /**
   * page-leave完了時に呼ばれるコールバック。
   *
   * page-leaveが成功するための条件が満たされたかに関わらず、呼び出される。
   * 最後以外の呼び出しなので無視された場合と、ユーザーがキャンセルした場合だけは呼び出されない。
   */
  private readonly onLeaveCompleted: () => Promise<void>;

  /**
   * 条件が満たされず、page-leave処理がタイムアウトした後、「それでもページを離れて良いですか？」と
   * 訊くためのメッセージ。これに対してユーザーが「OK」を選択した場合、page-leaveする。
   */
  private readonly confirmationMessageAfterTimeout: string;

  /**
   * page-leave処理のタイムアウト秒数。
   */
  private readonly timeoutSec: number;

  /**
   * tryLeaveが呼ばれた回数のカウンタ。
   * 重複して呼ばれた場合、最後の呼び出し以外はすべてfalseを返したい。
   * ・・・ので、その目的で用いる。
   */
  private numFunctionCalls: number = 0;

  constructor({
    onLeaveStart,
    requirementToLeave,
    timeoutSec = 4,
    confirmationMessageAfterTimeout = "移動すると未保存の入力が失われます。よろしいですか？",
    onRequirementMet = async () => {},
    onRequirementUnmet = async () => {},
    onLeaveCompleted = async () => {}
  }: {
    onLeaveStart: () => Promise<void>;
    requirementToLeave: () => Promise<boolean>;
    timeoutSec?: number;
    confirmationMessageAfterTimeout?: string;
    onRequirementMet?: () => Promise<void>;
    onRequirementUnmet?: () => Promise<void>;
    onLeaveCompleted?: () => Promise<void>;
  }) {
    this.onLeaveStart = onLeaveStart;
    this.requirementToLeave = requirementToLeave;
    this.timeoutSec = timeoutSec;
    this.confirmationMessageAfterTimeout = confirmationMessageAfterTimeout;
    this.onRequirementMet = onRequirementMet;
    this.onRequirementUnmet = onRequirementUnmet;
    this.onLeaveCompleted = onLeaveCompleted;
  }

  /**
   * page-leaveしようとする。
   * （実際にはこの処理がページ遷移を発生させるわけではなく、ページ遷移して良いかどうかをbooleanで返すだけ。）
   * beforeRouteUpdateや、beforeRouteLeaveから呼び出される想定。
   */
  async tryLeave(this: this): Promise<boolean> {
    const functionNumber = ++this.numFunctionCalls;

    // page-leave開始前処理を行う。
    await this.onLeaveStart();

    // 最初から条件が満たされていれば、すぐに完了する。
    if (await this.requirementToLeave()) {
      await this.onRequirementMet();

      // 最後のtryLeave呼び出しであれば、trueを返す。最後以外なら問答無用でfalseを返す。
      return this.finishLeave(functionNumber);
    }

    // 条件が満たされていなければ、タイムアウトまで待つ。条件が満たされたか、0.5秒ごとにチェックする。
    for (let i = 0; i < this.timeoutSec * 2; i++) {
      await delay(500);
      if (await this.requirementToLeave()) {
        await this.onRequirementMet();

        // 条件が満たされれば、最後のtryLeave呼び出しであれば、trueを返す。最後以外なら問答無用でfalseを返す。
        return this.finishLeave(functionNumber);
      }
    }

    // 条件が満たされないままタイムアウトした。
    await this.onRequirementUnmet();

    // 最後のtryLeave呼び出しであれば、次へ進む。最後以外なら問答無用でfalseを返す。
    if (!this.isLastActiveFunctionCall(functionNumber)) return false;

    // 「条件が満たされていないが、良いか？」とユーザーに訊いて、ダメならfalseを返す。
    const ok = window.confirm(this.confirmationMessageAfterTimeout);
    if (!ok) return false;

    // 最後のtryLeave呼び出しであれば、trueを返す。最後以外なら問答無用でfalseを返す。
    return this.finishLeave(functionNumber);
  }

  private async finishLeave(this: this, functionNumber: number): Promise<boolean> {
    // 最後のtryLeave呼び出しであれば、trueを返す。最後以外なら問答無用でfalseを返す。
    const isLast = this.isLastActiveFunctionCall(functionNumber);
    if (!isLast) return false;

    await this.onLeaveCompleted();
    return true;
  }

  private isLastActiveFunctionCall(this: this, functionNumber: number) {
    return this.numFunctionCalls === functionNumber;
  }
}
