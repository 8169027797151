



















import Vue from "vue";

export default Vue.extend({
  name: "LoadingBlock",
  props: {
    color: { type: String, default: "#9f9f9f" }
  },
  computed: {
    styles() {
      return {
        "--color": this.color
      };
    }
  }
});
