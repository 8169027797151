






































import Vue, { PropType } from "vue";
import { RecentStudy } from "@/ts/objects/common/RecentStudy";
import TipBlock from "@/components/TipBlock.vue";
import { Quarter } from "@/ts/objects/common/Quarter";

type ActionObject = {
  text: string;
  cssClass: string;
  linkPath: string;
};

export default Vue.extend({
  name: "RecentStudyItem",
  components: { TipBlock },
  props: {
    recentStudy: { type: Object as PropType<RecentStudy>, required: true },
    currentQuarter: { type: Object as PropType<Quarter>, required: true },
    teacherBasePath: { type: String, required: true },
    curriculumDefaultPath: { type: String, required: true }
  },
  computed: {
    actionObject(): ActionObject {
      switch (this.recentStudy.objectService()) {
        case "curriculum":
          return {
            text: "教科学習",
            cssClass: "curriculum",
            linkPath: this.curriculumDefaultPath
          };
        case "project":
          return {
            text: "プロジェクト学習",
            cssClass: "project",
            linkPath: `${this.teacherBasePath}/project/${this.currentQuarter.schoolYear}/${this.currentQuarter.quarter}/init`
          };
        case "solan":
          return {
            text: "SOLAN学習",
            cssClass: "solan",
            linkPath: `${this.teacherBasePath}/solan`
          };
        default:
          return {
            text: "",
            cssClass: "",
            linkPath: ""
          };
      }
    }
  }
});
