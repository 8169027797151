


















import Vue from "vue";

export default Vue.extend({
  name: "ToggleButton",
  props: {
    active: { type: Boolean, default: false },
    inactiveText: { type: String, default: "inactive" },
    activeText: { type: String, default: "active" },
    height: { type: Number, default: 35 },
    width: { type: Number, default: 100 },
    fontSize: { type: Number, default: 18 },
    disabled: { type: Boolean, default: false }
  },
  mounted() {
    this.emit();
  },
  data() {
    return {
      isActive: this.active
    };
  },
  computed: {
    styles(): any {
      return {
        "--height": `${this.height}px`,
        "--width": `${this.width}px`,
        "--font-size": `${this.fontSize}px`
      };
    }
  },
  methods: {
    toggle() {
      if (this.disabled) return;
      this.isActive = !this.isActive;
      this.emit();
    },
    emit() {
      if (this.disabled) return;
      this.$emit("input", this.isActive);
    }
  }
});
