import { Content as ContentResp } from "@/ts/api/curriculum-service";
import { isNullish, MonthValue } from "@/ts/utils";
import { NECContentMonth, NECContentMonthTree } from "@/ts/objects/curriculum/value/NECContentMonth";
import { INECContent, INECContentTree } from "@/ts/objects/curriculum/INECContent";

/**
 * 数値評価教科の内容構成。
 *
 * リソース名は /neCurriculums/{necId}/viewPoints/{viewPointId}/contents/{contentId}。
 */
export class NECContent implements INECContent {
  /**
   * コンストラクタ。
   *
   * @param necId
   * @param viewPointId
   * @param contentId
   * @param name
   */
  constructor(
    public readonly necId: string,
    public readonly viewPointId: string,
    public readonly contentId: string,
    public readonly name: string
  ) {}

  get resourceName(): string {
    return `/neCurriculums/${this.necId}/viewPoints/${this.viewPointId}/contents/${this.contentId}`;
  }

  static fromContentResp(resp: ContentResp): NECContent {
    return new NECContent(resp.necId, resp.viewPointId, resp.contentId, resp.name.value);
  }
}

export class NECContentTree implements INECContentTree {
  /**
   * コンストラクタ。
   *
   * @param self
   * @param months 必ず、4月～3月の順番に、12個あること。
   */
  constructor(public readonly self: NECContent, public readonly months: NECContentMonthTree[]) {
    this.enabledMonths = months.filter(m => m.self.enabled).map(m => m.self.month);
    this._valueToMonth = Object.fromEntries(months.map(m => [m.self.month, m]));
  }

  readonly enabledMonths: MonthValue[];
  private readonly _valueToMonth: Record<string, NECContentMonthTree>;

  getMonth(month: number | null | undefined): NECContentMonth | null {
    if (isNullish(month)) return null;
    return this._valueToMonth[month.toString(10)]?.self ?? null;
  }
}
