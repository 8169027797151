import { render, staticRenderFns } from "./CurriculumReadMenu.vue?vue&type=template&id=2be570ad&scoped=true&"
import script from "./CurriculumReadMenu.vue?vue&type=script&lang=ts&"
export * from "./CurriculumReadMenu.vue?vue&type=script&lang=ts&"
import style0 from "./CurriculumReadMenu.vue?vue&type=style&index=0&id=2be570ad&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2be570ad",
  null
  
)

export default component.exports