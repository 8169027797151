
























































































import Vue, { PropType } from "vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import AutoResizeTextarea from "@/components/AutoResizeTextarea.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { EditableSolanLookback } from "@/ts/objects/solan/editable/EditableSolanLookback";
import { messages } from "@/ts/const/Messages";
import { SolanStoreT } from "@/store/SolanStore";
import { Err } from "@/ts/objects/Err";
import { emptySaveResult, SaveResult } from "@/ts/objects/editable/SaveResult";
import TipBlock from "@/components/TipBlock.vue";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { UserRepository } from "@/ts/repositories/UserRepository";

export default Vue.extend({
  name: "SolanStudentViewLookbackT",
  components: { LoadingBlock, MessageView, AutoResizeTextarea, TipBlock },
  props: {
    solanStoreT: { type: Object as PropType<SolanStoreT>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true }
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave
    });
    this.reloadData();
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      this.reloadData();
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;
    lookback: EditableSolanLookback | null;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,
      lookback: null,

      pageLeaveService: null
    };
  },
  computed: {
    needSave(): boolean {
      return this.lookback?.needSave() ?? false;
    }
  },
  methods: {
    async reloadData() {
      this.lookback = null;
      this.messageView = null;

      const studentUserId = this.$route.params["solanStudentUserId"];
      if (studentUserId === null || studentUserId === undefined) {
        this.messageView = { message: messages.pleaseSelectStudent };
        return;
      }

      await this.solanStoreT.reloadProjectsIfStudentChanged({
        userRepository: this.userRepository,
        solanRepository: this.solanRepository,
        studentUserId
      });

      const projectId = this.$route.params["solanProjectId"];
      if (projectId === null || projectId === undefined) {
        this.messageView = { message: messages.pleaseSelectSolanTheme };
        return;
      }

      const project = await this.solanStoreT.getProject(projectId).getDataWithTimeout();
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectSolanTheme };
        return;
      }

      if (!project.started) {
        this.messageView = { message: messages.solanThemeNotStarted };
        return;
      }

      const resp = await this.solanRepository.getEditableSolanLookback(project.projectId, true, false, false);
      if (resp instanceof Err) {
        log.debug(`Error loading lookbacks: ${JSON.stringify(resp)}`);
        this.messageView = { message: messages.failedToLoadData };
        return;
      }

      this.lookback = resp;
    },
    async saveAll(force: boolean): Promise<SaveResult> {
      log.debug("SAVING!");
      return (await this.lookback?.saveAllChanges(force)) ?? emptySaveResult();
    }
  }
});
