

















import Vue, { PropType } from "vue";
import { getDataWithTimeout, Loadable, LoadableIdle } from "@/ts/Loadable";
import { EECurriculumTree } from "@/ts/objects/curriculum/value/EECurriculum";
import CurriculumWriteEECPure from "@/views/curriculum/student/CurriculumWriteEEC/CurriculumWriteEECPure.vue";
import log from "loglevel";
import debounce from "lodash/debounce";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import { EditableEECJournalTree } from "@/ts/objects/curriculum/editable/EditableEECJournal";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import { Err } from "@/ts/objects/Err";
import { isNullish, MonthValue } from "@/ts/utils";
import { NavigationGuardNext, Route } from "vue-router";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { CurriculumStoreS } from "@/store/CurriculumStoreS";

export default Vue.extend({
  name: "CurriculumWriteEEC",
  components: { CurriculumWriteEECPure },
  props: {
    curriculumStoreS: { type: Object as PropType<CurriculumStoreS>, required: true },
    curriculumRepository: { type: Object as PropType<CurriculumRepository>, required: true }
  },
  async created() {
    log.debug(`CurriculumWriteEEC:created: Started.`);

    this.debouncedSaveAll = debounce(() => this.saveAll(false), 3000);
    this.periodicSaverId = window.setInterval(() => {
      if (!this.needSave) return;
      this.debouncedSaveAll();
    }, 10000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave,
      onLeaveCompleted: async () => this.curriculumStoreS.loadJournals(this.curriculumRepository)
    });

    this.thisMonth = this.curriculumStoreS.thisMonth;

    const eecTree = await getDataWithTimeout(() => this.curriculumStoreS.eeCurriculumTree, 10);
    const studentUserId = this.curriculumStoreS.studentUserId;

    if (isNullish(eecTree) || isNullish(studentUserId)) {
      return;
    }

    this.eeCurriculumTree = eecTree;
    this.studentUserId = studentUserId;

    const resp = await this.curriculumRepository.listEditableEECJournals(
      true,
      false,
      eecTree.self.eecId,
      undefined,
      studentUserId,
      undefined,
      undefined
    );
    if (resp instanceof Err) {
      log.error(`CurriculumWriteEEC:created: Error fetching journals: ${resp.internalMessage}`);
      return;
    }

    this.journalTrees = Loadable.fromValue(resp);

    log.debug(`CurriculumWriteEEC:created: Completed.`);
  },
  async beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    log.debug(`CurriculumWriteEEC:beforeRouteUpdate: Started.`);
    const ok = await this.pageLeaveService!.tryLeave();
    if (!ok) {
      next(false);
      return;
    }
    next();
  },
  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    log.debug(`CurriculumWriteEEC:beforeRouteLeave: Started.`);
    const ok = await this.pageLeaveService!.tryLeave();
    if (!ok) {
      next(false);
      return;
    }
    next();
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    eeCurriculumTree: EECurriculumTree | null;
    studentUserId: string | null;
    thisMonth: MonthValue;

    /**
     * 現在表示中の教科・児童生徒配下の学習記録。
     */
    journalTrees: Loadable<EditableEECJournalTree[]>;

    /**
     * 現在ファイルビューを開いている学習記録のリソース名。
     */
    filesViewJournal: string | null;

    debouncedSaveAll: any;
    periodicSaverId: number | undefined;

    currentlyCreatingJournal: boolean;
    currentlyDeletingJournal: boolean;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      eeCurriculumTree: null,
      studentUserId: null,
      thisMonth: undefined!,

      journalTrees: new LoadableIdle(),
      filesViewJournal: null,

      debouncedSaveAll: undefined,
      periodicSaverId: undefined,

      currentlyCreatingJournal: false,
      currentlyDeletingJournal: false,

      pageLeaveService: null
    };
  },
  computed: {
    eecId(): string | null {
      return this.eeCurriculumTree?.self.eecId ?? null;
    },
    needSave(): boolean {
      const journalTrees = this.journalTrees.data;
      if (journalTrees === null) return false;
      return journalTrees.map(jt => jt.self).some(j => j.needSave());
    }
  },
  methods: {
    async saveAll(force: boolean): Promise<SaveResult> {
      const journalTrees = this.journalTrees.data;
      if (journalTrees === null) return emptySaveResult();

      log.debug(`CurriculumWriteEEC:saveAll: force=${force}`);

      const saveResults = await Promise.all(journalTrees.map(jt => jt.self.saveAllChanges(force)));
      return flattenSaveResults(saveResults);
    },

    /**
     * 現在ファイルビューを開いている学習記録を変更する。
     * 閉じる場合はnullを渡す。
     */
    selectFilesViewJournal(rname: string | null) {
      log.debug(`selectFilesViewJournal: rname=${rname}`);
      this.filesViewJournal = rname;
    },
    onInputMonth(journalId: string, value: MonthValue) {
      const journal = findJournal(this.journalTrees, this.eecId, this.studentUserId, journalId);
      if (journal === null) return;

      journal.self.month = value;
      this.debouncedSaveAll();
    },
    onInputActivity(journalId: string, value: string) {
      const journal = findJournal(this.journalTrees, this.eecId, this.studentUserId, journalId);
      if (journal === null) return;

      journal.self.activity = value;
      this.debouncedSaveAll();
    },
    onInputStudentComment(journalId: string, value: string) {
      const journal = findJournal(this.journalTrees, this.eecId, this.studentUserId, journalId);
      if (journal === null) return;

      journal.self.studentComment = value;
      this.debouncedSaveAll();
    },
    async onUploadJournalFile(journalId: string, file: any) {
      const journal = findJournal(this.journalTrees, this.eecId, this.studentUserId, journalId);
      if (journal === null) return;

      const resp = await this.curriculumRepository.uploadEECJournalFile(
        journal.self.eecId,
        journal.self.studentUserId,
        journal.self.journalId,
        file,
        30000
      );
      if (resp instanceof Err) {
        // TODO エラーハンドリング？
        log.error(`CurriculumWriteEEC:onUploadJournalFile: ${resp.internalMessage}`);
        return;
      }

      await journal.reloadJournalFiles();
    },
    async onDeleteJournalFile(journalId: string, journalFileId: string) {
      const journal = findJournal(this.journalTrees, this.eecId, this.studentUserId, journalId);
      if (journal === null) return;

      const resp = await this.curriculumRepository.deleteEECJournalFile(
        journal.self.eecId,
        journal.self.studentUserId,
        journal.self.journalId,
        journalFileId
      );
      if (resp instanceof Err) {
        // TODO エラーハンドリング？
        log.error(`CurriculumWriteEEC:onDeleteJournalFile: ${resp.internalMessage}`);
        return;
      }

      await journal.reloadJournalFiles();
    },
    async onCreateJournal() {
      const eecId = this.eecId;
      const studentUserId = this.studentUserId;
      if (eecId === null || studentUserId === null) return null;

      const journals = this.journalTrees.data;
      if (journals === null) return;

      if (this.currentlyCreatingJournal) {
        log.debug("CurriculumWriteEEC:onCreateJournal: Already creating.");
        return;
      }

      this.currentlyCreatingJournal = true;

      const resp = await this.curriculumRepository.postAndGetEditableEECJournal(true, false, eecId, studentUserId, {
        month: this.thisMonth
      });

      this.currentlyCreatingJournal = false;

      if (resp instanceof Err) {
        log.error(`CurriculumWriteEEC:onCreateJournal: Error creating journal: ${resp.internalMessage}`);
        // TODO エラーハンドリング？
        return;
      }

      this.journalTrees = Loadable.fromValue([...journals, resp]);
    },
    async onDeleteJournal(journalId: string): Promise<boolean> {
      const journals = this.journalTrees.data;
      if (journals === null) return false;

      const journal = findJournal(this.journalTrees, this.eecId, this.studentUserId, journalId);
      if (journal === null) return false;

      const journalRname = journal.self.resourceName;

      log.debug(`CurriculumWriteEEC:onDeleteJournal: journalRname=${journalRname}`);

      if (!window.confirm("入力したものがすべて消えます。消しますか？")) {
        return false;
      }

      const resp = await this.curriculumRepository.deleteEECJournal(
        journal.self.eecId,
        journal.self.studentUserId,
        journal.self.journalId
      );
      if (resp instanceof Err) {
        log.error(`CurriculumWriteEEC:onDeleteJournal: Error deleting journal: ${resp.internalMessage}`);
        // TODO エラーハンドリング？
        return false;
      }

      this.journalTrees = Loadable.fromValue(journals.filter(j => j.self.resourceName !== journalRname));
      return true;
    }
  }
});

function findJournal(
  journalTrees: Loadable<EditableEECJournalTree[]>,
  eecId: string | null,
  studentUserId: string | null,
  journalId: string
): EditableEECJournalTree | null {
  if (eecId === null || studentUserId === null) return null;

  const journals = journalTrees.data;
  if (journals === null) return null;

  return (
    journals.find(
      j => j.self.eecId === eecId && j.self.studentUserId === studentUserId && j.self.journalId === journalId
    ) ?? null
  );
}
