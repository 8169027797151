









import Vue, { PropType } from "vue";
import { NavigationGuardNext, Route } from "vue-router";
import { MessageViewParam } from "@/components/MessageView.vue";
import { messages } from "@/ts/const/Messages";
import { Err } from "@/ts/objects/Err";
import { emptySaveResult, SaveResult } from "@/ts/objects/editable/SaveResult";
import { EditableSolanLookback } from "@/ts/objects/solan/editable/EditableSolanLookback";
import { SolanStoreS } from "@/store/SolanStore";
import { AppStateStore } from "@/store/AppStateStore";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import SolanLookbackPure from "@/views/solan/student/SolanLookback/SolanLookbackPure.vue";
import { SolanRepository } from "@/ts/repositories/SolanRepository";

export default Vue.extend({
  name: "SolanLookback",
  components: { SolanLookbackPure },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    solanStoreS: { type: Object as PropType<SolanStoreS>, required: true },
    solanRepository: { type: Object as PropType<SolanRepository>, required: true }
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave
    });
    this.reloadData();
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      this.reloadData();
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;
    lookback: EditableSolanLookback | null;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,
      lookback: null,

      pageLeaveService: null
    };
  },
  computed: {
    needSave(): boolean {
      return this.lookback?.needSave() ?? false;
    }
  },
  methods: {
    async reloadData() {
      this.lookback = null;
      this.messageView = null;

      const project = await this.solanStoreS.project.getDataWithTimeout();
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectSolanTheme };
        return;
      }

      if (!project.started) {
        this.messageView = { message: messages.solanThemeNotStarted };
        return;
      }

      const resp = await this.solanRepository.getEditableSolanLookback(
        project.projectId,
        false,
        this.appStateStore.isAppUserStudent,
        this.appStateStore.isAppUserGuardian
      );
      if (resp instanceof Err) {
        log.info(`Error loading lookbacks: ${JSON.stringify(resp)}`);
        this.messageView = { message: messages.failedToLoadData };
        return;
      }

      this.lookback = resp;
      this.messageView = null;
    },
    async saveAll(force: boolean): Promise<SaveResult> {
      log.debug("SAVING!");
      return (await this.lookback?.saveAllChanges(force)) ?? emptySaveResult();
    }
  }
});
