














import Vue, { PropType } from "vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import CurriculumMenuT from "@/views/curriculum/teacher/CurriculumMenuT/CurriculumMenuT.vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { CurriculumStoreT } from "@/store/CurriculumStoreT";
import { messages } from "@/ts/const/Messages";

/**
 * 教師用教科学習画面のうち、一覧画面(list), シラバス画面(syllabus)のレイアウト。
 */
export default Vue.extend({
  name: "CurriculumMainT",
  components: {
    LoadingBlock,
    CurriculumMenuT,
    MessageView
  },
  props: {
    curriculumStoreT: { type: Object as PropType<CurriculumStoreT>, required: true }
  },
  computed: {
    messageView(): MessageViewParam | null {
      const store = this.curriculumStoreT;
      if (store.classId === null || store.tab === null) return { message: messages.pageNotFound };
      return null;
    }
  }
});
