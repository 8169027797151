import { Class, ClassStudent } from "@/ts/objects/common/Class";
import { Grade } from "@/ts/objects/common/Grade";

export type UserTestDataSet = {
  readonly classes: Record<string, Class>;
};

export function getUserTestData(): UserTestDataSet {
  return {
    classes: {
      class000: new Class("class000", 2000, new Grade("e1"), "A", 0, [
        new ClassStudent("student000", 2, "瀬戸 空治郎", "iconurl"),
        new ClassStudent("student001", 1, "山口 美津枝", "iconurl"),
        new ClassStudent("student002", 3, "瀬戸瀬戸 空治郎空治郎", "iconurl"),
        new ClassStudent("student003", 24, "瀬戸 空治郎2", "iconurl")
      ]),
      class001: new Class("class001", 1999, new Grade("e1"), "B", 1, [
        new ClassStudent("student000", 2, "瀬戸 空治郎", "iconurl"),
        new ClassStudent("student001", 1, "山口 美津枝", "iconurl"),
        new ClassStudent("student002", 3, "瀬戸瀬戸 空治郎空治郎", "iconurl"),
        new ClassStudent("student003", 24, "瀬戸 空治郎2", "iconurl")
      ]),
      class002: new Class("class002", 2000, new Grade("j2"), "C", 2, [
        new ClassStudent("student000", 2, "瀬戸 空治郎", "iconurl"),
        new ClassStudent("student001", 1, "山口 美津枝", "iconurl"),
        new ClassStudent("student002", 3, "瀬戸瀬戸 空治郎空治郎", "iconurl"),
        new ClassStudent("student003", 24, "瀬戸 空治郎2", "iconurl")
      ])
    }
  };
}
