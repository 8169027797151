





















import Vue, { PropType } from "vue";
import { NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { Loadable } from "@/ts/Loadable";
import { CurriculumPeriodMode } from "@/ts/objects/curriculum/value/CurriculumPeriodMode";
import log from "loglevel";
import TipBlock from "@/components/TipBlock.vue";
import { INECEvaluation } from "@/ts/objects/curriculum/INECEvaluation";
import { MonthValue } from "@/ts/utils";
import { NECContentMonth } from "@/ts/objects/curriculum/value/NECContentMonth";

export default Vue.extend({
  name: "CurriculumReadMenuNECItem",
  components: { TipBlock },
  props: {
    /**
     * 月別表示/年間表示。
     */
    periodMode: { type: String as PropType<CurriculumPeriodMode>, required: true },

    /**
     * 選択中の月。
     */
    selectedMonth: { type: Number as PropType<MonthValue | null | undefined> },

    /**
     * 数値評価教科。
     */
    neCurriculumTree: { type: Object as PropType<NECurriculumTree>, required: true },

    /**
     * 現在表示中の児童生徒配下の、 **全教科の** 評価。
     */
    allEvaluations: { type: Object as PropType<Loadable<INECEvaluation[]>>, required: true },

    /**
     * 選択中の教科のリソース名。
     * 数値評価教科でも文書評価教科でもありうる。
     */
    selectedCurriculum: { type: String as PropType<string | null | undefined> },

    /**
     * 教科学習のパスを取得する関数。
     */
    getPath: {
      type: Function as PropType<
        (
          periodMode: CurriculumPeriodMode | null,
          month: MonthValue | null | undefined,
          resourceName: string | null
        ) => string
      >,
      required: true
    }
  },
  computed: {
    /**
     * このタブ(教科)が選択状態ならtrue。
     */
    selected(): boolean {
      return this.selectedCurriculum === this.neCurriculumTree.self.resourceName;
    },
    /**
     * 評価対象の月のリスト。
     * このリストの要素が1件以上で、すべてが評価済であるとき、「評価済」マークを表示する。
     */
    monthsToEvaluated(): NECContentMonth[] {
      const enabledMonths = this.neCurriculumTree.viewPoints
        .flatMap(v => v.contents)
        .flatMap(c => c.months)
        .map(m => m.self)
        .filter(m => m.enabled);
      if (this.periodMode === "monthly") {
        const m = enabledMonths.filter(m => m.month === this.selectedMonth);
        log.debug(`monthsToEvaluated: ${JSON.stringify(m)}`);
        return m;
      } else {
        return enabledMonths;
      }
    },
    /**
     * この数値評価教科の評価のうち、評価済である(空文字列以外が入っている)ものの一覧。
     * teacherInputPublishedかどうかまでは見ない。
     * (公開前のが評価済として取得できていたら、その時点でおかしいので、ここではわざわざ確認しない。)
     */
    nonEmptyEvaluationsOfNEC(): INECEvaluation[] | null {
      const allEvaluations = this.allEvaluations.data;
      if (allEvaluations === null) return null;

      return allEvaluations.filter(e => e.necId === this.neCurriculumTree.self.necId && e.rating !== "");
    },
    /**
     * 評価対象の月が、すべて評価済ならtrue。
     * ※ ただし、これがtrueでも、そもそも評価対象が0件なら「評価済」マークを付けない。
     */
    allEvaluated(): boolean {
      const evaluated = this.nonEmptyEvaluationsOfNEC;
      if (evaluated === null) return false;

      return this.monthsToEvaluated.every(m => evaluated.some(ev => ev.contentMonthResourceName === m.resourceName));
    }
  }
});
