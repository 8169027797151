

















import Vue, { PropType } from "vue";
import CurriculumSyllabusEECPure from "./CurriculumSyllabusEECPure.vue";
import { CurriculumStoreT } from "@/store/CurriculumStoreT";
import { CurriculumRepository } from "@/ts/repositories/CurriculumRepository";
import log from "loglevel";
import debounce from "lodash/debounce";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { arrayMoveOne, downloadBlob, isNullish, MonthValue, monthValues } from "@/ts/utils";
import { getDataWithTimeout, Loadable } from "@/ts/Loadable";
import { CurriculumSyllabusEECModel } from "@/views/curriculum/teacher/CurriculumSyllabusEEC/CurriculumSyllabusEECModel";
import { QuarterSwitchValue, quarterSwitchValueToMonths } from "@/components/QuarterSwitch/QuarterSwitch.vue";
import { EditableEECActivity } from "@/ts/objects/curriculum/editable/EditableEECActivity";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import { Err } from "@/ts/objects/Err";
import { NavigationGuardNext, Route } from "vue-router";
import { ErrorNotificationParam } from "@/components/ErrorNotification.vue";
import { unparse } from "papaparse";
import { format } from "date-fns";

// TODO 追加・削除時のエラーを表示する。

export default Vue.extend({
  name: "CurriculumSyllabusEEC",
  components: { CurriculumSyllabusEECPure },
  props: {
    curriculumStoreT: { type: Object as PropType<CurriculumStoreT>, required: true },
    curriculumRepository: { type: Object as PropType<CurriculumRepository>, required: true }
  },
  async created() {
    log.debug(`CurriculumSyllabusEEC:created: Started.`);

    this.debouncedUpdateNeedSave = debounce(this.updateNeedSave, 500);
    this.debouncedUpdateCurrentErrors = debounce(this.updateCurrentErrors, 500);
    this.debouncedSaveAll = debounce(() => this.saveAll(false), 3000);
    this.periodicSaverId = window.setInterval(() => {
      if (!this.needSave) return;
      this.debouncedSaveAll();
    }, 10000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
        this.updateNeedSave(); // この行が無いと、needSaveがdebounceにより未更新である場合、requirementToLeaveを一瞬で通ってしまう。
      },
      requirementToLeave: async () => !this.needSave,
      onRequirementUnmet: async () => {
        // TODO highlightUnsaved?
        // this.highlightUnsaved = true;
      },
      onLeaveCompleted: async () => {
        const eecId = this.eecId;
        if (isNullish(eecId)) return;
        await this.curriculumStoreT.loadACurriculum({
          curriculumRepository: this.curriculumRepository,
          resourceName: `/eeCurriculums/${eecId}`
        });
      }
    });

    this.model = Loadable.loading();

    const eecTree = await getDataWithTimeout(() => this.curriculumStoreT.eeCurriculumTree, 10);
    if (eecTree === null) {
      log.error(`CurriculumSyllabusEEC:created: eecTree is null.`);
      this.model = Loadable.error();
      return;
    }

    const resp = await this.curriculumRepository.listEditableEECActivities(true, eecTree.self.eecId);
    if (resp instanceof Err) {
      log.error(`CurriculumSyllabusEEC:created: Error fetching eecActivities: ${resp.internalMessage}`);
      this.model = Loadable.error();
      return;
    }

    const eecId = eecTree.self.eecId;

    const model: CurriculumSyllabusEECModel = {
      eecId,
      syllabusFileGcsObjectPath: eecTree.self.syllabusFileGcsObjectPath,
      eecName: eecTree.self.name,
      months: monthValues.map(month => ({
        month,
        activities: resp.filter(a => a.eecId === eecId && a.month === month)
      }))
    };
    this.model = Loadable.fromValue(model);
  },
  async beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    const ok = await this.pageLeaveService!.tryLeave();
    if (!ok) {
      next(false);
      return;
    }
    next();
  },
  async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    const ok = await this.pageLeaveService!.tryLeave();
    if (!ok) {
      next(false);
      return;
    }
    next();
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    model: Loadable<CurriculumSyllabusEECModel>;

    quarterSwitchValue: QuarterSwitchValue;

    currentlyCreatingActivity: boolean;
    currentlyDeletingActivity: boolean;

    needSave: boolean;
    currentErrors: ErrorNotificationParam[];

    debouncedUpdateNeedSave: any;
    debouncedUpdateCurrentErrors: any;
    debouncedSaveAll: any;
    periodicSaverId: number | undefined;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      model: Loadable.idle(),

      quarterSwitchValue: "all",

      currentlyCreatingActivity: false,
      currentlyDeletingActivity: false,

      needSave: false,
      currentErrors: [],

      debouncedUpdateNeedSave: undefined,
      debouncedUpdateCurrentErrors: undefined,
      debouncedSaveAll: undefined,
      periodicSaverId: undefined,

      pageLeaveService: null
    };
  },
  computed: {
    modelData(): CurriculumSyllabusEECModel | null {
      return this.model.data;
    },
    eecId(): string | null {
      return this.modelData?.eecId ?? null;
    },
    allActivities(): EditableEECActivity[] | null {
      return this.modelData?.months.flatMap(m => m.activities) ?? null;
    }
  },
  watch: {
    allActivities: {
      handler: function() {
        log.debug(`CurriculumSyllabusEEC:watch:allActivities`);
        this.debouncedUpdateNeedSave();
        this.debouncedUpdateCurrentErrors();
      },
      deep: true
    }
  },
  methods: {
    updateNeedSave() {
      const allActivities = this.allActivities;
      if (allActivities === null) {
        this.needSave = false;
        return;
      }

      this.needSave = allActivities.some(a => a.needSave());
    },
    updateCurrentErrors() {
      const allActivities = this.allActivities;
      if (allActivities === null) {
        this.currentErrors = [];
        return;
      }

      this.currentErrors = allActivities.flatMap(activity =>
        activity.currentErrors().map(err => ({
          heading: `${activity.month}月`,
          text: err.message
        }))
      );
    },
    async saveAll(force: boolean): Promise<SaveResult> {
      const allActivities = this.allActivities;
      if (allActivities === null) return emptySaveResult();

      log.debug(`CurriculumSyllabusEEC: SAVING!`);

      const saveResults = await Promise.all(allActivities.map(a => a.saveAllChanges(force)));
      return flattenSaveResults(saveResults);
    },
    onInputQuarterSwitch(value: QuarterSwitchValue) {
      this.quarterSwitchValue = value;
    },
    async uploadSyllabusFile(file: File) {
      const model = this.modelData;
      if (model === null) return;

      const resp = await this.curriculumRepository.uploadEECSyllabusFile(model.eecId, file, 30000);
      log.debug(`CurriculumSyllabusEEC:uploadSyllabusFile: resp=${JSON.stringify(resp)}`);
      if (resp instanceof Err) {
        // TODO エラーハンドリング？
        log.error(`CurriculumSyllabusEEC:uploadSyllabusFile: Error uploading syllabus file: ${resp.internalMessage}`);
        return;
      }
      model.syllabusFileGcsObjectPath = resp.syllabusFileGcsObjectPath ?? null;
    },
    onInputText(month: MonthValue, activityId: string, value: string) {
      const activity = getActivity(this.modelData, month, activityId);
      if (activity === null) return;

      activity.text = value;

      this.debouncedSaveAll();
    },
    onInputEnabled(month: MonthValue, activityId: string, value: boolean) {
      const activity = getActivity(this.modelData, month, activityId);
      if (activity === null) return;

      activity.enabled = value;

      this.debouncedSaveAll();
    },
    async moveActivity(month: MonthValue, activityId: string, up: boolean) {
      const model = this.modelData;
      if (model === null) return;

      log.debug(`CurriculumSyllabusEEC:moveActivity: month=${month}, activityId=${activityId}, up=${up}`);

      const monthCol = model.months.find(m => m.month === month);
      if (monthCol === undefined) return;

      const activity = monthCol.activities.find(a => a.activityId === activityId);
      if (activity === undefined) return;

      const sorted = arrayMoveOne(monthCol.activities, activity, up);
      monthCol.activities = sorted;

      const resp = await this.curriculumRepository.sortEECActivities(
        model.eecId,
        month,
        sorted.map(a => a.activityId)
      );
      if (resp instanceof Err) {
        log.error(`CurriculumSyllabusEEC:moveActivity: Error sorting activities: ${resp.internalMessage}`);
        return;
      }

      this.debouncedSaveAll();
    },
    async createActivity(month: MonthValue) {
      if (this.currentlyCreatingActivity) {
        log.debug(`CurriculumSyllabusEEC:createActivity: Already creating activity!`);
        return;
      }

      const model = this.modelData;
      if (model === null) return;

      const monthModel = model.months.find(m => m.month === month);
      if (isNullish(monthModel)) return;

      this.currentlyCreatingActivity = true;

      const resp = await this.curriculumRepository.postAndGetEditableEECActivity(true, model.eecId, month, {});
      if (resp instanceof Err) {
        log.error(`Error creating eecActivity: ${resp.internalMessage}`);
        this.currentlyCreatingActivity = false;
        return;
      }

      monthModel.activities = [...monthModel.activities, resp];

      this.currentlyCreatingActivity = false;

      this.debouncedSaveAll();
    },
    async deleteActivity(month: MonthValue, activityId: string) {
      log.debug(`CurriculumSyllabusEEC:deleteActivity: month=${month}, activityId=${activityId}`);
      if (this.currentlyDeletingActivity) {
        log.debug(`CurriculumSyllabusEEC:deleteActivity: Already deleting activity!`);
        return;
      }

      const model = this.modelData;
      if (model === null) return;

      const monthModel = model.months.find(m => m.month === month);
      if (isNullish(monthModel)) return;

      const activity = monthModel.activities.find(a => a.activityId === activityId);
      if (isNullish(activity)) return;

      if (!window.confirm("この学習活動を削除してよろしいですか？")) return;

      this.currentlyDeletingActivity = true;

      const resp = await this.curriculumRepository.deleteEECActivity(model.eecId, month, activityId);
      if (resp instanceof Err) {
        log.error(`Error deleting eecActivity: ${resp.internalMessage}`);
        this.currentlyDeletingActivity = false;
        return;
      }

      monthModel.activities = monthModel.activities.filter(a => a.activityId !== activityId);

      this.currentlyDeletingActivity = false;

      this.debouncedSaveAll();
    },
    exportCsv() {
      const model = this.modelData;
      if (isNullish(model)) return;

      exportCsv(model, this.quarterSwitchValue);
    }
  }
});

function getActivity(
  model: CurriculumSyllabusEECModel | null,
  month: MonthValue,
  activityId: string
): EditableEECActivity | null {
  if (model === null) return null;

  const monthModel = model.months.find(m => m.month === month);
  if (isNullish(monthModel)) return null;

  const activity = monthModel.activities.find(a => a.activityId === activityId);
  if (isNullish(activity)) return null;

  return activity;
}

function exportCsv(model: CurriculumSyllabusEECModel, quarterSwitchValue: QuarterSwitchValue) {
  const monthsToDisplay = quarterSwitchValueToMonths(quarterSwitchValue);

  const maxColLength = Math.max(...model.months.map(m => m.activities.length));
  const rowNums = [...Array(maxColLength).keys()];

  const csvRows = rowNums.map(rowNum => {
    const monthToActivity = monthsToDisplay.map((month): [string, string] => {
      const eecMonth = model.months.find(m => m.month === month);
      if (eecMonth === undefined) {
        return [`${month}月`, ""];
      }
      const activity = eecMonth.activities[rowNum];
      if (activity === undefined || !activity.enabled) {
        return [`${month}月`, ""];
      }
      return [`${month}月`, activity.text];
    });
    return Object.fromEntries(monthToActivity);
  });
  const columnNames = monthsToDisplay.map(m => `${m}月`);

  // BOMはエクセル対策。参考: https://qiita.com/wadahiro/items/eb50ac6bbe2e18cf8813
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, unparse(csvRows, { columns: columnNames })], { type: "text/plain" });
  downloadBlob(blob, `${model.eecName}_${format(new Date(), "yyyyMMdd'T'HHmmss")}.csv`);
}
