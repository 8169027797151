
























import Vue, { PropType } from "vue";
import OpenSyllabusFileButton from "@/views/curriculum/components/OpenSyllabusFileButton/OpenSyllabusFileButton.vue";
import CurriculumTitle from "@/views/curriculum/components/CurriculumTitle/CurriculumTitle.vue";
import QuarterSwitch, { QuarterSwitchValue } from "@/components/QuarterSwitch/QuarterSwitch.vue";
import SaveStateIndicator from "@/components/SaveStateIndicator/SaveStateIndicator.vue";
import PopupMenuButton, { MenuButton, MenuItem } from "@/components/PopupMenuButton.vue";
import UploadSyllabusFileButton from "@/views/curriculum/teacher/components/UploadSyllabusFileButton/UploadSyllabusFileButton.vue";
import ErrorNotification, { ErrorNotificationParam } from "@/components/ErrorNotification.vue";

export default Vue.extend({
  name: "CurriculumSyllabusHeader",
  components: {
    SaveStateIndicator,
    QuarterSwitch,
    CurriculumTitle,
    OpenSyllabusFileButton,
    PopupMenuButton,
    UploadSyllabusFileButton,
    ErrorNotification
  },
  props: {
    curriculumName: { type: String, required: true },
    quarterSwitchValue: { type: String as PropType<QuarterSwitchValue>, required: true },
    needSave: { type: Boolean, required: true },
    currentErrors: { type: Array as PropType<ErrorNotificationParam[]>, required: true },

    /**
     * シラバスファイルの、GCSパス。
     */
    syllabusFileGcsObjectPath: { type: String },

    /**
     * シラバスファイルをアップロードする関数。
     */
    uploadSyllabusFile: { type: Function as PropType<(file: File) => Promise<void>>, required: true },

    onInputQuarterSwitch: { type: Function as PropType<(value: QuarterSwitchValue) => void>, required: true },
    exportCsv: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  data(): {
    extraMenuItems: MenuItem[];
  } {
    return {
      extraMenuItems: [new MenuButton("exportCsv", "現在の状態をCSV出力", ["fas", "download"])]
    };
  },
  methods: {
    selectExtraMenu(menuItem: string) {
      switch (menuItem) {
        case "exportCsv":
          this.exportCsv();
          return;
      }
    }
  }
});
