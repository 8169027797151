












import Vue, { PropType } from "vue";
import { format } from "date-fns";
import { fileTypeToIcon, fileTypeToJapanese } from "@/ts/utils/AppUtil";
import { JournalFile } from "@/ts/objects/common/JournalFile";

export default Vue.extend({
  name: "JournalFilePreviewItem",
  props: {
    journalFile: { type: Object as PropType<JournalFile>, required: true }
  },
  methods: {
    formatDate(date: Date): string {
      return format(date, "M/d H:mm");
    },
    fileTypeToJapanese(fileType: string): string {
      return fileTypeToJapanese(fileType);
    },
    fileTypeToIcon(fileType: string): [string, string] {
      return fileTypeToIcon(fileType);
    }
  }
});
