var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"solan-student-view-journals-t-container"},[(_vm.messageView !== null)?_c('MessageView',{attrs:{"param":_vm.messageView}}):(_vm.reversedJournals !== null)?[_c('div',{staticClass:"table",class:{ 'any-files-view-open': _vm.filesViewJournal !== null }},[_c('div',{staticClass:"col-header-row"},[_c('div',{staticClass:"col-header process-col"},[_vm._v("プロセス")]),_c('div',{staticClass:"col-header learning-activity-col"},[_vm._v("学習活動")]),_c('div',{staticClass:"col-header journal-files-col"},[_vm._v("学習の記録")]),_c('div',{staticClass:"col-header student-input-header"},[_vm._v("学習のふりかえり")]),_c('div',{staticClass:"col-header teacher-rating-col"},[_vm._v("先生評価")]),_c('div',{staticClass:"col-header teacher-comment-col"},[_vm._v("先生から")])]),_c('ol',{staticClass:"journal-rows-area"},[_vm._l((_vm.reversedJournals),function(journal){return [_c('li',{key:((journal.rubricId) + "-0"),staticClass:"journal-row"},[_c('div',{staticClass:"cell process-col cell-odd-column",class:[
                {
                  'my-files-view-open': _vm.isMyFilesViewOpen(journal.rubricId)
                },
                ("cell-of-process-" + (journal.process))
              ]},[_c('div',{staticClass:"cell-content"},[_vm._v(_vm._s(_vm._solanProcessTextOf(journal.process)))])]),_c('div',{staticClass:"cell learning-activity-col cell-even-column",class:[
                {
                  'my-files-view-open': _vm.isMyFilesViewOpen(journal.rubricId)
                },
                ("cell-of-process-" + (journal.process))
              ]},[_c('div',{staticClass:"cell-content"},[_vm._v(_vm._s(journal.rubricLearningActivity))])]),_c('div',{staticClass:"cell journal-files-col cell-odd-column",class:[
                {
                  'my-files-view-open': _vm.isMyFilesViewOpen(journal.rubricId)
                },
                ("cell-of-process-" + (journal.process))
              ]},[_c('div',{staticClass:"cell-content",on:{"click":function () { return _vm.setFilesView(_vm.isMyFilesViewOpen(journal.rubricId) ? null : journal.rubricId); }}},[_c('div',{staticClass:"journal-files-col-cell-content-main"},[(journal.journalFiles.length >= 1)?_c('div',{staticClass:"journal-file-preview-area"},[_c('div',{staticClass:"journal-files-col-cell-content-num-files"},[_vm._v(" "+_vm._s(journal.journalFiles.length)+"件のファイル ")]),_c('JournalFilePreviewItem',{staticClass:"journal-file-preview-item",attrs:{"parent-journal":journal,"journal-file":journal.journalFiles[journal.journalFiles.length - 1]}})],1):_c('div',{staticClass:"no-journal-files-yet-message"},[_vm._v("まだ記録はありません。")])])])]),_c('div',{staticClass:"cell student-comment-col cell-even-column",class:[
                {
                  'my-files-view-open': _vm.isMyFilesViewOpen(journal.rubricId)
                },
                ("cell-of-process-" + (journal.process))
              ]},[_c('DisableableAutoResizeTextarea',{staticClass:"cell-content",attrs:{"placeholder":"学習のふりかえり","maxHeight":300,"padding-horizontal":10,"padding-vertical":20,"disabled":true},model:{value:(journal.studentComment),callback:function ($$v) {_vm.$set(journal, "studentComment", $$v)},expression:"journal.studentComment"}})],1),_c('div',{staticClass:"cell student-rating-col cell-even-column",class:[
                {
                  'my-files-view-open': _vm.isMyFilesViewOpen(journal.rubricId)
                },
                ("cell-of-process-" + (journal.process))
              ]},[(journal.studentRating === '')?_c('div',{staticClass:"cell-content rating-empty"},[_vm._v("?")]):_c('div',{staticClass:"cell-content"},[_vm._v(_vm._s(journal.studentRating))])]),_c('div',{staticClass:"cell teacher-rating-col cell-odd-column",class:[
                {
                  'my-files-view-open': _vm.isMyFilesViewOpen(journal.rubricId)
                },
                ("cell-of-process-" + (journal.process))
              ]},[_c('div',{staticClass:"cell-content"},[(!journal.teacherInputPublished)?_c('TipBlock',{staticClass:"unpublished-icon-area",attrs:{"tip-message":"先生評価とコメントは非公開です。"}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":['fas', 'lock']}})],1):_vm._e(),_c('select',{directives:[{name:"model",rawName:"v-model",value:(journal.teacherRating),expression:"journal.teacherRating"}],staticClass:"rating-select",class:{ 'rating-empty': journal.teacherRating === '' },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(journal, "teacherRating", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("?")]),_c('option',[_vm._v("S")]),_c('option',[_vm._v("A")]),_c('option',[_vm._v("B")]),_c('option',[_vm._v("C")])])],1)]),_c('div',{staticClass:"cell teacher-comment-col cell-even-column",class:[
                {
                  'my-files-view-open': _vm.isMyFilesViewOpen(journal.rubricId)
                },
                ("cell-of-process-" + (journal.process))
              ]},[_c('DisableableAutoResizeTextarea',{staticClass:"cell-content",attrs:{"placeholder":"先生からのコメント","maxHeight":300,"padding-horizontal":10,"padding-vertical":20,"blur":function () { return _vm.saveAll(false); }},model:{value:(journal.teacherComment),callback:function ($$v) {_vm.$set(journal, "teacherComment", $$v)},expression:"journal.teacherComment"}})],1)]),(_vm.isMyFilesViewOpen(journal.rubricId))?_c('li',{key:((journal.rubricId) + "-1"),staticClass:"journal-files-view",class:("journal-files-view-of-process-" + (journal.process))},[(journal.journalFiles.length === 0)?_c('div',{staticClass:"journal-files-view-inner"},[_c('div',{staticClass:"journal-files-view-empty"},[_c('span',{staticClass:"journal-files-view-empty-text"},[_vm._v("まだ、学習の記録がありません。")]),_c('JournalFileUploadButton',{attrs:{"upload-fn":function () {},"parent-journal":journal,"disabled":true}})],1)]):_c('div',{staticClass:"journal-files-view-inner"},[_vm._l((journal.journalFiles),function(jf){return _c('JournalFilesViewItem',{key:jf.journalFileId,attrs:{"journal-file":jf,"readonly":true}})}),_c('JournalFileUploadButton',{key:journal.journalFiles.length,attrs:{"upload-fn":function () {},"parent-journal":journal,"disabled":true}})],2)]):_vm._e()]})],2)])]:_c('LoadingBlock')],2)}
var staticRenderFns = []

export { render, staticRenderFns }