








































































































import Vue, { PropType } from "vue";
import {
  CurriculumListEECJournalRowFilterState,
  CurriculumListEECSelectionState,
  CurriculumListEECStudentRowFilterState,
  CurriculumListEECModel
} from "@/views/curriculum/teacher/CurriculumListEEC/CurriculumListEECModel";
import FilterableHeaderButton, { CheckboxItem } from "@/components/FilterableHeaderButton/FilterableHeaderButton.vue";
import { isMonthValue, MonthValue, monthValues } from "@/ts/utils";
import CurriculumListEECStudentRow from "@/views/curriculum/teacher/CurriculumListEEC/components/CurriculumListEECStudentRow/CurriculumListEECStudentRow.vue";
import { WholeSelectionState } from "@/ts/objects/common/WholeSelectionState";
import { EmptinessFilterValue, isEmptinessFilterValue } from "@/ts/objects/common/EmptinessFilterValue";
import { isPublishStateFilterValue, PublishStateFilterValue } from "@/ts/objects/common/PublishStateFilterValue";
import { ClassStudent } from "@/ts/objects/common/Class";

export default Vue.extend({
  name: "CurriculumListEECTable",
  components: { CurriculumListEECStudentRow, FilterableHeaderButton },
  props: {
    /**
     * 児童生徒のリスト。
     */
    students: { type: Array as PropType<ClassStudent[]>, required: true },

    /**
     * ビューモデル。
     * フィルタは適用済。
     */
    model: { type: Object as PropType<CurriculumListEECModel>, required: true },

    /**
     * 児童生徒行のフィルタ状態。
     */
    studentRowFilterState: { type: Object as PropType<CurriculumListEECStudentRowFilterState>, required: true },

    /**
     * 学習記録行のフィルタ状態。
     */
    journalRowFilterState: { type: Object as PropType<CurriculumListEECJournalRowFilterState>, required: true },

    /**
     * 現在の選択状態。
     */
    selectionState: { type: Object as PropType<CurriculumListEECSelectionState>, required: true },

    /**
     * 現在、ファイルビューが開かれている学習記録のリソース名。
     */
    filesViewJournal: { type: String as PropType<string | null | undefined> },

    /**
     * 児童生徒カラムの幅（px）。
     */
    studentColWidth: { type: Number, required: true },

    /**
     * 月カラムの幅（px）。
     */
    monthColWidth: { type: Number, required: true },

    /**
     * 学習活動カラムの幅（px）。
     */
    activityColWidth: { type: Number, required: true },

    /**
     * 学習記録ファイルカラムの幅（px）。
     */
    filesColWidth: { type: Number, required: true },

    /**
     * 児童生徒コメント(学習のふりかえり)カラムの幅（px）。
     */
    studentCommentColWidth: { type: Number, required: true },

    /**
     * 教師コメント(先生から)カラムの幅（px）。
     */
    teacherCommentColWidth: { type: Number, required: true },

    /**
     * 公開状態カラムの幅（px）。
     */
    publishStateColWidth: { type: Number, required: true },

    /**
     * チェックボックスカラムの幅（px）。
     */
    checkboxColWidth: { type: Number, required: true },

    studentUserIdToStudentViewPath: { type: Function as PropType<(studentUserId: string) => string>, required: true },

    /**
     * 現在ファイルビューを開いている学習記録を変更する関数。
     */
    selectFilesViewJournal: { type: Function as PropType<(rname: string | null) => void>, required: true },

    onInputMonth: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: MonthValue) => void>,
      required: true
    },
    onInputActivity: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: string) => void>,
      required: true
    },
    onInputStudentComment: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: string) => void>,
      required: true
    },
    onInputTeacherComment: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: string) => void>,
      required: true
    },
    onUploadJournalFile: {
      type: Function as PropType<(studentUserId: string, journalId: string, file: File) => Promise<void>>,
      required: true
    },
    onDeleteJournalFile: {
      type: Function as PropType<(studentUserId: string, journalId: string, journalFileId: string) => Promise<void>>,
      required: true
    },
    onChangeSelectionState: {
      type: Function as PropType<(studentUserId: string, journalId: string, value: boolean) => void>,
      required: true
    },
    onChangeStudentRowFilterState: {
      type: Function as PropType<(filterState: CurriculumListEECStudentRowFilterState) => void>,
      required: true
    },
    onChangeJournalRowFilterState: {
      type: Function as PropType<(filterState: CurriculumListEECJournalRowFilterState) => void>,
      required: true
    },
    toggleAllSelections: {
      type: Function as PropType<(currentSelectionState: WholeSelectionState) => void>,
      required: true
    }
  },
  data(): {
    emptinessRowFilterCheckboxItems: CheckboxItem[];
    publishStateRowFilterCheckboxItems: CheckboxItem[];
  } {
    return {
      emptinessRowFilterCheckboxItems: [
        { key: "empty", label: "未入力" },
        { key: "hasValue", label: "入力済" }
      ],
      publishStateRowFilterCheckboxItems: [
        { key: "unpublished", label: "非公開" },
        { key: "published", label: "公開" }
      ]
    };
  },
  computed: {
    studentRowFilterCheckboxItems(): CheckboxItem[] {
      return this.students.map(s => ({ key: s.studentUserId, label: `${s.studentNumber}番 ${s.name}` }));
    },
    monthRowFilterCheckboxItems(): CheckboxItem[] {
      return monthValues.map(m => ({ key: m.toString(10), label: `${m}月` }));
    },
    wholeSelectionState(): WholeSelectionState {
      const journals = this.model.studentRows.flatMap(s => s.journals);
      const selectionState = this.selectionState;
      if (journals.every(j => selectionState[j.self.resourceName] === false)) return "none";
      else if (journals.every(j => selectionState[j.self.resourceName] === true)) return "all";
      else return "some";
    }
  },
  methods: {
    onStudentRowFilterChanged(event: Record<string, boolean>) {
      const studentUserIds = Object.entries(event)
        .filter(([_key, enabled]) => enabled)
        .map(([key, _enabled]) => key);
      this.onChangeStudentRowFilterState({ studentUserIds });
    },
    onMonthRowFilterChanged(event: Record<string, boolean>) {
      const months = Object.entries(event)
        .filter(([_key, enabled]) => enabled)
        .map(([key, _enabled]) => parseInt(key, 10))
        .filter((key): key is MonthValue => isMonthValue(key));
      this.onChangeJournalRowFilterState({
        ...this.journalRowFilterState,
        months
      });
    },
    onActivityRowFilterChanged(event: Record<string, boolean>) {
      this.onChangeJournalRowFilterState({
        ...this.journalRowFilterState,
        activity: Object.entries(event)
          .filter(([_key, enabled]) => enabled)
          .map(([key, _enabled]) => key)
          .filter((key): key is EmptinessFilterValue => isEmptinessFilterValue(key))
      });
    },
    onStudentCommentRowFilterChanged(event: Record<string, boolean>) {
      this.onChangeJournalRowFilterState({
        ...this.journalRowFilterState,
        studentComment: Object.entries(event)
          .filter(([_key, enabled]) => enabled)
          .map(([key, _enabled]) => key)
          .filter((key): key is EmptinessFilterValue => isEmptinessFilterValue(key))
      });
    },
    onTeacherCommentRowFilterChanged(event: Record<string, boolean>) {
      this.onChangeJournalRowFilterState({
        ...this.journalRowFilterState,
        teacherComment: Object.entries(event)
          .filter(([_key, enabled]) => enabled)
          .map(([key, _enabled]) => key)
          .filter((key): key is EmptinessFilterValue => isEmptinessFilterValue(key))
      });
    },
    onPublishStateRowFilterChanged(event: Record<string, boolean>) {
      this.onChangeJournalRowFilterState({
        ...this.journalRowFilterState,
        publishState: Object.entries(event)
          .filter(([_key, enabled]) => enabled)
          .map(([key, _enabled]) => key)
          .filter((key): key is PublishStateFilterValue => isPublishStateFilterValue(key))
      });
    }
  }
});
