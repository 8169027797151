import { Mutation, VuexModule, Module, Action } from "vuex-module-decorators";
import { store } from "@/store/Store";
import { Err } from "@/ts/objects/Err";
import { appStateStoreGlobal } from "@/store/AppStateStore";
import { SolanProject } from "@/ts/objects/solan/value/SolanProject";
import { Class } from "@/ts/objects/common/Class";
import { DataSlot, DataSlotProjectionView, DataSlotView } from "@/ts/DataSlot";
import log from "loglevel";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { SolanRepository } from "@/ts/repositories/SolanRepository";

/**
 * 教師用の、
 * SOLAN学習児童生徒用画面専用のストア。
 *
 * 選択中クラスの年度における、選択中の児童生徒の全プロジェクトを持つ。
 *
 * パスは、
 * - 学習記録画面では "/t/{classId}/solan/journals"
 * - 児童生徒画面では "/t/{classId}/solan/studentView/{studentUserId}/{projectId}"
 * となる。
 */
@Module({ dynamic: true, store, name: "solanStoreT", namespaced: true })
export class SolanStoreT extends VuexModule {
  studentUserId: string | null = null; // 児童生徒画面でのみ使用する。パスと同期するので、どんな値でもありうる。

  _allProjects: DataSlot<SolanProject[]> = new DataSlot<SolanProject[]>();

  get projects(): DataSlotView<SolanProject[]> {
    return new DataSlotView<SolanProject[]>(this._allProjects);
  }

  /**
   * プロジェクトを取得する。
   */
  get getProject(): (projectId: string | null) => DataSlotProjectionView<SolanProject[], SolanProject | null> {
    const schoolYear = appStateClass()?.schoolYear ?? null;
    const studentUserId = this.studentUserId;
    const _allProjects = this._allProjects;

    return function(projectId: string | null) {
      return new DataSlotProjectionView(_allProjects, allProjects => {
        if (schoolYear === null || studentUserId === null || projectId === null) return null;
        const project = allProjects.find(
          p => p.schoolYear === schoolYear && p.studentUserId === studentUserId && p.projectId === projectId
        );
        if (project === undefined) return null;
        return project;
      });
    };
  }

  /**
   * 児童生徒画面でのベースパスを取得する。
   */
  get studentViewBasePathOf(): (projectId: string | null) => string {
    const studentUserId = this.studentUserId ?? "-";
    const appStateStoreTeacherBasePath = appStateStoreGlobal.teacherBasePath;
    return function(projectId: string | null) {
      const _projectId = projectId ?? "-";
      const path = `${appStateStoreTeacherBasePath}/solan/studentView/${studentUserId}/${_projectId}`;
      log.debug(`SolanStoreT.studentViewBasePathOf = ${path}`);
      return path;
    };
  }

  @Mutation
  resetState() {
    this.studentUserId = null;
    this._allProjects.resetState();
  }

  @Mutation
  setStudentUserId(studentUserId: string) {
    this.studentUserId = studentUserId;
  }

  @Mutation
  startLoadingWith(loadingFn: () => Promise<SolanProject[] | null>) {
    log.debug(`SolanStoreT.startLoadingWith`);
    this._allProjects.startLoadingWith(loadingFn);
  }

  // Actionについて: https://github.com/championswimmer/vuex-module-decorators/blob/master/docs/pages/core/actions.md
  // Actionでは(vuexの仕様上？)複数引数を渡せないので、オブジェクトにまとめる: https://github.com/championswimmer/vuex-module-decorators/issues/27
  @Action
  public async reloadProjectsIfStudentChanged({
    userRepository,
    solanRepository,
    studentUserId
  }: {
    userRepository: UserRepository;
    solanRepository: SolanRepository;
    studentUserId: string;
  }) {
    log.debug(`SolanStoreT.reloadProjects: studentUserId=${studentUserId}, this.studentUserId=${this.studentUserId}`);
    if (studentUserId === this.studentUserId) return;
    const selectedClass = appStateStoreGlobal.teacherState?.selectedClass() ?? null;
    if (selectedClass === null) {
      return null;
    }
    const isStudentInClass = (await selectedClass.sortedClassStudents(userRepository))
      .map(s => s.studentUserId)
      .includes(studentUserId);
    if (!isStudentInClass) {
      return null;
    }
    this.context.commit("setStudentUserId", studentUserId);

    const loadingFn: () => Promise<SolanProject[] | null> = async function() {
      const resp = await solanRepository.listSolanProjects(studentUserId, selectedClass.schoolYear);

      if (resp instanceof Err) {
        return null;
      }
      log.debug(`SolanStoreT.reloadProjects: ${JSON.stringify(resp)}`);
      return resp;
    };

    log.debug(`SolanStoreT.reloadProjects: committing...`);
    this.context.commit("startLoadingWith", loadingFn);
  }

  /**
   * 選択中の (クラス, 児童生徒) の中で、1個目のプロジェクトへのパスを取得する。
   *
   * 児童生徒が未選択なら、未選択パス(/-/-)を返す。
   * 児童生徒は選択済だが、その子のプロジェクトが無ければ、プロジェクト未選択パス(/{studentUserId}/-)を返す。
   */
  get firstProjectPath(): DataSlotProjectionView<SolanProject[], string | null> {
    const schoolYear = appStateClass()?.schoolYear ?? null;
    const studentUserId = this.studentUserId;
    const appStateStoreTeacherBasePath = appStateStoreGlobal.teacherBasePath;
    return new DataSlotProjectionView<SolanProject[], string | null>(this._allProjects, allProjects => {
      if (schoolYear === null || studentUserId === null) {
        return `${appStateStoreTeacherBasePath}/solan/studentView/-/-`;
      }

      const project = allProjects.find(p => p.schoolYear === schoolYear && p.studentUserId === studentUserId);
      log.debug(`SolanStoreT.firstProjectId: project=${JSON.stringify(project)}`);

      const projectId = project?.projectId ?? "-";
      return `${appStateStoreTeacherBasePath}/solan/studentView/${studentUserId}/${projectId}`;
    });
  }
}

/**
 * 児童生徒・保護者用の、
 * SOLAN学習画面で選択できるすべてのプロジェクトと、その選択状態のストア。
 *
 * [児童生徒が使う場合]
 * 自分の(過去分含めた)全プロジェクトを持つ。
 * パスは、 "/solan/{schoolYear}/{projectId}" となる。
 *
 * [保護者が使う場合]
 * 選択中の児童生徒の(過去分含めた)全プロジェクトを持つ。
 * パスは、 "/solan/{schoolYear}/{projectId}" となる。
 */
@Module({ dynamic: true, store, name: "solanStoreS", namespaced: true })
export class SolanStoreS extends VuexModule {
  schoolYear: number | null = null;
  projectId: string | null = null;
  allProjects: DataSlot<SolanProject[]> = new DataSlot<SolanProject[]>();

  get project(): DataSlotProjectionView<SolanProject[], SolanProject | null> {
    log.debug(
      `SolanStoreS: schoolYear=${this.schoolYear}, projectId=${this.projectId}, allProjects=${JSON.stringify(
        this.allProjects
      )}`
    );

    const schoolYear = this.schoolYear;
    const projectId = this.projectId;

    return new DataSlotProjectionView(this.allProjects, allProjects => {
      if (schoolYear === null || projectId === null) return null;
      const project = allProjects.find(p => p.schoolYear === schoolYear && p.projectId === projectId);
      if (project === undefined) return null;
      return project;
    });
  }

  get projectsOfSchoolYear(): DataSlotProjectionView<SolanProject[], SolanProject[]> {
    const schoolYear = this.schoolYear;

    return new DataSlotProjectionView<SolanProject[], SolanProject[]>(this.allProjects, allProjects => {
      if (schoolYear === null) return [];
      return allProjects.filter(p => p.schoolYear === schoolYear);
    });
  }

  get studentInitPath(): string {
    const schoolYear = this.schoolYear ?? 2000;
    return `${appStateStoreGlobal.studentOrGuardianBasePath}/solan/${schoolYear}/init`;
  }

  get studentBasePath(): string {
    const schoolYear = this.schoolYear ?? 2000;
    const projectId = this.projectId ?? "-";
    return `${appStateStoreGlobal.studentOrGuardianBasePath}/solan/${schoolYear}/${projectId}`;
  }

  // 直接変更せずに、ナビゲーションガードからのみ変更すること。（それにより、パスと同期できる）
  @Mutation
  public setSchoolYear(schoolYear: number | null) {
    this.schoolYear = schoolYear;
  }

  // 直接変更せずに、ナビゲーションガードからのみ変更すること。（それにより、パスと同期できる）
  @Mutation
  public setProjectId(projectId: string | null) {
    this.projectId = projectId;
  }

  @Mutation
  startLoadingWith(loadingFn: () => Promise<SolanProject[] | null>) {
    this.allProjects.startLoadingWith(loadingFn);
  }

  @Action
  public async reloadProjects({
    solanRepository,
    studentUserId
  }: {
    solanRepository: SolanRepository;
    studentUserId: string;
  }) {
    const loadingFn: () => Promise<SolanProject[] | null> = async function() {
      const resp = await solanRepository.listSolanProjects(studentUserId, undefined);
      if (resp instanceof Err) {
        return null;
      }
      log.debug(`SolanStoreS.reloadProjects: ${JSON.stringify(resp)}`);
      return resp;
    };

    this.context.commit("startLoadingWith", loadingFn);
  }

  get firstProjectIdInSchoolYear(): DataSlotProjectionView<SolanProject[], string | null> {
    const schoolYear = this.schoolYear;

    return new DataSlotProjectionView(this.allProjects, allProjects => {
      if (schoolYear === null) return null;

      const project = allProjects.find(p => p.schoolYear === schoolYear);
      if (project === undefined) return null;

      return project.projectId;
    });
  }

  /**
   * 選択中の年度の中で、1個目のプロジェクトへのパスを取得する。
   */
  get firstProjectInSchoolYearPath(): DataSlotProjectionView<SolanProject[], string | null> {
    const schoolYear = this.schoolYear;

    return new DataSlotProjectionView(this.allProjects, allProjects => {
      if (schoolYear === null) {
        return `${appStateStoreGlobal.studentOrGuardianBasePath}/solan/-/-`;
      }

      const project = allProjects.find(p => p.schoolYear === schoolYear);
      log.debug(`SolanStoreS.pathOfFirstProjectOfSchoolYear: project=${JSON.stringify(project)}`);

      const projectId = project?.projectId ?? "-";
      return `${appStateStoreGlobal.studentOrGuardianBasePath}/solan/${schoolYear}/${projectId}`;
    });
  }
}

function appStateClass(): Class | null {
  return appStateStoreGlobal.teacherState?.selectedClass() ?? null;
}
