import { SolanProject } from "@/ts/objects/solan/value/SolanProject";
import { DisplayableErr, Err } from "@/ts/objects/Err";
import { EditableSolanProject } from "@/ts/objects/solan/editable/EditableSolanProject";
import {
  Journal as JournalResp,
  JournalFile as JournalFileResp,
  JournalWrite,
  Lookback as LookbackResp,
  LookbackWrite,
  Project as ProjectResp,
  ProjectWrite,
  Rubric as RubricResp,
  RubricWrite
} from "@/ts/api/solan-service";
import { EditableSolanLookback } from "@/ts/objects/solan/editable/EditableSolanLookback";
import { SolanRubric } from "@/ts/objects/solan/value/SolanRubric";
import { EditableSolanRubric } from "@/ts/objects/solan/editable/EditableSolanRubric";
import { EditableSolanJournal } from "@/ts/objects/solan/editable/EditableSolanJournal";
import { SolanJournalFile } from "@/ts/objects/solan/value/SolanJournalFile";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { Class } from "@/ts/objects/common/Class";
import { EditableSolanStudent } from "@/ts/objects/solan/editable/EditableSolanStudent";
import { SolanRepository } from "@/ts/repositories/SolanRepository";
import { getSolanTestData } from "@/test-tools/SolanTestData";
import { v4 as uuidv4 } from "uuid";
import {
  partialArgsToSolanTestDataSet,
  SolanProjectArg,
  SolanTestDataPartialArgs,
  SolanTestDataSet
} from "@/test-tools/SolanTestDataTypes";
import { isNullish } from "@/ts/utils";
import { messages } from "@/ts/const/Messages";

/**
 * SolanRepositoryのモック（スタブ）。
 *
 * Get/Listは、 {@link getSolanTestData} から検索して取得する。
 * Postは、もらったデータそのままで作成したふりをし、足りない項目は適当に埋める。実際には作成しない。
 * Patchは、とにかくただ成功する。実際には更新しない。
 * Deleteも、とにかくただ成功する。実際には削除しない。
 */
export class SolanRepositoryMock extends SolanRepository {
  constructor(testData?: SolanTestDataPartialArgs) {
    super();

    this.testData = isNullish(testData) ? getSolanTestData() : partialArgsToSolanTestDataSet(testData);
  }

  private testData: SolanTestDataSet;

  async getEditableSolanProject(this: this, projectId: string, savable: boolean): Promise<EditableSolanProject | Err> {
    const projectArg = Object.values(this.testData).find(p => p.projectId === projectId);
    if (projectArg === undefined) throw new Error("error on SolanRepositoryMock");
    return projectArg.toEditable(this, savable);
  }

  async listSolanProjects(
    this: this,
    studentUserId: string | undefined,
    schoolYear: number | undefined
  ): Promise<SolanProject[] | Err> {
    return Object.values(this.testData)
      .filter(
        p =>
          (studentUserId === undefined || p.studentUserId === studentUserId) &&
          (schoolYear === undefined || p.schoolYear === schoolYear)
      )
      .map(p => p.toObject());
  }

  async postProject(this: this, studentUserId: string, schoolYear: number): Promise<ProjectResp | Err> {
    const projectId = uuidv4();
    return {
      projectId,
      studentUserId,
      schoolYear,
      name: { value: "", hash: "" },
      description: { value: "", hash: "" },
      started: false,
      completed: false,
      lookback: {
        studentComment: { value: "", hash: "" },
        studentRating: "",
        teacherComment: { value: "", hash: "" },
        teacherRating: "",
        teacherInputPublished: false,
        guardianComment: { value: "", hash: "" }
      },
      studentInputLocked: false,
      guardianInputLocked: false,
      createdAt: "2000-01-01T00:00:00Z"
    };
  }

  /**
   * 受け取ったprojectWriteを、ほぼそのまま返す。
   * よって、patchで更新したところだけを書き換える使い方をする場合(EditableSolanProject等)は問題ないが、
   * 更新後に全データがあることを期待している箇所では、このモックはうまく働かないことになる。
   *
   * @param projectId
   * @param projectWrite
   */
  async patchProject(this: this, projectId: string, projectWrite: ProjectWrite): Promise<ProjectResp | Err> {
    return {
      projectId,
      studentUserId: projectWrite.studentUserId ?? "",
      schoolYear: projectWrite.schoolYear ?? 2000,
      name: projectWrite.name ?? { value: "", hash: "" },
      description: projectWrite.description ?? { value: "", hash: "" },
      started: projectWrite.started ?? false,
      completed: projectWrite.completed ?? false,
      lookback: {
        studentComment: projectWrite.lookback?.studentComment ?? { value: "", hash: "" },
        studentRating: projectWrite.lookback?.studentRating ?? "",
        teacherComment: projectWrite.lookback?.teacherComment ?? { value: "", hash: "" },
        teacherRating: projectWrite.lookback?.teacherRating ?? "",
        teacherInputPublished: projectWrite.lookback?.teacherInputPublished ?? false,
        guardianComment: projectWrite.lookback?.guardianComment ?? { value: "", hash: "" }
      },
      studentInputLocked: false,
      guardianInputLocked: false,
      createdAt: "2000-01-01T00:00:00Z"
    };
  }

  async deleteProject(this: this, _projectId: string): Promise<void | Err> {
    return;
  }

  async getEditableSolanLookback(
    projectId: string,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<EditableSolanLookback | Err> {
    const projectArg = Object.values(this.testData).find(l => l.projectId === projectId);
    if (projectArg === undefined) throw new Error("error on SolanRepositoryMock");
    return projectArg.toEditableLookback(this, teacherInputSavable, studentInputSavable, guardianInputSavable);
  }

  /**
   * 受け取ったLookbackWriteを、ほぼそのまま返す。
   * よって、patchで更新したところだけを書き換える使い方をする場合は問題ないが、
   * 更新後に全データがあることを期待している箇所では、このモックはうまく働かないことになる。
   *
   * @param _projectId
   * @param lookbackWrite
   */
  async patchLookback(this: this, _projectId: string, lookbackWrite: LookbackWrite): Promise<LookbackResp | Err> {
    return {
      studentComment: lookbackWrite.studentComment ?? { value: "", hash: "" },
      studentRating: lookbackWrite.studentRating ?? "",
      teacherComment: lookbackWrite.teacherComment ?? { value: "", hash: "" },
      teacherRating: lookbackWrite.teacherRating ?? "",
      teacherInputPublished: lookbackWrite.teacherInputPublished ?? false,
      guardianComment: lookbackWrite.guardianComment ?? { value: "", hash: "" }
    };
  }

  async listSolanRubrics(this: this, projectId: string): Promise<SolanRubric[] | Err> {
    return Object.values(this.testData)
      .flatMap(p => p.rubrics)
      .filter(r => r.projectId === projectId)
      .map(r => r.toObject());
  }

  async listEditableSolanRubrics(
    this: this,
    projectId: string,
    savable: boolean
  ): Promise<EditableSolanRubric[] | Err> {
    return Object.values(this.testData)
      .flatMap(p => p.rubrics)
      .filter(r => r.projectId === projectId)
      .map(r => r.toEditable(this, savable));
  }

  /**
   * ルーブリックを作成したふりをして、EditableSolanRubricとして返す。
   * 対応するプロジェクトがテストデータ(editableProject)として存在しないと、失敗する。
   *
   * @param projectId
   * @param process
   * @param savable
   */
  async postAndGetEditableSolanRubric(
    this: this,
    projectId: string,
    process: number,
    savable: boolean
  ): Promise<EditableSolanRubric | Err> {
    const project = this.findProjectFromTestData(projectId);
    const studentUserId = project.studentUserId;
    const rubricId = uuidv4();
    return new EditableSolanRubric(
      this,
      savable,
      projectId,
      rubricId,
      studentUserId,
      process,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "2000-01-01T00:00:00Z",
      false
    );
  }

  async patchRubric(
    this: this,
    projectId: string,
    rubricId: string,
    rubricWrite: RubricWrite
  ): Promise<RubricResp | Err> {
    const project = this.findProjectFromTestData(projectId);
    const studentUserId = project.studentUserId;

    return {
      rubricId,
      projectId,
      studentUserId,
      process: rubricWrite.process ?? 0,
      learningActivity: rubricWrite.learningActivity ?? { value: "", hash: "" },
      viewPointS: rubricWrite.viewPointS ?? { value: "", hash: "" },
      viewPointA: rubricWrite.viewPointA ?? { value: "", hash: "" },
      viewPointB: rubricWrite.viewPointB ?? { value: "", hash: "" },
      viewPointC: rubricWrite.viewPointC ?? { value: "", hash: "" },
      journal: {
        files: [],
        studentComment: rubricWrite.journal?.studentComment ?? { value: "", hash: "" },
        studentRating: rubricWrite.journal?.studentRating ?? "",
        teacherComment: rubricWrite.journal?.teacherComment ?? { value: "", hash: "" },
        teacherRating: rubricWrite.journal?.teacherRating ?? "",
        teacherInputPublished: rubricWrite.journal?.teacherInputPublished ?? false
      },
      createdAt: "2000-01-01T00:00:00Z",
      studentInputLocked: false
    };
  }

  async deleteRubric(this: this, _projectId: string, _rubricId: string): Promise<void | Err> {
    return;
  }

  async listEditableSolanJournals(
    this: this,
    projectId: string,
    teacherInputSavable: boolean,
    studentInputSavable: boolean
  ): Promise<{ rubrics: SolanRubric[]; editableJournals: EditableSolanJournal[] } | Err> {
    const rubricArgs = Object.values(this.testData)
      .flatMap(p => p.rubrics)
      .filter(r => r.projectId === projectId);
    const rubrics = rubricArgs.map(r => r.toObject());
    const editableJournals = rubricArgs.map(r => r.toEditableJournal(this, teacherInputSavable, studentInputSavable));
    return { rubrics, editableJournals };
  }

  async patchJournal(
    this: this,
    projectId: string,
    rubricId: string,
    journalWrite: JournalWrite
  ): Promise<JournalResp | Err> {
    return {
      files: [],
      studentComment: journalWrite.studentComment ?? { value: "", hash: "" },
      studentRating: journalWrite.studentRating ?? "",
      teacherComment: journalWrite.teacherComment ?? { value: "", hash: "" },
      teacherRating: journalWrite.teacherRating ?? "",
      teacherInputPublished: journalWrite.teacherInputPublished ?? false
    };
  }

  async listJournalFiles(this: this, projectId: string, rubricId: string): Promise<SolanJournalFile[] | Err> {
    return Object.values(this.testData)
      .flatMap(p => p.rubrics)
      .flatMap(r => r.journal.journalFiles)
      .filter(jf => jf.projectId === projectId && jf.rubricId === rubricId)
      .map(jf => jf.toObject());
  }

  async postJournalFile(
    this: this,
    projectId: string,
    rubricId: string,
    _file: any,
    _timeoutMillis: number
  ): Promise<JournalFileResp | Err> {
    const journalFileId = uuidv4();
    return {
      journalFileId: journalFileId,
      projectId,
      rubricId,
      type: "any",
      subtype: "any",
      mediaType: "application/octet-stream",
      filename: "myfilename",
      ext: "ext",
      gcsObjectPath: "gcs-object-path",
      thumbnailGcsObjectPath: "thumbnail-gcs-object-path",
      width: undefined,
      height: undefined,
      createdAt: "2000-01-01T00:00:00Z",
      updatedAt: "2000-01-01T00:00:00Z",
      hasThumbnail: false
    };
  }

  async deleteJournalFile(
    this: this,
    _projectId: string,
    _rubricId: string,
    _journalFileId: string
  ): Promise<void | Err> {
    return;
  }

  async listEditableSolanStudents(
    this: this,
    userRepository: UserRepository,
    cls: Class | null,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean
  ): Promise<EditableSolanStudent[] | Err> {
    if (cls === null) {
      return new DisplayableErr(
        `SolanRepositoryMock.loadEditableSolanStudents: class must not be null`,
        messages.pleaseSelectClass
      );
    }
    const classStudents = await cls.sortedClassStudents(userRepository);
    return classStudents.map(
      student =>
        new EditableSolanStudent(
          student.studentUserId,
          student.studentNumber,
          student.name,
          Object.values(this.testData)
            .filter(p => p.studentUserId === student.studentUserId)
            .map(p => p.toEditableStudentProject(this, teacherInputSavable, studentInputSavable, guardianInputSavable))
        )
    );
  }

  /**
   * テストデータから、プロジェクトを探す。
   * 見つからなければエラーとなる。
   * (主に、studentUserIdを取得するために使う。)
   *
   * @param projectId
   * @private
   */
  private findProjectFromTestData(projectId: string): SolanProjectArg {
    const project = Object.values(this.testData).find(p => p.projectId === projectId);
    if (project === undefined)
      throw new Error(`error on SolanRepositoryMock: no projects found for projectId ${projectId}`);
    return project;
  }
}
