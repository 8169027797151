















































import Vue, { PropType } from "vue";
import { ClassStudent } from "@/ts/objects/common/Class";
import { ProjectStore } from "@/store/ProjectStore";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { messages } from "@/ts/const/Messages";
import { AppStateStore } from "@/store/AppStateStore";
import { UserRepository } from "@/ts/repositories/UserRepository";

export default Vue.extend({
  name: "ProjectStudentViewT",
  components: { MessageView },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true }
  },
  created() {
    this.projectStore.project.getDataWithTimeout().then(project => {
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectProject };
        return;
      }
      if (!project.published) {
        this.messageView = { message: messages.projectUnpublished };
        return;
      }
    });
  },
  data(): { messageView: MessageViewParam | null } {
    return {
      messageView: null
    };
  },
  computed: {
    projectBasePath(): string {
      return this.projectStore.teacherBasePath;
    },
    selectedStudentUserId(): string | null {
      const id: string | null | undefined = this.$route.params["projectStudentUserId"];
      if (id === null || id === undefined || id.length === 0) return null;
      return id;
    },

    isCurrentTabJournals(): boolean {
      return this.$route.name === "T.Project.StudentView.Journals";
    },
    isCurrentTabLookback(): boolean {
      return this.$route.name === "T.Project.StudentView.Lookback";
    },
    currentTabPathSegment(): string {
      if (this.isCurrentTabJournals) return "journals";
      if (this.isCurrentTabLookback) return "lookback";
      return "";
    }
  },
  asyncComputed: {
    async selectedStudent(): Promise<ClassStudent | null> {
      const studentUserId = this.selectedStudentUserId;
      if (studentUserId === null) return null;
      return this.appStateStore.teacherState?.studentOfSelectedClass(this.userRepository, studentUserId) ?? null;
    },
    async classStudents(): Promise<ClassStudent[]> {
      return this.appStateStore.teacherState?.studentsOfSelectedClass(this.userRepository) ?? [];
    }
  }
});
