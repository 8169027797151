
































import Vue, { PropType } from "vue";
import { CurriculumListNECMonthPublishState } from "@/views/curriculum/teacher/CurriculumListNEC/CurriculumListNECModel";

export default Vue.extend({
  name: "CurriculumListNECHeaderPublishButton",
  props: {
    publishState: { type: String as PropType<CurriculumListNECMonthPublishState>, required: true },
    onClickPublishAll: { type: Function as PropType<() => void>, required: true },
    onClickUnpublishAll: { type: Function as PropType<() => void>, required: true }
  },
  data(): {
    active: boolean;
  } {
    return {
      active: false
    };
  },
  computed: {
    stateMessage(): string {
      switch (this.publishState) {
        case "none":
          return "すべて非公開";
        case "partial":
          return "一部公開中";
        case "all":
          return "すべて公開中";
        default:
          return "状態不明";
      }
    }
  },
  methods: {
    onClick() {
      this.active = !this.active;
    },
    onClickOutside() {
      this.active = false;
    },
    _onClickPublishAll() {
      this.active = false;
      this.onClickPublishAll();
    },
    _onClickUnpublishAll() {
      this.active = false;
      this.onClickUnpublishAll();
    }
  }
});
