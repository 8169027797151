







import Vue, { PropType } from "vue";
import SolanHeaderT from "@/views/solan/teacher/SolanHeaderT.vue";
import { AppStateStore } from "@/store/AppStateStore";

export default Vue.extend({
  name: "SolanT",
  components: { SolanHeaderT },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true }
  }
});
