

























import Vue, { PropType } from "vue";
import { CurriculumStoreS } from "@/store/CurriculumStoreS";
import CurriculumReadMenu from "@/views/curriculum/components/CurriculumReadMenu/CurriculumReadMenu.vue";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import { messages } from "@/ts/const/Messages";
import log from "loglevel";

export default Vue.extend({
  name: "CurriculumRead",
  components: { MessageView, CurriculumReadMenu },
  props: {
    curriculumStoreS: { type: Object as PropType<CurriculumStoreS>, required: true }
  },
  computed: {
    messageView(): MessageViewParam | null {
      const store = this.curriculumStoreS;
      log.debug(
        `store.tab=${store.tab}, store.selectedSchoolYear=${store.selectedSchoolYear}, store.grade=${JSON.stringify(
          store.grade
        )}, store.periodMode=${store.periodMode}`
      );
      if (store.tab === null || store.selectedSchoolYear === null || store.grade === null || store.periodMode === null)
        return { message: messages.pageNotFound };
      return null;
    }
  }
});
