





















import Vue, { PropType } from "vue";
import { Loadable } from "@/ts/Loadable";
import { NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { EECurriculumTree } from "@/ts/objects/curriculum/value/EECurriculum";
import CurriculumMenuTItem from "@/views/curriculum/teacher/CurriculumMenuT/components/CurriculumMenuTItem.vue";

export default Vue.extend({
  name: "CurriculumMenuT",
  components: { CurriculumMenuTItem },
  props: {
    /**
     * 選択中の教科のリソース名。
     */
    selectedCurriculum: { type: String as PropType<string | null> },

    /**
     * 数値評価教科。
     */
    neCurriculumTrees: { type: Object as PropType<Loadable<NECurriculumTree[]>>, required: true },

    /**
     * 文書評価教科。
     */
    eeCurriculumTrees: { type: Object as PropType<Loadable<EECurriculumTree[]>>, required: true },

    /**
     * 教科学習のパスを取得する関数。
     */
    getPath: {
      type: Function as PropType<(resourceName: string | null) => string>,
      required: true
    }
  },
  computed: {
    neCurriculumTreesData(): NECurriculumTree[] {
      return this.neCurriculumTrees.data ?? [];
    },
    eeCurriculumTreesData(): EECurriculumTree[] {
      return this.eeCurriculumTrees.data ?? [];
    }
  }
});
