import Vue from "vue";
import App from "./App.vue";
import { getRouter } from "./router/router";
import { InitializationResult, initializeApp } from "@/ts/InitializeApp";
import { initializeVue } from "@/ts/InitializeVue";
import VueRouter from "vue-router";
import { sync } from "vuex-router-sync";
import { store } from "@/store/Store";

const localDebugging = /^localhost:\d+$/.test(window.location.host);

initializeApp(localDebugging).then((initResult: InitializationResult) => {
  initializeVue();

  Vue.use(VueRouter);
  const router = getRouter(
    initResult.appStateStore,
    initResult.curriculumStoreS,
    initResult.curriculumStoreT,
    initResult.projectStore,
    initResult.projectInfoOnEditStore,
    initResult.solanStoreS,
    initResult.solanStoreT,
    initResult.solanProjectInfoOnEditStore,
    initResult.userRepository,
    initResult.activityRepository,
    initResult.projectRepository,
    initResult.solanRepository,
    initResult.curriculumRepository,
    initResult.logRepository
  );
  // https://github.com/vuejs/vuex-router-sync
  sync(store, router);

  new Vue({
    router,
    render: h =>
      h(App, {
        props: {
          authService: initResult.authService,
          appStateStore: initResult.appStateStore,
          projectStore: initResult.projectStore
        }
      })
  }).$mount("#app");
});
