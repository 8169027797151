






























































































































































































































































































































































































































































import Vue, { PropType } from "vue";
import AutoResizeTextarea from "@/components/AutoResizeTextarea.vue";
import FilterableHeaderButton, { CheckboxItem } from "@/components/FilterableHeaderButton/FilterableHeaderButton.vue";
import { ProjectStore } from "@/store/ProjectStore";
import { Err } from "@/ts/objects/Err";
import { NavigationGuardNext, Route } from "vue-router";
import { EditableProjectStudent } from "@/ts/objects/project/editable/EditableProjectStudent";
import { EditableProjectJournal } from "@/ts/objects/project/editable/EditableProjectJournal";
import { EditableProjectLookback } from "@/ts/objects/project/editable/EditableProjectLookback";
import { ProjectRubric } from "@/ts/objects/project/value/ProjectRubric";
import { messages } from "@/ts/const/Messages";
import MessageView, { MessageViewParam } from "@/components/MessageView.vue";
import LoadingBlock from "@/components/loading/LoadingBlock.vue";
import { emptySaveResult, flattenSaveResults, SaveResult } from "@/ts/objects/editable/SaveResult";
import { AppStateStore } from "@/store/AppStateStore";
import ErrorNotification, { ErrorNotificationParam } from "@/components/ErrorNotification.vue";
import JournalFilePreviewItem from "@/components/journalfile/JournalFilePreviewItem.vue";
import { unparse } from "papaparse";
import { format } from "date-fns";
import PopupMenuButton, { MenuButton } from "@/components/PopupMenuButton.vue";
import log from "loglevel";
import { PageLeaveService } from "@/ts/services/PageLeaveService";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";
import { UserRepository } from "@/ts/repositories/UserRepository";
import { downloadBlob, projectJournalRnameToId } from "@/ts/utils";
import JournalFilesView from "@/components/journalfile/JournalFilesView.vue";
import PublishSelectedRowsButton from "@/components/PublishSelectedRowsButton/PublishSelectedRowsButton.vue";
import SaveStateIndicator from "@/components/SaveStateIndicator/SaveStateIndicator.vue";
import { JournalFile } from "@/ts/objects/common/JournalFile";
import { ProjectJournalFile } from "@/ts/objects/project/value/ProjectJournalFile";

// TODO col-filterを切り替えた時、選択状態が解除されたりされなかったりする。なぜ？

type EmptyFilterCheckState = {
  empty: boolean;
  hasValue: boolean;
};

type RowFilterState = {
  student: Record<string, boolean>;
  learningActivity: Record<string, boolean>;
  teacherRating: EmptyFilterCheckState;
  teacherComment: EmptyFilterCheckState;
  guardianComment: EmptyFilterCheckState;
};

type ColFilterState = {
  learningActivity: boolean;
  journalFiles: boolean;
  ratingsAndComments: boolean;
  guardianComment: boolean;
};

/**
 * 児童生徒行。
 */
type StudentRow = {
  student: EditableProjectStudent;

  /**
   * 現在表示中の学習記録行のリスト。
   */
  journalRows: JournalRow[];

  /**
   * 現在表示中のふりかえり行。
   */
  lookbackRow: LookbackRow | null;
};

/**
 * 現在表示中の学習記録行。
 */
type JournalRow = {
  journal: EditableProjectJournal;
  selected: boolean;
};

/**
 * 現在表示中のふりかえり行。
 */
type LookbackRow = {
  lookback: EditableProjectLookback;
  selected: boolean;
};

enum WholeSelectionState {
  AllSelected,
  SomeSelected,
  NoneSelected
}

const studentColWidth = 140;
const learningActivityColWidth = 160;
const journalFilesColWidth = 160;
const studentCommentColWidth = 200;
const studentRatingColWidth = 60;
const studentInputHeaderWidth = studentCommentColWidth + studentRatingColWidth;
const teacherRatingColWidth = 60;
const teacherCommentColWidth = 200;
const guardianCommentColWidth = 160;
const teacherInputPublishedColWidth = 97;
const selectionColWidth = 60;

export default Vue.extend({
  name: "ProjectJournalsT",
  components: {
    SaveStateIndicator,
    PublishSelectedRowsButton,
    JournalFilesView,
    LoadingBlock,
    MessageView,
    ErrorNotification,
    AutoResizeTextarea,
    FilterableHeaderButton,
    JournalFilePreviewItem,
    PopupMenuButton
  },
  props: {
    appStateStore: { type: Object as PropType<AppStateStore>, required: true },
    projectStore: { type: Object as PropType<ProjectStore>, required: true },
    userRepository: { type: Object as PropType<UserRepository>, required: true },
    projectRepository: { type: Object as PropType<ProjectRepository>, required: true }
  },
  created() {
    const vm = this;
    this.periodicSaverId = window.setInterval(() => vm.saveAll(false), 15000);
    this.pageLeaveService = new PageLeaveService({
      onLeaveStart: async () => {
        await this.saveAll(true);
      },
      requirementToLeave: async () => !this.needSave,
      onRequirementUnmet: async () => {
        this.highlightUnsaved = true;
      }
    });

    this.projectStore.project.getDataWithTimeout().then(project => {
      if (project === null) {
        this.messageView = { message: messages.pleaseSelectProject };
        return;
      }
      if (!project.published) {
        this.messageView = { message: messages.projectUnpublished };
        return;
      }

      this.projectRepository
        .listEditableProjectStudents(
          this.userRepository,
          project.projectId,
          this.appStateStore.teacherState?.selectedClass() ?? null,
          true,
          true,
          false
        )
        .then(resp => {
          if (resp instanceof Err) {
            log.debug("Error loading journals!");
            this.messageView = { message: messages.failedToLoadData, fadeIn: true };
            return;
          }

          this.students = resp.editableStudents;
          this.studentRowFilterCheckboxItems = resp.editableStudents.map(student => {
            return {
              key: student.studentUserId,
              label: `${student.studentNumber}番 ${student.name}`
            };
          });
          this.rubrics = resp.rubrics;
          this.learningActivityRowFilterCheckboxItems = [
            ...resp.rubrics.map(rubric => {
              return {
                key: rubric.self,
                label: rubric.learningActivity
              };
            }),
            { key: "lookback", label: "プロジェクトのふりかえり" }
          ];
        });
    });
  },
  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    this.pageLeaveService!.tryLeave().then(ok => {
      if (!ok) {
        next(false);
        return;
      }
      next();
    });
  },
  beforeDestroy() {
    clearInterval(this.periodicSaverId);
  },
  data(): {
    messageView: MessageViewParam | null;

    periodicSaverId: number | undefined;

    extraMenuItems: MenuButton[];

    students: EditableProjectStudent[] | null;
    rubrics: ProjectRubric[] | null;

    studentRowFilterCheckboxItems: CheckboxItem[];
    learningActivityRowFilterCheckboxItems: CheckboxItem[];
    teacherRatingRowFilterCheckboxItems: CheckboxItem[];
    teacherCommentRowFilterCheckboxItems: CheckboxItem[];
    guardianCommentRowFilterCheckboxItems: CheckboxItem[];

    rowFilterState: RowFilterState;
    colFilterState: ColFilterState;

    studentRows: StudentRow[];

    filesViewJournal: EditableProjectJournal | null;

    highlightUnsaved: boolean;

    pageLeaveService: PageLeaveService | null;
  } {
    return {
      messageView: null,

      periodicSaverId: undefined,

      extraMenuItems: [new MenuButton("exportCsv", "現在の状態をCSV出力", ["fas", "download"])],

      students: null,
      rubrics: null,

      studentRowFilterCheckboxItems: [],
      learningActivityRowFilterCheckboxItems: [],
      teacherRatingRowFilterCheckboxItems: [
        { key: "empty", label: "未入力" },
        { key: "hasValue", label: "入力済" }
      ],
      teacherCommentRowFilterCheckboxItems: [
        { key: "empty", label: "未入力" },
        { key: "hasValue", label: "入力済" }
      ],
      guardianCommentRowFilterCheckboxItems: [
        { key: "empty", label: "未入力" },
        { key: "hasValue", label: "入力済" }
      ],

      /**
       * 行フィルタの状態。
       */
      rowFilterState: {
        student: {},
        learningActivity: {},
        teacherRating: { empty: true, hasValue: true },
        teacherComment: { empty: true, hasValue: true },
        guardianComment: { empty: true, hasValue: true }
      },

      /**
       * 列フィルタの状態。
       */
      colFilterState: {
        learningActivity: true,
        journalFiles: true,
        ratingsAndComments: true,
        guardianComment: true
      },

      /**
       * 現在表示中の児童生徒行のリスト。
       */
      studentRows: [],

      filesViewJournal: null,

      highlightUnsaved: false,

      pageLeaveService: null
    };
  },
  computed: {
    styles(): any {
      return {
        "--studentColWidth": `${studentColWidth}px`,
        "--learningActivityColWidth": `${learningActivityColWidth}px`,
        "--journalFilesColWidth": `${journalFilesColWidth}px`,
        "--studentCommentColWidth": `${studentCommentColWidth}px`,
        "--studentRatingColWidth": `${studentRatingColWidth}px`,
        "--studentInputHeaderWidth": `${studentInputHeaderWidth}px`,
        "--teacherRatingColWidth": `${teacherRatingColWidth}px`,
        "--teacherCommentColWidth": `${teacherCommentColWidth}px`,
        "--guardianCommentColWidth": `${guardianCommentColWidth}px`,
        "--teacherInputPublishedColWidth": `${teacherInputPublishedColWidth}px`,
        "--selectionColWidth": `${selectionColWidth}px`,
        "--currentJournalRowWidth": `${this.currentJournalRowWidth}px`,
        "--currentTableWidth": `${this.currentTableWidth}px`
      };
    },
    currentJournalRowWidth(): number {
      let width = teacherInputPublishedColWidth + selectionColWidth;
      if (this.colFilterState.learningActivity) {
        width += learningActivityColWidth;
      }
      if (this.colFilterState.journalFiles) {
        width += journalFilesColWidth;
      }
      if (this.colFilterState.ratingsAndComments) {
        width += studentInputHeaderWidth + teacherRatingColWidth + teacherCommentColWidth;
      }
      if (this.colFilterState.guardianComment) {
        width += guardianCommentColWidth;
      }
      return width;
    },
    currentTableWidth(): number {
      return studentColWidth + this.currentJournalRowWidth;
    },
    projectBasePath(): string {
      return this.projectStore.teacherBasePath;
    },
    needSave(): boolean {
      log.debug(`ProjectJournalsT: needSave`);
      const students = this.students;
      if (students === null) return false;
      const needSave = students.some(s => s.needSave());
      log.debug(`ProjectJournalsT: needSave=${needSave}`);
      return needSave;
    },
    currentErrors(): ErrorNotificationParam[] {
      return (
        this.students?.flatMap(s =>
          s.currentErrors().map(e => {
            return {
              heading: `${s.studentNumber}番 ${s.name}`,
              text: `${e.message}`
            };
          })
        ) ?? []
      );
    },
    wholeSelectionState(): WholeSelectionState {
      // TODO この程度重くないとは思うが、要確認？
      const values = this.studentRows.flatMap(sr => {
        const journalSelections = sr.journalRows.map(jr => jr.selected);
        const lookbackSelections = sr.lookbackRow !== null ? [sr.lookbackRow.selected] : [];
        return [...journalSelections, ...lookbackSelections];
      });
      let foundSelected = false;
      let foundUnselected = false;
      for (const v of values) {
        if (v === true) {
          foundSelected = true;
        } else {
          foundUnselected = true;
        }
      }

      if (foundSelected && !foundUnselected) {
        return WholeSelectionState.AllSelected;
      } else if (foundSelected && foundUnselected) {
        return WholeSelectionState.SomeSelected;
      } else {
        return WholeSelectionState.NoneSelected;
      }
    },
    allSelected(): boolean {
      return this.wholeSelectionState === WholeSelectionState.AllSelected;
    },
    someSelected(): boolean {
      return this.wholeSelectionState === WholeSelectionState.SomeSelected;
    },
    noneSelected(): boolean {
      return this.wholeSelectionState === WholeSelectionState.NoneSelected;
    }
  },
  methods: {
    async saveAll(force: boolean): Promise<SaveResult> {
      const students = this.students;
      if (students === null) return emptySaveResult();
      log.debug("SAVING!");
      return flattenSaveResults(await Promise.all(students.map(s => s.saveAllChanges(force))));
    },
    onStudentRowFilterChanged(state: Record<string, boolean>) {
      this.rowFilterState.student = state;
      this.applyRowFilter();
    },
    onLearningActivityRowFilterChanged(state: Record<string, boolean>) {
      this.rowFilterState.learningActivity = state;
      this.applyRowFilter();
    },
    onTeacherRatingRowFilterChanged(state: EmptyFilterCheckState) {
      this.rowFilterState.teacherRating = state;
      this.applyRowFilter();
    },
    onTeacherCommentRowFilterChanged(state: EmptyFilterCheckState) {
      this.rowFilterState.teacherComment = state;
      this.applyRowFilter();
    },
    onGuardianCommentRowFilterChanged(state: EmptyFilterCheckState) {
      this.rowFilterState.guardianComment = state;
      this.applyRowFilter();
    },
    applyRowFilter() {
      const students = this.students;
      if (students === null) return [];

      // 画面初期表示時に何度も呼ばれるので、もし重ければなんとかする。
      const rowFilterState = this.rowFilterState;

      const filteredStudents = students.filter(s => rowFilterState.student[s.studentUserId] === true);
      const filteredStudentRows = filteredStudents
        .map(student => {
          return {
            student: student,
            journalRows: student.projectJournals
              .filter(j => shouldDisplayJournal(j, rowFilterState))
              .map(j => {
                return {
                  journal: j,
                  selected: false
                };
              }),
            lookbackRow: shouldDisplayLookback(student.projectLookback, rowFilterState)
              ? {
                  lookback: student.projectLookback,
                  selected: false
                }
              : null
          };
        })
        .filter(fjs => fjs.journalRows.length > 0 || fjs.lookbackRow !== null);
      log.debug(`applyRowFilter!`);
      this.studentRows = filteredStudentRows;
    },
    toggleAllSelections() {
      // 参考: https://github.com/vuejs/vue/issues/9535#issuecomment-466217819
      // TODO WindowsのChrome以外でもちゃんと動くか確認。
      setTimeout(() => {
        const changeTo = this.noneSelected ? true : false;
        for (const sr of this.studentRows) {
          for (const jr of sr.journalRows) {
            jr.selected = changeTo;
          }
          if (sr.lookbackRow !== null) {
            sr.lookbackRow.selected = changeTo;
          }
        }
      });
    },
    publishSelectedRows() {
      if (this.noneSelected) return;
      changeSelectedRowsPublishState(true, this.studentRows);
      this.saveAll(false);
    },
    unpublishSelectedRows() {
      if (this.noneSelected) return;
      changeSelectedRowsPublishState(false, this.studentRows);
      this.saveAll(false);
    },
    setFilesView(studentUserId: string | null, journalRname: string | null) {
      if (studentUserId === null || journalRname === null) {
        this.filesViewJournal = null;
        return;
      }

      const studentRow: StudentRow | undefined = this.studentRows.find(s => s.student.studentUserId === studentUserId);
      if (studentRow === undefined) {
        this.filesViewJournal = null;
        return;
      }

      const journalRow = studentRow.journalRows.find(j => j.journal.self === journalRname);
      if (journalRow === undefined) {
        this.filesViewJournal = null;
        return;
      }
      this.filesViewJournal = journalRow.journal;
    },
    isMyFilesViewOpen(journalRname: string): boolean {
      const filesViewJournal = this.filesViewJournal;
      if (filesViewJournal === null) return false;
      return filesViewJournal.self === journalRname;
    },
    selectExtraMenu(menuItem: string) {
      switch (menuItem) {
        case "exportCsv":
          this.exportCsv();
          break;
      }
    },
    async uploadJournalFile(file: File, journal: EditableProjectJournal) {
      const ids = projectJournalRnameToId(journal.self);
      if (ids === null) return;
      const [projectId, rubricId, journalId] = ids;
      const resp = await this.projectRepository.postJournalFile(projectId, rubricId, journalId, file, 30000);
      log.debug(`uploadFile: resp=${JSON.stringify(resp)}`);

      await journal.reloadJournalFiles();
    },
    async deleteJournalFile(journalFile: JournalFile, journal: EditableProjectJournal) {
      if (!(journalFile instanceof ProjectJournalFile)) return;

      const ids = projectJournalRnameToId(journalFile.journal);
      if (ids === null) {
        log.info(`invalid journal resourceName: ${journalFile.journal}`);
        return;
      }
      const [projectId, rubricId, journalId] = ids;
      const resp = await this.projectRepository.deleteJournalFile(
        projectId,
        rubricId,
        journalId,
        journalFile.journalFileId
      );
      log.debug(`deleteFile: resp=${JSON.stringify(resp)}`);

      await journal.reloadJournalFiles();
    },
    exportCsv() {
      const projectName = this.projectStore.project.data?.name;
      if (projectName === undefined) return;
      exportCsv(this.studentRows, this.colFilterState, projectName);
    }
  }
});

function changeSelectedRowsPublishState(changeTo: boolean, studentRows: StudentRow[]) {
  studentRows.forEach(sr => {
    sr.journalRows
      .filter(jr => jr.selected)
      .forEach(jr => {
        jr.journal.teacherInputPublished = changeTo;
      });
    if (sr.lookbackRow !== null && sr.lookbackRow.selected) {
      sr.lookbackRow.lookback.teacherInputPublished = changeTo;
    }
  });
}

function shouldDisplayJournal(journal: EditableProjectJournal, rowFilterState: RowFilterState): boolean {
  const learningActivityOk = rowFilterState.learningActivity[journal.rubric];
  const teacherRatingOk =
    (rowFilterState.teacherRating.empty && journal.teacherRating === "") ||
    (rowFilterState.teacherRating.hasValue && journal.teacherRating !== "");
  const teacherCommentOk =
    (rowFilterState.teacherComment.empty && journal.teacherComment === "") ||
    (rowFilterState.teacherComment.hasValue && journal.teacherComment !== "");
  const guardianCommentOk = rowFilterState.guardianComment.empty;
  return learningActivityOk && teacherRatingOk && teacherCommentOk && guardianCommentOk;
}

function shouldDisplayLookback(lookback: EditableProjectLookback, rowFilterState: RowFilterState): boolean {
  const learningActivityOk = rowFilterState.learningActivity["lookback"];
  const teacherRatingOk =
    (rowFilterState.teacherRating.empty && lookback.teacherRating === "") ||
    (rowFilterState.teacherRating.hasValue && lookback.teacherRating !== "");
  const teacherCommentOk =
    (rowFilterState.teacherComment.empty && lookback.teacherComment === "") ||
    (rowFilterState.teacherComment.hasValue && lookback.teacherComment !== "");
  const guardianCommentOk =
    (rowFilterState.guardianComment.empty && lookback.guardianComment === "") ||
    (rowFilterState.guardianComment.hasValue && lookback.guardianComment !== "");
  return learningActivityOk && teacherRatingOk && teacherCommentOk && guardianCommentOk;
}

function exportCsv(studentRows: StudentRow[], colFilterState: ColFilterState, projectName: string) {
  const csvRows = studentRows.flatMap(s => {
    const journalCsvRows = s.journalRows.map(j => {
      return {
        番号: s.student.studentNumber,
        氏名: s.student.name,
        学習活動: j.journal.rubricLearningActivity,
        学習の記録: j.journal.journalFiles.length,
        "学習のふりかえり(コメント)": j.journal.studentComment,
        "学習のふりかえり(評価)": j.journal.studentRating,
        先生評価: j.journal.teacherRating,
        先生から: j.journal.teacherComment,
        保護者から: "",
        状態: j.journal.teacherInputPublished ? "公開" : "非公開"
      };
    });
    if (s.lookbackRow === null) {
      return journalCsvRows;
    }

    const lookbackRow = {
      番号: s.student.studentNumber,
      氏名: s.student.name,
      学習活動: "ふりかえり",
      学習の記録: 0,
      "学習のふりかえり(コメント)": s.lookbackRow.lookback.studentComment,
      "学習のふりかえり(評価)": s.lookbackRow.lookback.studentRating,
      先生評価: s.lookbackRow.lookback.teacherRating,
      先生から: s.lookbackRow.lookback.teacherComment,
      保護者から: s.lookbackRow.lookback.guardianComment,
      状態: s.lookbackRow.lookback.teacherInputPublished ? "公開" : "非公開"
    };

    return [...journalCsvRows, lookbackRow];
  });
  const columnNames = [
    "番号",
    "氏名",
    colFilterState.learningActivity ? "学習活動" : undefined,
    colFilterState.journalFiles ? "学習の記録" : undefined,
    colFilterState.ratingsAndComments ? "学習のふりかえり(コメント)" : undefined,
    colFilterState.ratingsAndComments ? "学習のふりかえり(評価)" : undefined,
    colFilterState.ratingsAndComments ? "先生評価" : undefined,
    colFilterState.ratingsAndComments ? "先生から" : undefined,
    colFilterState.guardianComment ? "保護者から" : undefined,
    "状態"
  ].filter((c: string | undefined): c is string => c !== undefined);

  // BOMはエクセル対策。参考: https://qiita.com/wadahiro/items/eb50ac6bbe2e18cf8813
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, unparse(csvRows, { columns: columnNames })], { type: "text/plain" });
  downloadBlob(blob, `${projectName}_${format(new Date(), "yyyyMMdd'T'HHmmss")}.csv`);
}
