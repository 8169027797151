




































import Vue, { PropType } from "vue";
import { downloadFileByUrl } from "@/ts/utils/AppUtil";
import { format } from "date-fns";

export default Vue.extend({
  name: "ImageModal",
  props: {
    url: { type: String },
    height: { type: Number, default: 100 },
    width: { type: Number, default: 100 },
    filename: { type: String },
    type: { type: String },
    mediatype: { type: String },
    datetime: { type: Date as PropType<Date> }
  },
  created() {
    // log.debug(`width=${this.width},height=${this.height}`);
  },
  computed: {
    fileTypeIcon(): [string, string] {
      return ["fas", "file-alt"];
    },
    isImage(): boolean {
      return this.type === "image";
    },
    isVideo(): boolean {
      return this.type === "video";
    },
    isAudio(): boolean {
      return this.type === "audio";
    },
    isOverlay(): boolean {
      return !(this.isVideo || this.isAudio);
    },
    mediaType(): string {
      return this.mediatype;
    },
    fileUrl(): string | null {
      return this.url;
    },
    imageWidth(): number | null {
      return this.width ?? null;
    },
    imageHeight(): number | null {
      return this.height ?? null;
    }
  },
  methods: {
    async download() {
      const url = this.fileUrl;
      if (url === null) return;

      await downloadFileByUrl(url, this.filename, this.mediaType);
    },
    formatDate(date: Date): string {
      return format(date, "yyyy/MM/dd HH:mm");
    }
  }
});
