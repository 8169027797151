import { RubricWrite } from "@/ts/api/project-service";
import { Err, InternalErr } from "@/ts/objects/Err";
import { EditableProjectRubric } from "@/ts/objects/project/editable/EditableProjectRubric";
import { v4 as uuidv4 } from "uuid";
import log from "loglevel";
import { ProjectRepository } from "@/ts/repositories/ProjectRepository";

export class LocalEditableProjectRubric {
  private readonly repo: ProjectRepository;

  readonly projectId: string;
  private readonly localUuid: string; // このオブジェクトを区別するためのUUID。実際に作られるルーブリックのIDとは無関係。
  learningActivity: string;
  viewPointS: string;
  viewPointA: string;
  viewPointB: string;
  viewPointC: string;

  private saveRequestSent = false;

  constructor(repo: ProjectRepository, projectId: string) {
    this.repo = repo;

    this.projectId = projectId;
    this.localUuid = uuidv4();
    this.learningActivity = "";
    this.viewPointS = "";
    this.viewPointA = "";
    this.viewPointB = "";
    this.viewPointC = "";
  }

  async save(): Promise<EditableProjectRubric | Err> {
    if (this.saveRequestSent) {
      log.debug("LocalEditableProjectRubric: Save request already sent.");
      return new InternalErr("LocalEditableProjectRubric: Save request already sent.");
    }
    this.saveRequestSent = true;

    const req: RubricWrite = {
      learningActivity: { value: this.learningActivity, hash: "" },
      viewPointS: { value: this.viewPointS, hash: "" },
      viewPointA: { value: this.viewPointA, hash: "" },
      viewPointB: { value: this.viewPointB, hash: "" },
      viewPointC: { value: this.viewPointC, hash: "" }
    };
    const r = await this.repo.postRubric(this.projectId, req);
    if (r instanceof Err) {
      this.saveRequestSent = false;
      return r;
    }

    return new EditableProjectRubric(
      this.repo,
      true,
      r.self,
      r.orderNum,
      r.learningActivity.value,
      r.learningActivity.hash,
      r.viewPointS.value,
      r.viewPointS.hash,
      r.viewPointA.value,
      r.viewPointA.hash,
      r.viewPointB.value,
      r.viewPointB.hash,
      r.viewPointC.value,
      r.viewPointC.hash
    );
  }
}
