import { EditableObject } from "@/ts/objects/editable/EditableObject";
import {
  EditableBoolean,
  EditableHashedString,
  EditableString
} from "@/ts/objects/editable/value/EditablePrimitiveValue";
import { DisplayableErr, Err } from "../../Err";
import { Editable } from "@/ts/objects/editable/Editable";
import { messages } from "@/ts/const/Messages";
import { SolanRepository } from "@/ts/repositories/SolanRepository";

/**
 * 編集可能なSOLANふりかえり。
 *
 * SOLANプロジェクトの一部でしかないので、リソース名は、無い。
 */
export class EditableSolanLookback extends EditableObject {
  readonly projectId: string;
  readonly studentUserId: string;
  readonly _studentComment: EditableHashedString;
  readonly _studentRating: EditableString;
  readonly _teacherComment: EditableHashedString;
  readonly _teacherRating: EditableString;
  readonly _teacherInputPublished: EditableBoolean;
  readonly _guardianComment: EditableHashedString;
  readonly studentInputLocked: boolean;
  readonly guardianInputLocked: boolean;

  constructor(
    repo: SolanRepository,
    teacherInputSavable: boolean,
    studentInputSavable: boolean,
    guardianInputSavable: boolean,
    projectId: string,
    studentUserId: string,
    studentComment: string,
    studentCommentHash: string,
    studentRating: string,
    teacherComment: string,
    teacherCommentHash: string,
    teacherRating: string,
    teacherInputPublished: boolean,
    guardianComment: string,
    guardianCommentHash: string,
    studentInputLocked: boolean,
    guardianInputLocked: boolean
  ) {
    super();
    this.projectId = projectId;
    this.studentUserId = studentUserId;
    this._studentComment = new EditableHashedString(
      "studentComment",
      studentComment,
      studentCommentHash,
      studentInputSavable,
      async ({ value, hash }) => {
        const resp = await repo.patchLookback(projectId, { studentComment: { value, hash } });
        if (resp instanceof Err) return resp;
        return [resp.studentComment.value, resp.studentComment.hash];
      }
    );
    this._studentRating = new EditableString("studentRating", studentRating, studentInputSavable, async value => {
      const resp = await repo.patchLookback(projectId, { studentRating: value });
      if (resp instanceof Err) return resp;
      return resp.studentRating;
    });
    this._teacherComment = new EditableHashedString(
      "teacherComment",
      teacherComment,
      teacherCommentHash,
      teacherInputSavable,
      async ({ value, hash }) => {
        const resp = await repo.patchLookback(projectId, { teacherComment: { value, hash } });
        if (resp instanceof Err) return resp;
        if (resp.teacherComment === undefined)
          return new DisplayableErr(`invalid response: ${JSON.stringify(resp)}`, messages.failedToLoadData);
        return [resp.teacherComment.value, resp.teacherComment.hash];
      }
    );
    this._teacherRating = new EditableString("teacherRating", teacherRating, teacherInputSavable, async value => {
      const resp = await repo.patchLookback(projectId, { teacherRating: value });
      if (resp instanceof Err) return resp;
      if (resp.teacherRating === undefined)
        return new DisplayableErr(`invalid response: ${JSON.stringify(resp)}`, messages.failedToLoadData);
      return resp.teacherRating;
    });
    this._teacherInputPublished = new EditableBoolean(
      "teacherInputPublished",
      teacherInputPublished,
      teacherInputSavable,
      async value => {
        const resp = await repo.patchLookback(projectId, { teacherInputPublished: value });
        if (resp instanceof Err) return resp;
        return resp.teacherInputPublished;
      }
    );
    this._guardianComment = new EditableHashedString(
      "guardianComment",
      guardianComment,
      guardianCommentHash,
      guardianInputSavable,
      async ({ value, hash }) => {
        const resp = await repo.patchLookback(projectId, { guardianComment: { value, hash } });
        if (resp instanceof Err) return resp;
        return [resp.guardianComment.value, resp.guardianComment.hash];
      }
    );
    this.studentInputLocked = studentInputLocked;
    this.guardianInputLocked = guardianInputLocked;
  }

  protected allEditables(): Editable[] {
    return [
      this._studentComment,
      this._studentRating,
      this._teacherComment,
      this._teacherRating,
      this._teacherInputPublished,
      this._guardianComment
    ];
  }

  get studentComment(): string {
    return this._studentComment.value;
  }

  set studentComment(value: string) {
    this._studentComment.value = value;
  }

  get studentRating(): string {
    return this._studentRating.value;
  }

  set studentRating(value: string) {
    this._studentRating.value = value;
  }

  get teacherRating(): string {
    return this._teacherRating.value;
  }

  set teacherRating(value: string) {
    this._teacherRating.value = value;
  }

  get teacherComment(): string {
    return this._teacherComment.value;
  }

  set teacherComment(value: string) {
    this._teacherComment.value = value;
  }

  get teacherInputPublished(): boolean {
    return this._teacherInputPublished.value;
  }

  set teacherInputPublished(value: boolean) {
    this._teacherInputPublished.value = value;
  }

  get guardianComment(): string {
    return this._guardianComment.value;
  }

  set guardianComment(value: string) {
    this._guardianComment.value = value;
  }
}
