























import Vue, { PropType } from "vue";
import CurriculumReadNECYearlyViewPointRow from "@/views/curriculum/student/CurriculumReadNECYearly/components/CurriculumReadNECYearlyViewPointRow/CurriculumReadNECYearlyViewPointRow.vue";
import CurriculumReadMainHeader from "@/views/curriculum/student/components/CurriculumReadMainHeader/CurriculumReadMainHeader.vue";
import { NECurriculumTree } from "@/ts/objects/curriculum/value/NECurriculum";
import { CurriculumPeriodMode } from "@/ts/objects/curriculum/value/CurriculumPeriodMode";
import { INECEvaluation } from "@/ts/objects/curriculum/INECEvaluation";
import { MonthValue } from "@/ts/utils";
import { Loadable } from "@/ts/Loadable";

export default Vue.extend({
  name: "CurriculumStudentViewNECYearly",
  components: { CurriculumReadNECYearlyViewPointRow, CurriculumReadMainHeader },
  props: {
    /**
     * 児童生徒ID。
     */
    studentUserId: { type: String, required: true },

    /**
     * 数値評価教科。
     */
    neCurriculumTree: { type: Object as PropType<NECurriculumTree>, required: true },

    /**
     * 現在表示中の教科・児童生徒配下の評価。
     */
    evaluations: { type: Object as PropType<Loadable<INECEvaluation[]>>, required: true },

    /**
     * 教科学習のパスを取得する関数。
     */
    getPath: {
      type: Function as PropType<
        (
          periodMode: CurriculumPeriodMode | null,
          month: MonthValue | null | undefined,
          resourceName: string | null
        ) => string
      >,
      required: true
    }
  }
});
